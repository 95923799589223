import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Container,
  Box,
  Card,
  CardContent,
  Divider,
  CardHeader,
  IconButton,
  Checkbox,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";

import { useStyles } from "../../Styles";
import moment from "moment";
import SettingsIcon from "@mui/icons-material/Settings";

const StageBlock = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const tdStyle = {
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "5px",
    paddingBottom: "5px",
  };
  const tableStyle = {
    width: "100%",
  };
  const trStyle = {};

  const borderStyle = [
    {
      border: "2px solid " + props.bgColor.text02.fontColor,
      borderRadius: "5px 5px 5px 5px",
      padding: "5px 10px",
    },
    {
      border: "2px solid " + props.bgColor.text03.fontColor,
      borderRadius: "5px 5px 5px 5px",
      padding: "5px 10px",
    },
  ];

  const title = {
    fontSize: "17px",
    fontWeight: "bold",
    padding: "2px 2px 2px 0px",
    color: props.bgColor.text01.fontColor,
  };

  const hint = {
    fontSize: "13px",
    fontWeight: "bold",
    padding: "2px 2px 2px 0px",
    color: props.bgColor.text02.fontColor,
  };

  const menushow = {
    fontSize: "15px",
    padding: "2px 2px 2px 2px",
    marginLeft: "10px",
    color: props.bgColor.text03.fontColor,
  };

  const menudisable = {
    fontSize: "15px",
    padding: "2px 2px 2px 2px",
    marginLeft: "10px",
    color: props.bgColor.text02.fontColor,
  };

  return (
    <table style={tableStyle}>
      <tbody>
        <tr style={trStyle}>
          <td style={tdStyle}>
            <div style={borderStyle[+props.isusable]}>
              <div style={title}>
                <Checkbox
                  //{...label}
                  icon={<CircleIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  disabled
                  checked={props.isusable}
                  style={{
                    color:
                      props.isusable == 0
                        ? props.bgColor.text02.fontColor
                        : props.bgColor.text03.fontColor,
                  }}
                />
                {props.title}{" "}
              </div>
              {props.hint ? (
                <div style={hint} key={"a"} align="center">
                  {props.hint}{" "}
                </div>
              ) : (
                <></>
              )}
              {props.isusable ? (
                <>
                  {props.both.map((item, index) => (
                    <>
                      <Button
                        style={menushow}
                        onClick={() => {
                          item.excute();
                        }}
                      >
                        {item.text}
                      </Button>
                      <br />
                    </>
                  ))}
                  {props.menu.map((item, index) => (
                    <>
                      <Button
                        style={menushow}
                        onClick={() => {
                          item.excute();
                        }}
                      >
                        {item.text}
                      </Button>
                      <br />
                    </>
                  ))}
                </>
              ) : (
                <>
                  {props.both.map((item, index) => (
                    <>
                      <Button
                        style={menushow}
                        onClick={() => {
                          item.excute();
                        }}
                      >
                        {item.text}
                      </Button>
                      <br />
                    </>
                  ))}
                  {props.subscribe.map((item, index) => (
                    <>
                      <Button
                        style={menushow}
                        onClick={() => {
                          item.excute();
                        }}
                      >
                        {item.text}
                      </Button>
                      <br />
                    </>
                  ))}

                  {props.menu.map((item, index) => (
                    <>
                      <Button style={menudisable} disabled>
                        + {item.text}
                      </Button>
                      <br />
                    </>
                  ))}
                </>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default StageBlock;

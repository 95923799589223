import { IconButton } from "@mui/material";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";

export default function OpinionPictureModal(props) {
  return (
    <>
      <h2 id="transition-modal-title" align="center">
        意見
        {props.adminFlag ? (
          <>
            <IconButton
              aria-label="delete"
              size="small"
              disabled={props.isCreatedAI}
              onClick={() => {
                props.setIsPointCheck(true);
                props.setPointPattarn(3);
              }}
            >
              <HdrAutoIcon fontSize="inherit" />
            </IconButton>
          </>
        ) : (
          <></>
        )}
      </h2>
    </>
  );
}

import { Button } from "@mui/material";

export default function RoleButtonMake(props) {
  const buttonFontsize = 8;
  return (
    <div>
      {props.roleDatas.notlogin.flag ? (
        <>
          <Button
            disabled={props.roleDatas.notlogin.disabled}
            size="large"
            color={props.roleDatas.notlogin.color}
            variant="contained"
            onClick={() => props.roleDatas.notlogin.excute()}
            style={{ fontSize: buttonFontsize }}
          >
            {props.roleDatas.notlogin.text}
          </Button>
        </>
      ) : (
        <></>
      )}
      {props.roleDatas.standard.flag ? (
        <>
          <Button
            disabled={props.roleDatas.standard.disabled}
            size="large"
            color={props.roleDatas.standard.color}
            variant="contained"
            style={{ fontSize: buttonFontsize }}
            onClick={() => props.roleDatas.standard.excute()}
          >
            {props.roleDatas.standard.text}
          </Button>
        </>
      ) : (
        <></>
      )}
      {props.roleDatas.pro.flag ? (
        <>
          <Button
            disabled={props.roleDatas.pro.disabled}
            size="large"
            color={props.roleDatas.pro.color}
            variant="contained"
            style={{ fontSize: buttonFontsize }}
            onClick={() => props.roleDatas.pro.excute()}
          >
            {props.roleDatas.pro.text}
          </Button>
        </>
      ) : (
        <></>
      )}
      {props.roleDatas.onlylogin.flag ? (
        <>
          <Button
            disabled={props.roleDatas.onlylogin.disabled}
            size="large"
            color={props.roleDatas.onlylogin.color}
            variant="contained"
            onClick={() => props.roleDatas.onlylogin.excute()}
            style={{ fontSize: buttonFontsize }}
          >
            {props.roleDatas.onlylogin.text}
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";

import {
  Button,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";

import { useStyles } from "../../Styles";

// import { useSpring, animated } from "react-spring";
import { useGestureResponder } from "react-gesture-responder";
import "./Sliderstyle.css";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import {
  VALIDATION_ERR_REQUIRED,
  VALIDATION_LONG_MAX_LENGTH,
  VALIDATION_LONG_MIN_LENGTH,
  VALIDAtION_INVALID_CHARACTER,
} from "../../constants/validation";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NORMAL_CHARACTER } from "../../constants/regex";

const schema = yup.object({
  claim: yup
    .string()
    .required(VALIDATION_ERR_REQUIRED)
    .max(100, VALIDATION_LONG_MAX_LENGTH)
    .min(4, VALIDATION_LONG_MIN_LENGTH)
    .matches(NORMAL_CHARACTER, VALIDAtION_INVALID_CHARACTER),
});

export default function LonpaBlockCreate(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  let { lonpaid } = useParams();
  const [claim, setClaim] = useState("");
  const [isShow, setIsShow] = useState(true);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { claim: props.initClaim },
  });

  useEffect(() => {
    console.log("uE_LonpaBlockCreate");
    if (props.isCreateAI) {
      setClaim(props.createAILonData.claim);
    }
  }, []);

  async function Post() {
    props.addLonChild(claim, props.isagree, props.isCreateAI);
    setClaim("");
    setIsShow(false);
  }
  //effectの種類を設定
  const effect = {
    bigBalanced: classes.bigglasseffect + " " + "sliding-pane",
    smallBalanced: classes.smallglasseffect + " " + "sliding-pane",
    middleBalanced: classes.middleglasseffect + " " + "sliding-pane",
  };
  //どの条件で、どのeffectをつかうのかを定義
  let pattern;

  if (props.styletext.glassstyle === "balanced") {
    pattern = effect.bigBalanced;
  } else if (props.styletext.glassstyle === "middle") {
    pattern = effect.middleBalanced;
  } else if (props.styletext.glassstyle === "small") {
    pattern = effect.smallBalanced;
  }

  const [movedx, setMovedx] = useState(0);
  const [open, setOpen] = useState(false);
  // const [{ x }, setx] = useSpring(() => {
  //   return { x: 0, immediate: true };
  // });

  const { bind } = useGestureResponder({
    // onStartShouldSet: () => true,
    // onMove: ({ delta, xy }) => {},
    // onRelease: ({ delta }) => {
    //   if (delta[1] > -20 && delta[1] < 20) {
    //     if (open) {
    //       //setline("1px rgba(192,192,192,0.4) solid")
    //       setx({ x: 0, immediate: false });
    //       setMovedx(0);
    //       setOpen(false);
    //     } else {
    //       //setline("1px rgba(192,192,192,0.4) solid")
    //       setx({ x: -160, immediate: false });
    //       setMovedx(-160);
    //       setOpen(true);
    //     }
    //   }
    // },
  });

  function addResistance(x) {
    const absX = Math.abs(x);

    if (absX > 60) {
      return x + (absX - 60) * 0.6 * (x < 0 ? 1 : -1);
    }
    return x;
  }

  return (
    <>
      {isShow ? (
        <>
          <div className="list-item">
            <div className={pattern}>
              <div
                className="background"
                style={{
                  //borderRadius: '5px',
                  overflow: "hidden",
                  transform: "background 0.3s ease",
                  background: "rgba(255,255,255, 0)",
                }}
              >
                <div style={{ width: "100%", minHeight: "100px" }}>
                  <ListItem
                    alignItems="flex-start"
                    style={{ padding: "5px 5px 5px 5px" }}
                  >
                    <ListItemText
                      primary={
                        <div
                          style={{
                            width: props.itemwidth,
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}
                        >
                          <Controller
                            control={control}
                            name="claim"
                            render={({ field: { onChange } }) => (
                              <TextField
                                fullWidth
                                id="standard-required"
                                variant="standard"
                                multiline
                                maxRows={4}
                                value={claim}
                                inputProps={{
                                  style: { fontWeight: "bold", fontSize: 16 },
                                }}
                                onChange={(e) => {
                                  onChange(e);
                                  setClaim(e.target.value);
                                }}
                              />
                            )}
                          />

                          {errors.claim?.message}
                        </div>
                      }
                    />
                  </ListItem>
                </div>
              </div>
            </div>
            <div
              {...bind}
              style={{
                height: "120px",
              }}
            >
              <p
                style={{
                  position: "absolute",
                  right: "0",
                  bottom: "0",
                  padding: "0",
                }}
              >
                {props.isbuttonlock ? (
                  <>
                    <IconButton
                      aria-label="vote"
                      size="middle"
                      style={{ display: "inline-block", paddingLeft: "6px" }}
                      disabled
                    >
                      <FavoriteBorderIcon fontSize="inherit" />
                    </IconButton>
                  </>
                ) : (
                  <>
                    {props.isagree ? (
                      <>
                        <Button
                          sx={{ color: "#3f51b5" }}
                          style={{
                            display: "inline-block",
                            paddingLeft: "6px",
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                          onClick={handleSubmit(Post)}
                        >
                          作成する
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          sx={{ color: "#f44336" }}
                          style={{
                            display: "inline-block",
                            paddingLeft: "6px",
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                          onClick={handleSubmit(Post)}
                        >
                          作成する
                        </Button>
                      </>
                    )}
                  </>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </p>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

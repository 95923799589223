export const nGrum = (name, n) => {
  const searchGrums = {};
  for (let i = 0; i < name.length; i++) {
    const results = [name.substr(i, n)];
    results.map((result) => {
      searchGrums[result] = true;
    });
  }
  return searchGrums;
};

export const SortByDescend = (list) => {
  const line = list.sort((a, b) => {
    if (a.data.votenum > b.data.votenum) return -1;
    if (a.data.votenum < b.data.votenum) return 1;
    return 0;
  });
};

export const Conversiondate = (org, type) => {
  const year = new Date(org).getFullYear();
  const month = ("0" + (new Date(org).getMonth() + 1)).slice(-2);
  const date = ("0" + new Date(org).getDate()).slice(-2);
  const hour = ("0" + new Date(org).getHours()).slice(-2);
  const min = ("0" + new Date(org).getMinutes()).slice(-2);
  if (type == "DATE") {
    const conversion = year + "/" + month + "/" + date;
    return conversion;
  } else {
    const conversion = hour + ":" + min;
    return conversion;
  }
};
//arr1とarr2をkeyで連結し、新たに配列を作る
export const joinBy = (arr1, arr2, key) => {
  const arr2Dict = new Map(arr2.map((o) => [o[key], o]));
  return arr1.map((item) => ({ ...item, ...arr2Dict.get(item[key]) }));
};

import { Typography } from "@mui/material";
import { useStyles } from "../../Styles";
import Copyright from "./Copyright";
import Link from "@mui/material/Link";

import TopWebpic from "../../images/top/topweb.jpg";
import Toppic from "../../images/top/top.jpg";
import TopWebpic_white from "../../images/top/topweb_white.jpg";
import Toppic_white from "../../images/top/top_white.jpg";

import React, { useReducer, useEffect, useState } from "react";

export default function Footer(props) {
  const classes = useStyles();
  const [marginOne, setMarginOne] = useState("33px");
  const [marginTwo, setMarginTwo] = useState("52px");

  useEffect(() => {
    console.log("uE_Footer");
    if (props.styletext.isbig) {
      setMarginOne("20px");
      setMarginTwo("20px");
    } else {
      setMarginOne("33px");
      setMarginTwo("52px");
    }
  }, [props.styletext.isbig]);
  return (
    <footer className={classes.footer}>
      {props.isimgshow ? (
        <>
          <div
            style={{
              backgroundColor: props.bgColor.back01,
              textAlign: "center",
            }}
          >
            {props.styletext.isbig ? (
              props.bgColor.type == "dark" ? (
                <>
                  <img
                    src={TopWebpic}
                    alt="アイコン"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </>
              ) : (
                <>
                  <img
                    src={TopWebpic_white}
                    align="center"
                    alt="アイコン"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </>
              )
            ) : props.bgColor.type == "dark" ? (
              <>
                <img src={Toppic} alt="アイコン" width="100%" />
              </>
            ) : (
              <>
                <img src={Toppic_white} alt="アイコン" width="100%" />
              </>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      <br />
      <Copyright bgColor={props.bgColor} />
      <br />

      <Typography
        variant="body2"
        align="center"
        style={{ color: props.bgColor.text01.fontColor }}
      >
        <Link
          color="inherit"
          href="https://lonpa.net/Terms"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            textDecoration: "none",
          }}
          // onClick={() => {
          //   window.scrollTo(0, 0);
          //   navigate("/Terms");
          // }}
        >
          Lonpa利用規約
        </Link>
        <Link
          color="inherit"
          href="https://lonpa.net/Law"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            textDecoration: "none",
          }}
          // onClick={() => {
          //   window.scrollTo(0, 0);
          //   navigate("/Law");
          // }}
        >
          特定商取引法に基づく表記
        </Link>
        {props.styletext.isbig ? <></> : <br />}
        <Link
          color="inherit"
          href="https://line.me/R/ti/p/@729gokgs"
          style={{
            marginLeft: "20px",
            marginRight: marginOne,
            textDecoration: "none",
          }}
        >
          お問い合わせ
        </Link>
        <Link
          color="inherit"
          href="https://lonpa.net/PrivacyPolicy"
          style={{
            marginLeft: "20px",
            marginRight: "52px",
            textDecoration: "none",
          }}
        >
          プライバシーポリシー
        </Link>
        <br />
        <br />
      </Typography>
    </footer>
  );
}

import { getAuth, onAuthStateChanged } from "firebase/auth";

import { userApp } from "../../common/firebase";

import { createUserSecretHistory, getUserSecretPoint } from "./FirebaseHelper";
import {
  pluspointUserSecret,
  minuspointUserSecret,
} from "./FirebaseTransactionHelper";

export const pointAdd = async () => {
  onAuthStateChanged(getAuth(userApp), async (user) => {
    if (user) {
      await user.getIdToken(true);
      const decodedToken = await user.getIdTokenResult();

      if (decodedToken.claims.stripeRole == "standard") {
        const nextpoint = await pluspointUserSecret(user.uid, 1000);
        const today = new Date();
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        createUserSecretHistory(user.uid, {
          uid: user.uid,
          point: nextpoint,
          pointchange: 1000,
          date: today,
          expirationDate: expirationDate,
          text1: "獲得",
          text2: "テストボタン押下",
          lonpaID: "none",
        });
        return nextpoint;
      } else if (decodedToken.claims.stripeRole == "pro") {
        const nextpoint = await pluspointUserSecret(user.uid, 5000);
        const today = new Date();
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        createUserSecretHistory(user.uid, {
          uid: user.uid,
          point: nextpoint,
          pointchange: 5000,
          date: today,
          expirationDate: expirationDate,
          text1: "獲得",
          text2: "テストボタン押下",
          lonpaID: "none",
        });
      } else {
        console.log("会員じゃないからポイントつかないよ");
        return false;
      }
    } else {
      console.log("会員じゃないからポイントつかないよ");
      return false;
    }
  });
};

export const pointSub = async (point: number, text: string, id: string) => {
  onAuthStateChanged(getAuth(userApp), async (user) => {
    if (user) {
      await user.getIdToken(true);
      const decodedToken = await user.getIdTokenResult();

      if (
        decodedToken.claims.stripeRole == "standard" ||
        decodedToken.claims.stripeRole == "pro"
      ) {
        const nextpoint = await minuspointUserSecret(user.uid, point);
        if (nextpoint != false) {
          const today = new Date();
          const expirationDate = new Date();
          expirationDate.setMonth(expirationDate.getMonth() + 1);
          createUserSecretHistory(user.uid, {
            uid: user.uid,
            point: nextpoint,
            pointchange: point * -1,
            date: today,
            text1: "利用",
            text2: text,
            lonpaID: id,
          });
        }
        return nextpoint;
      } else {
        console.log("会員じゃないからポイント使えないよ");
        return false;
      }
    } else {
      console.log("会員じゃないからポイント使えないよ");
      return false;
    }
  });
};

export const pointSubMakeIdea = async (id: string) => {
  pointSub(200, "意見案作成", id);
};

export const pointSubMakeRon = async (id: string) => {
  pointSub(200, "論拠作成", id);
};

export const pointSubMakePicture = async (id: string) => {
  pointSub(200, "画像作成", id);
};

export const pointSubMakeManuscript = async (id: string) => {
  pointSub(400, "動画原稿作成", id);
};

export const pointSubMakeSNS = async (id: string) => {
  pointSub(200, "SNS文章作成", id);
};

export const pointSubMakeTag = async (id: string) => {
  console.log(id);
  pointSub(200, "タグ作成", id);
};

export const pointSubMakeMovie = async (id: string) => {
  pointSub(1000, "動画作成", id);
};

export const pointSubMakeMovieOnly = async (id: string) => {
  pointSub(1200, "動画のみ作成", id);
};

export const pointSubAutoCreation600article = async (id: string) => {
  pointSub(600, "自動作成_article", id);
};

export const pointSubAutoCreation2500 = async (id: string) => {
  pointSub(2500, "自動作成_all", id);
};

export const pointSubAutoCreation1600 = async (id: string) => {
  pointSub(1600, "自動作成_movieall", id);
};

export const pointSubAutoCreation600movie = async (id: string) => {
  pointSub(1600, "自動作成_movie", id);
};

interface excutable {
  isExcutable: boolean;
  isSubscribe: boolean;
  isMember: boolean;
  stripeRole: string;
  possession: number; //現在所持している量
  deficient: number; //足りない量
  required: number; //要求されている量
}

interface subscription {
  uid: string | null;
  email: string | null;
  isEmailVerified: boolean;
  isUser: boolean;
  isSubscribe: boolean;
  isStandard: boolean;
  isPro: boolean;
}

export function checkisExecutable(needpoint: number): Promise<excutable> {
  return new Promise((resolve) => {
    onAuthStateChanged(getAuth(userApp), async (user) => {
      if (user) {
        await user.getIdToken(true);
        const decodedToken = await user.getIdTokenResult();
        if (
          decodedToken.claims.stripeRole == "standard" ||
          decodedToken.claims.stripeRole == "pro"
        ) {
          const nowpoint = await getUserSecretPoint(user.uid);
          if (nowpoint >= needpoint) {
            resolve({
              isExcutable: true,
              isSubscribe: true,
              isMember: true,
              stripeRole: decodedToken.claims.stripeRole,
              possession: nowpoint,
              deficient: 0,
              required: needpoint,
            });
          } else {
            resolve({
              isExcutable: false,
              isSubscribe: true,
              isMember: true,
              stripeRole: decodedToken.claims.stripeRole,
              possession: nowpoint,
              deficient: needpoint - nowpoint,
              required: needpoint,
            });
          }
        } else {
          resolve({
            isExcutable: false,
            isSubscribe: false,
            isMember: true,
            stripeRole: "none",
            possession: 0,
            deficient: needpoint,
            required: needpoint,
          });
        }
      } else {
        resolve({
          isExcutable: false,
          isSubscribe: false,
          isMember: false,
          stripeRole: "none",
          possession: 0,
          deficient: needpoint,
          required: needpoint,
        });
      }
    });
  });
}

export function checkisSubscription(): Promise<subscription> {
  return new Promise((resolve) => {
    onAuthStateChanged(getAuth(userApp), async (user) => {
      if (user) {
        await user.getIdToken(true);
        const decodedToken = await user.getIdTokenResult();
        if (decodedToken.claims.stripeRole == "standard") {
          resolve({
            uid: user.uid,
            email: user.email,
            isEmailVerified: user.emailVerified,
            isUser: true,
            isSubscribe: true,
            isStandard: true,
            isPro: false,
          });
        } else if (decodedToken.claims.stripeRole == "pro") {
          resolve({
            uid: user.uid,
            email: user.email,
            isEmailVerified: user.emailVerified,
            isUser: true,
            isSubscribe: true,
            isStandard: false,
            isPro: true,
          });
        } else {
          resolve({
            uid: user.uid,
            email: user.email,
            isEmailVerified: user.emailVerified,
            isUser: true,
            isSubscribe: false,
            isStandard: false,
            isPro: false,
          });
        }
      } else {
        resolve({
          uid: null,
          email: null,
          isEmailVerified: false,
          isUser: false,
          isSubscribe: false,
          isStandard: false,
          isPro: false,
        });
      }
    });
  });
}

import { getMovieString } from "../../common/Helper/StorageHelper";

export const downloadExplain = async (lonpaid, setExplainvideo) => {
  const explaindata = await getMovieString(lonpaid, "explain.txt");
  if (explaindata) {
    setExplainvideo(explaindata.toString());
    return true;
  } else {
    return false;
  }
};

export const downloadMovieSetting = async (
  lonpaid,
  setMovieSetting,
  setMovietype
) => {
  const movieSettingData = await getMovieString(lonpaid, "movieSetting.csv");
  if (movieSettingData) {
    const stringMovieSettingData = movieSettingData.toString();
    const trimstr = stringMovieSettingData.split("\n"); // 整形
    const trimtrimstr = trimstr[1].split(","); // 整形
    setMovieSetting({
      set1: trimtrimstr[1],
      set2: trimtrimstr[2],
      set3: trimtrimstr[3],
      set4: trimtrimstr[4],
      set5: trimtrimstr[5],
    });
    setMovietype(Number(trimtrimstr[2]));
    return true;
  } else {
    return false;
  }
};

export const downloadGen = async (
  lonpaid,
  setGenko,
  createIsMovieShort,
  setIsShort,
  setMovieSetting,
  setMovieStage,
  movieStage,
  movieSetting
) => {
  const gendata = await getMovieString(lonpaid, "genko.csv");
  if (gendata) {
    let stringGenData = gendata.toString();
    const trimstr = stringGenData.split("\n"); // 整形

    let allgen = [];
    let strnum = 0;
    const shortMovieStrNum = 400;

    trimstr.map((item, index) => {
      if (index > 0) {
        const trimtrimstr = item.split(","); // 整形
        if (trimtrimstr[2]) {
          strnum = strnum + trimtrimstr[2].length + 4;
          allgen.push({
            index: trimtrimstr[0],
            vocal: trimtrimstr[1],
            text: trimtrimstr[2],
            isShortMovie: strnum < shortMovieStrNum,
          });
        }
      }
    });
    setGenko(allgen);

    createIsMovieShort(
      strnum < shortMovieStrNum,
      setIsShort,
      movieSetting,
      setMovieSetting
    );
    setMovieStage({ ...movieStage, 4: "完" });

    return true;
  } else {
    return false;
  }
};

import React from "react";

interface CustomCircularProgressProps {
  size: number; // コンポーネントのサイズ
  value: number; // 進捗値 (0 から 100)
  strokeWidth: number; // 線の太さ
  color: string;
}

const Circule: React.FC<CustomCircularProgressProps> = ({
  size,
  value,
  strokeWidth,
  color,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const progress = ((100 - value) / 100) * circumference;
  const rotation = -90;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={{
        position: "absolute",
        left: "50%", // 親要素の中心から開始
        transform: "translateX(-50%)", // SVGの幅の半分だけ左に移動
      }}
      // viewBox をここでカスタマイズ
    >
      <g transform={`rotate(${rotation}, ${size / 2}, ${size / 2})`}>
        <circle
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progress}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
      </g>
    </svg>
  );
};

export default Circule;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../Styles";
import LonpaItem from "../Search/LonpaItem";

import TopItem from "./TopItem";
import AdItem2 from "./Ad/AdItem2";

import rankicon from "../../images/rankicon.png";

import {
  List,
  ListItem,
  Divider,
  ListItemText,
  Box,
  Typography,
  Checkbox,
} from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Recommend(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const ChoiceData = (id, title) => {
    if (props.searchword === "useurl") {
      window.scrollTo(0, 0);
      navigate("/Lonpa/" + id);
    } else {
      props.ConnectLonpa(id, title);
    }
  };

  const ChoiceDataNewTab = (id, title) => {
    if (props.searchword === "useurl") {
      const url = "https://lonpa.net/Lonpa/" + id;
      const win = window.open(url, "_blank");
      if (win != null) {
        win.focus();
      }
    } else {
      // props.ConnectLonpa(id, title);
      console.log("please check there are bug or not");
    }
  };

  return (
    <>
      <Typography
        color={props.bgColor.text06.fontColor}
        gutterBottom
        variant="h6"
        fontWeight="bold"
      >
        <Checkbox
          icon={<CircleIcon />}
          checkedIcon={<CheckCircleIcon />}
          disabled
          style={{
            color: props.bgColor.text06.fontColor,
          }}
        />
        ランキング(毎日更新)
      </Typography>

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <List sx={{ width: "100%", backgroundColor: props.bgColor.back01 }}>
          {props.idealist.map((idea, index) => (
            <div
              className={classes.indicatorButtonwhite}
              onClick={() => {
                ChoiceData(idea.id, idea.claim);
              }}
              onAuxClick={() => {
                ChoiceDataNewTab(idea.id, idea.claim);
              }}
              key={idea.id + "_0"}
              style={{
                backgroundColor: props.bgColor.back01,
                color: "#ffffff",
                border: props.bgColor.line01,
              }}
            >
              <TopItem
                idea={idea}
                icon={rankicon}
                bgColor={props.bgColor}
                text={idea.rank + "位"}
                itemtype={"Ranking"}
              />
              {props.ideaVotedList.length === 0 ? (
                <></>
              ) : (
                <>
                  {props.ideaVotedList.includes(idea.id) ? (
                    <></> //投票済み
                  ) : (
                    <>.</> //未投票
                  )}
                </>
              )}
            </div>
          ))}

          <AdItem2 bgColor={props.bgColor} styletext={props.styletext} />
        </List>
      </Box>
    </>
  );
}

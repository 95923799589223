import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  IconButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";

import Message from "../Chat/Message";

type Props = {
  bgColor: any;
  styletext: any;
  setIsStage1Finish: any;
  setIsBlue: any;
  info: any;
  talkerpic1: any;
  talkerpic2: any;
  talkerpic3: any;
  talkerpic4: any;
  messageReflesh: number;
};

const Stage1: React.VFC<Props> = ({
  bgColor,
  styletext,
  setIsStage1Finish,
  setIsBlue,
  info,
  talkerpic1,
  talkerpic2,
  talkerpic3,
  talkerpic4,
  messageReflesh,
}: Props) => {
  const RedButton = {
    fontSize: "20px",
    color: bgColor.text01.fontColor,
    width: styletext.windowPhoneWidth * 0.45,
    height: "50px",
    fontWeight: "bold",
    // border: bgColor.line01,
    backgroundColor: bgColor.back02,
  };

  const BlueButton = {
    fontSize: "20px",
    color: bgColor.text01.fontColor,
    width: styletext.windowPhoneWidth * 0.45,
    height: "50px",
    fontWeight: "bold",
    // border: bgColor.line01,
    backgroundColor: bgColor.back03,
  };

  return (
    <>
      <div
        style={{
          zIndex: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "126px",
        }}
      >
        <Message
          bgColor={bgColor}
          styletext={styletext}
          info={info}
          messageReflesh={messageReflesh}
        />
      </div>
      <div
        style={{
          zIndex: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <table style={{ width: styletext.windowPhoneWidth }}>
          <tbody>
            <tr>
              <td
                style={{
                  position: "relative",
                  width: styletext.windowPhoneWidth * 0.45,
                }}
              >
                <img
                  style={{
                    width: styletext.windowPhoneWidth * 0.45, // この変更により、画像はtdの幅に合わせて表示されます
                  }}
                  src={talkerpic1}
                  alt="img"
                />
                <Button
                  style={{
                    ...BlueButton,
                    position: "absolute",
                    bottom: "-20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  onClick={() => {
                    setIsStage1Finish(true);
                    setIsBlue(true);
                  }}
                >
                  賛成する
                </Button>
              </td>
              <td style={{ width: styletext.windowPhoneWidth * 0.1 }}>
                <div style={{ width: styletext.windowPhoneWidth * 0.1 }}></div>
              </td>
              <td
                style={{
                  position: "relative",
                  width: styletext.windowPhoneWidth * 0.45,
                }}
              >
                <img
                  style={{
                    width: styletext.windowPhoneWidth * 0.45, // この変更により、画像はtdの幅に合わせて表示されます
                  }}
                  src={talkerpic2}
                  alt="img"
                />
                <Button
                  style={{
                    ...RedButton,
                    position: "absolute",
                    bottom: "-20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  onClick={() => {
                    setIsStage1Finish(true);
                    setIsBlue(false);
                  }}
                >
                  反対する
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Stage1;

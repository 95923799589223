import { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import BorderColor from "@mui/icons-material/BorderColor";
import { Button, TextField, Typography } from "@mui/material";
import { useStyles } from "../../../Styles";
import * as yup from "yup";
import { getAuth } from "firebase/auth";

import {
  createLonpaChild,
  setLonpa,
  createLonpa,
  setUserPrivateCreateLonpa,
  setLonpaChild,
} from "../../../common/Helper/FirebaseHelper";
import {
  VALIDATION_ERR_REQUIRED,
  VALIDATION_LONG_MAX_LENGTH,
  VALIDAtION_INVALID_CHARACTER,
} from "../../../constants/validation";
import { serverTimestamp } from "firebase/firestore";
import { userApp } from "../../../common/firebase";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NORMAL_CHARACTER } from "../../../constants/regex";

const schema = yup.object({
  claim: yup
    .string()
    .required(VALIDATION_ERR_REQUIRED)
    .max(100, VALIDATION_LONG_MAX_LENGTH)
    .matches(NORMAL_CHARACTER, VALIDAtION_INVALID_CHARACTER),
});

export default function LonpaModal(props) {
  const classes = useStyles();
  const [claim, setClaim] = useState([]);
  // const [errortext, setErrortext] = useState("");
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const nGrum = (name, n) => {
    const searchGrums = {};
    for (let i = 0; i < name.length; i++) {
      const results = [name.substr(i, n)];
      results.map((result) => {
        searchGrums[result] = true;
      });
    }
    return searchGrums;
  };

  async function Post() {
    await props.setLoadopen(true);
    if (getAuth(userApp).currentUser) {
      //現在見ているLonpaの論拠（子）要素として、登録
      const docRef = await createLonpaChild(props.id, {
        claim: claim,
        votenum: 0,
        agree: props.lon,
        child: true,
        createdAt: serverTimestamp(),
        createdBy: getAuth(userApp).currentUser.uid,
        oppositionStatus: "balanced",
        isDelete: false,
      });
      //Lonpa一覧に本件を作成
      await setLonpa(docRef.id, {
        claim: claim,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
        ngram: nGrum(claim, 2),
        agree: 0,
        disagree: 0,
        isopinion: false,
        createdBy: getAuth(userApp).currentUser.uid,
        parent: props.id,
        parentClaim: props.parentclaim,
        isDelete: false,
        imageurl: "",
      });
      //ユーザの作成履歴を記録する
      await setUserPrivateCreateLonpa(docRef.id, {
        claim: claim,
        createdAt: serverTimestamp(),
        isopinion: false,
        parent: props.id,
        isDelete: false,
      });
    } else {
      console.log("notlogin");
      //現在見ているLonpaの論拠（子）要素として、登録
      console.log({
        claim: claim,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
        ngram: nGrum(claim, 2),
        agree: 0,
        disagree: 0,
        isopinion: false,
        parent: props.id,
        parentClaim: props.parentclaim,
        isDelete: false,
        imageurl: "",
      });
      //Lonpa一覧に本件を作成
      const docRef = await createLonpa({
        claim: claim,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
        ngram: nGrum(claim, 2),
        agree: 0,
        disagree: 0,
        isopinion: false,
        parent: props.id,
        parentClaim: props.parentclaim,
        isDelete: false,
        imageurl: "",
      });
      console.log("setlonpa");
      //ユーザがログインユーザでない場合
      //現在見ているLonpaの論拠（子）要素として、登録
      await setLonpaChild(props.id, docRef.id, {
        claim: claim,
        votenum: 0,
        agree: props.lon,
        child: true,
        createdAt: serverTimestamp(),
        isDelete: false,
      });
      console.log("finish");
    }

    window.scrollTo(0, 0);
    setClaim("");
    props.setOpen(false);
    await props.setLoadopen(false);
  }

  const handleCloseinside = () => {
    setClaim("");
    props.setOpen(false);
    reset();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <h3 id="transition-modal-title">{props.maketext}</h3>
          <div id="transition-modal-description" style={{ fontSize: 20 }}>
            <Controller
              control={control}
              name="claim"
              render={({ field: { onChange } }) => (
                <TextField
                  fullWidth
                  id="standard-required"
                  value={claim}
                  inputProps={{ style: { fontSize: 30 } }}
                  onChange={(e) => {
                    onChange(e);
                    setClaim(e.target.value);
                  }}
                />
              )}
            />

            <br />
            <Button
              color="primary"
              endIcon={<BorderColor />}
              onClick={handleSubmit(Post)}
              style={{ fontSize: 20, fontWeight: "bold" }}
            >
              Lonpa作成
            </Button>
            {/* {errortext} */}
            <Typography>{errors.claim?.message}</Typography>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

import { useRef } from "react";
import { Avatar } from "@mui/material";

import { firebaseApp, userApp } from "../../common/firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { updateUserPublic } from "../../common/Helper/FirebaseHelper";

import Compressor from "compressorjs";

export default function Useravatar(props) {
  const inputRef = useRef(null);
  const fileUpload = () => {
    inputRef.current.click();
  };
  //uploadできる画像のバイト数のリミット
  const limitUpdateByte = 300000;

  async function onSubmit(event) {
    event.preventDefault();
    if (!props.user) {
      console.log("ログインしていません");
      return;
    }
    if (typeof event.target.files[0] === "undefined") {
      console.log("ファイルが選択されていません");
      return;
    }
    props.setOpen(true);
    let avatarImage = event.target.files[0];
    //画像を圧縮
    new Compressor(avatarImage, {
      quality: 0.6,
      success(result) {
        const image = result;
        if (image.size < limitUpdateByte) {
          const storage = getStorage(userApp);
          const storageRef = ref(
            storage,
            "useravatar/" + props.user.uid + "/avatar"
          ); //どのフォルダの配下に入れるかを設定
          const uploadTask = uploadBytesResumable(storageRef, image); //ファイル名
          //ファイルをアップロードする
          uploadTask.on("state_changed", () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              const f = async () => {
                await updateUserPublic(props.user.uid, {
                  imageurl: downloadURL,
                });
                props.setimageurl(downloadURL);
                props.setOpen(false);
              };
              f();
            });
          });
        } else {
          alert("容量が大きすぎます。やり直してください");
          props.setOpen(false);
        }
      },
      error(err) {
        console.log(err);
        return;
      },
    });
  }

  return (
    <>
      {/* <button onClick={fileUpload}>ファイルアップロード</button> */}
      <Avatar
        onClick={fileUpload}
        src={props.avatar.img}
        sx={{
          height: 100,
          width: 100,
        }}
      />
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onSubmit}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Button,
} from "@mui/material";

import { useStyles } from "../../Styles";

import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import {
  VALIDATION_ERR_REQUIRED,
  VALIDATION_LONG_MAX_LENGTH,
  VALIDATION_LONG_MIN_LENGTH,
  VALIDAtION_INVALID_CHARACTER,
} from "../../constants/validation";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NORMAL_CHARACTER } from "../../constants/regex";

const schema = yup.object({
  claim: yup
    .string()
    .required(VALIDATION_ERR_REQUIRED)
    .max(100, VALIDATION_LONG_MAX_LENGTH)
    .min(4, VALIDATION_LONG_MIN_LENGTH)
    .matches(NORMAL_CHARACTER, VALIDAtION_INVALID_CHARACTER),
});

export default function LonpaBlockbigCreate(props) {
  const classes = useStyles();
  const [claim, setClaim] = useState("");
  const [isShow, setIsShow] = useState(true);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { claim: props.initClaim },
  });

  useEffect(() => {
    console.log("uE_LonpaBlockbigCreate");
    if (props.isCreateAI) {
      setClaim(props.createAILonData.claim);
    }
  }, []);

  async function Post() {
    props.addLonChild(claim, props.isagree, props.isCreateAI);
    setClaim("");
    setIsShow(false);
  }
  //effectの種類を設定
  const effect = {
    bigBalanced: classes.bigglasseffect,
    smallBalanced: classes.smallglasseffect,
    middleBalanced: classes.middleglasseffect,
  };
  //どの条件で、どのeffectをつかうのかを定義
  let pattern;
  if (props.styletext.glassstyle2 === "big") {
    pattern = effect.bigBalanced;
  } else if (props.styletext.glassstyle2 === "middle") {
    pattern = effect.middleBalanced;
  } else if (props.styletext.glassstyle2 === "small") {
    pattern = effect.smallBalanced;
  }

  //console.log(props.styletext.glassstyle2);

  return (
    <>
      {isShow ? (
        <>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow key="aa">
                  <TableCell
                    align="right"
                    style={{ width: "100%", padding: "0", lineHeight: "0" }}
                  >
                    {/*  賛成と反対の票数で、どのeffectpatternが決まるので、それを設定する  */}
                    <div className={pattern}>
                      <div
                        align="left"
                        style={{ width: "100%", minHeight: "120px" }}
                      >
                        <div
                          style={{
                            width: props.itemwidth,
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                          }}
                        >
                          <Controller
                            control={control}
                            name="claim"
                            render={({ field: { onChange } }) => (
                              <TextField
                                fullWidth
                                id="standard-required"
                                variant="standard"
                                multiline
                                maxRows={4}
                                value={claim}
                                inputProps={{
                                  style: { fontWeight: "bold", fontSize: 16 },
                                }}
                                onChange={(e) => {
                                  onChange(e);
                                  setClaim(e.target.value);
                                }}
                              />
                            )}
                          />

                          {errors.claim?.message}
                        </div>
                      </div>
                    </div>
                  </TableCell>

                  <TableCell
                    align="right"
                    style={{ padding: "0", lineHeight: "0" }}
                  >
                    <table>
                      <tbody
                        style={{
                          position: "relative",
                          left: "0",
                          top: "10px",
                          padding: "0",
                        }}
                      >
                        <tr align="center">
                          <td>
                            {props.isagree ? (
                              <>
                                <Button
                                  sx={{ color: "#3f51b5" }}
                                  align="center"
                                  style={{
                                    display: "inline-block",
                                    paddingLeft: "6px",
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    minWidth: 0,
                                  }}
                                  onClick={handleSubmit(Post)}
                                >
                                  作<br />成
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  sx={{ color: "#f44336" }}
                                  align="center"
                                  style={{
                                    display: "inline-block",
                                    paddingLeft: "6px",
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    minWidth: 0,
                                  }}
                                  onClick={handleSubmit(Post)}
                                >
                                  作<br />成
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

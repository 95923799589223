import React from "react";
import { Typography, Link } from "@mui/material";

export default function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        onClick={() => {
          window.open("https://lamact.com/");
        }}
        style={{
          color: "#000000",
          width: "170px",
          height: "48px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: props.bgColor.pic01,
          backgroundSize: "170px 48px",
        }}
      ></div>
      {/* {new Date().getFullYear()} */}
    </Typography>
  );
}

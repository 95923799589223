import { useNavigate } from "react-router-dom";
import { useStyles } from "../../Styles";

import { ListItem, ListItemText } from "@mui/material";
import PieChart from "../Top/PieChart";

export default function SearchItem(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <table>
      <tbody key={props.idea.id}>
        <tr>
          <td>
            {props.idea.taghit ? (
              <>
                <PieChart
                  imageurl={props.idea.imageurl}
                  src={props.idea.icon}
                  disagreeratio={props.idea.disagreeratio}
                />
              </>
            ) : (
              <>
                <PieChart
                  imageurl={props.idea.imageurl}
                  src={props.idea.icon}
                  disagreeratio={props.idea.disagreeratio}
                />
                {/* <img src={searchkeyicon} alt="アイコン" width="60px" /> */}
              </>
            )}
            &nbsp;&nbsp;
          </td>

          <td>
            <div align="left">
              <a
                style={{
                  fontSize: props.bgColor.text07.fontSize,
                  color: props.bgColor.text07.fontColor,
                }}
              >
                {props.idea.text}
              </a>
            </div>
            <div>
              <ListItem
                alignItems="flex-start"
                style={{
                  width: "100%",
                  padding: "0px 0px 0px 0px",
                  backgroundColor: props.bgColor.back01,
                  color: props.bgColor.text04.fontColor,
                }}
              >
                <ListItemText
                  primary={
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        width: "100%",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        color: props.bgColor.text01.fontColor,
                      }}
                    >
                      {props.idea.title}
                    </div>
                  }
                />
              </ListItem>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

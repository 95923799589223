import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Link,
  IconButton,
} from "@mui/material";
import { useStyles } from "../Styles";
import { Timestamp } from "firebase/firestore";
import {
  getAuth,
  updateProfile,
  updatePassword,
  signOut,
  onAuthStateChanged,
  getRedirectResult,
  sendEmailVerification,
  sendPasswordResetEmail,
  deleteUser,
} from "firebase/auth";
import * as yup from "yup";
import moment from "moment";

import { userApp } from "../common/firebase";

import {
  getUserPublic,
  setUserPublic,
  getUserPrivateVoteDescStartAfter,
  getUserPrivateCreateLonpaDescStartAfter,
} from "../common/Helper/FirebaseHelper";

import Useravatar from "./Userpage/Useravatar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LodingModal from "./CommonModal/LodingModal";
import LonpaEditModal from "./CommonModal/LonpaEditModal";
import {
  VALIDATION_EMAIL,
  VALIDATION_ERR_REQUIRED,
  VALIDAtION_INVALID_CHARACTER,
  VALIDATION_LONG_MAX_LENGTH,
  VALIDATION_PASSWORD_MAX_LENGTH,
  VALIDATION_PASSWORD_MIN_LENGTH,
  VALIDATION_USERNAME_MIN_LENGTH,
} from "../constants/validation";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NORMAL_CHARACTER } from "../constants/regex";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CachedIcon from "@mui/icons-material/Cached";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import NewspaperIcon from "@mui/icons-material/Newspaper";

import ConfirmModal from "./CommonModal/ConfirmModal";
import Footer from "./components/Footer";

const schema = yup.object({
  username: yup
    .string()
    .required(VALIDATION_ERR_REQUIRED)
    .min(4, VALIDATION_USERNAME_MIN_LENGTH)
    .max(64, VALIDATION_LONG_MAX_LENGTH)
    .matches(NORMAL_CHARACTER, VALIDAtION_INVALID_CHARACTER),
});

const Userpage = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [upload, setupload] = useState(0);
  const [errormessage, setErrorMessage] = useState("");
  const [votedata, setVoteData] = useState({});
  const [createdata, setCreateData] = useState({});
  // const [imageurl, setAvatar] = useState("");
  const [lodingmodalopen, setLodingmodalopen] = useState(true);
  const [lonpaEditmodalopen, setLonpaEditmodalopen] = useState(false);
  const [editLonpa, setEditLonpa] = useState("");
  const [editLonpaIsDelete, setEditLonpaIsDelete] = useState("");
  const [targetDateVote, setTargetDateVote] = useState("");
  const [targetDateCreate, setTargetDateCreate] = useState("");
  const [emailverified, setEmailVerified] = useState(false);
  //画像投稿時のエラー内容を格納
  const [uploaderror, setUploaderror] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit: handleFormSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };
  const picurl = [
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/category%2Fhome.jpg?alt=media&token=78540a61-565b-40ca-9343-2596543df212",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/category%2Fsocial.jpg?alt=media&token=dbd1c1bd-44ab-4955-925c-67ec7dfb0dec",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/category%2Fwork.jpg?alt=media&token=ee6854a0-03d7-486e-b8b2-75373548c42b",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/category%2Fcountry.jpg?alt=media&token=9d9af185-d76d-41b3-abc2-6a758e61cd6f",
  ];
  const [help, sethelp] = useState(false);

  useEffect(() => {
    console.log("uE_Userpage");
    const f = async () => {
      onAuthStateChanged(getAuth(userApp), async (user) => {
        // const user = getAuth(userApp).currentUser;
        if (user) {
          if (typeof user.uid !== "undefined") {
            //console.log(user.uid);
            setEmail(user.email);
            setValue("email", user.email);
            setUid(user.uid);
            let doc = await getUserPublic(user.uid);
            let dataexsitflag = false;

            if (typeof doc.data() !== "undefined") {
              if (typeof doc.data().name !== "undefined") {
                dataexsitflag = true;
              } else {
                dataexsitflag = false;
              }
            } else {
              dataexsitflag = false;
            }

            if (dataexsitflag) {
              props.setAvatar({
                img: doc.data().imageurl,
                name: doc.data().name,
              });
              const username = doc.data().name;
              setName(username);
              setValue("username", username);
            } else {
              const num = Math.floor(Math.random() * 4);
              props.setAvatar({
                img: picurl[num],
                name: "名無し",
              });
              setName(user.uid.substr(0, 4));
              updateProfile(user, {
                displayName: user.uid.substr(0, 4),
              });
              setUserPublic(user.uid, {
                name: user.uid.substr(0, 4),
                imageurl: picurl[num],
              });
              props.setChangeImg(props.changeImg + 1);
            }

            await loadCreateHistory().catch((error) => {
              console.log(error);
            });
            await loadVoteHistory().catch((error) => {
              console.log(error);
            });
            setEmailVerified(getAuth(userApp).currentUser.emailVerified);

            setLodingmodalopen(false);
          } else {
            window.scrollTo(0, 0);
            navigate("/Signin");
          }
        } else {
          // getRedirectResult(getAuth(userApp))
          //   .then((result) => {
          //     // console.log(result);
          //   })
          //   .catch((error) => {
          //   });
          window.scrollTo(0, 0);
          navigate("/Signin");
          setLodingmodalopen(false);
        }
      });
    };
    f();
  }, [upload]);

  async function loadCreateHistory() {
    let querySnapshot = await getUserPrivateCreateLonpaDescStartAfter(
      targetDateCreate,
      3
    );
    let createlist = createdata;
    querySnapshot.forEach((doc) => {
      createlist[doc.id] = {};
      createlist[doc.id]["claim"] = doc.data().claim;
      createlist[doc.id]["isopinion"] = doc.data().isopinion;
      createlist[doc.id]["parent"] = doc.data().parent;
      createlist[doc.id]["isDelete"] = doc.data().isDelete;
      createlist[doc.id]["createdAt"] = parseAsMoment(
        doc.data().createdAt.toDate()
      )
        .format("YYYY/MM/DD HH:mm")
        .toString();
    });
    if (querySnapshot.size) {
      setTargetDateCreate(
        Timestamp.fromDate(querySnapshot.docs.pop().data().createdAt.toDate())
      );
      setCreateData(createlist);
    }
  }

  const VerifyEmail = () => {
    try {
      const actionCodeSettings = {
        url: `https://lonpa.net/Signin`,
      };
      const email = getAuth(userApp).currentUser.email;
      sendEmailVerification(
        getAuth(userApp).currentUser,
        actionCodeSettings
      ).then(() => {
        alert(
          email +
            " へ検証用のメールを送信しました。メール記載のURLから有効化をお願いします。"
        );
      });
    } catch (e) {
      console.log(e);
      alert("有効化メールの送信に失敗しました");
    }
  };

  async function loadVoteHistory() {
    let querySnapshot = await getUserPrivateVoteDescStartAfter(
      targetDateVote,
      3
    );
    let votelist = votedata;

    querySnapshot.forEach((doc) => {
      votelist[doc.id] = {};
      votelist[doc.id]["claim"] = doc.data().claim;
      votelist[doc.id]["isAgree"] = doc.data().isAgree;
      votelist[doc.id]["votedAt"] = parseAsMoment(doc.data().votedAt.toDate())
        .format("YYYY/MM/DD HH:mm")
        .toString();
      votelist[doc.id]["votedClaim"] = doc.data().votedClaim;
    });
    if (querySnapshot.size) {
      setTargetDateVote(
        Timestamp.fromDate(querySnapshot.docs.pop().data().votedAt.toDate())
      );
      setVoteData(votelist);
    }
  }
  const handleLogout = (props) => {
    signOut(getAuth(userApp))
      .then(() => {
        // console.log("ログアウトしました");
        props.update();
        window.scrollTo(0, 0);
        navigate("/Userpage");
      })
      .catch((error) => {
        // console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
  };

  async function handleSubmit() {
    let user = getAuth(userApp).currentUser;
    if (user.displayName != name) {
      if (user) {
        await updateProfile(user, {
          displayName: name,
          email: email,
        })
          .then(() => {
            setupload(upload + 1);
            // setErrorMessage("変更しました");
            alert("名前を変更しました");
          })
          .catch((error) => {
            // An error occurred
            // ...
            alert("名前を変更に失敗しました。時間を空けて再度実施してください");
          });
      }
      await setUserPublic(user.uid, {
        name: name,
        imageurl: props.avatar.img,
      });
    }
  }

  async function handleRePasswordSet() {
    if (email) {
      const actionCodeSettings = {
        url: `https://lonpa.net/Signin`,
      };
      sendPasswordResetEmail(getAuth(userApp), email, actionCodeSettings)
        .then(() => {
          alert("password再設定用のメールを送りました");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          alert("メール送信に失敗しました");
        });
    } else {
      alert(
        "password再設定用のメールに失敗しました。時間を空けて再度実施してください"
      );
    }
  }

  const handleCloseDialog = (confirmed) => {
    setDialogOpen(false);
    if (confirmed) {
      console.log("Confirmed");
      handleDeleteUser();
    } else {
      console.log("Cancelled");
      // キャンセルされた時の処理をここに記述
    }
  };

  async function handleDeleteUser() {
    let user = getAuth(userApp).currentUser;
    if (user) {
      const credential = GoogleAuthProvider.credential(
        await currentUser.getIdToken(true),
        null
      );
      // 最近サインインしていないとエラーになってしまうので、再認証してクレデンシャルを取得
      login().then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        reauthenticateWithCredential(currentUser, credential)
          .then(() => {
            // User re-authenticated.
            deleteUser(currentUser)
              .then(() => {
                // User deleted.
                console.log("ユーザ削除しました");
                alert("ユーザ削除しました");
              })
              .catch((error) => {
                // An error ocurred
                alert("ユーザ削除に失敗しました");
                console.log(error);
              });
          })
          .catch((error) => {
            // An error ocurred
            alert("ユーザ削除に失敗しました");
            console.log(error);
          });
      });
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor: props.bgColor.back01,
          color: props.bgColor.text01.fontColor,
        }}
      >
        <div className={classes.space4}></div>
        <Box
          sx={{
            minHeight: "100%",
            py: 3,
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card
                  style={{
                    backgroundColor: props.bgColor.back01,
                    color: props.bgColor.text01.fontColor,
                  }}
                >
                  <CardHeader
                    title="プロフィール"
                    action={
                      <IconButton
                        aria-label="help"
                        onClick={() => {
                          sethelp(!help);
                          console.log("test");
                        }}
                      >
                        <SettingsIcon sx={{ color: "#808080" }} />
                      </IconButton>
                    }
                  />
                  <Divider />
                  <CardContent>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Useravatar
                        user={getAuth(userApp).currentUser}
                        imageurl={props.avatar.img}
                        avatar={props.avatar}
                        setOpen={setLodingmodalopen}
                      />
                      <Controller
                        control={control}
                        name="username"
                        render={({ field: { onChange } }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              width: "100%",
                            }}
                          >
                            <TextField
                              label="表示名"
                              name="Name"
                              value={name}
                              variant="standard"
                              fullWidth
                              margin="dense"
                              onChange={(e) => {
                                onChange(e);
                                setName(e.target.value);
                              }}
                              sx={{
                                input: {
                                  color: props.bgColor.text04.fontColor,
                                },
                              }}
                              style={{
                                border: props.bgColor.line01,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: props.bgColor.text04.fontColor,
                                }, // ここに希望の色を指定
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleFormSubmit(handleSubmit)}
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <CachedIcon
                                        sx={{ color: "#6495ed" }}
                                      ></CachedIcon>
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        )}
                      />
                      <Typography>{errors.username?.message}</Typography>

                      {emailverified ? (
                        <>
                          <TextField
                            label="メールアドレス"
                            name="email"
                            margin="normal"
                            value={email}
                            variant="standard"
                            fullWidth
                            disabled
                            sx={{
                              input: {
                                color: props.bgColor.text01.fontColor,
                                disabled: props.bgColor.text01.fontColor,
                              },
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor:
                                  props.bgColor.text01.fontColor,
                              },
                            }}
                            style={{
                              border: props.bgColor.line01,
                              disabled: props.bgColor.text01.fontColor,
                            }}
                            InputLabelProps={{
                              style: { color: props.bgColor.text01.fontColor }, // ラベルの色を指定
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CheckCircleIcon sx={{ color: "#008000" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <TextField
                            label="Email Address"
                            name="email"
                            margin="normal"
                            value={email}
                            variant="standard"
                            fullWidth
                            disabled
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ErrorIcon sx={{ color: "#DD0000" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Button onClick={() => VerifyEmail()}>
                            メール認証を実施する
                          </Button>
                        </>
                      )}

                      {/* <table>
                      <tbody>
                        <tr>
                          <td>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                handleLogout(props);
                              }}
                            >
                              <ExitToAppIcon />
                              ログアウト
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                navigate("/Payment");
                              }}
                            >
                              <ContactEmergencyIcon />
                              ステータス確認
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                navigate("/Point");
                              }}
                            >
                              <NewspaperIcon />
                              プラン確認
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                    </Box>
                  </CardContent>
                  <Divider />
                </Card>
              </Grid>
              {help ? (
                <>
                  <Grid item xs={12} md={6}>
                    <Card
                      style={{
                        backgroundColor: props.bgColor.back01,
                        color: props.bgColor.text01.fontColor,
                      }}
                    >
                      <CardHeader title="プロフィールの変更方法" />
                      <Divider />
                      <CardContent>
                        <Typography>
                          ユーザ名変更
                          <br />
                          ⇒表示名欄を変えて、
                          <CachedIcon sx={{ color: "#6495ed" }} />
                          を押下で変更できます。
                        </Typography>
                        <br />
                        <Typography>
                          メール認証マーク
                          <br />
                          <CheckCircleIcon sx={{ color: "#008000" }} />
                          はメール認証済み
                          <ErrorIcon sx={{ color: "#DD0000" }} />
                          は未認証
                          <br />
                          「メール認証を実施する」を押下ください。
                        </Typography>
                        <br />
                        <Typography>
                          パスワードを変更したい場合は、
                          <Link
                            href="#"
                            color="#6495ed"
                            onClick={() => {
                              handleRePasswordSet;
                            }}
                          >
                            こちら
                          </Link>
                        </Typography>
                        <br />
                        <Typography>
                          アカウントを削除したい場合は、
                          <Link
                            href="#"
                            color="#6495ed"
                            onClick={() => {
                              setDialogOpen(true);
                            }}
                          >
                            こちら
                          </Link>
                        </Typography>
                        <div style={{ color: props.bgColor.back01 }}>{uid}</div>
                        <br />
                      </CardContent>
                      <Divider />
                    </Card>
                  </Grid>
                </>
              ) : (
                <></>
              )}

              <Grid item xs={12}>
                <form autoComplete="off" noValidate>
                  <Card
                    style={{
                      backgroundColor: props.bgColor.back01,
                      color: props.bgColor.text01.fontColor,
                    }}
                  >
                    <CardHeader title="作成履歴" />
                    <Divider />
                    <CardContent>
                      {Object.entries(createdata).map((create) => (
                        <div key={create[0]}>
                          {!create[1].isDelete ? (
                            <table>
                              <tbody style={{ tableLayout: "fixed" }}>
                                <tr>
                                  <td style={{ width: "100%" }}>
                                    <button
                                      className={classes.indicatorButtongray}
                                      style={{
                                        backgroundColor: props.bgColor.back01,
                                        color: props.bgColor.text01.fontColor,
                                      }}
                                      onClick={() => {
                                        window.scrollTo(0, 0);
                                        navigate("/Lonpa/" + create[0]);
                                      }}
                                    >
                                      <div
                                        className={
                                          classes.IndicatorButtonCaption
                                        }
                                        style={{
                                          width: "100%",
                                          whiteSpace: "pre-wrap",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        <font style={{ fontSize: "10px" }}>
                                          {create[1].createdAt}
                                        </font>
                                        <br />
                                        <font style={{ fontWeight: "bold" }}>
                                          {create[1].claim}
                                        </font>
                                      </div>
                                    </button>
                                  </td>
                                  <td>
                                    <Button
                                      onClick={() => {
                                        setEditLonpa({
                                          id: create[0],
                                          claim: create[1].claim,
                                          isopinion: create[1].isopinion,
                                          parent: create[1].parent,
                                          isDelete: create[1].isDelete,
                                        });
                                        setEditLonpaIsDelete(
                                          create[1].isDelete
                                        );
                                        setLonpaEditmodalopen(true);
                                      }}
                                    >
                                      編集
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                      <Button onClick={() => loadCreateHistory()}>
                        さらに確認する
                      </Button>
                    </CardContent>
                  </Card>
                </form>
              </Grid>
              <Grid item xs={12}>
                <form autoComplete="off" noValidate>
                  <Card
                    style={{
                      backgroundColor: props.bgColor.back01,
                      color: props.bgColor.text01.fontColor,
                    }}
                  >
                    <CardHeader title="投票履歴" />
                    <Divider />
                    <CardContent>
                      {Object.entries(votedata).map((vote) => (
                        <div key={vote[0]}>
                          {vote[1].isAgree ? (
                            <div>
                              <button
                                className={classes.indicatorButtonblue}
                                style={{
                                  backgroundColor: props.bgColor.back01,
                                  color: props.bgColor.text01.fontColor,
                                }}
                                onClick={() => {
                                  navigate("/Lonpa/" + vote[0]);
                                }}
                              >
                                <div
                                  className={classes.IndicatorButtonCaption}
                                  style={{
                                    width: "100%",
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  <font style={{ fontSize: "10px" }}>
                                    {vote[1].votedAt}
                                  </font>
                                  <br />
                                  <font style={{ fontWeight: "bold" }}>
                                    {vote[1].claim}
                                  </font>
                                  <table>
                                    <tbody style={{ tableLayout: "fixed" }}>
                                      <tr>
                                        <td style={{ width: "80px" }}>
                                          <font color={"#3f51b5"}>
                                            【賛成】{" "}
                                          </font>
                                        </td>
                                        <td> {vote[1].votedClaim}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                className={classes.indicatorButtonred}
                                style={{
                                  backgroundColor: props.bgColor.back01,
                                  color: props.bgColor.text01.fontColor,
                                }}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  navigate("/Lonpa/" + vote[0]);
                                }}
                              >
                                <div
                                  className={classes.IndicatorButtonCaption}
                                  style={{
                                    width: "100%",
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  <font style={{ fontSize: "10px" }}>
                                    {vote[1].votedAt}
                                  </font>
                                  <br />
                                  <font style={{ fontWeight: "bold" }}>
                                    {vote[1].claim}
                                  </font>

                                  <table>
                                    <tbody style={{ tableLayout: "fixed" }}>
                                      <tr>
                                        <td style={{ width: "80px" }}>
                                          <font color={"#f44336"}>
                                            【反対】{" "}
                                          </font>
                                        </td>
                                        <td> {vote[1].votedClaim}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      <Button onClick={() => loadVoteHistory()}>
                        さらに確認する
                      </Button>
                    </CardContent>
                  </Card>
                </form>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
        <LonpaEditModal
          open={lonpaEditmodalopen}
          setOpen={setLonpaEditmodalopen}
          editLonpa={editLonpa}
          editLonpaIsDelete={editLonpaIsDelete}
          setEditLonpaIsDelete={setEditLonpaIsDelete}
          setUploaderror={setUploaderror}
        />
      </div>

      <ConfirmModal
        open={dialogOpen}
        onClose={handleCloseDialog}
        title="Confirm Action"
        message="Are you sure you want to perform this action?"
      />

      <Footer
        bgColor={props.bgColor}
        styletext={props.styletext}
        isimgshow={true}
      />
    </>
  );
};
export default Userpage;

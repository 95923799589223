import { initializeApp } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey_Lonpa,
  authDomain: process.env.REACT_APP_authDomain_Lonpa,
  projectId: process.env.REACT_APP_projectId_Lonpa,
  storageBucket: process.env.REACT_APP_storageBucket_Lonpa,
  messagingSenderId: process.env.REACT_APP_messagingSenderId_Lonpa,
  appId: process.env.REACT_APP_appId_Lonpa,
  measurementId: process.env.REACT_APP_measurementId_Lonpa,
};

const firebaseConfig2 = {
  apiKey: process.env.REACT_APP_apiKey_User,
  authDomain: process.env.REACT_APP_authDomain_User,
  projectId: process.env.REACT_APP_projectId_User,
  storageBucket: process.env.REACT_APP_storageBucket_User,
  messagingSenderId: process.env.REACT_APP_messagingSenderId_User,
  appId: process.env.REACT_APP_appId_User,
  measurementId: process.env.REACT_APP_measurementId_User,
};
// Initialize Firebase
//firebase.initializeApp(firebaseConfig);

export const firebaseApp = initializeApp(firebaseConfig);
// 別のプロジェクトのFirebaseアプリを初期化
export const userApp = initializeApp(firebaseConfig2, "LamactUser");

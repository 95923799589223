import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useStyles } from "../Styles";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Button, Link } from "@mui/material";
import { pointAdd, pointSubMakeRon } from "../common/Helper/PointHelper";

import LodingModal from "./CommonModal/LodingModal";

import {
  getUserPublic,
  getUserSecret,
  getCustomersSubscriptions,
  getUserSecretHistory12,
  getUserSecretPoint,
} from "../common/Helper/FirebaseHelper";

import { userApp } from "../common/firebase";

import moment from "moment";

export default function Cancell(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPoint, setUserPoint] = useState("");

  const [userIsStandard, setUserIsStandard] = useState(false);
  const [userIsPro, setUserIsPro] = useState(false);

  const [userPointHistory, setUserPointHistory] = useState([]);

  const [subscriptionsdatas, setSubscriptionsdatas] = useState([]);

  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  const cellStyle = {
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center",
    fontSize: "14px",
  };

  const parseDate = (dateTimeStr) => {
    const date = new Date(dateTimeStr * 1000);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    return (
      year +
      "/" +
      month +
      "/" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  useEffect(() => {
    console.log("uE_Payment");
    setLodingmodalopen(true);

    onAuthStateChanged(getAuth(userApp), async (user) => {
      if (user) {
        //サブスク登録状況確認
        await user.getIdToken(true);
        const decodedToken = await user.getIdTokenResult();
        if (decodedToken.claims.stripeRole == "standard") {
          setUserIsStandard(true);
        } else if (decodedToken.claims.stripeRole == "pro") {
          setUserIsPro(true);
        } else {
          navigate("/Point");
        }

        setUserId(user.uid);
        setUserEmail(user.email);

        //■■■■■■■■■■■■■point確認
        const nowpoint = await getUserSecretPoint(user.uid);
        setUserPoint(nowpoint);

        //■■■■■■■■■■■■■point履歴確認
        const pointhistoryquerySnapshot = await getUserSecretHistory12(
          user.uid
        );

        let makepointhistorydatas = [];
        pointhistoryquerySnapshot.forEach((doc) => {
          if (doc.data()) {
            let expirationDate;
            if (doc.data().expirationDate) {
              expirationDate = parseAsMoment(doc.data().expirationDate.toDate())
                .format("YYYY/MM/DD HH:mm")
                .toString();
            } else {
              expirationDate = "-";
            }

            //console.log(doc.data());

            makepointhistorydatas.push({
              date: parseAsMoment(doc.data().date.toDate())
                .format("YYYY/MM/DD HH:mm")
                .toString(),
              expirationDate: expirationDate,
              pointchange: doc.data().pointchange,
              text1: doc.data().text1,
              text2: doc.data().text2,
              lonpaID: doc.data().lonpaID,
              uid: doc.data().uid,
            });
          }
        });
        setUserPointHistory(makepointhistorydatas);
        //console.log(makepointhistorydatas);

        //■■■■■■■■■■■■■サブスク履歴確認
        const subscriptionsquerySnapshot = await getCustomersSubscriptions(
          user.uid
        );
        let makesubscriptionsdatas = [];
        subscriptionsquerySnapshot.forEach((doc) => {
          if (doc.data()) {
            console.log(doc.data());
            let canceled;
            if (doc.data().canceled_at) {
              canceled = parseDate(doc.data().canceled_at.seconds);
            } else {
              canceled = "継続中";
            }
            makesubscriptionsdatas.push({
              created: parseDate(doc.data().created.seconds),
              canceld: canceled,
              amount: doc.data().items[0].plan.amount,
              role: doc.data().role,
            });
          }
        });
        setSubscriptionsdatas(makesubscriptionsdatas);
      }
    });
    setLodingmodalopen(false);
  }, []);

  return (
    <div
      style={{
        backgroundColor: props.bgColor.back01,
        color: props.bgColor.text01.fontColor,
      }}
      align="center"
    >
      <div style={{ height: "6vh" }}></div>
      <Container>
        <Grid container spacing={3}>
          {userIsStandard || userIsPro ? (
            <>
              <Grid item xs={12}>
                <h2 id="transition-modal-title" align="center">
                  ステータス確認
                </h2>
              </Grid>
              <Grid item xs={12}>
                {userIsStandard ? (
                  <>現在 Lamact Standardプラン加入中</>
                ) : (
                  <>現在 Lamact Proプラン加入中</>
                )}
                <br />
                <br />
                <div style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
                  {userPoint} point 所持
                </div>
                <br />
                <br />
                <Button
                  disabled={false}
                  size="large"
                  variant="contained"
                  style={{ fontSize: 15 }}
                  onClick={() => {
                    navigate("/Cancell");
                  }}
                >
                  領収書・プラン変更・解約
                </Button>
                <br />
              </Grid>
              <Grid item xs={12}>
                <br />
                あなたの支払い履歴を表示します
                <br />
                <table>
                  <tbody>
                    <tr style={{ borderTop: "solid 3px #CCC" }}>
                      <td style={cellStyle}>プラン</td>
                      <td style={cellStyle}>料金</td>
                      <td style={cellStyle}>加入日</td>
                      <td style={cellStyle}>キャンセル日</td>
                    </tr>

                    {subscriptionsdatas.map((item, index) => (
                      <tr style={{ borderTop: "solid 3px #CCC" }}>
                        <td style={cellStyle}>{item.role}</td>
                        <td style={cellStyle}>{item.amount}</td>
                        <td style={cellStyle}>{item.created}</td>
                        <td style={cellStyle}>{item.canceld}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
              </Grid>

              <Grid item xs={12}>
                あなたのpoint履歴を表示します
                <br />
                <table>
                  <tbody>
                    <tr style={{ borderTop: "solid 3px #CCC" }}>
                      <td style={cellStyle}>種別</td>
                      <td style={cellStyle}>内容</td>
                      <td style={cellStyle}>ポイント</td>
                      <td style={cellStyle}>日付</td>
                      <td style={cellStyle}>期限</td>
                      <td style={cellStyle}>リンク</td>
                    </tr>

                    {userPointHistory.map((item, index) => (
                      <tr style={{ borderTop: "solid 3px #CCC" }}>
                        <td style={cellStyle}>{item.text1}</td>
                        <td style={cellStyle}>{item.text2}</td>

                        <td style={cellStyle}>{item.pointchange}</td>
                        <td style={cellStyle}>{item.date}</td>
                        <td style={cellStyle}>{item.expirationDate}</td>
                        <td style={cellStyle}>
                          {item.lonpaID != "none" ? (
                            <>
                              <Link
                                color="inherit"
                                onClick={() =>
                                  navigate("/Lonpa/" + item.lonpaID)
                                }
                              >
                                リンク
                              </Link>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
              </Grid>
            </>
          ) : (
            <>
              {" "}
              <Grid item xs={12}>
                <h2 id="transition-modal-title" align="center">
                  ステータス確認
                </h2>
                <div style={{ height: "70vh" }}></div>
              </Grid>
            </>
          )}

          {/* <Grid item xs={6}>
            <Button
              disabled={false}
              size="large"
              variant="contained"
              onClick={() => {
                pointAdd(userId);
              }}
              style={{ fontSize: 20 }}
            >
              毎月ポイント追加
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={false}
              size="large"
              variant="contained"
              onClick={() => {
                pointSubMakeRon("none");
              }}
              style={{ fontSize: 20 }}
            >
              ポイント利用
            </Button>
          </Grid> */}
        </Grid>
      </Container>

      {lodingmodalopen ? (
        <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
      ) : (
        <></>
      )}
    </div>
  );
}

import {
  Box,
  CircularProgress,
  createTheme,
  Grid,
  Typography,
} from "@mui/material";
import Battery80Icon from "@mui/icons-material/Battery80";
import React from "react";
//import styled from 'styled-components'
import styled from "styled-components";

const CircularInternalContent = styled.div`
  left: 0;
  top: 0px;
  bottom: 0;
  right: 0;
  position: absolute;
`;

const ProbabilitySuffix = styled(Typography)`
  margin-bottom: 4px;
`;

const StyledCircularBackground = styled(CircularProgress)`
  color: #ffffff;
`;

const StyledCircularBar = styled(CircularProgress)`
  position: absolute;
  color: #ffffff;
`;

export default function HowtoPieChart(props) {
  const value = 29;
  const chartcolors = { 0: "#757ce8", 1: "#ff7961" };
  return (
    <Box position="relative" display="inline-flex">
      <CircularInternalContent>
        <Grid container justify="center"></Grid>
      </CircularInternalContent>
      {isNaN(props.disagreeratio) ? (
        <>
          {" "}
          {/* 背景用のCircularProgress */}
          <StyledCircularBackground
            variant="determinate"
            size={100}
            value={100}
            style={{ color: "gray" }}
          />
        </>
      ) : (
        <>
          {" "}
          {/* 背景用のCircularProgress */}
          <StyledCircularBackground
            variant="determinate"
            size={100}
            value={100}
            style={{ color: chartcolors[0] }}
          />
          {/* バロメーター用のCircularProgress */}
          <StyledCircularBar
            variant="determinate"
            size={100}
            value={props.disagreeratio}
            style={{ color: chartcolors[1] }}
          />
        </>
      )}
    </Box>
  );
}

import { Button } from "@mui/material";
import React from "react";

type Props = {
  message: any;
  setIsStage1Finish: any;
  setIsStage2Finish: any;
  setIsStage25Finish: any;
  bgColor: any;
  styletext: any;
  isBlue: boolean;
  setChoice: any;
  UpdateVote: any;
};

const Reason: React.VFC<Props> = ({
  message,
  setIsStage1Finish,
  setIsStage2Finish,
  setIsStage25Finish,
  bgColor,
  styletext,
  isBlue,
  setChoice,
  UpdateVote,
}: Props) => {
  const BlueButton2 = {
    fontSize: "13px",
    color: bgColor.text01.fontColor,
    width: "100%",
    height: "5em",
    fontWeight: "bold",
    // border: bgColor.line01,
    backgroundColor: bgColor.back03,
  };
  const RedButton2 = {
    fontSize: "13px",
    color: bgColor.text01.fontColor,
    width: "100%",
    height: "5em",
    fontWeight: "bold",
    // border: bgColor.line01,
    backgroundColor: bgColor.back02,
  };
  const GrayButton2 = {
    fontSize: "13px",
    color: bgColor.text01.fontColor,
    width: "100%",
    height: "50px",
    fontWeight: "bold",
    // border: bgColor.line01,
    backgroundColor: bgColor.back01,
  };

  return (
    <>
      <tr>
        <div
          style={{
            height: "10px",
          }}
        ></div>
      </tr>

      {Object.values(message).map((item: any, index: number) => (
        <tr key={index}>
          <td
            style={{
              width: styletext.windowPhoneWidth * 0.55,
            }}
          >
            <Button
              style={{
                ...(isBlue ? BlueButton2 : RedButton2),
                position: "relative",
              }}
              onClick={() => {
                setChoice({
                  choiceText: item.data.claim,
                  sameAgree: 0,
                  sameOpinion: item.data.votenum,
                  talkText: "",
                });
                UpdateVote(item.data);
                setIsStage2Finish(true);
                setIsStage25Finish(false);
              }}
            >
              {item.data.claim}
            </Button>
          </td>
        </tr>
      ))}
      <tr>
        <td
          style={{
            width: styletext.windowPhoneWidth * 0.55,
          }}
        >
          <Button
            style={{
              ...GrayButton2,
              position: "relative",
            }}
            onClick={() => {
              setIsStage1Finish(false);
              setIsStage25Finish(false);
            }}
          >
            戻る
          </Button>
        </td>
      </tr>
    </>
  );
};

export default Reason;

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getUserPrivateUploadStartAfter } from "../common/Helper/FirebaseHelper";

import LodingModal from "./CommonModal/LodingModal";
import Footer from "./components/Footer";

type Props = {
  bgColor: any;
  setAutoCreate: any;
  styletext: any;
};

const Uploader: React.VFC<Props> = ({
  bgColor,
  setAutoCreate,
  styletext,
}: Props) => {
  const [test, setTest] = useState("");
  const [lodingmodalopen, setLodingmodalopen] = useState(false);
  const navigate = useNavigate();
  const { userid } = useParams();
  const [selectedPickups, setSelectedPickups] = useState([]);

  const cellStyle = {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };
  const tabletitlestyle = {
    fontSize: 16,
    fontWeight: "bold",
    color: bgColor.text01.fontColor,
  };

  const useridstring = userid as string;

  useEffect(() => {
    console.log("uE_Uploader");
    const f = async () => {
      await getUploads();
    };
    f();
  }, []);

  async function getUploads() {
    console.log("look");
    const querySnapshot = await getUserPrivateUploadStartAfter(0, 3);
    const idealist: any = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.data());
      idealist.push({
        id: doc.id,
        num: doc.data().num,
        createdAt: doc.data().createdAt,
      });
    });
    setSelectedPickups(idealist);
  }

  return (
    <>
      <div style={{ backgroundColor: bgColor.back01 }}>
        <div style={{ height: "6vh" }}></div>

        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: bgColor.back01,
                color: bgColor.text01.fontColor,
              }}
            >
              ここはアップロード予定の動画を一覧化するところだよ
              <br />
              あなたのID：{useridstring}
              <br />
              <Button
                onClick={() => {
                  getUploads();
                }}
              >
                一覧更新
              </Button>
              <br />
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow key="top">
                      <TableCell align="center" style={tabletitlestyle}>
                        id
                      </TableCell>

                      <TableCell align="center" style={tabletitlestyle}>
                        主張
                      </TableCell>
                      <TableCell align="center" style={tabletitlestyle}>
                        1
                      </TableCell>
                      <TableCell align="center" style={tabletitlestyle}>
                        2
                      </TableCell>
                      <TableCell align="center" style={tabletitlestyle}>
                        3
                      </TableCell>
                      <TableCell align="center" style={tabletitlestyle}>
                        4
                      </TableCell>
                      <TableCell align="center" style={tabletitlestyle}>
                        5
                      </TableCell>
                    </TableRow>
                    {selectedPickups.map((pickup: any, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          style={{
                            width: "5%",
                            padding: "0",
                            lineHeight: "0",
                            color: bgColor.text01.fontColor,
                          }}
                        >
                          {pickup.id}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            width: "20%",
                            padding: "0",
                            lineHeight: "0",
                            color: bgColor.text01.fontColor,
                          }}
                        >
                          {pickup.num}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            width: "5%",
                            padding: "0",
                            lineHeight: "0",
                            color: bgColor.text01.fontColor,
                          }}
                        >
                          <Checkbox
                            checked={pickup.isflagone}
                            style={{
                              color: bgColor.back11,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            width: "5%",
                            padding: "0",
                            lineHeight: "0",
                            color: bgColor.text01.fontColor,
                          }}
                        >
                          <Checkbox
                            checked={pickup.isflagtwo}
                            style={{
                              color: bgColor.back11,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            width: "5%",
                            padding: "0",
                            lineHeight: "0",
                            color: bgColor.text01.fontColor,
                          }}
                        >
                          <Checkbox
                            checked={pickup.isflagthree}
                            style={{
                              color: bgColor.back11,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            width: "5%",
                            padding: "0",
                            lineHeight: "0",
                            color: bgColor.text01.fontColor,
                          }}
                        >
                          <Checkbox
                            checked={pickup.isflagfour}
                            style={{
                              color: bgColor.back11,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            width: "5%",
                            padding: "0",
                            lineHeight: "0",
                            color: bgColor.text01.fontColor,
                          }}
                        >
                          <Checkbox
                            checked={pickup.isflagfive}
                            style={{
                              color: bgColor.back11,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>

        <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
      </div>
      <Footer bgColor={bgColor} styletext={styletext} isimgshow={true} />
    </>
  );
};

export default Uploader;

import React from "react";
import styled, { keyframes } from "styled-components";

import Reason from "./Reason";
import Seyana from "./Seyana";

// フェードインアニメーションのキーフレームを定義
const fadeInAnimation = keyframes`
0%, 20% { /* アニメーションの最初の20%の間、28%の位置に待機 */
    opacity: 0;
}50%{
    opacity: 1;
}
`;

// フェードインアニメーションを適用するスタイル付きコンポーネント
const FadeInParagraph = styled.p`
  animation: ${fadeInAnimation} 2s ease-in-out;
`;

// フェードインアニメーションのキーフレームを定義
const fadeInAnimation2 = keyframes`
0%, 70% { /* アニメーションの最初の20%の間、28%の位置に待機 */
    opacity: 0;
}90%{
    opacity: 1;
}
`;

// フェードインアニメーションを適用するスタイル付きコンポーネント
const FadeInParagraph2 = styled.p`
  animation: ${fadeInAnimation2} 2s ease-in-out;
`;

type Props = {
  bgColor: any;
  styletext: any;
  setIsStage1Finish: any;
  setIsStage2Finish: any;
  setIsStage25Finish: any;
  message: any;
  isBlue: any;
  setChoice: any;
  UpdateVote: any;
};

const FadeInReason: React.VFC<Props> = ({
  bgColor,
  styletext,
  setIsStage1Finish,
  setIsStage2Finish,
  setIsStage25Finish,
  message,
  isBlue,
  setChoice,
  UpdateVote,
}: Props) => {
  return (
    <>
      <table
        style={{
          width: styletext.windowPhoneWidth * 0.55,
          position: "absolute",
          top: 0,
        }}
      >
        <tbody>
          <FadeInParagraph>
            <Seyana bgColor={bgColor} styletext={styletext} isBlue={isBlue} />
          </FadeInParagraph>

          <FadeInParagraph2>
            <Reason
              bgColor={bgColor}
              styletext={styletext}
              setIsStage1Finish={setIsStage1Finish}
              setIsStage2Finish={setIsStage2Finish}
              setIsStage25Finish={setIsStage25Finish}
              message={message}
              isBlue={isBlue}
              setChoice={setChoice}
              UpdateVote={UpdateVote}
            />
          </FadeInParagraph2>
        </tbody>
      </table>
    </>
  );
};

export default FadeInReason;

import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import LodingModal from "./CommonModal/LodingModal";

import {
  updateLonpa,
  getLonpa,
  updateUserPrivateCreateLonpa,
  getLonpaList,
  getAllLonpaChild,
  setLonpa,
  setUserPrivateCreateLonpa,
} from "../common/Helper/FirebaseHelper";

import { Button, Container, Grid, Checkbox } from "@mui/material";

import "bootstrap/dist/css/bootstrap.min.css";
import { checkisSubscription } from "../common/Helper/PointHelper";

import moment from "moment";
import { serverTimestamp } from "firebase/firestore";

export default function Lonpa(props) {
  let { lonpaid } = useParams();
  const navigate = useNavigate();
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const [claim, setClaim] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [isopinion, setIsopinion] = useState(false);
  const [userId, setUserId] = useState(false);

  const [childList, setChildList] = useState([]);

  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    console.log("uE_Debug");
    const f = async () => {
      const userStatus = await checkisSubscription();
      setUserId(userStatus.uid);
      if (!userStatus.isSubscribe) {
        navigate("/Top/");
      }
      const one = await getLonpa(lonpaid);
      const onedata = one.data();
      if (onedata.claim !== undefined) {
        setClaim(onedata.claim);
      } else {
        setLodingmodalopen(false);
        return;
      }
      if (onedata.createdBy) {
        setCreatedBy(onedata.createdBy);
      }
      if (onedata.isDelete) {
        setIsDelete(onedata.isDelete);
      }
      if (onedata.isopinion) {
        setIsopinion(onedata.isopinion);
      }

      const lonpaChildSnapshot = await getAllLonpaChild(lonpaid);

      //id一覧を取得
      let itemlist = [];
      for (let i = 0; i < lonpaChildSnapshot.size; i++) {
        const oneid = lonpaChildSnapshot.docs[i].id;
        itemlist.push(oneid);
      }

      //子のデータも確認
      const itemlistdata = await getLonpaList(itemlist);
      let childlons = [];

      for (let i = 0; i < lonpaChildSnapshot.size; i++) {
        const oneid = lonpaChildSnapshot.docs[i].id;
        const onedata = lonpaChildSnapshot.docs[i].data();

        let existFlag = false;

        itemlistdata.forEach((doc) => {
          if (typeof doc.id === "undefined") {
            console.log("データなし");
          } else {
            if (doc.id == oneid) {
              existFlag = true;
            }
          }
        });

        const oneeditdata = {
          id: oneid,
          agree: onedata.agree,
          claim: onedata.claim,
          isDelete: onedata.isDelete,
          votenum: onedata.votenum,
          isExist: existFlag,
        };

        childlons.push(oneeditdata);
      }
      setChildList(childlons);

      //ローディングモーダルを非表示に
      setLodingmodalopen(false);
    };
    f();
  }, []);

  async function LogicalDelete() {
    if (!isopinion) {
      //親Lonpaがいる場合
      console.log("削除失敗");
      return false;
    }
    await updateLonpa(lonpaid, {
      isDelete: true,
    });
    if (createdBy == userId) {
      await updateUserPrivateCreateLonpa(lonpaid, {
        isDelete: true,
      });
    }
    alert("このLonpaは削除されました");

    navigate("/Top/");
  }

  const nGrum = (name, n) => {
    const searchGrums = {};
    for (let i = 0; i < name.length; i++) {
      const results = [name.substr(i, n)];
      results.map((result) => {
        searchGrums[result] = true;
      });
    }
    return searchGrums;
  };

  async function newMakeChild(childid, childClaim, createdBy, lonpaid, claim) {
    //Lonpa一覧に本件を作成
    await setLonpa(childid, {
      claim: childClaim,
      updatedAt: serverTimestamp(),
      createdAt: serverTimestamp(),
      ngram: nGrum(childClaim, 2),
      agree: 0,
      disagree: 0,
      isopinion: false,
      createdBy: createdBy,
      parent: lonpaid,
      parentClaim: claim,
      isDelete: false,
      imageurl: "",
    });
    //ユーザの作成履歴を記録する
    await setUserPrivateCreateLonpa(childid, {
      claim: childClaim,
      createdAt: serverTimestamp(),
      isopinion: false,
      parent: lonpaid,
      isDelete: false,
    });

    alert("作成しました");
    navigate("/Sorry/" + lonpaid);
  }

  return (
    <div style={{ backgroundColor: props.bgColor.back01 }} align="center">
      <div style={{ height: "6vh" }}></div>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 id="transition-modal-title" align="center">
              Debug画面
            </h2>

            <table>
              <tbody>
                <tr style={{ borderTop: "solid 3px #CCC" }}>
                  <td>要素</td>
                  <td>内容</td>
                </tr>
                <tr style={{ borderTop: "solid 5px #CCC" }}>
                  <td>ID</td>
                  <td>
                    {" "}
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate("/Lonpa/" + lonpaid);
                      }}
                      style={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      {lonpaid}
                    </Button>
                  </td>
                </tr>
                <tr style={{ borderTop: "solid 1px #CCC" }}>
                  <td>claim</td>
                  <td>{claim}</td>
                </tr>
                <tr style={{ borderTop: "solid 1px #CCC" }}>
                  <td>createdBy</td>
                  <td>{createdBy}</td>
                </tr>

                <tr style={{ borderTop: "solid 1px #CCC" }}>
                  <td>あなたのID</td>
                  <td>{userId}</td>
                </tr>

                <tr style={{ borderTop: "solid 1px #CCC" }}>
                  <td>isDelete</td>
                  <td>
                    {isDelete ? (
                      <>
                        {" "}
                        <Checkbox disabled checked />
                      </>
                    ) : (
                      <>
                        <Checkbox disabled />
                      </>
                    )}
                  </td>
                </tr>
                <tr style={{ borderTop: "solid 1px #CCC" }}>
                  <td>isopinion</td>
                  <td>
                    {isopinion ? (
                      <>
                        {" "}
                        <Checkbox disabled checked />
                      </>
                    ) : (
                      <>
                        <Checkbox disabled />
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            {!isDelete && isopinion ? (
              <>
                {" "}
                <Button
                  disabled={false}
                  size="large"
                  variant="contained"
                  style={{ fontSize: 15 }}
                  onClick={() => {
                    LogicalDelete();
                  }}
                >
                  削除
                </Button>
              </>
            ) : (
              <></>
            )}
            <br />
          </Grid>
          {childList.map((item, index) => (
            <Grid item xs={12}>
              <table>
                <tbody>
                  <tr style={{ borderTop: "solid 3px #CCC" }}>
                    <td>子要素</td>
                    <td>内容</td>
                  </tr>

                  <tr style={{ borderTop: "solid 5px #CCC" }}>
                    <td>ID</td>
                    <td>
                      <Button
                        color="primary"
                        onClick={() => {
                          navigate("/Lonpa/" + item.id);
                        }}
                        style={{ fontSize: 16, fontWeight: "bold" }}
                      >
                        {item.id}
                      </Button>
                    </td>
                  </tr>
                  <tr style={{ borderTop: "solid 1px #CCC" }}>
                    <td>claim</td>
                    <td>{item.claim}</td>
                  </tr>

                  <tr style={{ borderTop: "solid 1px #CCC" }}>
                    <td>isAgree</td>
                    <td>
                      {item.agree ? (
                        <>
                          {" "}
                          <Checkbox disabled checked />
                        </>
                      ) : (
                        <>
                          <Checkbox disabled />
                        </>
                      )}
                    </td>
                  </tr>
                  <tr style={{ borderTop: "solid 1px #CCC" }}>
                    <td>isDelete</td>
                    <td>
                      {item.isDelete ? (
                        <>
                          {" "}
                          <Checkbox disabled checked />
                        </>
                      ) : (
                        <>
                          <Checkbox disabled />
                        </>
                      )}
                    </td>
                  </tr>
                  <tr style={{ borderTop: "solid 1px #CCC" }}>
                    <td>isExist</td>
                    <td>
                      {item.isExist ? (
                        <>
                          {" "}
                          <Checkbox disabled checked />
                        </>
                      ) : (
                        <>
                          <Checkbox disabled />
                          <Button
                            disabled={false}
                            size="large"
                            variant="contained"
                            style={{ fontSize: 15 }}
                            onClick={() => {
                              newMakeChild(
                                item.id,
                                item.claim,
                                createdBy,
                                lonpaid,
                                claim
                              );
                            }}
                          >
                            作る
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>

                  <tr style={{ borderTop: "solid 1px #CCC" }}>
                    <td>投票数</td>
                    <td>{item.votenum}</td>
                  </tr>
                </tbody>
              </table>
              <br />

              <br />
            </Grid>
          ))}
        </Grid>
      </Container>
      <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
    </div>
  );
}

import { useEffect, useState } from "react";
import { useStyles } from "../Styles";
import { getRandom } from "../common/Helper/FirebaseHelper";
import { useNavigate, useParams, Link } from "react-router-dom";

export default function Random(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("uE_Random");
    const f = async () => {
      //console.log("ランダム生成中");
      const nextlonpaid = await getRandom();
      console.log(nextlonpaid);
      window.scrollTo(0, 0);
      navigate("/Lonpa/" + nextlonpaid);
    };
    f();
  }, []);

  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <div style={{ height: "40vh" }}></div>
      <div align="center">ランダムLonpa選定中</div>

      <div style={{ height: "40vh" }}></div>
    </div>
  );
}

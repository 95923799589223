import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Box, Button } from "@mui/material";
import { useStyles } from "../Styles";
import { withStyles } from "@mui/styles";
import moment from "moment";
import LodingModal from "./CommonModal/LodingModal";
import StageBlock from "./Loadmap/StageBlock";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { userApp } from "../common/firebase";

const Loadmap = (props) => {
  const [upload, setupload] = useState(0);
  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const [userId, setUserId] = useState("");
  const [userIsLogin, setUserIsLogin] = useState(false);
  const [userIsSubscribed, setUserIsSubscribed] = useState(false);
  const [userIsStandard, setUserIsStandard] = useState(false);
  const [userIsPro, setUserIsPro] = useState(false);

  const [titleTextLogin, setTitleTextLogin] = useState(false);
  const [titleTextSubscribed, setTitleTextSubscribed] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  const CustomButton = withStyles({
    // root: {
    //   fontWeight: "normal",
    //   fontSize: "140px",
    //   borderWidth: "0px",
    //   borderColor: "#ffffff",
    //   "&:hover": {
    //     background: props.bgColor.back04,
    //     fontWeight: "bold",
    //     color: "#ffffff",
    //   },
    // },
  })((props) => <Button {...props} />);

  useEffect(() => {
    console.log("uE_Loadmap");
    const f = async () => {
      setLodingmodalopen(true);
      onAuthStateChanged(getAuth(userApp), async (user) => {
        if (user) {
          setUserId(user.uid);
          setUserIsLogin(true);
          setTitleTextLogin(false);
          await user.getIdToken(true);
          const decodedToken = await user.getIdTokenResult();

          if (decodedToken.claims.stripeRole == "standard") {
            setUserIsStandard(true);
            setUserIsPro(false);
            setUserIsSubscribed(true);
            setTitleTextSubscribed(false);
          } else if (decodedToken.claims.stripeRole == "pro") {
            setUserIsStandard(false);
            setUserIsPro(true);
            setUserIsSubscribed(true);
            setTitleTextSubscribed(false);
            // } else if (props.adminFlag) {
            //   //ここは要調整
            //   setUserIsStandard(false);
            //   setUserIsPro(true);
            //   setUserIsSubscribed(true);
            //   setTitleTextSubscribed(false);
          } else {
            setUserIsStandard(false);
            setUserIsPro(false);
            setUserIsSubscribed(false);
            setTitleTextSubscribed("サブスク加入しましょう");
          }
        } else {
          setUserIsLogin(false);
          setTitleTextLogin("アカウント作成しましょう");
          setUserIsSubscribed(false);
        }
      });
      setLodingmodalopen(false);
    };
    f();
  }, [upload]);

  const handleSignout = () => {
    signOut(getAuth(userApp))
      .then(() => {
        // alert("サインアウトしました");
        props.update();
        window.scrollTo(0, 0);
        navigate("/Signin");
      })
      .catch((error) => {
        // console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: props.bgColor.back04,
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <CustomButton
                  onClick={() => {
                    props.setIsSwipeOpen(false);
                  }}
                  style={{
                    width: `calc(calc(100% - 110px) / 3)`,
                    height: props.buttonHeight,
                    borderRadius: "0px",
                    color: props.bgColor.text08.fontColor,
                    fontWeight: "bold",
                  }}
                >
                  <ArrowForwardIosIcon
                    style={{
                      fill: props.bgColor.text08.fontColor,
                    }}
                  />
                </CustomButton>
              </td>

              {props.checkLogin ? (
                <td>
                  <Button
                    themecolor={"white2"}
                    onClick={() => {
                      props.stopAuto();
                      navigate("/Userpage");
                      props.setIsSwipeOpen(false);
                    }}
                    style={{
                      borderRadius: "0px",
                      paddingLeft: "0px",
                      color: props.bgColor.text04.fontColor,
                      fontSize: "10px",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr>
                          <td align="center">
                            <img
                              src={props.avatar.img}
                              alt="img"
                              className={classes.loginicon}
                            ></img>
                          </td>
                          <td align="center">
                            {props.avatar.name.length > 9
                              ? `${props.avatar.name.substring(0, 8)}...`
                              : props.avatar.name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Button>
                </td>
              ) : (
                <>
                  <td>
                    <CustomButton
                      onClick={() => {
                        props.stopAuto();
                        navigate("/Signup");
                        props.setIsSwipeOpen(false);
                      }}
                      style={{
                        width: `calc(calc(100% - 110px) / 4)`,
                        height: props.buttonHeight,
                        borderRadius: "0px",
                        color: props.bgColor.text08.fontColor,
                        fontWeight: "bold",
                      }}
                    >
                      <PersonAddAltIcon
                        style={{
                          fill: props.bgColor.text08.fontColor,
                          marginTop: "-10px",
                        }}
                      />
                      <div style={{ position: "absolute" }}>
                        <div
                          style={{
                            height: props.textMarginTop,
                            minWidth: "75px",
                          }}
                        ></div>
                        登録
                      </div>
                    </CustomButton>
                  </td>
                  <td>
                    <CustomButton
                      onClick={() => {
                        props.stopAuto();
                        navigate("/Signin");
                        props.setIsSwipeOpen(false);
                      }}
                      style={{
                        width: `calc(calc(100% - 110px) / 4)`,
                        height: props.buttonHeight,
                        borderRadius: "0px",
                        color: props.bgColor.text08.fontColor,
                        fontWeight: "bold",
                      }}
                    >
                      <PersonIcon
                        style={{
                          fill: props.bgColor.text08.fontColor,
                          marginTop: "-10px",
                        }}
                      />
                      <div style={{ position: "absolute" }}>
                        <div
                          style={{
                            height: props.textMarginTop,
                            minWidth: "75px",
                          }}
                        ></div>
                        ログイン
                      </div>
                    </CustomButton>
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ backgroundColor: props.bgColor.back01 }}>
        <Container maxWidth="lg">
          <StageBlock
            bgColor={props.bgColor}
            title={"基本機能"}
            hint={false}
            menu={[
              {
                text: "Lonpaとは？",
                excute: () => {
                  navigate("/Howto");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "意見の使い方",
                excute: () => {
                  navigate("/Lonpa");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "意見の検索",
                excute: () => {
                  navigate("/NewSearch/empty");
                  props.setIsSwipeOpen(false);
                },
              },
            ]}
            both={[]}
            subscribe={[
              {
                text: "存在しない選択肢",
                excute: () => {},
              },
            ]}
            isusable={true}
          />

          <StageBlock
            bgColor={props.bgColor}
            title={"無料機能"}
            hint={titleTextLogin}
            menu={[
              {
                text: "アカウント情報",
                excute: () => {
                  navigate("/Userpage");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "意見作成",
                excute: () => {
                  navigate("/Create");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "投票・作成履歴閲覧",
                excute: () => {
                  navigate("/Userpage");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "カラーパターン変更",
                excute: () => {
                  navigate("/ColorPattern");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "サインアウト",
                excute: () => {
                  handleSignout(); //この中で画面遷移
                  props.setIsSwipeOpen(false);
                },
              },
            ]}
            both={[]}
            subscribe={[
              {
                text: "アカウント登録",
                excute: () => {
                  navigate("/Signup");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "ログイン",
                excute: () => {
                  navigate("/Signin");
                  props.setIsSwipeOpen(false);
                },
              },
            ]}
            isusable={userIsLogin}
          />

          <StageBlock
            bgColor={props.bgColor}
            title={"有料機能"}
            hint={titleTextSubscribed}
            menu={[
              {
                text: "旧TOPページ",
                excute: () => {
                  navigate("/Top");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "旧Searchページ",
                excute: () => {
                  navigate("/Search");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "ポイント・支払い確認",
                excute: () => {
                  navigate("/Payment");
                  props.setIsSwipeOpen(false);
                },
              },
              {
                text: "プラン解約・変更",
                excute: () => {
                  navigate("/Cancell");
                  props.setIsSwipeOpen(false);
                },
              },
            ]}
            both={[
              {
                text: "動画自動生成",
                excute: () => {
                  navigate("/MovieMaker");
                  props.setIsSwipeOpen(false);
                },
              },
            ]}
            subscribe={[
              {
                text: "サブスクリプション登録",
                excute: () => {
                  navigate("/Point");
                  props.setIsSwipeOpen(false);
                },
              },
            ]}
            isusable={userIsSubscribed}
          />

          {props.adminFlag ? (
            <StageBlock
              bgColor={props.bgColor}
              title={"Admin機能"}
              hint={titleTextSubscribed}
              menu={[
                {
                  text: "動画整理",
                  excute: () => {
                    navigate("/MovieState/");
                    props.setIsSwipeOpen(false);
                  },
                },
                {
                  text: "Uploader",
                  excute: () => {
                    navigate("/Uploader/" + userId);
                    props.setIsSwipeOpen(false);
                  },
                },
              ]}
              both={[]}
              subscribe={[]}
              isusable={userIsSubscribed}
            />
          ) : (
            <></>
          )}
        </Container>
        <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
      </div>
    </>
  );
};
export default Loadmap;

import React from "react";
import { useStyles } from "../../Styles";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon,
} from "react-share";

const config = {
  size: 25,
};

export default function SocialShare(props) {
  const classes = useStyles();
  const { followtag, url, title, size, tag } = props;
  return (
    <div className={classes.miniicon}>
      <FacebookShareButton url={url}>
        <FacebookIcon size={size ? size : config.size} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title + tag + followtag}>
        <TwitterIcon size={size ? size : config.size} round />
      </TwitterShareButton>
      <LineShareButton url={url} title={title}>
        <LineIcon size={size ? size : config.size} round />
      </LineShareButton>
    </div>
  );
}

import { useEffect, useState } from "react";
import { Button, SwipeableDrawer } from "@mui/material";
import { useStyles } from "../../Styles";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SearchIcon from "@mui/icons-material/Search";
import CreateIcon from "@mui/icons-material/Create";

import MenuIcon from "@mui/icons-material/Menu";
import { getUserPublic } from "../../common/Helper/FirebaseHelper";
import Logobar from "../components/Logobar";
import Loadmap from "../Loadmap";

import LoadingNavbar from "./LoadingNavbar";

import { adminUid } from "../../common/Config/AdminConfig";

import { userApp } from "../../common/firebase";

const Navbar = (props) => {
  const CustomButton = withStyles({
    root: {
      fontWeight: "normal",
      fontSize: "140px",
      borderWidth: "0px",
      borderColor: "#ffffff",
      "&:hover": {
        background: props.bgColor.back04,
        fontWeight: "bold",
        color: "#ffffff",
      },
    },
  })((props) => <Button {...props} />);

  const [checkLogin, setCheckLogin] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [isSwipeOpen, setIsSwipeOpen] = useState(false);
  const [adminFlag, setAdminFlag] = useState(false);

  const [isParamReady, setIsParamReady] = useState(false);

  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    console.log("uE_Navbar");

    const unsubscribed = onAuthStateChanged(getAuth(userApp), async (user) => {
      if (user) {
        if (adminUid.includes(user.uid)) {
          setAdminFlag(true);
        }

        const avatarDoc = await getUserPublic(user.uid);
        if (
          typeof avatarDoc.data() !== "undefined" &&
          avatarDoc.data().imageurl
        ) {
          props.setAvatar({
            img: avatarDoc.data().imageurl,
            name: avatarDoc.data().name,
          });
          if (avatarDoc.data().colorPattern) {
            props.changeBgColor(avatarDoc.data().colorPattern);
          } else {
            props.changeBgColor(1);
          }
        } else {
          props.setAvatar({
            img: "../nanashi.png",
            name: "名無し",
          });
          props.changeBgColor(1);
        }
        setCheckLogin(true);
      } else {
        setCheckLogin(false);
        props.changeBgColor(2);
      }
      setIsParamReady(true);
    });
    return () => {
      props.changeBgColor(2);
      unsubscribed();
    };
  }, []);
  // useEffect(() => {
  //   console.log("uE_Navbar__props.avatar");
  //   setAvatar(props.avatar);
  // }, [props.avatar]);

  //modal呼び出しに必要

  const buttonHeight = "70px";
  const textMarginTop = "30px";

  return (
    <>
      {isParamReady ? (
        <>
          {props.bgColor ? (
            <>
              <div style={{ position: "fixed", zIndex: 100 }}>
                <Logobar stopAuto={props.stopAuto} bgColor={props.bgColor} />
              </div>
              <img
                style={{
                  display: "inline-block",
                  width: "110px",
                  backgroundColor: props.bgColor.back04,
                }}
              ></img>

              <CustomButton
                onClick={() => {
                  props.stopAuto();
                  navigate("/NewSearch/empty");
                }}
                style={{
                  width: `calc(calc(100% - 110px) / 3)`,
                  height: buttonHeight,
                  borderRadius: "0px",
                  color: props.bgColor.text08.fontColor,
                  fontWeight: "bold",
                }}
              >
                <SearchIcon
                  style={{
                    fill: props.bgColor.text08.fontColor,
                  }}
                />
              </CustomButton>
              <CustomButton
                onClick={() => {
                  props.stopAuto();
                  navigate("/Create");
                }}
                style={{
                  width: `calc(calc(100% - 110px) / 3)`,
                  height: buttonHeight,
                  borderRadius: "0px",
                  color: props.bgColor.text08.fontColor,
                  fontWeight: "bold",
                }}
              >
                <CreateIcon
                  style={{
                    fill: props.bgColor.text08.fontColor,
                  }}
                />
              </CustomButton>
              <CustomButton
                onClick={() => {
                  //props.stopAuto();
                  setIsSwipeOpen(true);
                }}
                style={{
                  width: `calc(calc(100% - 110px) / 3)`,
                  height: buttonHeight,
                  borderRadius: "0px",
                  color: props.bgColor.text08.fontColor,
                  fontWeight: "bold",
                }}
              >
                <MenuIcon
                  style={{
                    fill: props.bgColor.text08.fontColor,
                  }}
                />
              </CustomButton>

              <SwipeableDrawer
                anchor={"right"}
                open={isSwipeOpen}
                onClose={() => {
                  setIsSwipeOpen(false);
                }}
                onOpen={() => {
                  setIsSwipeOpen(true);
                }}
                PaperProps={{
                  sx: {
                    backgroundColor: props.bgColor.back01,
                    width: props.styletext.widthUnder / 2,
                  },
                }}
              >
                <Loadmap
                  styletext={props.styletext}
                  bgColor={props.bgColor}
                  checkLogin={checkLogin}
                  avatar={props.avatar}
                  buttonHeight={buttonHeight}
                  textMarginTop={textMarginTop}
                  setIsSwipeOpen={setIsSwipeOpen}
                  stopAuto={props.stopAuto}
                  adminFlag={adminFlag}
                />
              </SwipeableDrawer>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <LoadingNavbar bgColor={props.bgColor} styletext={props.styletext} />
      )}
    </>
  );
};

export default Navbar;

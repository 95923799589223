import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useStyles } from "../../Styles";
import ReactLoading from "react-loading";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Button } from "@mui/material";
import { useRef, useState } from "react";
import Compressor from "compressorjs";

import {
  updateLonpa,
  updateLonpaChild,
  updateUserPrivateCreateLonpa,
} from "../../common/Helper/FirebaseHelper";
import { getAuth } from "firebase/auth";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firebaseApp, userApp } from "../../common/firebase";

import { useNavigate } from "react-router-dom";
export default function LonpaEditModal(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  //uploadできる画像のバイト数のリミット
  const limitUpdateByte = 300000;
  const [uploadProgress, setUploadProgress] = useState("");
  const handleCloseinside = () => {
    props.setOpen(false);
  };
  //特定のボタンを押すとフォルダが開くようにする
  //useRefの初期期はnullとする。
  const inputRef = useRef(null);
  const fileUpload = () => {
    //ref属性を持っている要素の情報がinputRef.currentにあり、それにクリックしたときの挙動をする。
    inputRef.current.click();
  };
  //画像アップロード処理
  async function onSubmit(event) {
    event.preventDefault();
    props.setUploaderror("");
    //ファイルが選択されたかどうかを確認する
    if (typeof event.target.files[0] === "undefined") {
      console.log("ファイルが選択されていません");
      props.setUploaderror("ファイルが選択されていません");
      return;
    } else {
      //event.target.files[0]にある選択されたファイル情報を取得する。
      let uploadImage = event.target.files[0];
      //画像を圧縮
      new Compressor(uploadImage, {
        quality: 0.6,
        success(result) {
          const image = result;
          if (image.size < limitUpdateByte) {
            //imgnameの名前づけ(uid+imgname)
            let imgname = Date.now();
            let uid = getAuth(userApp).currentUser.uid;
            //console.log(uid);
            const storage = getStorage(firebaseApp);
            //どのフォルダを基準にするのかを設定
            const storageRef = ref(
              storage,
              "lonpa/" + props.editLonpa.id + "/image/" + uid + imgname
            );
            //第一引数で設定したところに、ファイルをアップロードするための設定
            const uploadTask = uploadBytesResumable(storageRef, image);
            // const uploadTask = uploadBytes(storageRef, image);
            //アップロード実行。格納うまくいったら、そのURL情報をchat部分に格納
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
                console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case "paused":
                    console.log("Upload is paused");
                    break;
                  case "running":
                    console.log("Upload is running");
                    break;
                }
              },
              (error) => {
                setUploaderror("ファイルアップに失敗しました。" + error);
                console.log(error.code);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  const f = async () => {
                    //URLをチャットの中身としてchat内容を登録する
                    let uid;
                    try {
                      uid = getAuth(userApp).currentUser.uid;
                    } catch {
                      uid = false;
                    }
                    await updateLonpa(props.editLonpa.id, {
                      imageurl: downloadURL,
                    });
                  };
                  f();
                });
              }
            );
            return;
          } else {
            alert("容量が大きすぎます。やり直してください");
            props.setUploadmodalopen(false);
          }
        },
        error(err) {
          console.log("圧縮に失敗しました。");
          return;
        },
      });
    }
  }

  async function LogicalDelete(event) {
    //console.log("削除ボタン押下");
    //console.log(props.editLonpa.parent, props.editLonpa.id);
    if (!props.editLonpa.isopinion) {
      //親Lonpaがいる場合
      await updateLonpaChild(props.editLonpa.parent, props.editLonpa.id, {
        isDelete: true,
      });
    }
    await updateLonpa(props.editLonpa.id, {
      isDelete: true,
    });
    if (props.createrFlag) {
      await updateUserPrivateCreateLonpa(props.editLonpa.id, {
        isDelete: true,
      });
    }
    props.setEditLonpaIsDelete(true);
    alert("このLonpaは削除されました");

    navigate("/Top/");
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">
            「{props.editLonpa.claim}」を削除しますか？
          </h2>
          {props.editLonpaIsDelete ? (
            <>このLonpaは削除されています</>
          ) : (
            <>
              この処理は元に戻すことはできません
              <br />
              <Button
                onClick={() => LogicalDelete()}
                style={{ fontSize: 20, bottom: 0 }}
              >
                削除
              </Button>
              {/* {props.editLonpa.isopinion ? (
                <>
                  <Button
                    color="primary"
                    endIcon={<AddPhotoAlternateIcon />}
                    onClick={fileUpload}
                    style={{ fontSize: 20, bottom: 0 }}
                  >
                    画像アップロード
                  </Button>

                  <input
                    hidden
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    onChange={onSubmit}
                  />
                  <br />
                  {uploadProgress ? (
                    <>アップロード進捗：{uploadProgress}%</>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )} */}
            </>
          )}
          <div id="transition-modal-description"></div>
        </div>
      </Fade>
    </Modal>
  );
}

import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useStyles } from "../Styles";
import { useNavigate, useParams, Link } from "react-router-dom";
import CommentIcon from "@mui/icons-material/Comment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import GridViewIcon from "@mui/icons-material/GridView";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoIcon from "@mui/icons-material/Photo";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import ShuffleIcon from "@mui/icons-material/Shuffle";

import { useCookies } from "react-cookie";

import LonpaSmallWidth from "./Lonpa/LonpaSmallWidth";
import LonpaBigWidth from "./Lonpa/LonpaBigWidth";
import SocialShare from "./components/SocialShare";
import TagArray from "./Lonpa/TagArray";
import LonpaModal from "./Lonpa/Modal/Lonpamodal";
// import GraphShow from "./Lonpa/GraphShow";
// import CommentShow from "./Lonpa/CommentShow";
import LodingModal from "./CommonModal/LodingModal";
import UploadModal from "./Lonpa/Modal/UploadModal";
import ImgModal from "./CommonModal/ImgModal";
import EditTagModal from "./Lonpa/Modal/EditTagModal";
import LonpaEditModal from "./CommonModal/LonpaEditModal";
import LonpaPictureModal from "./Lonpa/Modal/LonpaPictureModal";
import LonpaAIModal from "./Lonpa/Modal/LonpaAIModal";
import SwipeableViews from "react-swipeable-views";
import LonpaPieChart from "./Lonpa/LonpaPieChart";
import BiotechIcon from "@mui/icons-material/Biotech";

import rankicon from "../images/rankicon.png";

import {
  createLonpaChild,
  createLonpa,
  setLonpa,
  setLonpaChild,
  setLonpaChildVote,
  setUserPrivateVote,
  setUserPrivateCreateLonpa,
  DeleteLonpaChildVote,
  deleteUserPrivateVote,
  addVote,
  updateLonpaChild,
  updateLonpa,
  getUserPrivateVote,
  getUserAvatarList,
  getAllLonpaChildVote,
  getLonpaList,
  getUserAvatar,
  getUserPublic,
  getLonpa,
  getUserPrivateCreateLonpa,
  getNextLonpa,
  getPrevLonpa,
} from "../common/Helper/FirebaseHelper";

import {
  transactionPushLon,
  getUserPrivateVoteTransaction,
} from "../common/Helper/FirebaseTransactionHelper";

import {
  SortByDescend,
  Conversiondate,
  joinBy,
} from "../common/Helper/CommonHelper";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  LinearProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import "bootstrap/dist/css/bootstrap.min.css";

import { serverTimestamp } from "firebase/firestore";
// import { useSpring, animated } from "@react-spring/web";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { firebaseApp, userApp } from "../common/firebase";

import { checkisSubscription } from "../common/Helper/PointHelper";

import moment from "moment";
import styled from "styled-components";
import { adminUid } from "../common/Config/AdminConfig";

const Styles = styled.div`
  table {
    table-layout: fixed;
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      :last-child {
        border-right: 0;
      }
    }
  }
`;

export default function Lonpa(props) {
  let { lonpaid } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    claim: "読み込み中",
    agree: [],
    disagree: [],
    agreesum: 0,
    disagreesum: 0,
    choiceditem: {},
    imageUrl: undefined,
    isDelete: undefined,
    ischoiced: undefined,
    isopinion: undefined,
    login: undefined,
  });
  // const [lonpamodalopen, setLonpamodalopen] = useState(false);
  const [lonpacreateopen, setLonpacreateopen] = useState({
    agree: false,
    disagree: false,
  });
  const [lodingmodalopen, setLodingmodalopen] = useState(true);
  const [imgmodalopen, setImgmodalopen] = useState(false);
  const [expimg, setExpimg] = useState("");
  //const [maketext, setMaketext] = useState("Lonpa作成");
  //const [lon, setLon] = useState("agree");
  const [update, setUpdate] = useState(0);
  const [isbuttonlock, setisbuttonlock] = useState(false);
  const [pinfo, setPinfo] = useState([]);
  const [smallwidthopen, setSmallwidthopen] = useState([false, true]);
  const [animationagree, setAnimationagree] = useState(false);
  const [animationdisagree, setAnimationdisagree] = useState(false);
  //コメント部分のmodal
  //const [commentmodalopen, setCommentmodalopen] = useState(false);
  // const [gotonextpage, setGotonextpage] = useState(true);
  //画像投稿時のエラー内容を格納
  const [uploaderror, setUploaderror] = useState("");
  //画像投稿時の進捗具合
  const [uploadprogress, setUploadprogress] = useState(0);
  //画像投稿時に使うmodal
  const [uploadmodalopen, setUploadmodalopen] = useState(false);
  const [comment, setComment] = useState([]);
  //tagリストの受け渡し
  const [tagList, setTagList] = useState([]);
  //Lonpa画面に表示するtagリストの受け渡し
  const [tagListShow, setTagListShow] = useState([]);
  //SNS用のtagのString
  const [tagListString, setTagListString] = useState([]);
  const [tagListString2, setTagListString2] = useState([]);
  const [followTagListString, setFollowTagListString] = useState([]);

  const [tagUpdate, setTagUpdate] = useState(0);
  const [tagmodalopen, setTagmodalopen] = useState(false);

  //SNS用の説明文のString
  const [snsString, setSnsString] = useState([]);

  const [userEmailVerified, setUserEmailVerified] = useState(false);

  const [createrFlag, setCreaterFlag] = useState(false);

  const [adminFlag, setAdminFlag] = useState(false);

  const [lonpaEditmodalopen, setLonpaEditmodalopen] = useState(false);
  const [editLonpa, setEditLonpa] = useState("");
  const [editLonpaIsDelete, setEditLonpaIsDelete] = useState("");

  const [lonpaPicturemodalopen, setLonpaPicturemodalopen] = useState(false);
  const [lonpaAImodalopen, setLonpaAImodalopen] = useState(false);

  const [lonpaImageUrl, setLonpaImageUrl] = useState("");

  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [selfVoteInfoCookie, setSelfVoteInfoCookie] = useState(undefined);
  const [useEffectTriggerInfo, setUseEffectTriggerInfo] = useState();
  const [useEffectTriggerInitFlag, setUseEffectTriggerInitFlag] = useState();
  const [useEffectItemVote, setUseEffectItemVote] = useState([]);

  const [infoagreesum, setInfoagreesum] = useState(0);
  const [infodisagreesum, setInfodisagreesum] = useState(0);
  const [infosumchange, setInfosumchange] = useState({
    isagree: true,
    changenum: 0,
  });

  // const { x } = useSpring({
  //   from: { x: 0 },
  //   x: gotonextpage ? 1 : 0,
  //   config: { duration: props.animationspeed.lonpatable },
  // });

  const [togglenum, setToggleNum] = useState(1);

  const [tabIndex, setTabIndex] = React.useState(props.openside);

  const handleChangeTabIndex = (index) => {
    setTabIndex(index);
    if (index == 0) {
      setAnimationagree(false);
      setAnimationdisagree(false);
      setSmallwidthopen([false, false]);
      setToggleNum(1);
    } else {
      setToggleNum(index + 2);
    }
  };

  //トップに表示するタイトルの文字数
  const claimlen = [20, 40, 60];
  const [claimSize, setClaimSize] = useState(20);
  const [claimChildSize, setClaimChildSize] = useState({
    textsize1: 16,
    textsize2: 15,
    textsize3: 20,
  });
  const [displayClaim, setDisplayClaim] = useState("");

  //グラフ描画契機として利用
  const [graphRewriteCounter, setGraphRewriteCounter] = useState(0);

  //AIによる論拠作成用
  const [createAILonData, setCreateAILonData] = useState({
    agree: undefined,
    disagree: undefined,
  });
  //AIによる論拠作成用
  const [createAICount, setCreateAICount] = useState({
    agree0: false,
    agree1: false,
    agree2: false,
    disagree0: false,
    disagree1: false,
    disagree2: false,
  });

  //二回以上AIを動かさないようにするためのフラグ
  const [isCreatedAI, setIsCreatedAI] = useState(false);
  const [isAITwitterDisable, setIsAITwitterDisable] = useState(false);

  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  const picursl = [
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/img01.jpg?alt=media&token=4eded214-50c7-4751-aa7b-5251f52d6dc8",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/img02.jpg?alt=media&token=d91b4d4e-3ed8-4474-93bc-55f40e373d66",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/img03.jpg?alt=media&token=265cfb1d-2381-41d3-91a8-bd0f9d894f63",
  ];
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleFormat = (event, newFormats) => {
    if (newFormats !== null) {
      if (newFormats === 0) {
        if (pinfo[0] !== undefined) {
          ChoiceData(pinfo[0]);
        }
      } else if (newFormats === 1) {
        setAnimationagree(false);
        setAnimationdisagree(false);
        setSmallwidthopen([false, false]);
        setToggleNum(1);
        setTabIndex(0);
      } else if (newFormats === 2) {
        if (tabIndex === 0) {
          if (!smallwidthopen[0]) {
            setAnimationagree(true);
            window.setTimeout(function () {
              setSmallwidthopen([true, true]);
              setToggleNum(2);
              setTabIndex(0);
            }, props.animationspeed.lonpatable);
          }
        } else {
          setToggleNum(2);
          setTabIndex(0);
        }
      } else {
        setToggleNum(newFormats);
        setTabIndex(newFormats - 2);
      }
    }
  };

  // const handleOpen = () => {
  //   setLonpamodalopen(true);
  // };

  // const handleClose = () => {
  //   setLonpamodalopen(false);
  // };

  const ChoiceData = (id) => {
    console.log("autoCreateStop");
    autoCreateStop();
    if (id === "Search") {
      navigate("/NewSearch/");
    } else {
      if (lonpaid !== id) {
        // setGotonextpage(true);
        setLodingmodalopen(true);
        setLonpacreateopen({ agree: false, disagree: false });
        setCreateAILonData({ agree: undefined, disagree: undefined });
        setIsCreatedAI(false);
        setIsAITwitterDisable(false);

        window.setTimeout(function () {
          window.scrollTo(0, 0);
          setSmallwidthopen([false, true]);
          setAnimationagree(false);
          setAnimationdisagree(false);
          navigate("/Lonpa/" + id);
          lonpaid = id;
        }, props.animationspeed.lonpatable);
      }
    }
  };

  const GotoEdit = (automakeflag) => {
    if (automakeflag) {
      props.setAutoCreateMovie({
        create: true,
        id: lonpaid,
        rapInputCreate: true,
        rapCreate: true,
        genCreate: true,
        csvCreate: true,
        imageCreate: true,
        settingCreate: true,
        explainCreate: true,
        saveCreate: true,
      });
    }
    props.setEditDatas({
      id: lonpaid,
      claim: info.claim,
      isopinion: info.isopinion,
      parent: pinfo[0],
      isDelete: info.isDelete,
      infoagree: info.agree,
      infodisagree: info.disagree,
      infochoice: info.choiceditem,
    });
    window.scrollTo(0, 0);
    navigate("/MovieMaker/" + lonpaid);
  };

  const GotoRandom = () => {
    window.scrollTo(0, 0);
    navigate("/Random/");
  };
  async function ConnectLonpa(childid, title) {
    await setLonpaChild(lonpaid, childid, {
      claim: title,
      votenum: 0,
      agree: lon,
      child: true,
    });

    await setLonpaChild(childid, lonpaid, {
      claim: info.claim,
      child: false,
    });

    //navigate('/Lonpa/' + childid);
    // setLonpamodalopen(false);
    handleClose();
  }

  // async function getNextLonpa(id) {
  //     //tree描画で，Lonpa押下時に呼び出される
  //     let nextdata = [];
  //     let querySnapshot = await lonpa_related_getall(id);
  //     querySnapshot.forEach((doc) => {
  //         if (doc.data().child) {
  //             nextdata.push({ id: doc.id, claim: doc.data().claim, agree: doc.data().agree });
  //         }
  //     });
  //     return (nextdata)
  // }

  const increseUpdate = () => {
    setUpdate(update + 1);
  };

  const autoCreateStop = () => {
    console.log("setAutoCreate");
    props.setAutoCreate({
      create: false,
      id: "",
      tagCreate: false,
      imageCreate: false,
      craimCreate: false,
      craimAccept: false,
      adCreate: false,
      movieCreate: false,
      counter: 0,
    });
  };

  useEffect(() => {
    console.log("uE_Lonpa");
    setUseEffectTriggerInitFlag(true);
    const f = async () => {
      //ユーザが承認ユーザか，Lonpa作成者か，記録する
      const userStatus = await checkisSubscription();
      if (userStatus.isUser) {
        setUserEmailVerified(userStatus.isEmailVerified);
        const querySnapshot = await getUserPrivateCreateLonpa(lonpaid);
        if (querySnapshot.data() === undefined) {
          setCreaterFlag(false);
        } else {
          setCreaterFlag(true);
        }
        if (userStatus.isSubscribe) {
          setAdminFlag(true);
          setFollowTagListString(
            // "\n\n#フォロバ100 \n#相互フォロー \n#拡散希望 \n\n"
            ""
          );
        }
      } else {
        setUserEmailVerified(false);
      }
    };

    // //コメント変更監視
    // setComment([]);
    // let commentData = [];
    // let userIdList = [];
    // let avatarList = [];
    // const q = query(
    //   collection(db, "lonpa", lonpaid, "comment"),
    //   orderBy("date", "desc"),
    //   limit(10)
    // );
    // const unsubscribe = onSnapshot(q, (snapshot) => {
    //   snapshot.docChanges().forEach((change) => {
    //     if (change.type === "added") {
    //       const f2 = async () => {
    //         commentData.push({
    //           chat: change.doc.data().chat,
    //           userid: change.doc.data().userid,
    //           tmpdate: change.doc.data().date,
    //           date: Conversiondate(change.doc.data().date, "DATE"),
    //           time: Conversiondate(change.doc.data().date, "TIME"),
    //         });
    //         if (
    //           !userIdList.includes(change.doc.data().userid) &&
    //           change.doc.data().userid
    //         ) {
    //           userIdList.push(change.doc.data().userid);
    //           const avatarquerySnapshot = await getUserAvatar(
    //             change.doc.data().userid
    //           );
    //           avatarquerySnapshot.forEach((doc) => {
    //             if (doc.data().imageurl) {
    //               avatarList.push({
    //                 userid: doc.id,
    //                 imageurl: doc.data().imageurl,
    //                 user: doc.data().name,
    //               });
    //             }
    //           });
    //         }
    //         const chatData = joinBy(commentData, avatarList, "userid").sort(
    //           (a, b) => {
    //             if (a.tmpdate > b.tmpdate) return 1;
    //             if (a.tmpdate < b.tmpdate) return -1;
    //             return 0;
    //           }
    //         );

    //         setComment(chatData);
    //       };
    //       f2();
    //     }
    //   });
    // });

    //Lonpa変更監視
    const db = getFirestore(firebaseApp);
    const q2 = query(collection(db, "lonpa", lonpaid, "child"));
    const unsubscribe2 = onSnapshot(q2, (relatedSnapshot) => {
      const f3 = async () => {
        setUseEffectTriggerInfo(relatedSnapshot);
      };
      f3();
    });

    //実行するのはここ
    f();
    return () => {
      //unsubscribe(); コメント監視
      unsubscribe2(); //Lonpa監視
    };
  }, [lonpaid]);

  useEffect(() => {
    console.log("uE_Lonpa_tagupdate");
    const f = async () => {
      const doc = await getLonpa(lonpaid);
      const tmpTagList = (() => {
        const tagLists = doc.data().tag;
        if (typeof tagLists !== "undefined") {
          return Object.keys(tagLists);
        } else {
          return [""];
        }
      })();
      setTagList(tmpTagList);

      //１文字7pxとし、余白16pxと仮定する
      let tmpTagListShow = [];
      let tagboxlength = 70; //タグ編集ボックスの横幅
      const oneWordWidth = 14; //１文字の横幅
      const oneBoxWidth = 28; //1boxの余白分の横幅
      const tagshowwidth = props.styletext.windowWidth * 0.7;
      tmpTagList.map((data, index) => {
        let oneTagBoxWidth = 0;
        if (data.length > 12) {
          oneTagBoxWidth = 12 * oneWordWidth + oneBoxWidth;
        } else {
          oneTagBoxWidth = data.length * oneWordWidth + oneBoxWidth;
        }
        // console.log(
        //   data,
        //   oneTagBoxWidth,
        //   tagboxlength + oneTagBoxWidth,
        //   tagshowwidth
        // );
        if (data != "" && tagboxlength + oneTagBoxWidth < tagshowwidth) {
          tagboxlength = tagboxlength + oneTagBoxWidth;
          let display = data;
          if (data.length > 12) {
            display = data.slice(0, 9) + "…";
          }
          tmpTagListShow.push({ display: display, data: data });
        }
      });
      //console.log("-----------");

      setTagListShow(tmpTagListShow);

      let tmpTagString = "\n#Lonpa";
      tmpTagList.map((info, index) => {
        if (info !== "" && index < 2) {
          tmpTagString = tmpTagString + " #" + info;
        }
      });
      setTagListString(tmpTagString);

      let tmpTagString2 = "";
      tmpTagList.map((info, index) => {
        if (info !== "" && index < 2) {
          tmpTagString2 = tmpTagString2 + " " + info;
        }
      });
      setTagListString2(tmpTagString2);
    };
    f();
  }, [tagUpdate, lonpaid]);

  //★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
  useEffect(() => {
    console.log("uE_Lonpa_useEffectTriggerInfo");
    //useEffectTriggerInfoの変更で起動する
    const f = async () => {
      //論拠（lonpa-related）の更新を検知して動き出す場所
      //注意！外部からのuseState値等は扱うことができません！
      let infoclaim;

      let infoIsopinion;
      let infoImageurl;
      let infoIsDelete = "true";

      let itemlist = [];
      let itemvote = useEffectItemVote;
      let prebnum = {};
      let parentid = "Search";
      let parentclaim = "検索";

      let choice = { islogin: false, ischoiced: false, choiceditem: null };
      let nextinfo = {};

      let lonpaVoteSnapshot; //各論拠のvote一覧
      let voteidlists = {}; // 各論拠ごとのvote内容の中間データ
      let voteidlist = []; // image一覧作成のために全データ入れる格納場所
      let votelist = {}; //投票者（名前と画像）の情報群
      onAuthStateChanged(getAuth(userApp), async (user) => {
        //console.log(useEffectTriggerInitFlag);
        if (useEffectTriggerInitFlag) {
          //console.log("最初の情報取得");

          let infoagree = []; // infoagree 賛成分の論拠をまとめたもの
          let infodisagree = []; // infodisagree 反対分の論拠をまとめたもの
          let nextinfoagreesum = 0;
          let nextinfodisagreesum = 0;

          //////////console.log("1　現在開いているLonpaと、その論拠情報を収集");//////////
          //itemlistにLonpaのIDを登録
          itemlist.push(lonpaid);

          //itemlistに論拠のIDを登録
          useEffectTriggerInfo.forEach((doc) => {
            itemlist.push(doc.id);
          });

          //Lonpaと論拠のagree,disagreenumを取得
          const itemlistdata = await getLonpaList(itemlist);

          //１つ以上のデータが取れているかの判定用フラグ
          let ischeckdataexist = false;

          //Lonpaと論拠を分けて，情報整理
          itemlistdata.forEach((doc) => {
            if (doc.id === lonpaid) {
              //Lonpaの情報
              infoclaim = doc.data().claim;
              prebnum.agree = doc.data().agree;
              prebnum.disagree = doc.data().disagree;
              parentid = doc.data().parent;
              parentclaim = "不明";
              infoIsDelete = doc.data().isDelete;
              infoIsopinion = doc.data().isopinion;
              infoImageurl = doc.data().imageurl;

              setLonpaImageUrl(infoImageurl);

              if (infoclaim.length > claimlen[2]) {
                //claimが60文字以上なら，表示内容を変える
                setDisplayClaim(infoclaim.slice(0, claimlen[2]) + "…");
                setClaimSize(12);
              } else if (infoclaim.length > claimlen[1]) {
                //claimが40文字以上なら，文字サイズを変える
                setDisplayClaim(infoclaim);
                setClaimSize(12);
              } else if (infoclaim.length > claimlen[0]) {
                //claimが20文字以上なら，文字サイズを変える
                setDisplayClaim(infoclaim);
                setClaimSize(17);
              } else {
                setDisplayClaim(infoclaim);
                setClaimSize(20);
              }
            } else {
              //論拠の情報
              itemvote[doc.id] = {
                agree: doc.data().agree,
                disagree: doc.data().disagree,
              };
            }
            ischeckdataexist = true;
          });

          setUseEffectItemVote(itemvote);

          //１つもデータが取れていない＝Lonpaが存在しないなら，エラーページへ遷移
          if (!ischeckdataexist || infoIsDelete) {
            console.log("err:341897321987423");
            navigate("/Sorry/" + lonpaid);
          }

          ////////////console.log("2　投票状況を収集");//////////
          //onAuthStateChanged(getAuth(userApp), async (user) => {
          if (user) {
            //console.log("最初の情報取得 ログイン状態");
            //const user = getAuth(userApp).currentUser ?? { emailVerified: false };

            //ログイン状態だった場合，自身が過去投票したlonpaの一覧を取得する

            const doc2 = await getUserPrivateVoteTransaction(lonpaid);
            //ログイン中のアカウントで，選択中のlonpa一覧
            if (doc2) {
              if (doc2.data()) {
                choice = {
                  islogin: true, //ログインしていて
                  ischoiced: true, //投票していて
                  choiceditem: {
                    id: doc2.data().votedLonpaId,
                    votenum: 0,
                    isAgree: doc2.data().isAgree,
                    votedClaim: doc2.data().votedClaim,
                  }, //投票先はここ
                };
              } else {
                choice = {
                  islogin: true, //ログインしていて
                  ischoiced: false, //投票していない
                  choiceditem: null,
                };
              }
            } else {
              choice = {
                islogin: true, //ログインしていて
                ischoiced: false, //投票していない
                choiceditem: null,
              };
            }
          } else {
            //console.log("最初の情報取得 未ログイン状態");
            //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
            console.log(cookies[lonpaid]);
            //ログインしていない場合は{ id: id, isAgree: data.agree }形式

            if (cookies[lonpaid] === undefined) {
              choice = {
                islogin: false, //ログインしていない
                ischoiced: false, //投票していない
                choiceditem: null,
              };
            } else {
              choice = {
                islogin: false, //ログインしていて
                ischoiced: true, //投票していて
                choiceditem: {
                  id: cookies[lonpaid].id,
                  votenum: 0,
                  isAgree: cookies[lonpaid].isAgree,
                  votedClaim: cookies[lonpaid].votedClaim,
                }, //投票先はここ
              };
            }
          }
          //});

          //////////console.log("3　表示情報を作成");//////////

          //変数整理
          // prebnum: Lonpaの賛成・反対の総数
          // useEffectTriggerInfo: 論拠の中身一覧（投票数numを取得するために必要）
          // infoclaim: Lonpaのclaim
          // itemvote: 論拠の，agree・disagreeの一覧
          // choice.islogin:ログインしてるかどうか　true false
          // choice.ischoiced:　投票済みかどうか　true false
          // choice.choiceditem: 投票した論拠
          // infoagree 賛成分の論拠をまとめたもの
          // infodisagree 反対分の論拠をまとめたもの
          // infodata 論拠1つ分のデータ
          // info 諸々全部の情報をまとめたもの
          // lonpaVoteSnapshot 各論拠のvote一覧

          ////////// 3-1　投票に関する情報群を作成////////////
          // voteidlists 各論拠ごとのvote内容の中間データ
          // voteidlist image一覧作成のために全データ入れる格納場所

          //【投票の整理】各論拠に入っている、voteの情報を全て取りに行く。
          for (let i = 0; i < useEffectTriggerInfo.size; i++) {
            const doc = useEffectTriggerInfo.docs[i];
            //各論拠に紐づくvote一覧を取得
            lonpaVoteSnapshot = await getAllLonpaChildVote(lonpaid, doc.id);
            let voteids = [];
            for (let i = 0; i < lonpaVoteSnapshot.size; i++) {
              // 各論拠ごとのvote内容の中間データ1
              voteids.push(lonpaVoteSnapshot.docs[i].id);
              // image一覧作成のために全データ入れる格納場所
              voteidlist.push(lonpaVoteSnapshot.docs[i].id);
            }
            //各論拠ごとのvote内容の中間データ2
            voteidlists[doc.id] = voteids;
          }
          //list型に変換する
          voteidlist = [...new Set(voteidlist)];

          //投票されたユーザ情報を全て取りに行き、プロフィール画像が登録されているものだけリストアップする
          const avatarquerySnapshot = await getUserAvatarList(voteidlist);
          avatarquerySnapshot.forEach((doc) => {
            //ユーザプロフィール画像が登録されている場合
            if (doc.data().imageurl) {
              //投票情報に、投票者とプロフィール画像を登録
              votelist[doc.id] = {
                imageurl: doc.data().imageurl,
                user: doc.data().name,
              };
            }
          });

          ////////// 3-2　各論拠の内容をinfodataとし，賛成論拠のリストinfoagreeと，反対論拠のリストinfodisagreeを作る////////////
          let createdBy;
          for (let i = 0; i < useEffectTriggerInfo.size; i++) {
            ////////// 3-2-1　1論拠の整形済み情報 = infodataを作成する////////////
            ////////// 3-2-1-1　1論拠の情報をdocに格納////////////
            const doc = useEffectTriggerInfo.docs[i];

            if (!doc.data().isDelete) {
              ////////// 3-2-1-2　1論拠の作者情報「createdBy」を整理////////////
              if (doc.data().createdBy) {
                //もし、作成者情報が,格納されていたら、詳細を取りに行く
                const userPrivateData = await getUserPublic(
                  doc.data().createdBy
                );
                if (typeof userPrivateData.data() !== "undefined") {
                  //作成者情報が取得できたら、infodataに追加する
                  createdBy = {
                    id: doc.data().createdBy,
                    imageurl: userPrivateData.data().imageurl,
                    name: userPrivateData.data().name,
                    createdAt: parseAsMoment(doc.data().createdAt.toDate())
                      .format("YYYY/MM/DD HH:mm")
                      .toString(),
                  };
                } else {
                  //作成者情報が取得できなければ、ビジター情報として追加する
                  createdBy = {
                    createdAt: parseAsMoment(doc.data().createdAt.toDate())
                      .format("YYYY/MM/DD HH:mm")
                      .toString(),
                  };
                }
              } else {
                //作成者情報が格納されていなければ、ビジター情報として追加する
                createdBy = {
                  createdAt: parseAsMoment(doc.data().createdAt.toDate())
                    .format("YYYY/MM/DD HH:mm")
                    .toString(),
                };
              }

              ////////// 3-2-1-3　1論拠の投票数，投票者情報(画像・ユーザID)整理////////////
              //投票と投票者情報を整形したものをvotedatasとしてつくる
              let votedatas = [];
              if (typeof voteidlists[doc.id] !== "undefined") {
                //1つ以上投票がある時
                for (let j = 0; j < voteidlists[doc.id].length; j++) {
                  //各論拠の各投票について、
                  if (typeof votelist[voteidlists[doc.id][j]] !== "undefined") {
                    //ユーザ情報が存在する場合
                    //votedatasにユーザ情報を追加する
                    votedatas.push({
                      imageurl: votelist[voteidlists[doc.id][j]].imageurl,
                      user: votelist[voteidlists[doc.id][j]].user,
                    });
                  } else {
                    //ユーザ情報が存在しない場合
                    //votedatasに、ビジター情報を追加する
                    votedatas.push({
                      imageurl: picursl[0],
                      user: "noname",
                    });
                  }
                }
              }

              ////////// 3-2-1-4　閲覧者による投票状況の整理////////////
              let ischoiceditem;

              if (choice.ischoiced) {
                //もし、表示者により投票されていれば
                if (doc.id === choice.choiceditem.id) {
                  //その１論拠へ投票されていれば
                  //投票がされているフラグを立てて、投票数を格納しておく？
                  ischoiceditem = true;
                  choice.choiceditem.votenum = doc.data().votenum;
                } else {
                  //別の論拠への投票であれば
                  //投票がされていないフラグを立てる
                  ischoiceditem = false;
                }
              } else {
                //そもそも投票されていなければ、
                //投票がされていないフラグを立てる
                ischoiceditem = false;
              }

              let infodata;

              try {
                ////////// 3-2-1-5　上記で整理した情報をまとめる////////////
                infodata = {
                  id: doc.id,
                  data: doc.data(),
                  agree: itemvote[doc.id].agree,
                  disagree: itemvote[doc.id].disagree,
                  votedatas: votedatas,
                  createdBy: createdBy,
                  ischoiceditem: ischoiceditem,
                };
              } catch {
                console.log("err:837267461728");
                console.log(itemvote[doc.id]);
                navigate("/Sorry/" + lonpaid);
              }

              ////////// 3-2-2　1論拠が 賛成論拠か、反対論拠か仕分ける////////////
              if (doc.data().agree) {
                //賛成論拠は，infoagreeにまとめる
                infoagree.push(infodata);
                nextinfoagreesum = nextinfoagreesum + infodata.data.votenum;
              } else {
                //反対論拠は，infodisagreeにまとめる
                infodisagree.push(infodata);
                nextinfodisagreesum =
                  nextinfodisagreesum + infodata.data.votenum;
              }
            }
          }

          ////////// 3-3　賛成・反対論拠をすべてまとめた，infoを作成する////////////
          // 投票数が多い順に並び替え
          SortByDescend(infoagree);
          SortByDescend(infodisagree);

          nextinfo = {
            claim: infoclaim,
            agree: infoagree,
            disagree: infodisagree,
            login: choice.islogin,
            ischoiced: choice.ischoiced,
            choiceditem: choice.choiceditem,
            isDelete: infoIsDelete,
            isopinion: infoIsopinion,
            imageUrl: infoImageurl,
          };
          //console.log(nextinfo);
          setInfo(nextinfo);
          //console.log("autoCreateFunc");
          autoCreateFunc(infoclaim);

          let tmpSnsString = "";
          if (choice.choiceditem == null) {
            tmpSnsString =
              "「" + infoclaim + "」\nみんなもLonpaで投票してみよう！";
          } else if (choice.choiceditem.isAgree) {
            tmpSnsString =
              "「" +
              infoclaim +
              "」に \n   賛成  \n\n理由は、「" +
              choice.choiceditem.votedClaim +
              "」\n\nみんなもLonpaで投票してみよう！";
          } else {
            tmpSnsString =
              "「" +
              infoclaim +
              "」に \n   反対  \n\n理由は、「" +
              choice.choiceditem.votedClaim +
              "」\n\nみんなもLonpaで投票してみよう！";
          }
          setSnsString(tmpSnsString);
          //console.log(tmpSnsString);

          setInfoagreesum(nextinfoagreesum);
          setInfodisagreesum(nextinfodisagreesum);

          ////////// 3-4　Lonpaの親情報Pinfoを作成する////////////
          setPinfo([parentid, parentclaim]);

          ////////// 4　各論拠の投票数と，Lonpaの投票結果が合致していない場合，DBを更新する////////////
          if (
            prebnum.agree !== nextinfoagreesum ||
            prebnum.disagree !== nextinfodisagreesum
          ) {
            updateLonpa(lonpaid, {
              agree: nextinfoagreesum,
              disagree: nextinfodisagreesum,
            });
          }

          ////////// 5 　画面セットアップ完了のお知らせ////////////
          setLodingmodalopen(false);
          // setGotonextpage(false);
          setUseEffectTriggerInitFlag(false);
          setGraphRewriteCounter(graphRewriteCounter + 1); //グラフを更新
        } else {
          sleep(10);
          //console.log("disagree必要か？");

          let infoagree = info.agree; // infoagree 賛成分の論拠をまとめたもの
          let infodisagree = info.disagree; // infodisagree 反対分の論拠をまとめたもの

          if (useEffectTriggerInfo) {
            /////////////////initではない場合 別の人が論拠作成など////////////////////////////
            useEffectTriggerInfo.docChanges().forEach(async (change) => {
              if (change.type === "added") {
                //■■■■■■■■ 追加の場合 ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■//
                //console.log("added");
                const doc = change.doc;
                //論拠の情報追加
                itemvote[doc.id] = {
                  agree: doc.data().agree,
                  disagree: doc.data().disagree,
                };
                //console.log(itemvote);
                setUseEffectItemVote(itemvote);

                ////////// 3　表示情報を作成 ////////////

                ////////// 3-2　各論拠の内容をinfodataとし，賛成論拠のリストinfoagreeと，反対論拠のリストinfodisagreeを作る////////////
                ////////// 3-2-1　1論拠の整形済み情報 = infodataを作成する////////////
                ////////// 3-2-1-2　1論拠の作者情報「createdBy」を整理////////////
                let createdBy;

                if (doc.data().createdAt !== null) {
                  if (doc.data().createdBy) {
                    //もし、作成者情報が,格納されていたら、詳細を取りに行く
                    const userPrivateData = await getUserPublic(
                      doc.data().createdBy
                    );
                    if (typeof userPrivateData.data() !== "undefined") {
                      //console.log(doc.data().createdAt !== null);
                      //作成者情報が取得できたら整形
                      createdBy = {
                        id: doc.data().createdBy,
                        imageurl: userPrivateData.data().imageurl,
                        name: userPrivateData.data().name,
                        createdAt: parseAsMoment(doc.data().createdAt.toDate())
                          .format("YYYY/MM/DD HH:mm")
                          .toString(),
                      };
                    } else {
                      //作成者情報が取得できなければ、ビジター情報として追加する
                      createdBy = {
                        createdAt: parseAsMoment(doc.data().createdAt.toDate())
                          .format("YYYY/MM/DD HH:mm")
                          .toString(),
                      };
                    }
                  } else {
                    //作成者情報が格納されていなければ、ビジター情報として追加する
                    createdBy = {
                      createdAt: parseAsMoment(doc.data().createdAt.toDate())
                        .format("YYYY/MM/DD HH:mm")
                        .toString(),
                    };
                  }
                } else {
                  createdBy = {};
                }

                ////////// 3-2-1-3　1論拠の投票数，投票者情報(画像・ユーザID)整理////////////
                //投票はとりこまない
                let votedatas = [];

                ////////// 3-2-1-4　閲覧者による投票状況はfalse////////////
                let ischoiceditem = false;

                ////////// 3-2-1-5　上記で整理した情報をまとめる////////////
                let infodata = {
                  id: doc.id,
                  data: doc.data(),
                  agree: itemvote[doc.id].agree,
                  disagree: itemvote[doc.id].disagree,
                  votedatas: votedatas,
                  createdBy: createdBy,
                  ischoiceditem: ischoiceditem,
                };

                ////////// 3-2-2　1論拠が 賛成論拠か、反対論拠か仕分ける////////////
                if (doc.data().agree) {
                  //賛成論拠は，infoagreeにまとめる
                  infoagree.push(infodata);
                } else {
                  //反対論拠は，infodisagreeにまとめる
                  infodisagree.push(infodata);
                }
                ////////// 3-3　賛成・反対論拠をすべてまとめた，infoを作成する////////////
                // 一番下に追加するので，ソートなどせず普通に作る
                //console.log(info);

                nextinfo = {
                  agree: infoagree,
                  disagree: infodisagree,
                  ...info,
                };
                setInfo(nextinfo);
                //論拠の追加だけなので，SnsStringの修正は不要

                ////////// 4　各論拠の投票数と，Lonpaの投票結果が合致していない場合，DBを更新する////////////
                //ここは不要

                ////////// 5 　画面セットアップ完了////////////
              } else if (change.type === "modified") {
                //■■■■■■■■ 変更の場合 ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■//
                const doc = change.doc;

                // ★　閲覧者自身の投票結果が変わっている可能性がある
                ////////// 2　閲覧者自身の投票状況を収集 ////////////

                //ユーザが承認ユーザか，Lonpa作成者か，記録する
                //onAuthStateChanged
                //console.log("変更の取り込み");
                if (user) {
                  //console.log("ログイン");
                  //const user = getAuth(userApp).currentUser ?? { emailVerified: false };
                  //if (user.emailVerified) {

                  //ログイン状態だった場合，自身が過去投票した情報を取得する
                  const doc2 = await getUserPrivateVote(lonpaid);

                  let votenumnew;
                  if (info.choiceditem === null) {
                    votenumnew = null;
                  } else if (typeof info.choiceditem === "undefined") {
                    votenumnew = null;
                  } else {
                    votenumnew = info.choiceditem.votenum;
                  }

                  if (doc.id == lonpaid) {
                    votenumnew = doc.data().votenum;
                  }
                  //ログイン中のアカウントで，選択中のlonpa一覧

                  if (doc2.data()) {
                    //ログインしていて，投票先がある場合
                    choice = {
                      islogin: true, //ログインしていて
                      ischoiced: true, //投票していて
                      choiceditem: {
                        id: doc2.data().votedLonpaId,
                        votenum: votenumnew,
                        isAgree: doc2.data().isAgree,
                        votedClaim: doc2.data().votedClaim,
                      }, //投票先はここ
                    };
                  } else {
                    //ログインしていて，投票先がない場合
                    //クッキーに情報があるかどうかは無視
                    // if (cookies[lonpaid].id === undefined) {

                    choice = {
                      islogin: true, //ログインしていて
                      ischoiced: false, //投票していない
                      choiceditem: null,
                    };
                  }
                } else {
                  //console.log("未ログイン");
                  //未ログイン状態または、メール未認証 ■ 投票中の内容設定

                  // console.log(cookies[lonpaid]);
                  //投票後のクッキー変更処理は画面更新しないと反映されないので、内部変数で渡す。

                  console.log(selfVoteInfoCookie);

                  if (selfVoteInfoCookie === undefined) {
                    choice = {
                      islogin: false, //ログインしていない
                      ischoiced: false, //投票していない
                      choiceditem: null,
                    };
                  } else {
                    let votenumnew;
                    if (info.choiceditem === null) {
                      votenumnew = null;
                    } else if (typeof info.choiceditem === "undefined") {
                      votenumnew = null;
                    } else {
                      votenumnew = info.choiceditem.votenum;
                    }
                    if (doc.id == cookies[lonpaid].id) {
                      votenumnew = doc.data().votenum;
                    }
                    choice = {
                      islogin: false, //ログインしていて
                      ischoiced: true, //投票していて
                      choiceditem: {
                        id: selfVoteInfoCookie.id,
                        votenum: votenumnew,
                        isAgree: selfVoteInfoCookie.isAgree,
                        votedClaim: selfVoteInfoCookie.votedClaim,
                      }, //投票先はここ
                    };
                  }
                }

                // ★　論拠の投票結果の数が変わっている可能性がある
                //論拠の情報追加
                itemvote[doc.id] = {
                  agree: doc.data().agree,
                  disagree: doc.data().disagree,
                };

                //nextinfoagreesum = itemvote[doc.id].agree.length;
                //nextinfodisagreesum = itemvote[doc.id].disagree.length;

                ////////// 3-1　投票に関する情報群を作成////////////
                // voteidlists 各論拠ごとのvote内容の中間データ
                // voteidlist image一覧作成のために全データ入れる格納場所

                //【投票の整理】各論拠に入っている、voteの情報を全て取りに行く。
                //for (let i = 0; i < useEffectTriggerInfo.size; i++) {
                //  const doc = useEffectTriggerInfo.docs[i];

                //変更のあった論拠に紐づくvoteの投票者一覧を取得し，今見ている投票ユーザ一覧を更新する  ★上手く動くか不明
                lonpaVoteSnapshot = await getAllLonpaChildVote(lonpaid, doc.id);
                let voteids = [];

                // if (typeof voteidlists[doc.id] !== "undefined") {
                //   if (doc.data().agree) {
                //     nextinfoagreesum =
                //       info.agreesum +
                //       (lonpaVoteSnapshot.size - voteidlists[doc.id].length);
                //   } else {
                //     nextinfodisagreesum =
                //       info.disagreesum +
                //       (lonpaVoteSnapshot.size - voteidlists[doc.id].length);
                //   }
                // }

                for (let i = 0; i < lonpaVoteSnapshot.size; i++) {
                  // 各論拠ごとのvote内容の中間データ1
                  voteids.push(lonpaVoteSnapshot.docs[i].id);
                  // image一覧作成のために全データ入れる格納場所
                  voteidlist.push(lonpaVoteSnapshot.docs[i].id);
                }
                //各論拠ごとのvote内容の中間データ2
                voteidlists[doc.id] = voteids;
                //}
                //list型に変換する
                voteidlist = [...new Set(voteidlist)];

                //投票されたユーザ情報を全て取りに行き、プロフィール画像が登録されているものだけリストアップする
                const avatarquerySnapshot = await getUserAvatarList(voteidlist);
                avatarquerySnapshot.forEach((doc) => {
                  //ユーザプロフィール画像が登録されている場合
                  if (doc.data().imageurl) {
                    //投票情報に、投票者とプロフィール画像を登録
                    votelist[doc.id] = {
                      imageurl: doc.data().imageurl,
                      user: doc.data().name,
                    };
                  }
                });

                ////////// 3　表示情報を作成 ////////////
                ////////// 3-2　各論拠の内容をinfodataとし，賛成論拠のリストinfoagreeと，反対論拠のリストinfodisagreeを作る////////////
                ////////// 3-2-1　1論拠の整形済み情報 = infodataを作成する////////////
                ////////// 3-2-1-2　1論拠の作者情報「createdBy」を仮で整理，元の情報をもとに作る予定////////////
                let createdBy;
                createdBy = {
                  createdAt: parseAsMoment(doc.data().createdAt.toDate())
                    .format("YYYY/MM/DD hh:mm")
                    .toString(),
                };

                ////////// 3-2-1-3　1論拠の投票数，投票者情報(画像・ユーザID)整理////////////
                //1論拠分の，投票と投票者情報を整形したものをvotedatasとしてつくる
                //変更のかかった論拠の，各投票について確認していく
                let votedatas = [];
                if (typeof voteidlists[doc.id] !== "undefined") {
                  //1つ以上投票がある時
                  for (let j = 0; j < voteidlists[doc.id].length; j++) {
                    //各論拠の各投票について、
                    if (
                      typeof votelist[voteidlists[doc.id][j]] !== "undefined"
                    ) {
                      //ユーザ情報が存在する場合
                      //votedatasにユーザ情報を追加する
                      votedatas.push({
                        imageurl: votelist[voteidlists[doc.id][j]].imageurl,
                        user: votelist[voteidlists[doc.id][j]].user,
                      });
                    } else {
                      //ユーザ情報が存在しない場合
                      //votedatasに、ビジター情報を追加する
                      votedatas.push({
                        imageurl: picursl[0],
                        user: "noname",
                      });
                    }
                  }
                }

                ////////// 3-2-1-4　閲覧者による投票状況の整理////////////
                let ischoiceditem;

                if (choice.ischoiced) {
                  //もし、表示者により投票されていれば
                  if (doc.id === choice.choiceditem.id) {
                    //その１論拠へ投票されていれば
                    //投票がされているフラグを立てて、投票数を格納しておく？
                    ischoiceditem = true;
                    choice.choiceditem.votenum = doc.data().votenum;
                  } else {
                    //別の論拠への投票であれば
                    //投票がされていないフラグを立てる
                    ischoiceditem = false;
                    //choice.choiceditem.votenum = info.choiceditem.votenum;
                  }
                } else {
                  //そもそも投票されていなければ、
                  //投票がされていないフラグを立てる
                  ischoiceditem = false;
                }

                ////////// 3-2-1-5　上記で整理した情報をまとめる////////////
                let infodata = {
                  id: doc.id,
                  data: doc.data(),
                  agree: itemvote[doc.id].agree,
                  disagree: itemvote[doc.id].disagree,
                  votedatas: votedatas,
                  createdBy: createdBy,
                  ischoiceditem: ischoiceditem,
                };

                ////////// 3-2-2　1論拠が 賛成論拠か、反対論拠か仕分ける////////////
                // let nextinfoagreesum = infoagreesum;
                // let nextinfodisagreesum = infodisagreesum;
                if (doc.data().agree) {
                  //賛成論拠は，infoagreeにまとめる
                  let infoindex = null;
                  infoagree.map((item, index) => {
                    if (item.id === doc.id) {
                      infoindex = index;
                    }
                  });
                  if (infoindex != null) {
                    setInfosumchange({
                      isagree: true,
                      changenum:
                        infodata.data.votenum -
                        infoagree[infoindex].data.votenum,
                    });
                    infoagree[infoindex] = infodata;
                  }
                  ////////// 3-3　賛成・反対論拠をすべてまとめた，infoを作成する////////////
                  // 一番下に追加するので，ソートなどせず普通に作る
                } else {
                  let infoindex = null;
                  infodisagree.map((item, index) => {
                    if (item.id === doc.id) {
                      infoindex = index;
                    }
                  });
                  if (infoindex != null) {
                    setInfosumchange({
                      isagree: false,
                      changenum:
                        infodata.data.votenum -
                        infodisagree[infoindex].data.votenum,
                    });
                    infodisagree[infoindex] = infodata;
                  }
                  ////////// 3-3　賛成・反対論拠をすべてまとめた，infoを作成する////////////
                  // 一番下に追加するので，ソートなどせず普通に作る
                }
                //console.log(choice.choiceditem);
                nextinfo = {
                  ...info,
                  agree: infoagree,
                  disagree: infodisagree,
                  login: choice.islogin,
                  ischoiced: choice.ischoiced,
                  choiceditem: choice.choiceditem,
                  isDelete: infoIsDelete,
                };

                setInfo(nextinfo);

                let tmpSnsString = "";
                if (choice.choiceditem == null) {
                  tmpSnsString =
                    "「" + info.claim + "」\nみんなもLonpaで投票してみよう！";
                } else if (choice.choiceditem.isAgree) {
                  tmpSnsString =
                    "「" +
                    info.claim +
                    "」に \n 🙆  賛成  🙆\n\n理由は、「" +
                    choice.choiceditem.votedClaim +
                    "」\n\nみんなもLonpaで投票してみよう！";
                } else {
                  tmpSnsString =
                    "「" +
                    info.claim +
                    "」に \n 🙅  反対  🙅\n\n理由は、「" +
                    choice.choiceditem.votedClaim +
                    "」\n\nみんなもLonpaで投票してみよう！";
                }
                setSnsString(tmpSnsString);
              }

              ////////// 4　各論拠の投票数と，Lonpaの投票結果が合致していない場合，DBを更新する////////////
              //ここは不要

              ////////// 5 　画面セットアップ完了////////////
              setGraphRewriteCounter(graphRewriteCounter + 1); //グラフを更新
            });
          } else {
            //console.log("更新がきたが，中身が空");
          }
        }
      });
    };
    f();
  }, [useEffectTriggerInfo]);

  useEffect(() => {
    console.log("uE_Lonpa_infosumchange");
    const f = async () => {
      if (infosumchange.isagree) {
        setInfoagreesum(infoagreesum + infosumchange.changenum);
      } else {
        setInfodisagreesum(infodisagreesum + infosumchange.changenum);
      }
    };
    f();
  }, [infosumchange]);

  async function autoCreateFunc(infoready) {
    const userStatus = await checkisSubscription();
    if (userStatus.isUser) {
      if (userStatus.isSubscribe) {
        if (infoready) {
          if (props.autoCreate.create) {
            //console.log(info.claim);
            //console.log(infoready);

            if (props.autoCreate.tagCreate) {
              console.log("タグ自動生成");
              setTagmodalopen(true);
            } else if (props.autoCreate.imageCreate) {
              //console.log("画像自動生成");
              setEditLonpa({
                ...editLonpa,
                id: lonpaid,
                claim: infoready,
              });
              setLonpaPicturemodalopen(true);
            } else if (props.autoCreate.craimCreate) {
              //console.log("意見自動生成");
              setLonpaPicturemodalopen(false);

              setEditLonpa({
                ...editLonpa,
                id: lonpaid,
                claim: infoready,
              });
              setLonpaAImodalopen(true);
            } else if (props.autoCreate.craimAccept) {
              setCreateAICount({
                agree0: false,
                agree1: false,
                agree2: false,
                disagree0: false,
                disagree1: false,
                disagree2: false,
              });
              try {
                if (typeof createAILonData.agree !== "undefined") {
                  await createAILonData.agree.map(async (item, index) => {
                    await addLonChild(
                      item.claim,
                      item.isagree,
                      true,
                      "agree" + index
                    );
                    console.log(item.claim);
                  });
                }
                if (typeof createAILonData.disagree !== "undefined") {
                  await createAILonData.disagree.map(async (item, index) => {
                    await addLonChild(
                      item.claim,
                      item.isagree,
                      true,
                      "disagree" + index
                    );
                    console.log(item.claim);
                  });
                }
              } catch {
                console.log("意見生成でエラー");
              } finally {
                setCreateAILonData({ agree: undefined, disagree: undefined });
                console.log("setAutoCreate");
                props.setAutoCreate({
                  ...props.autoCreate,
                  craimAccept: false,
                });
              }
            } else if (props.autoCreate.movieCreate) {
              if (props.autoCreate.counter == "allset") {
                console.log("setAutoCreate");
                props.setAutoCreate({
                  ...props.autoCreate,
                  movieCreate: false,
                });
                //console.log("ここでmoviemakerに移動")
                GotoEdit(true);
              } else {
                //console.log("ここはスキップ");
              }
            }

            if (props.autoCreate.adCreate) {
              //console.log("広告自動生成");
            }
          }
        } else {
          //console.log("まだinfo準備できてない");
        }
      } else {
        if (infoready) {
          if (props.autoCreate.create) {
            console.log("autoCreateStop");
            autoCreateStop();
          }
        }
      }
    } else {
      if (infoready) {
        if (props.autoCreate.create) {
          console.log("autoCreateStop");
          autoCreateStop();
        }
      }
    }
  }

  useEffect(() => {
    console.log("uE_Lonpa_autoCreate");
    const f = async () => {
      if (info.claim !== "読み込み中") {
        //console.log("実行開始");
        //console.log(props.autoCreate);
        autoCreateFunc(info.claim);
      } else {
        //console.log("読み込み中");
      }
    };
    f();
  }, [props.autoCreate]);

  const nGrum = (name, n) => {
    const searchGrums = {};
    for (let i = 0; i < name.length; i++) {
      const results = [name.substr(i, n)];
      results.map((result) => {
        searchGrums[result] = true;
      });
    }
    return searchGrums;
  };

  //★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
  //＋ボタン押下時に動作
  const addLon = (lon) => {
    //setLon(lon);
    if (lon) {
      //setMaketext("賛成要因を作成");
      setLonpacreateopen({ ...lonpacreateopen, agree: true });
    } else {
      //setMaketext("反対要因を作成");
      setLonpacreateopen({ ...lonpacreateopen, disagree: true });
    }
    //handleOpen();
    //console.log(lonpacreateopen);
  };

  //★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
  //作成するボタン押下時に動作
  async function addLonChild(
    childClaim,
    childIsagree,
    isCreateAI,
    autoCreateData
  ) {
    setLodingmodalopen(true);
    //aiクリエイトの枠からの作成なのか、手動でプラスボタン押下枠からの作成なのか
    if (isCreateAI) {
      //aiクリエイト枠なら閉じる必要もないのでそのまま進める
      //console.log("autoクリエイトです")
    } else {
      if (childIsagree) {
        setLonpacreateopen({ ...lonpacreateopen, agree: false });
      } else {
        setLonpacreateopen({ ...lonpacreateopen, disagree: false });
      }
    }

    if (getAuth(userApp).currentUser) {
      //現在見ているLonpaの論拠（子）要素として、登録
      const docRef = await createLonpaChild(lonpaid, {
        claim: childClaim,
        votenum: 0,
        agree: childIsagree,
        child: true,
        createdAt: serverTimestamp(),
        createdBy: getAuth(userApp).currentUser.uid,
        oppositionStatus: "balanced",
        isDelete: false,
      });
      //Lonpa一覧に本件を作成
      await setLonpa(docRef.id, {
        claim: childClaim,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
        ngram: nGrum(info.claim, 2),
        agree: 0,
        disagree: 0,
        isopinion: false,
        createdBy: getAuth(userApp).currentUser.uid,
        parent: lonpaid,
        parentClaim: info.claim,
        isDelete: false,
        imageurl: "",
      });
      //ユーザの作成履歴を記録する
      await setUserPrivateCreateLonpa(docRef.id, {
        claim: childClaim,
        createdAt: serverTimestamp(),
        isopinion: false,
        parent: lonpaid,
        isDelete: false,
      });
    } else {
      //console.log("notlogin");
      //現在見ているLonpaの論拠（子）要素として、登録
      //Lonpa一覧に本件を作成
      const docRef = await createLonpa({
        claim: childClaim,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
        ngram: nGrum(info.claim, 2),
        agree: 0,
        disagree: 0,
        isopinion: false,
        parent: lonpaid,
        parentClaim: info.claim,
        isDelete: false,
        imageurl: "",
      });
      //console.log("setlonpa");
      //ユーザがログインユーザでない場合
      //現在見ているLonpaの論拠（子）要素として、登録
      await setLonpaChild(lonpaid, docRef.id, {
        claim: childClaim,
        votenum: 0,
        agree: childIsagree,
        child: true,
        createdAt: serverTimestamp(),
        isDelete: false,
      });
    }

    setLodingmodalopen(false);

    if (isCreateAI) {
      //console.log(autoCreateData, createAICount);
      let nextcreateAICount = createAICount;
      nextcreateAICount[autoCreateData] = true;
      setCreateAICount(nextcreateAICount);

      if (
        nextcreateAICount.agree0 &&
        nextcreateAICount.agree1 &&
        nextcreateAICount.agree2 &&
        nextcreateAICount.disagree0 &&
        nextcreateAICount.disagree1 &&
        nextcreateAICount.disagree2
      ) {
        console.log("setAutoCreate");
        props.setAutoCreate({
          ...props.autoCreate,
          counter: "allset",
        });
      }
    }
  }

  //投票ボタン押下時に動作する　■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  async function pushLon(id, data, increment) {
    const excutetime = new Date();
    const excutetimestring = parseAsMoment(excutetime)
      .format("YYYYMMDDHHmm")
      .toString();

    if (!isbuttonlock) {
      setisbuttonlock(true);
      onAuthStateChanged(getAuth(userApp), async (user) => {
        ///////cookieとSelectDataを先に更新する///////
        //リスナー関数はDB更新後にすぐ動いてしまうので、画面設定は先に入れておく必要がある。
        //cookieはブラウザ更新しないと反映されなそうなので、内部変数で画面更新が必要
        if (!user) {
          await updateSelectDataAndCookie(id, data, increment);
        }
        // ここでDBのデータを更新
        if (
          await transactionPushLon(
            id,
            data,
            increment,
            user,
            lonpaid,
            serverTimestamp(),
            excutetimestring,
            info,
            infoagreesum,
            infodisagreesum,
            pinfo,
            excutetime
          )
        ) {
          ///////console.log("トランザクションが成功終了")//////
        } else {
          console.log("トランザクションが失敗終了、クッキーを戻す必要がある");
        }
        await sleep(500);

        setisbuttonlock(false);
      });
    }
  }

  async function updateSelectDataAndCookie(id, data, increment, user) {
    let choiceid;
    if (increment) {
      choiceid = {
        id: id,
        isAgree: data.agree,
        votedClaim: data.claim,
      };
    } else {
      choiceid = "";
    }

    setSelfVoteInfoCookie(choiceid);

    ///////クッキー書き込み///////
    console.log(choiceid);
    //ログインしていない場合はクッキーを利用
    if (increment) {
      await setCookie(lonpaid, choiceid);

      //期限付きでcookieを設定したいがうまくいかない
      // let cookieDate = new Date();
      // cookieDate.setDate(cookieDate.getDate() + 365); // クッキーの期限を365日後に変更する.
      //console.log(cookieDate);
      //クッキーに投票内容を書き込む
      // await setCookie(lonpaid, choiceid, {
      //   expires: cookieDate,
      //   path: "/",
      // });
    } else {
      await removeCookie(lonpaid); //クッキーの投票内容を削除する
    }
  }

  async function GotoNextLonpa(id) {
    const querysnapshot = await getNextLonpa(id);
    if (querysnapshot.docs.pop()) {
      ChoiceData(querysnapshot.docs.pop().id);
    } else {
      alert("移動に失敗しました");
    }
  }
  async function GotoPrevLonpa(id) {
    const querysnapshot = await getPrevLonpa(id);
    if (querysnapshot.docs.pop()) {
      ChoiceData(querysnapshot.docs.pop().id);
    } else {
      alert("移動に失敗しました");
    }
  }

  async function lonpaAutoMake() {
    setEditLonpa({
      id: lonpaid,
      claim: info.claim,
      isopinion: info.isopinion,
      parent: pinfo[0],
      isDelete: info.isDelete,
      infoagree: info.agree,
      infodisagree: info.disagree,
      infochoice: info.choiceditem,
    });
    setLonpaAImodalopen(true);
  }

  return (
    <>
      {/* <ToggleButtonGroup
        value={togglenum}
        exclusive
        onChange={handleFormat}
        align="center"
        style={{ width: "100%", backgroundColor: "#BBBBBB", lineHeight: "0px" }}
      >
        <ToggleButton
          value={0}
          aria-label={0}
          style={{
            width: "110px",
            border: 0,
            padding: "0px",
            lineHeight: "0px",
          }}
        >


          {pinfo[0] !== undefined ? (
            <>
              <KeyboardReturnIcon fontSize="inherit" />
            </>
          ) : (
            <Typography
              variant={"h7"}
              align="left"
              color="textPrimary"
              gutterBottom
            >
              /
            </Typography>
          )}

          <Typography
            variant={"h7"}
            align="left"
            color="textPrimary"
            gutterBottom
          ></Typography>
        </ToggleButton>
        <ToggleButton
          value={1}
          aria-label={1}
          style={{
            width: `calc(calc(100% - 110px) / 4)`,
            border: 0,
            padding: "0px",
          }}
        >
          <GridViewIcon fontSize="small" style={{ fill: "#CCCCCC" }} />

        </ToggleButton>

        <ToggleButton
          value={2}
          aria-label={2}
          style={{
            width: `calc(calc(100% - 110px) / 4)`,
            border: 0,
            padding: "0px",
          }}
        >
          <OpenWithIcon fontSize="small" style={{ fill: "#CCCCCC" }} />

        </ToggleButton>

        <ToggleButton
          value={3}
          aria-label={3}
          style={{
            width: `calc(calc(100% - 110px) / 4)`,
            border: 0,
            padding: "0px",
          }}
        >
          <CommentIcon fontSize="small" style={{ fill: "#CCCCCC" }} />

        </ToggleButton>
        <ToggleButton
          value={4}
          aria-label={4}
          style={{
            width: `calc(calc(100% - 110px) / 4)`,
            border: 0,
            padding: "0px",
          }}
        >
          <AccountTreeIcon fontSize="small" style={{ fill: "#CCCCCC" }} />

        </ToggleButton>
      </ToggleButtonGroup> */}

      <div style={{ backgroundColor: props.bgColor.back01 }}>
        <div style={{ height: "2vh" }}></div>
        <table
          style={{
            width: "100%",
            //isplay: "flex",
            //flexDirection: "column",
            //justifyContent: "center",
            //alignItems: "center",
          }}
        >
          <tbody>
            <tr>
              <td style={{ width: "1%" }} align="center"></td>
              <td style={{ width: "13%" }} align="center">
                <div
                // onClick={() => {
                //   if (createrFlag) {
                //     setEditLonpa({
                //       id: lonpaid,
                //       claim: info.claim,
                //       isopinion: info.isopinion,
                //       parent: pinfo[0],
                //       isDelete: info.isDelete,
                //     });
                //     setEditLonpaIsDelete(info.isDelete);
                //     setLonpaEditmodalopen(true);
                //   }
                // }}
                >
                  <LonpaPieChart
                    createrFlag={createrFlag}
                    adminFlag={adminFlag}
                    lonpaid={lonpaid}
                    setOpen={setLodingmodalopen}
                    imageurl={lonpaImageUrl}
                    setimageurl={setLonpaImageUrl}
                    src={rankicon}
                    disagreeratio={
                      (infodisagreesum / (infoagreesum + infodisagreesum)) * 100
                    }
                  />
                </div>
              </td>
              <td style={{ width: "1%" }} align="center"></td>

              <td style={{ width: "70%" }} align="center">
                <Typography
                  width="100%"
                  fontSize={claimSize}
                  fontWeight={"bold"}
                  margin="0"
                  color={props.bgColor.text01.fontColor}
                  gutterBottom
                  align="center"
                  style={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "60px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* ■■■■■■■■■■■■■■■■　Lonpa意見（claim）　■■■■■■■■■■■■■■■■ */}
                    {displayClaim}
                  </div>
                </Typography>

                <TagArray
                  styletext={props.styletext}
                  tagListShow={tagListShow}
                  setOpen={setTagmodalopen}
                  bgColor={props.bgColor}
                />
              </td>
              <td style={{ width: "15%" }} align="center"></td>
            </tr>
          </tbody>
        </table>
        <div>
          <>
            {/* <SwipeableViews
              enableMouseEvents
              resistance
              index={tabIndex}
              onChangeIndex={(index) => handleChangeTabIndex(index)}
            > */}
            {/* <div
                key={0}
                className={classes[`slide${0}`]}
                style={{ height: "60vh" }}
              > */}
            {togglenum !== 2 ? (
              <>
                <LonpaBigWidth
                  lonpaBigWidthUIHeight={"50vh"}
                  addLon={(text) => addLon(text)}
                  pushLon={(id, data, increment) =>
                    pushLon(id, data, increment)
                  }
                  info={info}
                  infoagreesum={infoagreesum}
                  infodisagreesum={infodisagreesum}
                  ChoiceData={ChoiceData}
                  isbuttonlock={isbuttonlock}
                  increseUpdate={increseUpdate}
                  styletext={props.styletext}
                  animationagree={animationagree}
                  animationdisagree={animationdisagree}
                  animationspeed={props.animationspeed}
                  blocktype={props.styletext.isbig === 0}
                  userEmailVerified={userEmailVerified}
                  lonpacreateopen={lonpacreateopen}
                  addLonChild={addLonChild}
                  claimChildSize={claimChildSize}
                  createAILonData={createAILonData}
                  bgColor={props.bgColor}
                />
              </>
            ) : (
              <>
                <LonpaSmallWidth
                  addLon={(text) => addLon(text)}
                  pushLon={(id, data, increment) =>
                    pushLon(id, data, increment)
                  }
                  info={info}
                  infoagreesum={infoagreesum}
                  infodisagreesum={infodisagreesum}
                  ChoiceData={ChoiceData}
                  isbuttonlock={isbuttonlock}
                  increseUpdate={increseUpdate}
                  styletext={props.styletext}
                  openside={0}
                  blocktype={false}
                  userEmailVerified={userEmailVerified}
                  lonpacreateopen={lonpacreateopen}
                  addLonChild={addLonChild}
                  claimChildSize={claimChildSize}
                  createAILonData={createAILonData}
                  bgColor={props.bgColor}
                />
              </>
            )}
            {/* </div>
              <div
                key={1}
                className={classes[`slide${1}`]}
                style={{ height: "60vh" }}
              >
                <div style={{ height: "3vh" }}></div>
                <CommentShow
                  lonpaid={lonpaid}
                  setUploadmodalopen={setUploadmodalopen}
                  setUploaderror={setUploaderror}
                  setUploadprogress={setUploadprogress}
                  setExpimg={setExpimg}
                  setImgmodalopen={setImgmodalopen}
                  uploadmodalopen={uploadmodalopen}
                  comment={comment}
                />
              </div>
              <div
                key={2}
                className={classes[`slide${1}`]}
                style={{ height: "60vh" }}
              >
                <div style={{ height: "3vh" }}></div>
                <GraphShow
                  info={info}
                  pinfo={pinfo}
                  id={lonpaid}
                  ChoiceData={ChoiceData}
                  graphRewriteCounter={graphRewriteCounter}
                />
              </div>
            </SwipeableViews> */}
            {
              //togglenum === 1
            }
          </>

          {/* SNSと前後 */}
          <div
            style={{
              width: "100vw",
              marginTop: "0vh",
              fontSize: "20px",
            }}
            align="center"
          >
            <Button
              onClick={() => {
                GotoPrevLonpa(lonpaid);
              }}
            >
              前
            </Button>

            <SocialShare
              followtag={followTagListString}
              title={snsString}
              url={"https://lonpa.net/Lonpa/" + lonpaid}
              tag={tagListString}
            />
            <IconButton
              aria-label="delete"
              size="small"
              style={{ color: props.bgColor.text01.fontColor }}
              onClick={() => {
                const snsText = snsString + tagListString + followTagListString;
                setEditLonpa({
                  id: lonpaid,
                  claim: info.claim,
                  isopinion: info.isopinion,
                  parent: pinfo[0],
                  isDelete: info.isDelete,
                  infoagree: info.agree,
                  infodisagree: info.disagree,
                  infochoice: info.choiceditem,
                  snsText: snsText,
                  followtag: followTagListString,
                  tag: tagListString,
                });
                //console.log(editLonpa);
                //console.log(snsText)
                //setPictureLonpaIsDelete(info.isDelete);
                setLonpaPicturemodalopen(true);
              }}
            >
              <PhotoIcon fontSize="inherit" />
            </IconButton>
            {createrFlag || adminFlag ? (
              <>
                <IconButton
                  aria-label="delete"
                  size="small"
                  style={{ color: props.bgColor.text01.fontColor }}
                  onClick={() => {
                    setEditLonpa({
                      id: lonpaid,
                      claim: info.claim,
                      isopinion: info.isopinion,
                      parent: pinfo[0],
                      isDelete: info.isDelete,
                    });
                    setEditLonpaIsDelete(info.isDelete);
                    setLonpaEditmodalopen(true);
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>

                <IconButton
                  aria-label="delete"
                  size="small"
                  style={{ color: props.bgColor.text01.fontColor }}
                  onClick={() => {
                    GotoEdit(false); //automakeの時はtrue
                  }}
                >
                  <AutoFixNormalIcon fontSize="inherit" />
                </IconButton>
              </>
            ) : (
              <></>
            )}
            <IconButton
              aria-label="delete"
              size="small"
              style={{ color: props.bgColor.text01.fontColor }}
              onClick={() => {
                GotoRandom();
              }}
            >
              <ShuffleIcon fontSize="inherit" />
            </IconButton>
            {/* デバッグ用
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                props.setAutoCreate({
                  ...props.autoCreate,
                  create: true,
                  tagCreate: true,
                });
              }}
            >
              <BiotechIcon fontSize="inherit" />
            </IconButton> */}

            <Button
              onClick={() => {
                GotoNextLonpa(lonpaid);
              }}
            >
              次
            </Button>
          </div>
        </div>

        {uploadmodalopen ? (
          <UploadModal
            open={uploadmodalopen}
            error={uploaderror}
            progress={uploadprogress}
            setOpen={setUploadmodalopen}
          />
        ) : (
          <></>
        )}

        {imgmodalopen ? (
          <ImgModal
            open={imgmodalopen}
            setOpen={setImgmodalopen}
            img={expimg}
            setExpimg={setExpimg}
          />
        ) : (
          <></>
        )}

        {tagmodalopen ? (
          <EditTagModal
            open={tagmodalopen}
            setOpen={setTagmodalopen}
            tagList={tagList}
            update={tagUpdate}
            setUpdate={setTagUpdate}
            adminFlag={adminFlag}
            id={lonpaid}
            claim={info.claim}
            lonpaAutoMake={lonpaAutoMake}
            tagCreate={props.autoCreate.tagCreate}
            setAutoCreateTagCreateFalse={() => {
              props.setAutoCreate({
                ...props.autoCreate,
                tagCreate: false,
              });
            }}
          />
        ) : (
          <></>
        )}

        {lonpaEditmodalopen ? (
          <LonpaEditModal
            open={lonpaEditmodalopen}
            setOpen={setLonpaEditmodalopen}
            editLonpa={editLonpa}
            editLonpaIsDelete={editLonpaIsDelete}
            setEditLonpaIsDelete={setEditLonpaIsDelete}
            setUploaderror={setUploaderror}
            createrFlag={createrFlag}
          />
        ) : (
          <></>
        )}

        {lonpaPicturemodalopen ? (
          <LonpaPictureModal
            open={lonpaPicturemodalopen}
            setOpen={setLonpaPicturemodalopen}
            editLonpa={editLonpa}
            url={"https://lonpa.net/Lonpa/" + lonpaid}
            adminFlag={adminFlag}
            setIsAITwitterDisable={setIsAITwitterDisable}
            isAITwitterDisable={isAITwitterDisable}
            imageCreate={props.autoCreate.imageCreate}
            setimageurl={setLonpaImageUrl}
            lonpaAutoMake={lonpaAutoMake}
            isCreatedAI={isCreatedAI}
            setAutoCreateImageCreateFalse={() => {
              props.setAutoCreate({
                ...props.autoCreate,
                imageCreate: false,
              });
            }}
            bgColor={props.bgColor}
            tagListString2={tagListString2}
          />
        ) : (
          <></>
        )}

        {lonpaAImodalopen ? (
          <LonpaAIModal
            open={lonpaAImodalopen}
            setOpen={setLonpaAImodalopen}
            editLonpa={editLonpa}
            url={"https://lonpa.net/Lonpa/" + lonpaid}
            setCreateAILonData={setCreateAILonData}
            isCreatedAI={isCreatedAI}
            setIsCreatedAI={setIsCreatedAI}
            setAutoCreateCraimCreateFalse={() => {
              props.setAutoCreate({
                ...props.autoCreate,
                craimCreate: false,
              });
            }}
          />
        ) : (
          <></>
        )}

        <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />

        {/* <LonpaModal
          setLoadopen={setLodingmodalopen}
          open={lonpamodalopen}
          // setOpen={setLonpamodalopen}
          lon={lon}
          info={info}
          maketext={maketext}
          increseUpdate={increseUpdate}
          id={lonpaid}
          parentclaim={info.claim}
        /> */}
      </div>
    </>
  );
}

export default function LonpaPictureType102(props) {
  return (
    <>
      <div align="center"></div>
      <div align="center" width={256} height={512}>
        <br />
        AIで画像生成中です
      </div>
      <div align="center"></div>
    </>
  );
}

import { useStyles } from "../../Styles";

import rankicon from "../../images/rankicon.png";
import newicon from "../../images/newicon.png";

import { Button } from "@mui/material";

import { styled } from "@mui/material/styles";

const CustomButton1 = styled(Button)(() => ({
  color: "#000000",
  backgroundColor: "#ffff7f",
  "&:hover": {
    backgroundColor: "#ffff7f",
  },
}));

const CustomButton2 = styled(Button)(() => ({
  color: "#000000",
  backgroundColor: "#ffffe0",
  "&:hover": {
    backgroundColor: "#ffff7f",
  },
}));

const CustomButton3 = styled(Button)(() => ({
  color: "#000000",
  backgroundColor: "#efffe0",
  "&:hover": {
    backgroundColor: "#bfff7f",
  },
}));

const CustomButton4 = styled(Button)(() => ({
  color: "#000000",
  backgroundColor: "#bfff7f",
  "&:hover": {
    backgroundColor: "#bfff7f",
  },
}));

export default function TopShowTypeToggle(props) {
  const classes = useStyles();

  return (
    <table>
      <tbody>
        <tr align="center">
          <td style={{ width: "50vw" }}>
            {props.showtype === 1 ? (
              <>
                <CustomButton1
                  onClick={() => props.setShowtype(1)}
                  className={classes.topChoiceButton}
                >
                  <img src={rankicon} alt="アイコン" width="40" />
                  人気
                </CustomButton1>
              </>
            ) : (
              <CustomButton2
                onClick={() => props.setShowtype(1)}
                className={classes.topChoiceButton}
              >
                <img src={rankicon} alt="アイコン" width="40" />
                人気
              </CustomButton2>
            )}
          </td>

          <td style={{ width: "50vw" }}>
            {props.showtype === 1 ? (
              <CustomButton3
                onClick={() => props.setShowtype(2)}
                className={classes.topChoiceButton}
              >
                <img src={newicon} alt="アイコン" width="40" />
                新着
              </CustomButton3>
            ) : (
              <CustomButton4
                onClick={() => props.setShowtype(2)}
                className={classes.topChoiceButton}
              >
                <img src={newicon} alt="アイコン" width="40" />
                新着
              </CustomButton4>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

import Signin from "./Signin";
import Signup from "./Signup";

import { Grid, Container, IconButton, Button } from "@mui/material";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import { useNavigate } from "react-router-dom";

import TitleBar from "./LonpaExplain/TitleBar";
import Footer from "./components/Footer";

// import { marked } from "marked";
function MovieMakerExplain(props) {
  const navigate = useNavigate();

  const bigtext = {
    fontWeight: "bold",
    fontSize: "20px",
    textDecoration: "underline",
  };
  // const markdown = `\n`;
  return (
    <>
      <div
        style={{
          backgroundColor: props.bgColor.back01,
          color: props.bgColor.text01.fontColor,
        }}
        align="left"
      >
        <div style={{ height: "6vh" }}></div>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TitleBar
                bgColor={props.bgColor}
                num={"01"}
                text={"閲覧・投票"}
              />
              <br />
              <div style={bigtext}>意見を見る</div>
              {/* <span
              dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }}
            />
            <br />
            <br /> */}
              <br />
              <Button
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Top/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                サイトトップ
              </Button>
              や，{" "}
              <Button
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Top/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                検索画面
              </Button>
              から
              <br />
              意見を選択して読んでみましょう
              <br />
              <br />
              <div style={bigtext}>投票</div>
              <br />
              意見に対して賛成・反対意見が書かれています
              <br />
              共感する意見を1つ選んで♡で投票しましょう
              <br />
              円グラフが更新され、あなたの意見が反映されます
              <br />
              <br />
              <img
                src={props.bgColor.explainpic01}
                style={{
                  width: "80vw", // 例えばビューポートの幅全体
                  maxWidth: "400px",
                  // height: (721/2)+'px', // 例えばビューポートの高さ全体
                }}
              ></img>
              <br />
              <br />
              <br />
              <div style={bigtext}>意見への意見を作成</div>
              <br />
              ＋ボタンを押すと
              <br />
              意見の意見の一番下に
              <br />
              作成用カードが表示されます
              <br />
              <br />
              <img
                src={props.bgColor.explainpic02}
                style={{
                  width: "80vw", // 例えばビューポートの幅全体
                  maxWidth: "200px",
                }}
              ></img>
              <br />
              <br />
              <TitleBar bgColor={props.bgColor} num={"02"} text={"意見作成"} />
              <br />
              <div style={bigtext}>アカウント作成</div>
              <br />
              意見を作成するには、ログインが必要です．
              <br />
              まずは
              <Button
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Signup/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                アカウント
              </Button>
              を作成しましょう
              <br />
              <br />
              既にアカウントをお持ちであれば
              <Button
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Signin/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                ログイン
              </Button>
              <br />
              <br />
              <div style={bigtext}>メールアドレス認証</div>
              <br />
              <Button
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Userpage/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                ユーザ設定画面
              </Button>
              より
              <br />
              メールアドレス認証を行いましょう
              <br />
              <br />
              <div style={bigtext}>意見作成</div>
              <br />
              <Button
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Create/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                作成画面
              </Button>
              より、意見を作成しましょう
              <br />
            </Grid>
          </Grid>
        </Container>
        <div style={{ height: "2vh" }}></div>
      </div>
      <Footer
        bgColor={props.bgColor}
        styletext={props.styletext}
        isimgshow={true}
      />
    </>
  );
}
export default MovieMakerExplain;

const cellStyle = {
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "10px",
  paddingBottom: "10px",
};
const cellStyleBold = {
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontWeight: "bold",
  color: "#B31B00",
};

export const pointtableTypeA = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyleBold}>意見案生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>論拠生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>画像生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>タグ生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>動画原稿生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>SNS文章生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyle}>動画生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeB = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>論拠生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>画像生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>タグ生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>動画原稿生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>SNS文章生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyle}>動画生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeC = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>論拠生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>画像生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>タグ生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>動画原稿生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>400pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>SNS文章生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyleBold}>動画生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>1300pt</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeD = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>論拠生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>画像生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>タグ生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>動画原稿生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>SNS文章生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyle}>動画生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeE = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>利用point</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>論拠生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0point</td>
      </tr>
      <tr>
        <td style={cellStyle}>画像生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0point</td>
      </tr>
      <tr>
        <td style={cellStyle}>タグ生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>動画原稿生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0point</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>SNS文章生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200point</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyle}>動画生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0point</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeF = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>利用point</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>論拠生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200point</td>
      </tr>
      <tr>
        <td style={cellStyle}>画像生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0point</td>
      </tr>
      <tr>
        <td style={cellStyle}>タグ生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>動画原稿生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0point</td>
      </tr>
      <tr>
        <td style={cellStyle}>SNS文章生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0point</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyle}>動画生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0point</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeG = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>論拠生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>画像生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>タグ生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>動画原稿生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>400pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>SNS文章生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyleBold}>動画生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>1000pt</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeH = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>論拠生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>画像生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>タグ生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>動画原稿生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>400pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>SNS文章生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyle}>動画生成</td>
        <td style={cellStyle}>1回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeI = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>論拠生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>画像生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>タグ生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>動画原稿生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>400pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>SNS文章生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyle}>動画生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeJ = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>論拠生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>画像生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyleBold}>タグ生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>200pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>動画原稿生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>SNS文章生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyle}>動画生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
    </tbody>
  </table>
);

export const pointtableTypeK = (
  <table>
    <tbody>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>内容</td>
        <td style={cellStyle}>回数</td>
        <td style={cellStyle}>消費</td>
      </tr>
      <tr style={{ borderTop: "solid 3px #CCC" }}>
        <td style={cellStyle}>意見案生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>論拠生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>画像生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>タグ生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>動画原稿生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr>
        <td style={cellStyle}>SNS文章生成</td>
        <td style={cellStyle}>0回</td>
        <td style={cellStyle}>0pt</td>
      </tr>
      <tr style={{ borderBottom: "solid 3px #EEE" }}>
        <td style={cellStyleBold}>動画生成</td>
        <td style={cellStyleBold}>1回</td>
        <td style={cellStyleBold}>1200pt</td>
      </tr>
    </tbody>
  </table>
);

import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useStyles } from "../Styles";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import GridViewIcon from "@mui/icons-material/GridView";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import DeleteIcon from "@mui/icons-material/Delete";

import { useCookies } from "react-cookie";

import LonpaSmallWidth from "./Lonpa/LonpaSmallWidth";
import LonpaBigWidth from "./Lonpa/LonpaBigWidth";
import SocialShare from "./components/SocialShare";
import TagArray from "./Lonpa/TagArray";
import LonpaModal from "./Lonpa/Modal/Lonpamodal";
import GraphShow from "./Lonpa/GraphShow";
import CommentShow from "./Lonpa/CommentShow";
import LodingModal from "./CommonModal/LodingModal";
import UploadModal from "./Lonpa/Modal/UploadModal";
import ImgModal from "./CommonModal/ImgModal";
import EditTagModal from "./Lonpa/Modal/EditTagModal";
import LonpaEditModal from "./CommonModal/LonpaEditModal";
import SwipeableViews from "react-swipeable-views";
import LonpaPieChart from "./Lonpa/LonpaPieChart";

import rankicon from "../images/rankicon.png";

import {
  createLonpaChild,
  createLonpa,
  setLonpa,
  setLonpaChild,
  setLonpaChildVote,
  setUserPrivateVote,
  setUserPrivateCreateLonpa,
  DeleteLonpaChildVote,
  deleteUserPrivateVote,
  addVote,
  updateLonpaChild,
  updateLonpa,
  getUserPrivateVote,
  getUserAvatarList,
  getAllLonpaChildVote,
  getLonpaList,
  getUserAvatar,
  getUserPublic,
  getLonpa,
  getUserPrivateCreateLonpa,
  getNextLonpa,
  getPrevLonpa,
} from "../common/Helper/FirebaseHelper";

import {
  transactionPushLon,
  getUserPrivateVoteTransaction,
} from "../common/Helper/FirebaseTransactionHelper";

import {
  SortByDescend,
  Conversiondate,
  joinBy,
} from "../common/Helper/CommonHelper";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  LinearProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import "bootstrap/dist/css/bootstrap.min.css";

import { serverTimestamp } from "firebase/firestore";
// import { useSpring, animated } from "@react-spring/web";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { firebaseApp, userApp } from "../common/firebase";

import moment from "moment";
import styled from "styled-components";
import { adminUid } from "../common/Config/AdminConfig";

// 文空オリジナル
import { useQRCode } from "next-qrcode";
//

const Styles = styled.div`
  table {
    table-layout: fixed;
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      :last-child {
        border-right: 0;
      }
    }
  }
`;

export default function Lonpa(props) {
  // 文空オリジナル変数
  //
  //
  const [isBunkuTitleShow, setIsBunkuTitleShow] = useState(true);

  const { Canvas } = useQRCode();
  //
  //AIによる論拠作成用
  const [createAILonData, setCreateAILonData] = useState({
    agree: undefined,
    disagree: undefined,
  });
  //
  let { lonpaid } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    claim: "読み込み中",
    agree: [],
    disagree: [],
    agreesum: 0,
    disagreesum: 0,
    choiceditem: {},
    imageUrl: undefined,
    isDelete: undefined,
    ischoiced: undefined,
    isopinion: undefined,
    login: undefined,
  });
  // const [lonpamodalopen, setLonpamodalopen] = useState(false);
  const [lonpacreateopen, setLonpacreateopen] = useState({
    agree: false,
    disagree: false,
  });
  const [lodingmodalopen, setLodingmodalopen] = useState(true);
  const [imgmodalopen, setImgmodalopen] = useState(false);
  const [expimg, setExpimg] = useState("");
  //const [maketext, setMaketext] = useState("Lonpa作成");
  //const [lon, setLon] = useState("agree");
  const [update, setUpdate] = useState(0);
  const [isbuttonlock, setisbuttonlock] = useState(true);
  const [pinfo, setPinfo] = useState([]);
  const [smallwidthopen, setSmallwidthopen] = useState([false, true]);
  const [animationagree, setAnimationagree] = useState(false);
  const [animationdisagree, setAnimationdisagree] = useState(false);
  //コメント部分のmodal
  //const [commentmodalopen, setCommentmodalopen] = useState(false);
  const [gotonextpage, setGotonextpage] = useState(true);
  //画像投稿時のエラー内容を格納
  const [uploaderror, setUploaderror] = useState("");
  //画像投稿時の進捗具合
  const [uploadprogress, setUploadprogress] = useState(0);
  //画像投稿時に使うmodal
  const [uploadmodalopen, setUploadmodalopen] = useState(false);
  const [comment, setComment] = useState([]);
  //tagリストの受け渡し
  const [tagList, setTagList] = useState([]);
  const [tagUpdate, setTagUpdate] = useState(0);
  const [tagmodalopen, setTagmodalopen] = useState(false);

  const [userEmailVerified, setUserEmailVerified] = useState(false);

  const [createrFlag, setCreaterFlag] = useState(false);

  const [adminFlag, setAdminFlag] = useState(false);

  const [lonpaEditmodalopen, setLonpaEditmodalopen] = useState(false);
  const [editLonpa, setEditLonpa] = useState("");
  const [editLonpaIsDelete, setEditLonpaIsDelete] = useState("");

  const [lonpaImageUrl, setLonpaImageUrl] = useState("");

  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [selfVoteInfo, setSelfVoteInfo] = useState(undefined);
  const [useEffectTriggerInfo, setUseEffectTriggerInfo] = useState();
  const [useEffectTriggerInitFlag, setUseEffectTriggerInitFlag] = useState();
  const [useEffectItemVote, setUseEffectItemVote] = useState([]);

  const [infoagreesum, setInfoagreesum] = useState(0);
  const [infodisagreesum, setInfodisagreesum] = useState(0);
  const [infosumchange, setInfosumchange] = useState({
    isagree: true,
    changenum: 0,
  });

  // const { x } = useSpring({
  //   from: { x: 0 },
  //   x: gotonextpage ? 1 : 0,
  //   config: { duration: props.animationspeed.lonpatable },
  // });

  const [togglenum, setToggleNum] = useState(1);

  const [tabIndex, setTabIndex] = React.useState(props.openside);

  const handleChangeTabIndex = (index) => {
    setTabIndex(index);
    if (index == 0) {
      setAnimationagree(false);
      setAnimationdisagree(false);
      setSmallwidthopen([false, false]);
      setToggleNum(1);
    } else {
      setToggleNum(index + 2);
    }
  };

  //トップに表示するタイトルの文字数
  const claimlen = [20, 40, 60];
  const [claimSize, setClaimSize] = useState(40);
  const [claimChildSize, setClaimChildSize] = useState({
    textsize1: 25,
    textsize2: 22,
    textsize3: 20,
  });
  const [displayClaim, setDisplayClaim] = useState("");

  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  const picursl = [
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/img01.jpg?alt=media&token=4eded214-50c7-4751-aa7b-5251f52d6dc8",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/img02.jpg?alt=media&token=d91b4d4e-3ed8-4474-93bc-55f40e373d66",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/img03.jpg?alt=media&token=265cfb1d-2381-41d3-91a8-bd0f9d894f63",
  ];
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleFormat = (event, newFormats) => {
    if (newFormats !== null) {
      if (newFormats === 0) {
        if (pinfo[0] !== undefined) {
          ChoiceData(pinfo[0]);
        }
      } else if (newFormats === 1) {
        setAnimationagree(false);
        setAnimationdisagree(false);
        setSmallwidthopen([false, false]);
        setToggleNum(1);
        setTabIndex(0);
      } else if (newFormats === 2) {
        if (tabIndex === 0) {
          if (!smallwidthopen[0]) {
            setAnimationagree(true);
            window.setTimeout(function () {
              setSmallwidthopen([true, true]);
              setToggleNum(2);
              setTabIndex(0);
            }, props.animationspeed.lonpatable);
          }
        } else {
          setToggleNum(2);
          setTabIndex(0);
        }
      } else {
        setToggleNum(newFormats);
        setTabIndex(newFormats - 2);
      }
    }
  };

  const ChoiceData = (id) => {
    if (id === "Search") {
      navigate("/NewSearch/");
    } else {
      if (lonpaid !== id) {
        setGotonextpage(true);
        setLodingmodalopen(true);
        setLonpacreateopen({ agree: false, disagree: false });

        window.setTimeout(function () {
          window.scrollTo(0, 0);
          setSmallwidthopen([false, true]);
          setAnimationagree(false);
          setAnimationdisagree(false);
          navigate("/Bunku/" + id);
          lonpaid = id;
        }, props.animationspeed.lonpatable);
      }
    }
  };
  async function ConnectLonpa(childid, title) {
    await setLonpaChild(lonpaid, childid, {
      claim: title,
      votenum: 0,
      agree: lon,
      child: true,
    });

    await setLonpaChild(childid, lonpaid, {
      claim: info.claim,
      child: false,
    });

    //navigate('/Lonpa/' + childid);
    // setLonpamodalopen(false);
    handleClose();
  }

  // async function getNextLonpa(id) {
  //     //tree描画で，Lonpa押下時に呼び出される
  //     let nextdata = [];
  //     let querySnapshot = await lonpa_related_getall(id);
  //     querySnapshot.forEach((doc) => {
  //         if (doc.data().child) {
  //             nextdata.push({ id: doc.id, claim: doc.data().claim, agree: doc.data().agree });
  //         }
  //     });
  //     return (nextdata)
  // }

  const increseUpdate = () => {
    setUpdate(update + 1);
  };

  useEffect(() => {
    console.log("uE_Bunku");
    setComment([]);
    let commentData = [];
    let userIdList = [];
    let avatarList = [];
    setUseEffectTriggerInitFlag(true);

    const db = getFirestore(firebaseApp);

    //コメント変更監視
    const q = query(
      collection(db, "lonpa", lonpaid, "comment"),
      orderBy("date", "desc"),
      limit(10)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const f = async () => {
            commentData.push({
              chat: change.doc.data().chat,
              userid: change.doc.data().userid,
              tmpdate: change.doc.data().date,
              date: Conversiondate(change.doc.data().date, "DATE"),
              time: Conversiondate(change.doc.data().date, "TIME"),
            });
            if (
              !userIdList.includes(change.doc.data().userid) &&
              change.doc.data().userid
            ) {
              userIdList.push(change.doc.data().userid);
              const avatarquerySnapshot = await getUserAvatar(
                change.doc.data().userid
              );
              avatarquerySnapshot.forEach((doc) => {
                if (doc.data().imageurl) {
                  avatarList.push({
                    userid: doc.id,
                    imageurl: doc.data().imageurl,
                    user: doc.data().name,
                  });
                }
              });
            }
            const chatData = joinBy(commentData, avatarList, "userid").sort(
              (a, b) => {
                if (a.tmpdate > b.tmpdate) return 1;
                if (a.tmpdate < b.tmpdate) return -1;
                return 0;
              }
            );

            setComment(chatData);
          };
          f();
        }
      });
    });

    //ユーザが承認ユーザか，Lonpa作成者か，記録する
    onAuthStateChanged(getAuth(userApp), async (user) => {
      if (user) {
        setUserEmailVerified(user.emailVerified);
        const querySnapshot = await getUserPrivateCreateLonpa(lonpaid);
        if (querySnapshot.data() === undefined) {
          setCreaterFlag(false);
        } else {
          setCreaterFlag(true);
        }

        // if (user.uid === "ZQtuSsdEfHZm32LG7L4q4J3hhfN2" || user.uid === "5r0llMYlnETBAtruqr8GpJxbfkk1") {
        if (adminUid.includes(user.uid)) {
          setAdminFlag(true);
          //console.log("admin権限です");
        }
      } else {
        setUserEmailVerified(false);
      }
    });

    const q2 = query(collection(db, "lonpa", lonpaid, "child"));

    //Lonpa変更監視
    const unsubscribe2 = onSnapshot(q2, (relatedSnapshot) => {
      const f = async () => {
        setUseEffectTriggerInfo(relatedSnapshot);
      };
      f();
    });
    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, [lonpaid]);

  useEffect(() => {
    console.log("uE_Bunku");
    const f = async () => {
      const doc = await getLonpa(lonpaid);
      const tmpTagList = (() => {
        const tagLists = doc.data().tag;
        if (typeof tagLists !== "undefined") {
          return Object.keys(tagLists);
        } else {
          return [""];
        }
      })();
      setTagList(tmpTagList);
    };
    f();
  }, [tagUpdate, lonpaid]);

  //★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
  useEffect(() => {
    console.log("uE_Bunku");
    //useEffectTriggerInfoの変更で起動する
    const f = async () => {
      //論拠（lonpa-related）の更新を検知して動き出す場所
      //注意！外部からのuseState値等は扱うことができません！
      let infoclaim;

      let infoIsopinion;
      let infoImageurl;
      let infoIsDelete = "true";

      let itemlist = [];
      let itemvote = useEffectItemVote;
      let prebnum = {};
      let parentid = "Search";
      let parentclaim = "検索";

      let choice = { islogin: false, ischoiced: false, choiceditem: null };
      let nextinfo = {};

      let lonpaVoteSnapshot; //各論拠のvote一覧
      let voteidlists = {}; // 各論拠ごとのvote内容の中間データ
      let voteidlist = []; // image一覧作成のために全データ入れる格納場所
      let votelist = {}; //投票者（名前と画像）の情報群
      onAuthStateChanged(getAuth(userApp), async (user) => {
        if (useEffectTriggerInitFlag) {
          let infoagree = []; // infoagree 賛成分の論拠をまとめたもの
          let infodisagree = []; // infodisagree 反対分の論拠をまとめたもの
          let nextinfoagreesum = 0;
          let nextinfodisagreesum = 0;

          ////////// 1　現在開いているLonpaと、その論拠情報を収集////////////

          //itemlistにLonpaのIDを登録
          itemlist.push(lonpaid);

          //itemlistに論拠のIDを登録
          useEffectTriggerInfo.forEach((doc) => {
            itemlist.push(doc.id);
          });

          //Lonpaと論拠のagree,disagreenumを取得
          const itemlistdata = await getLonpaList(itemlist);

          //１つ以上のデータが取れているかの判定用フラグ
          let ischeckdataexist = false;

          //Lonpaと論拠を分けて，情報整理
          itemlistdata.forEach((doc) => {
            if (doc.id === lonpaid) {
              //Lonpaの情報
              infoclaim = doc.data().claim;
              prebnum.agree = doc.data().agree;
              prebnum.disagree = doc.data().disagree;
              parentid = doc.data().parent;
              parentclaim = "不明";
              infoIsDelete = doc.data().isDelete;
              infoIsopinion = doc.data().isopinion;
              infoImageurl = doc.data().imageurl;

              setLonpaImageUrl(infoImageurl);

              if (infoclaim.length > claimlen[2]) {
                //claimが60文字以上なら，表示内容を変える
                setDisplayClaim(infoclaim.slice(0, claimlen[2]) + "…");
                setClaimSize(24);
              } else if (infoclaim.length > claimlen[1]) {
                //claimが40文字以上なら，文字サイズを変える
                setDisplayClaim(infoclaim);
                setClaimSize(24);
              } else if (infoclaim.length > claimlen[0]) {
                //claimが20文字以上なら，文字サイズを変える
                setDisplayClaim(infoclaim);
                setClaimSize(34);
              } else {
                setDisplayClaim(infoclaim);
                setClaimSize(40);
              }
            } else {
              //論拠の情報
              itemvote[doc.id] = {
                agree: doc.data().agree,
                disagree: doc.data().disagree,
              };
            }
            ischeckdataexist = true;
          });

          setUseEffectItemVote(itemvote);

          //１つもデータが取れていない＝Lonpaが存在しないなら，エラーページへ遷移
          if (!ischeckdataexist || infoIsDelete) {
            navigate("/Sorry");
          }

          ////////////console.log("2　投票状況を収集");//////////
          //onAuthStateChanged(getAuth(userApp), async (user) => {
          if (user) {
            //const user = getAuth(userApp).currentUser ?? { emailVerified: false };

            //ログイン状態だった場合，自身が過去投票したlonpaの一覧を取得する
            const doc2 = await getUserPrivateVoteTransaction(lonpaid);
            //ログイン中のアカウントで，選択中のlonpa一覧
            if (doc2) {
              if (doc2.data()) {
                choice = {
                  islogin: true, //ログインしていて
                  ischoiced: true, //投票していて
                  choiceditem: {
                    id: doc2.data().votedLonpaId,
                    votenum: 0,
                    isAgree: doc2.data().isAgree,
                  }, //投票先はここ
                };
              } else {
                choice = {
                  islogin: true, //ログインしていて
                  ischoiced: false, //投票していない
                  choiceditem: null,
                };
              }
            } else {
              choice = {
                islogin: true, //ログインしていて
                ischoiced: false, //投票していない
                choiceditem: null,
              };
            }
          } else {
            //未ログイン状態■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
            setSelfVoteInfo(cookies[lonpaid]);

            if (cookies[lonpaid] === undefined) {
              choice = {
                islogin: false, //ログインしていない
                ischoiced: false, //投票していない
                choiceditem: null,
              };
            } else {
              choice = {
                islogin: false, //ログインしていて
                ischoiced: true, //投票していて
                choiceditem: {
                  id: cookies[lonpaid].id,
                  votenum: 0,
                  isAgree: cookies[lonpaid].isAgree,
                }, //投票先はここ
              };
            }
          }
          //});

          ////////// 3　表示情報を作成 ////////////

          //変数整理
          // prebnum: Lonpaの賛成・反対の総数
          // useEffectTriggerInfo: 論拠の中身一覧（投票数numを取得するために必要）
          // infoclaim: Lonpaのclaim
          // itemvote: 論拠の，agree・disagreeの一覧
          // choice.islogin:ログインしてるかどうか　true false
          // choice.ischoiced:　投票済みかどうか　true false
          // choice.choiceditem: 投票した論拠
          // infoagree 賛成分の論拠をまとめたもの
          // infodisagree 反対分の論拠をまとめたもの
          // infodata 論拠1つ分のデータ
          // info 諸々全部の情報をまとめたもの
          // lonpaVoteSnapshot 各論拠のvote一覧

          ////////// 3-1　投票に関する情報群を作成////////////
          // voteidlists 各論拠ごとのvote内容の中間データ
          // voteidlist image一覧作成のために全データ入れる格納場所

          //【投票の整理】各論拠に入っている、voteの情報を全て取りに行く。
          for (let i = 0; i < useEffectTriggerInfo.size; i++) {
            const doc = useEffectTriggerInfo.docs[i];
            //各論拠に紐づくvote一覧を取得
            lonpaVoteSnapshot = await getAllLonpaChildVote(lonpaid, doc.id);
            let voteids = [];
            for (let i = 0; i < lonpaVoteSnapshot.size; i++) {
              // 各論拠ごとのvote内容の中間データ1
              voteids.push(lonpaVoteSnapshot.docs[i].id);
              // image一覧作成のために全データ入れる格納場所
              voteidlist.push(lonpaVoteSnapshot.docs[i].id);
            }
            //各論拠ごとのvote内容の中間データ2
            voteidlists[doc.id] = voteids;
          }
          //list型に変換する
          voteidlist = [...new Set(voteidlist)];

          //投票されたユーザ情報を全て取りに行き、プロフィール画像が登録されているものだけリストアップする
          const avatarquerySnapshot = await getUserAvatarList(voteidlist);
          avatarquerySnapshot.forEach((doc) => {
            //ユーザプロフィール画像が登録されている場合
            if (doc.data().imageurl) {
              //投票情報に、投票者とプロフィール画像を登録
              votelist[doc.id] = {
                imageurl: doc.data().imageurl,
                user: doc.data().name,
              };
            }
          });

          ////////// 3-2　各論拠の内容をinfodataとし，賛成論拠のリストinfoagreeと，反対論拠のリストinfodisagreeを作る////////////
          let createdBy;
          for (let i = 0; i < useEffectTriggerInfo.size; i++) {
            ////////// 3-2-1　1論拠の整形済み情報 = infodataを作成する////////////
            ////////// 3-2-1-1　1論拠の情報をdocに格納////////////
            const doc = useEffectTriggerInfo.docs[i];

            if (!doc.data().isDelete) {
              ////////// 3-2-1-2　1論拠の作者情報「createdBy」を整理////////////
              if (doc.data().createdBy) {
                //もし、作成者情報が,格納されていたら、詳細を取りに行く
                const userPrivateData = await getUserPublic(
                  doc.data().createdBy
                );
                if (typeof userPrivateData.data() !== "undefined") {
                  //作成者情報が取得できたら、infodataに追加する
                  createdBy = {
                    id: doc.data().createdBy,
                    imageurl: userPrivateData.data().imageurl,
                    name: userPrivateData.data().name,
                    createdAt: parseAsMoment(doc.data().createdAt.toDate())
                      .format("YYYY/MM/DD HH:mm")
                      .toString(),
                  };
                } else {
                  //作成者情報が取得できなければ、ビジター情報として追加する
                  createdBy = {
                    createdAt: parseAsMoment(doc.data().createdAt.toDate())
                      .format("YYYY/MM/DD HH:mm")
                      .toString(),
                  };
                }
              } else {
                //作成者情報が格納されていなければ、ビジター情報として追加する
                createdBy = {
                  createdAt: parseAsMoment(doc.data().createdAt.toDate())
                    .format("YYYY/MM/DD HH:mm")
                    .toString(),
                };
              }

              ////////// 3-2-1-3　1論拠の投票数，投票者情報(画像・ユーザID)整理////////////
              //投票と投票者情報を整形したものをvotedatasとしてつくる
              let votedatas = [];
              if (typeof voteidlists[doc.id] !== "undefined") {
                //1つ以上投票がある時
                for (let j = 0; j < voteidlists[doc.id].length; j++) {
                  //各論拠の各投票について、
                  if (typeof votelist[voteidlists[doc.id][j]] !== "undefined") {
                    //ユーザ情報が存在する場合
                    //votedatasにユーザ情報を追加する
                    votedatas.push({
                      imageurl: votelist[voteidlists[doc.id][j]].imageurl,
                      user: votelist[voteidlists[doc.id][j]].user,
                    });
                  } else {
                    //ユーザ情報が存在しない場合
                    //votedatasに、ビジター情報を追加する
                    votedatas.push({
                      imageurl: picursl[0],
                      user: "noname",
                    });
                  }
                }
              }

              ////////// 3-2-1-4　閲覧者による投票状況の整理////////////
              let ischoiceditem;

              if (choice.ischoiced) {
                //もし、表示者により投票されていれば
                if (doc.id === choice.choiceditem.id) {
                  //その１論拠へ投票されていれば
                  //投票がされているフラグを立てて、投票数を格納しておく？
                  ischoiceditem = true;
                  choice.choiceditem.votenum = doc.data().votenum;
                } else {
                  //別の論拠への投票であれば
                  //投票がされていないフラグを立てる
                  ischoiceditem = false;
                }
              } else {
                //そもそも投票されていなければ、
                //投票がされていないフラグを立てる
                ischoiceditem = false;
              }

              ////////// 3-2-1-5　上記で整理した情報をまとめる////////////
              let infodata = {
                id: doc.id,
                data: doc.data(),
                agree: itemvote[doc.id].agree,
                disagree: itemvote[doc.id].disagree,
                votedatas: votedatas,
                createdBy: createdBy,
                ischoiceditem: ischoiceditem,
              };

              ////////// 3-2-2　1論拠が 賛成論拠か、反対論拠か仕分ける////////////
              if (doc.data().agree) {
                //賛成論拠は，infoagreeにまとめる
                infoagree.push(infodata);
                nextinfoagreesum = nextinfoagreesum + infodata.data.votenum;
              } else {
                //反対論拠は，infodisagreeにまとめる
                infodisagree.push(infodata);
                nextinfodisagreesum =
                  nextinfodisagreesum + infodata.data.votenum;
              }
            }
          }

          ////////// 3-3　賛成・反対論拠をすべてまとめた，infoを作成する////////////
          // 投票数が多い順に並び替え
          SortByDescend(infoagree);
          SortByDescend(infodisagree);

          nextinfo = {
            claim: infoclaim,
            agree: infoagree,
            disagree: infodisagree,
            login: choice.islogin,
            ischoiced: choice.ischoiced,
            choiceditem: choice.choiceditem,
            isDelete: infoIsDelete,
            isopinion: infoIsopinion,
            imageUrl: infoImageurl,
          };
          //console.log(nextinfo);
          setInfo(nextinfo);
          setInfoagreesum(nextinfoagreesum);
          setInfodisagreesum(nextinfodisagreesum);

          ////////// 3-4　Lonpaの親情報Pinfoを作成する////////////
          setPinfo([parentid, parentclaim]);

          ////////// 4　各論拠の投票数と，Lonpaの投票結果が合致していない場合，DBを更新する////////////
          if (
            prebnum.agree !== nextinfoagreesum ||
            prebnum.disagree !== nextinfodisagreesum
          ) {
            updateLonpa(lonpaid, {
              agree: nextinfoagreesum,
              disagree: nextinfodisagreesum,
            });
          }

          ////////// 5 　画面セットアップ完了のお知らせ////////////
          setLodingmodalopen(false);
          setGotonextpage(false);
          setUseEffectTriggerInitFlag(false);
        } else {
          let infoagree = info.agree; // infoagree 賛成分の論拠をまとめたもの
          let infodisagree = info.disagree; // infodisagree 反対分の論拠をまとめたもの

          if (useEffectTriggerInfo) {
            /////////////////initではない場合 別の人が論拠作成など////////////////////////////
            useEffectTriggerInfo.docChanges().forEach(async (change) => {
              if (change.type === "added") {
                //■■■■■■■■ 追加の場合 ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■//
                const doc = change.doc;
                //論拠の情報追加
                itemvote[doc.id] = {
                  agree: doc.data().agree,
                  disagree: doc.data().disagree,
                };
                setUseEffectItemVote(itemvote);

                ////////// 3　表示情報を作成 ////////////

                ////////// 3-2　各論拠の内容をinfodataとし，賛成論拠のリストinfoagreeと，反対論拠のリストinfodisagreeを作る////////////
                ////////// 3-2-1　1論拠の整形済み情報 = infodataを作成する////////////
                ////////// 3-2-1-2　1論拠の作者情報「createdBy」を整理////////////
                let createdBy;

                if (doc.data().createdAt !== null) {
                  if (doc.data().createdBy) {
                    //もし、作成者情報が,格納されていたら、詳細を取りに行く
                    const userPrivateData = await getUserPublic(
                      doc.data().createdBy
                    );
                    if (typeof userPrivateData.data() !== "undefined") {
                      //console.log(doc.data().createdAt !== null);
                      //作成者情報が取得できたら整形
                      createdBy = {
                        id: doc.data().createdBy,
                        imageurl: userPrivateData.data().imageurl,
                        name: userPrivateData.data().name,
                        createdAt: parseAsMoment(doc.data().createdAt.toDate())
                          .format("YYYY/MM/DD HH:mm")
                          .toString(),
                      };
                    } else {
                      //作成者情報が取得できなければ、ビジター情報として追加する
                      createdBy = {
                        createdAt: parseAsMoment(doc.data().createdAt.toDate())
                          .format("YYYY/MM/DD HH:mm")
                          .toString(),
                      };
                    }
                  } else {
                    //作成者情報が格納されていなければ、ビジター情報として追加する
                    createdBy = {
                      createdAt: parseAsMoment(doc.data().createdAt.toDate())
                        .format("YYYY/MM/DD HH:mm")
                        .toString(),
                    };
                  }
                } else {
                  createdBy = {};
                }

                ////////// 3-2-1-3　1論拠の投票数，投票者情報(画像・ユーザID)整理////////////
                //投票はとりこまない
                let votedatas = [];

                ////////// 3-2-1-4　閲覧者による投票状況はfalse////////////
                let ischoiceditem = false;

                ////////// 3-2-1-5　上記で整理した情報をまとめる////////////
                let infodata = {
                  id: doc.id,
                  data: doc.data(),
                  agree: itemvote[doc.id].agree,
                  disagree: itemvote[doc.id].disagree,
                  votedatas: votedatas,
                  createdBy: createdBy,
                  ischoiceditem: ischoiceditem,
                };

                ////////// 3-2-2　1論拠が 賛成論拠か、反対論拠か仕分ける////////////
                if (doc.data().agree) {
                  //賛成論拠は，infoagreeにまとめる
                  infoagree.push(infodata);
                } else {
                  //反対論拠は，infodisagreeにまとめる
                  infodisagree.push(infodata);
                }
                ////////// 3-3　賛成・反対論拠をすべてまとめた，infoを作成する////////////
                // 一番下に追加するので，ソートなどせず普通に作る
                //console.log(info);

                nextinfo = {
                  agree: infoagree,
                  disagree: infodisagree,
                  ...info,
                };
                setInfo(nextinfo);

                ////////// 4　各論拠の投票数と，Lonpaの投票結果が合致していない場合，DBを更新する////////////
                //ここは不要

                ////////// 5 　画面セットアップ完了////////////
              } else if (change.type === "modified") {
                //■■■■■■■■ 変更の場合 ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■//
                const doc = change.doc;

                // ★　閲覧者自身の投票結果が変わっている可能性がある
                ////////// 2　閲覧者自身の投票状況を収集 ////////////

                //ユーザが承認ユーザか，Lonpa作成者か，記録する
                //onAuthStateChanged
                if (user) {
                  //console.log("ログイン");
                  //const user = getAuth(userApp).currentUser ?? { emailVerified: false };
                  //if (user.emailVerified) {
                  //ログイン状態だった場合，自身が過去投票した情報を取得する
                  const doc2 = await getUserPrivateVote(lonpaid);

                  let votenumnew;
                  if (info.choiceditem === null) {
                    votenumnew = null;
                  } else if (typeof info.choiceditem === "undefined") {
                    votenumnew = null;
                  } else {
                    votenumnew = info.choiceditem.votenum;
                  }

                  if (doc.id == lonpaid) {
                    votenumnew = doc.data().votenum;
                  }
                  //console.log(doc2.data());
                  //ログイン中のアカウントで，選択中のlonpa一覧
                  if (doc2.data()) {
                    //ログインしていて，投票先がある場合
                    choice = {
                      islogin: true, //ログインしていて
                      ischoiced: true, //投票していて
                      choiceditem: {
                        id: doc2.data().votedLonpaId,
                        votenum: votenumnew,
                        isAgree: doc2.data().isAgree,
                      }, //投票先はここ
                    };
                  } else {
                    //ログインしていて，投票先がない場合
                    //クッキーに情報があるかどうかは無視
                    // if (cookies[lonpaid].id === undefined) {

                    choice = {
                      islogin: true, //ログインしていて
                      ischoiced: false, //投票していない
                      choiceditem: null,
                    };
                  }
                } else {
                  console.log("未ログイン");
                  //未ログイン状態または、メール未認証 ■ 投票中の内容設定

                  if (selfVoteInfo === undefined) {
                    choice = {
                      islogin: false, //ログインしていない
                      ischoiced: false, //投票していない
                      choiceditem: null,
                    };
                  } else {
                    let votenumnew;
                    if (info.choiceditem === null) {
                      votenumnew = null;
                    } else if (typeof info.choiceditem === "undefined") {
                      votenumnew = null;
                    } else {
                      votenumnew = info.choiceditem.votenum;
                    }
                    if (doc.id == selfVoteInfo.id) {
                      votenumnew = doc.data().votenum;
                    }
                    choice = {
                      islogin: false, //ログインしていて
                      ischoiced: true, //投票していて
                      choiceditem: {
                        id: selfVoteInfo.id,
                        votenum: votenumnew,
                        isAgree: selfVoteInfo.isAgree,
                      }, //投票先はここ
                    };
                  }
                }

                // ★　論拠の投票結果の数が変わっている可能性がある
                //論拠の情報追加
                itemvote[doc.id] = {
                  agree: doc.data().agree,
                  disagree: doc.data().disagree,
                };

                //nextinfoagreesum = itemvote[doc.id].agree.length;
                //nextinfodisagreesum = itemvote[doc.id].disagree.length;

                ////////// 3-1　投票に関する情報群を作成////////////
                // voteidlists 各論拠ごとのvote内容の中間データ
                // voteidlist image一覧作成のために全データ入れる格納場所

                //【投票の整理】各論拠に入っている、voteの情報を全て取りに行く。
                //for (let i = 0; i < useEffectTriggerInfo.size; i++) {
                //  const doc = useEffectTriggerInfo.docs[i];

                //変更のあった論拠に紐づくvoteの投票者一覧を取得し，今見ている投票ユーザ一覧を更新する  ★上手く動くか不明
                lonpaVoteSnapshot = await getAllLonpaChildVote(lonpaid, doc.id);
                let voteids = [];

                // if (typeof voteidlists[doc.id] !== "undefined") {
                //   if (doc.data().agree) {
                //     nextinfoagreesum =
                //       info.agreesum +
                //       (lonpaVoteSnapshot.size - voteidlists[doc.id].length);
                //   } else {
                //     nextinfodisagreesum =
                //       info.disagreesum +
                //       (lonpaVoteSnapshot.size - voteidlists[doc.id].length);
                //   }
                // }

                for (let i = 0; i < lonpaVoteSnapshot.size; i++) {
                  // 各論拠ごとのvote内容の中間データ1
                  voteids.push(lonpaVoteSnapshot.docs[i].id);
                  // image一覧作成のために全データ入れる格納場所
                  voteidlist.push(lonpaVoteSnapshot.docs[i].id);
                }
                //各論拠ごとのvote内容の中間データ2
                voteidlists[doc.id] = voteids;
                //}
                //list型に変換する
                voteidlist = [...new Set(voteidlist)];

                //投票されたユーザ情報を全て取りに行き、プロフィール画像が登録されているものだけリストアップする
                const avatarquerySnapshot = await getUserAvatarList(voteidlist);
                avatarquerySnapshot.forEach((doc) => {
                  //ユーザプロフィール画像が登録されている場合
                  if (doc.data().imageurl) {
                    //投票情報に、投票者とプロフィール画像を登録
                    votelist[doc.id] = {
                      imageurl: doc.data().imageurl,
                      user: doc.data().name,
                    };
                  }
                });

                ////////// 3　表示情報を作成 ////////////
                ////////// 3-2　各論拠の内容をinfodataとし，賛成論拠のリストinfoagreeと，反対論拠のリストinfodisagreeを作る////////////
                ////////// 3-2-1　1論拠の整形済み情報 = infodataを作成する////////////
                ////////// 3-2-1-2　1論拠の作者情報「createdBy」を仮で整理，元の情報をもとに作る予定////////////
                let createdBy;
                createdBy = {
                  createdAt: parseAsMoment(doc.data().createdAt.toDate())
                    .format("YYYY/MM/DD hh:mm")
                    .toString(),
                };

                ////////// 3-2-1-3　1論拠の投票数，投票者情報(画像・ユーザID)整理////////////
                //1論拠分の，投票と投票者情報を整形したものをvotedatasとしてつくる
                //変更のかかった論拠の，各投票について確認していく
                let votedatas = [];
                if (typeof voteidlists[doc.id] !== "undefined") {
                  //1つ以上投票がある時
                  for (let j = 0; j < voteidlists[doc.id].length; j++) {
                    //各論拠の各投票について、
                    if (
                      typeof votelist[voteidlists[doc.id][j]] !== "undefined"
                    ) {
                      //ユーザ情報が存在する場合
                      //votedatasにユーザ情報を追加する
                      votedatas.push({
                        imageurl: votelist[voteidlists[doc.id][j]].imageurl,
                        user: votelist[voteidlists[doc.id][j]].user,
                      });
                    } else {
                      //ユーザ情報が存在しない場合
                      //votedatasに、ビジター情報を追加する
                      votedatas.push({
                        imageurl: picursl[0],
                        user: "noname",
                      });
                    }
                  }
                }

                ////////// 3-2-1-4　閲覧者による投票状況の整理////////////
                let ischoiceditem;

                if (choice.ischoiced) {
                  //もし、表示者により投票されていれば
                  if (doc.id === choice.choiceditem.id) {
                    //その１論拠へ投票されていれば
                    //投票がされているフラグを立てて、投票数を格納しておく？
                    ischoiceditem = true;
                    choice.choiceditem.votenum = doc.data().votenum;
                  } else {
                    //別の論拠への投票であれば
                    //投票がされていないフラグを立てる
                    ischoiceditem = false;
                    //choice.choiceditem.votenum = info.choiceditem.votenum;
                  }
                } else {
                  //そもそも投票されていなければ、
                  //投票がされていないフラグを立てる
                  ischoiceditem = false;
                }

                ////////// 3-2-1-5　上記で整理した情報をまとめる////////////
                let infodata = {
                  id: doc.id,
                  data: doc.data(),
                  agree: itemvote[doc.id].agree,
                  disagree: itemvote[doc.id].disagree,
                  votedatas: votedatas,
                  createdBy: createdBy,
                  ischoiceditem: ischoiceditem,
                };

                ////////// 3-2-2　1論拠が 賛成論拠か、反対論拠か仕分ける////////////
                // let nextinfoagreesum = infoagreesum;
                // let nextinfodisagreesum = infodisagreesum;
                if (doc.data().agree) {
                  //賛成論拠は，infoagreeにまとめる
                  let infoindex = null;
                  infoagree.map((item, index) => {
                    if (item.id === doc.id) {
                      infoindex = index;
                    }
                  });
                  if (infoindex != null) {
                    setInfosumchange({
                      isagree: true,
                      changenum:
                        infodata.data.votenum -
                        infoagree[infoindex].data.votenum,
                    });
                    infoagree[infoindex] = infodata;
                  }
                  ////////// 3-3　賛成・反対論拠をすべてまとめた，infoを作成する////////////
                  // 一番下に追加するので，ソートなどせず普通に作る
                } else {
                  let infoindex = null;
                  infodisagree.map((item, index) => {
                    if (item.id === doc.id) {
                      infoindex = index;
                    }
                  });
                  if (infoindex != null) {
                    setInfosumchange({
                      isagree: false,
                      changenum:
                        infodata.data.votenum -
                        infodisagree[infoindex].data.votenum,
                    });
                    infodisagree[infoindex] = infodata;
                  }
                  ////////// 3-3　賛成・反対論拠をすべてまとめた，infoを作成する////////////
                  // 一番下に追加するので，ソートなどせず普通に作る
                }
                //console.log(choice.choiceditem);
                nextinfo = {
                  ...info,
                  agree: infoagree,
                  disagree: infodisagree,
                  login: choice.islogin,
                  ischoiced: choice.ischoiced,
                  choiceditem: choice.choiceditem,
                  isDelete: infoIsDelete,
                };

                setInfo(nextinfo);
              }

              ////////// 4　各論拠の投票数と，Lonpaの投票結果が合致していない場合，DBを更新する////////////
              //ここは不要

              ////////// 5 　画面セットアップ完了////////////
            });
          } else {
            //console.log("更新がきたが，中身が空");
          }
        }
      });
    };
    f();
  }, [useEffectTriggerInfo]);

  useEffect(() => {
    console.log("uE_Bunku");
    const f = async () => {
      if (infosumchange.isagree) {
        setInfoagreesum(infoagreesum + infosumchange.changenum);
      } else {
        setInfodisagreesum(infodisagreesum + infosumchange.changenum);
      }
    };
    f();
  }, [infosumchange]);

  const nGrum = (name, n) => {
    const searchGrums = {};
    for (let i = 0; i < name.length; i++) {
      const results = [name.substr(i, n)];
      results.map((result) => {
        searchGrums[result] = true;
      });
    }
    return searchGrums;
  };

  //★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
  //＋ボタン押下時に動作
  const addLon = (lon) => {
    //setLon(lon);
    if (lon) {
      //setMaketext("賛成要因を作成");
      setLonpacreateopen({ ...lonpacreateopen, agree: true });
    } else {
      //setMaketext("反対要因を作成");
      setLonpacreateopen({ ...lonpacreateopen, disagree: true });
    }
    //handleOpen();
    //console.log(lonpacreateopen);
  };

  //★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
  //作成するボタン押下時に動作
  async function addLonChild() {
    console.log("Bunkuではこの機能は提供されていません");
  }

  //投票ボタン押下時に動作する　■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  async function pushLon(id, data, increment) {
    setisbuttonlock(true);
    // const excutetime = new Date();
    // const excutetimestring = parseAsMoment(excutetime)
    //   .format("YYYYMMDDHHmm")
    //   .toString();

    // if (!isbuttonlock) {
    //   setisbuttonlock(true);
    //   onAuthStateChanged(getAuth(userApp), async (user) => {
    //     if (
    //       await transactionPushLon(
    //         id,
    //         data,
    //         increment,
    //         user,
    //         lonpaid,
    //         serverTimestamp(),
    //         excutetimestring,
    //         info,
    //         infoagreesum,
    //         infodisagreesum,
    //         pinfo,
    //         excutetime
    //       )
    //     ) {
    //       ///////console.log("トランザクションが成功終了")//////

    //       //投票状態を更新する
    //       if (increment) {
    //         const choiceid = id;
    //         setSelfVoteInfo(choiceid);
    //         if (!user) {
    //           //ログインしていない場合はクッキーを利用
    //           //クッキーの期限を2日に設定
    //           let cookieDate = new Date();
    //           cookieDate.setDate(cookieDate.getDate() + 3650); // 3650日後に変更する.
    //           //クッキーに投票内容を書き込む
    //           await setCookie(lonpaid, choiceid, {
    //             expires: cookieDate,
    //             path: "/",
    //           });
    //         }
    //       } else {
    //         //const choiceid = "";
    //         setSelfVoteInfo(undefined);
    //         if (!user) {
    //           //ログインしていない場合はクッキーを利用
    //           //クッキーの投票内容を削除する
    //           await removeCookie(lonpaid);
    //         }
    //       }
    //     } else {
    //       console.log("トランザクションが失敗終了");
    //     }
    //     await sleep(500);

    //     setisbuttonlock(false);
    //   });
    // }
  }

  async function GotoNextLonpa(id) {
    const querysnapshot = await getNextLonpa(id);
    if (querysnapshot.docs.pop()) {
      ChoiceData(querysnapshot.docs.pop().id);
    } else {
      alert("移動に失敗しました");
    }
  }
  async function GotoPrevLonpa(id) {
    const querysnapshot = await getPrevLonpa(id);
    if (querysnapshot.docs.pop()) {
      ChoiceData(querysnapshot.docs.pop().id);
    } else {
      alert("移動に失敗しました");
    }
  }

  return (
    <div style={{ backgroundColor: props.bgColor, height: "100vh" }}>
      {isBunkuTitleShow ? (
        <>
          {" "}
          <div style={{ height: "40vh" }}></div>
          <Typography
            onClick={() => setIsBunkuTitleShow(false)}
            width="100%"
            margin="0"
            fontSize={claimSize}
            fontWeight={"bold"}
            color="textPrimary"
            gutterBottom
            align="center"
            style={{
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
            }}
          >
            {/* ■■■■■■■■■■■■■■■■　Lonpa意見（claim）　■■■■■■■■■■■■■■■■ */}
            {displayClaim + "?"}
          </Typography>
          <div style={{ height: "5vh" }}></div>
          <div align="center">
            <Canvas
              text={"https://lonpa.net/Lonpa/" + lonpaid}
              options={{
                type: "image/jpeg",
                quality: 0.3,
                level: "M",
                margin: 3,
                scale: 4,
                width: 300,
                color: {
                  dark: "#000000",
                  light: "#FFFFFF",
                },
              }}
            />
            <br />
            <Typography fontSize={25}>
              {"https://lonpa.net/Lonpa/" + lonpaid}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div style={{ height: "80px" }}></div>
          <div
            style={{ height: "50px" }}
            onClick={() => setIsBunkuTitleShow(true)}
          >
            <Typography
              width="100%"
              fontSize={claimSize}
              fontWeight={"bold"}
              margin="0"
              color="textPrimary"
              gutterBottom
              align="center"
              style={{
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
              }}
            >
              {/* ■■■■■■■■■■■■■■■■　Lonpa意見（claim）　■■■■■■■■■■■■■■■■ */}
              {displayClaim}
            </Typography>
          </div>

          <div style={{ height: "30px" }}></div>

          <div style={{ backgroundColor: "#111111" }}>
            <LonpaBigWidth
              lonpaBigWidthUIHeight={"calc(100vh - 270px)"}
              addLon={(text) => addLon(text)}
              pushLon={(id, data, increment) => pushLon(id, data, increment)}
              info={info}
              infoagreesum={infoagreesum}
              infodisagreesum={infodisagreesum}
              ChoiceData={ChoiceData}
              isbuttonlock={isbuttonlock}
              increseUpdate={increseUpdate}
              styletext={props.styletext}
              animationagree={animationagree}
              animationdisagree={animationdisagree}
              animationspeed={props.animationspeed}
              blocktype={props.styletext.isbig === 0}
              userEmailVerified={userEmailVerified}
              lonpacreateopen={lonpacreateopen}
              addLonChild={addLonChild}
              claimChildSize={claimChildSize}
              createAILonData={createAILonData}
            />

            <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />

            <LonpaEditModal
              open={lonpaEditmodalopen}
              setOpen={setLonpaEditmodalopen}
              editLonpa={editLonpa}
              editLonpaIsDelete={editLonpaIsDelete}
              setEditLonpaIsDelete={setEditLonpaIsDelete}
              setUploaderror={setUploaderror}
              createrFlag={createrFlag}
            />
          </div>
        </>
      )}
    </div>
  );
}

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  IconButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";

type Props = {
  bgColor: any;
  styletext: any;
  isBlue: boolean;
};

const Reason: React.VFC<Props> = ({ bgColor, styletext, isBlue }: Props) => {
  const BlueButton2 = {
    fontSize: "20px",
    color: bgColor.text01.fontColor,
    width: "100%",
    height: "50px",
    fontWeight: "bold",
    // border: bgColor.line01,
    backgroundColor: bgColor.back03,
  };

  return (
    <>
      <tr>
        <div
          style={{
            height: "20px",
          }}
        ></div>
      </tr>
      <tr>
        <div
          style={{
            width: styletext.windowPhoneWidth * 0.55,
            padding: "8px",
            backgroundColor: isBlue ? bgColor.back03 : bgColor.back02,
            color: "#000000",
            borderRadius: "20px",
            textAlign: "center",
          }}
        >
          {isBlue ? "賛成か" : "反対か"}
          、うちと一緒やな
        </div>
      </tr>
      <tr>
        {" "}
        <div
          style={{
            width: styletext.windowPhoneWidth * 0.55,
            padding: "8px",
            backgroundColor: isBlue ? bgColor.back03 : bgColor.back02,
            color: "#000000",
            borderRadius: "20px",
            textAlign: "center",
          }}
        >
          理由も教えてくれる？
        </div>
      </tr>
    </>
  );
};

export default Reason;

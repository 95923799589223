import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Checkbox,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

// import PieChart from "../PieChart";

type Props = {
  bgColor: any;
  styletext: any;
};

const AdItem2: React.VFC<Props> = ({ bgColor, styletext }: Props) => {
  //   const classes = useStyles();
  const MyButton = styled(Button)({
    border: 0,
    borderRadius: 3,
    color: "white",
    height: 68,
    width: "100%",
  });
  return (
    <div>
      <MyButton
        style={{
          color: bgColor.text01.fontColor,
          border: bgColor.line01,
          backgroundImage: bgColor.adpic02,
        }}
        onClick={() => window.open("https://lamact.com/offlineEventsTop")}
      >
        {/* <a
          style={{
            fontWeight: "bold",
            backgroundColor: bgColor.back10,
          }}
        >
          宣伝
        </a> */}
        <div>
          <ListItem
            alignItems="flex-start"
            style={{ width: "100%", padding: "0px 0px 0px 0px" }}
          >
            <ListItemText
              primary={
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: "bold",
                    width: "100%",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    backgroundColor: bgColor.back12,
                  }}
                >
                  {"　　React勉強会　毎週開催中！　　"}
                </div>
              }
            />
          </ListItem>
        </div>
      </MyButton>
    </div>
  );
};

export default AdItem2;

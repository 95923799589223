import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useStyles } from "../../../Styles";
import { useEffect, useState } from "react";
import { Button, Grid, TextField, IconButton } from "@mui/material";
import LonpaPictures from "./Picture/LonpaPictures";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReactLoading from "react-loading";

import { getFunctions, httpsCallable } from "firebase/functions";

import axios from "axios";

export default function LonpaAIModal(props) {
  const classes = useStyles();
  const [oneTextShow, setOneTextShow] = useState("");
  const [twoTextShow, setTwoTextShow] = useState("");
  const [threeTextShow, setThreeTextShow] = useState("モーダルを開きました");
  const [isLoding, setIsLoding] = useState(true);
  const [startTime, setStartTime] = useState("start");

  useEffect(() => {
    console.log("uE_LonpaAIModal");
    if (props.open) {
      if (!props.isCreatedAI) {
        props.setIsCreatedAI(true);
        setOneTextShow("");
        setTwoTextShow("");
        setThreeTextShow("論拠生成中");
        setStartTime("開始：" + Date(Date.now()).toLocaleString());
        createMessage();
      }
    }
  }, [props.open]);

  const handleCloseinside = () => {
    if (!isLoding) {
      props.setOpen(false);
    }
  };

  const createLon = async (message) => {
    try {
      const trimmessage = message
        .split("\n")
        .join("")
        .split("。")
        .join("")
        .split("(")
        .join("")
        .split(")")
        .join("")
        .trim(); // 整形

      console.log(trimmessage);

      let agreeindex;
      let disagreeindex;

      if (trimmessage.indexOf("【反対意見】")) {
        agreeindex = trimmessage.indexOf("【賛成意見】"); // 何文字目か検索
        disagreeindex = trimmessage.indexOf("【反対意見】"); // 何文字目か検索
      } else if (trimmessage.indexOf("反対意見")) {
        agreeindex = trimmessage.indexOf("賛成意見"); // 何文字目か検索
        disagreeindex = trimmessage.indexOf("反対意見"); // 何文字目か検索
      } else {
        throw new Error("意見が抽出できませんでした");
      }

      const agreelons = trimmessage.slice(agreeindex, disagreeindex).trim(); // 賛成部分を分割
      const disagreelons = trimmessage.slice(disagreeindex).trim(); // 反対部分を分割

      let agreeresult = agreelons.split("- "); // 「,」 カンマ区切りで分割して配列に格納
      agreeresult.splice(0, 1);
      let agreearray = [];
      agreeresult.map((item, index) => {
        agreearray.push({
          claim: item.trim(),
          isagree: true,
          num: index,
          iscreated: false,
        });
      });

      let disagreeresult = disagreelons.split("- "); // 「,」 カンマ区切りで分割して配列に格納
      disagreeresult.splice(0, 1);

      let disagreearray = [];
      disagreeresult.map((item, index) => {
        disagreearray.push({
          claim: item.trim(),
          isagree: false,
          num: index,
          iscreated: false,
        });
      });

      props.setCreateAILonData({ agree: agreearray, disagree: disagreearray });
      props.setAutoCreateCraimCreateFalse();
      setIsLoding(false);
      props.setOpen(false);
    } catch (error) {
      alert(
        error +
          "\n文字列が想定した形式でないようです\n一回の実行に数百円かかるので、二度と押さないでください"
      );
      props.setAutoCreateCraimCreateFalse();
      setIsLoding(false);
      props.setOpen(false);
      //console.log(error)
      //console.log(message);
    }
  };

  const createMessage = async (message) => {
    const chatGPTMessage =
      "「" +
      props.editLonpa.claim +
      "」" +
      "に対して\n 【賛成意見】と【反対意見】を30文字以内\n箇条書きでそれぞれ3個づつ\n" +
      "ただし、フォーマットは下記としてください\n" +
      "【賛成意見】- (賛成意見１)- (賛成意見２)- (賛成意見３)  \n【反対意見】- (反対意見１)- (反対意見２)- (反対意見３)";
    setOneTextShow(chatGPTMessage);
    //setThreeTextShow("chatGPTの機能をテスト中");

    const functions = getFunctions();
    const messageCreate = httpsCallable(functions, "messageCreate");
    messageCreate({ message: chatGPTMessage }).then((result) => {
      //console.log(result);
      // 回答の取得
      setTwoTextShow(result.data.aimessage);
      //論拠作成
      //setThreeTextShow("論拠を作成しています");
      createLon(result.data.aimessage);
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <br />
          <h2 id="transition-modal-title" align="center">
            {threeTextShow}
          </h2>
          <div align="center" style={{ border: 0, height: "128px" }}>
            <div style={{ marginTop: "64px" }}></div>

            <ReactLoading type="balls" color="black" />
            <div style={{ color: "#AAAAAA" }}>
              {startTime}
              <br />
              数十分かかる可能性があります
              <br />
              たまに失敗します。
            </div>
          </div>
          <br />
          <br />
          <br />
          <TextField
            fullWidth
            disabled
            label="テキスト(改行もできます)"
            multiline
            maxRows={15}
            minRows={5}
            value={oneTextShow}
            onChange={(e) => setOneTextShow(e.target.value)}
          />
        </div>
      </Fade>
    </Modal>
  );
}

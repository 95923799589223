import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  IconButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getAuth, updateProfile } from "firebase/auth";
import React, { useState } from "react";

import { updateUserPublic } from "../common/Helper/FirebaseHelper";
import { userApp } from "../common/firebase";

import ColorPicker from "./ColorPicker/ColorPicker";

type Props = {
  bgColor: any;
  styletext: any;
  changeBgColor: any;
  darktheme: any;
  lighttheme: any;
  testtheme: any;
};

const ColorPattern: React.VFC<Props> = ({
  bgColor,
  styletext,
  changeBgColor,
  darktheme,
  lighttheme,
  testtheme,
}: Props) => {
  const cellStyle = {
    width: "10%",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  const colorSheatStyle = {
    width: "50%",
    height: "20px",
  };

  async function setUserPublicColor(colorNum: number) {
    const user = getAuth(userApp).currentUser;
    if (user) {
      await updateUserPublic(user.uid, {
        colorPattern: colorNum,
      });
    }
  }

  return (
    <div style={{ backgroundColor: bgColor.back01 }}>
      <div style={{ height: "6vh" }}></div>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: bgColor.back01,
              color: bgColor.text01.fontColor,
              textAlign: "center",
            }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                <tr style={{ borderTop: "solid 3px #CCC" }}>
                  <td style={cellStyle}>
                    <Button
                      color="primary"
                      onClick={() => {
                        changeBgColor(1);
                        setUserPublicColor(1);
                      }}
                      style={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      ダーク
                    </Button>
                  </td>
                  <td style={cellStyle}>
                    <Button
                      color="primary"
                      onClick={() => {
                        changeBgColor(2);
                        setUserPublicColor(2);
                      }}
                      style={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      ライト
                    </Button>
                  </td>
                  <td style={cellStyle}>
                    <Button
                      color="primary"
                      onClick={() => {
                        changeBgColor(3);
                        setUserPublicColor(3);
                      }}
                      style={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      テスト
                    </Button>
                  </td>
                </tr>

                <tr style={{ borderTop: "solid 3px #CCC" }}>
                  <td style={cellStyle}>
                    {/* ダーク */}
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: darktheme.back01,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: darktheme.text01.fontColor,
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: darktheme.back08,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: darktheme.text02.fontColor,
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: darktheme.back09,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: darktheme.text03.fontColor,
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: darktheme.back05,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: darktheme.back06,
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style={cellStyle}>
                    {/* ライト */}
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: lighttheme.back01,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: lighttheme.text01.fontColor,
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: lighttheme.back08,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: lighttheme.text02.fontColor,
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: lighttheme.back09,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: lighttheme.text03.fontColor,
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: lighttheme.back05,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: lighttheme.back06,
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style={cellStyle}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: testtheme.back01,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: testtheme.text01.fontColor,
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: testtheme.back08,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: testtheme.text02.fontColor,
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: testtheme.back09,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: testtheme.text03.fontColor,
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: testtheme.back05,
                            }}
                          ></td>
                          <td
                            style={{
                              ...colorSheatStyle,
                              backgroundColor: testtheme.back06,
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              backgroundColor: bgColor.back01,
              color: bgColor.text01.fontColor,
              textAlign: "center",
            }}
          >
            <Typography
              color={bgColor.text01.fontColor}
              gutterBottom
              variant="h6"
              fontWeight="bold"
            >
              <Checkbox
                //{...label}
                icon={<CircleIcon />}
                checkedIcon={<CheckCircleIcon />}
                disabled
                style={{
                  color: bgColor.back03,
                }}
              />
              <Checkbox
                //{...label}
                icon={<CircleIcon />}
                checkedIcon={<CheckCircleIcon />}
                disabled
                style={{
                  color: bgColor.back02,
                }}
              />
              サンプル
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              backgroundColor: bgColor.back01,
              color: bgColor.text01.fontColor,
              textAlign: "center",
              paddingLeft: 0,
            }}
          >
            <div style={{ height: "20vh" }}></div>
            <ColorPicker />
          </Grid>
        </Grid>
      </Container>
      <div style={{ height: "6vh" }}></div>
    </div>
  );
};

export default ColorPattern;

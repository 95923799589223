import React, { useState } from "react";

import LonpaItem from "../Search/LonpaItem";

// import { useSpring, animated } from "@react-spring/web";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import BoyIcon from "@mui/icons-material/Boy";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SettingsIcon from "@mui/icons-material/Settings";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import SettingModal from "./Modal/SettingModal";
import VoteListModal from "./Modal/VoteListModal";
import { useStyles } from "../../Styles";

import { useParams } from "react-router-dom";

import { getAuth } from "firebase/auth";

import { useNavigate } from "react-router-dom";

export default function LonpaBlockbig(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  let { lonpaid } = useParams();
  const [settingmodalopen, setSettingmodalopen] = useState(false);
  const [votelistmodalopen, setVotelistmodalopen] = useState(false);
  // const [user, setUser] = useState(
  //   getAuth(userApp).currentUser ?? { emailVerified: false }
  // );

  //effectの種類を設定
  const effect = {
    bigBalanced: classes.bigglasseffect,
    smallBalanced: classes.smallglasseffect,
    middleBalanced: classes.middleglasseffect,
    bigDisagreeLevel1:
      classes.bigglasseffect + " " + classes.bigDisagreeLevel1effect,

    smallDisagreeLevel1:
      classes.smallglasseffect + " " + classes.smallDisagreeLevel1effect,

    middleDisagreeLevel1:
      classes.middleglasseffect + " " + classes.middleDisagreeLevel1effect,

    bigDisagreeLevel2:
      classes.bigglasseffect + " " + classes.bigDisagreeLevel2effect,

    smallDisagreeLevel2:
      classes.smallglasseffect + " " + classes.smallDisagreeLevel2effect,

    middleDisagreeLevel2:
      classes.middleglasseffect + " " + classes.middleDisagreeLevel2effect,

    bigAgreeLevel1: classes.bigglasseffect + " " + classes.bigAgreeLevel1effect,

    smallAgreeLevel1:
      classes.smallglasseffect + " " + classes.smallAgreeLevel1effect,

    middleAgreeLevel1:
      classes.middleglasseffect + " " + classes.middleAgreeLevel1effect,
  };
  //どの条件で、どのeffectをつかうのかを定義
  let pattern;
  if (props.item.data.oppositionStatus === "disagreeLevel2") {
    if (props.styletext.glassstyle2 === "big") {
      pattern = effect.bigDisagreeLevel2;
    } else if (props.styletext.glassstyle2 === "middle") {
      pattern = effect.middleDisagreeLevel2;
    } else if (props.styletext.glassstyle2 === "small") {
      pattern = effect.smallDisagreeLevel2;
    }
  } else if (props.item.data.oppositionStatus === "disagreeLevel1") {
    if (props.styletext.glassstyle2 === "big") {
      pattern = effect.bigDisagreeLevel1;
    } else if (props.styletext.glassstyle2 === "middle") {
      pattern = effect.middleDisagreeLevel1;
    } else if (props.styletext.glassstyle2 === "small") {
      pattern = effect.smallDisagreeLevel1;
    }
  } else if (props.item.data.oppositionStatus === "agreeLevel1") {
    if (props.styletext.glassstyle2 === "big") {
      pattern = effect.bigAgreeLevel1;
    } else if (props.styletext.glassstyle2 === "middle") {
      pattern = effect.middleAgreeLevel1;
    } else if (props.styletext.glassstyle2 === "small") {
      pattern = effect.smallAgreeLevel1;
    }
  } else {
    if (props.styletext.glassstyle2 === "big") {
      pattern = effect.bigBalanced;
    } else if (props.styletext.glassstyle2 === "middle") {
      pattern = effect.middleBalanced;
    } else if (props.styletext.glassstyle2 === "small") {
      pattern = effect.smallBalanced;
    }
  }
  //console.log(props.styletext.glassstyle2);

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow key="aa">
              <TableCell
                align="right"
                style={{ width: "100%", padding: "0", lineHeight: "0" }}
              >
                {/* 賛成と反対の票数で、どのeffectpatternが決まるので、それを設定する */}
                <div className={pattern}>
                  <div
                    align="left"
                    style={{ width: "100%", minHeight: "120px" }}
                    onClick={() => {
                      props.ChoiceData(props.item.id);
                    }}
                  >
                    {props.styletext.isbig === 0 ? (
                      <LonpaItem
                        key={props.item.id}
                        index={props.item.id}
                        id={props.item.id}
                        title={props.item.data.claim}
                        agree={props.item.agree}
                        disagree={props.item.disagree}
                        ChoiceData={props.ChoiceData}
                        parentclaim={props.parentclaim}
                        styletext={props.styletext}
                        itemwidth={"43vw"}
                        claimChildSize={props.claimChildSize}
                        bgColor={props.bgColor}
                      />
                    ) : (
                      <LonpaItem
                        key={props.item.id}
                        index={props.item.id}
                        id={props.item.id}
                        title={props.item.data.claim}
                        agree={props.item.agree}
                        disagree={props.item.disagree}
                        ChoiceData={props.ChoiceData}
                        parentclaim={props.parentclaim}
                        styletext={props.styletext}
                        itemwidth={"80vw"}
                        claimChildSize={props.claimChildSize}
                        bgColor={props.bgColor}
                      />
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: "0", lineHeight: "0" }}
              >
                <table>
                  <tbody
                    style={{
                      position: "relative",
                      left: "0",
                      top: "10px",
                      padding: "0",
                    }}
                  >
                    <tr align="center">
                      <td>
                        {props.isbuttonlock ? (
                          <IconButton
                            aria-label="vote"
                            size="middle"
                            style={{
                              display: "inline-block",
                              minWidth: 0,
                              color: props.isagree
                                ? props.bgColor.back14
                                : props.bgColor.back13,
                            }}
                            disabled
                          >
                            <FavoriteBorderIcon fontSize="inherit" />
                          </IconButton>
                        ) : (
                          <>
                            {props.ischoiced ? (
                              <>
                                {props.item.ischoiceditem ? (
                                  <>
                                    {!props.isagree ? (
                                      <>
                                        <IconButton
                                          aria-label="vote"
                                          size="middle"
                                          onClick={() =>
                                            props.pushLon(
                                              props.item.id,
                                              props.item.data,
                                              false
                                            )
                                          }
                                          sx={{ color: props.bgColor.back13 }}
                                          style={{
                                            display: "inline-block",
                                            minWidth: 0,
                                          }}
                                        >
                                          <FavoriteIcon fontSize="inherit" />
                                        </IconButton>
                                      </>
                                    ) : (
                                      <>
                                        <IconButton
                                          aria-label="vote"
                                          size="middle"
                                          onClick={() =>
                                            props.pushLon(
                                              props.item.id,
                                              props.item.data,
                                              false
                                            )
                                          }
                                          sx={{ color: props.bgColor.back14 }}
                                          style={{
                                            display: "inline-block",
                                            minWidth: 0,
                                          }}
                                        >
                                          <FavoriteIcon fontSize="inherit" />
                                        </IconButton>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <IconButton
                                      aria-label="vote"
                                      size="middle"
                                      onClick={() =>
                                        props.pushLon(
                                          props.item.id,
                                          props.item.data,
                                          true
                                        )
                                      }
                                      style={{
                                        display: "inline-block",
                                        minWidth: 0,
                                        color: props.isagree
                                          ? props.bgColor.back14
                                          : props.bgColor.back13,
                                      }}
                                    >
                                      <FavoriteBorderIcon fontSize="inherit" />
                                    </IconButton>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {/* {props.userEmailVerified ? ( */}
                                <>
                                  <IconButton
                                    aria-label="vote"
                                    size="middle"
                                    onClick={() =>
                                      props.pushLon(
                                        props.item.id,
                                        props.item.data,
                                        true
                                      )
                                    }
                                    style={{
                                      display: "inline-block",
                                      minWidth: 0,
                                      color: props.isagree
                                        ? props.bgColor.back14
                                        : props.bgColor.back13,
                                    }}
                                  >
                                    <FavoriteBorderIcon fontSize="inherit" />
                                  </IconButton>
                                </>
                                {/* ) : (
                                  <>
                                    <IconButton
                                      aria-label="vote"
                                      size="middle"
                                      onClick={() => {
                                        alert(
                                          "投票するには，アカウント作成 ＆ メール検証が必要です"
                                        );
                                        navigate("/Signin");
                                      }}
                                      style={{
                                        display: "inline-block",
                                        minWidth: 0,
                                      }}
                                    >
                                      <FavoriteBorderIcon fontSize="inherit" />
                                    </IconButton>
                                  </>
                                )} */}
                              </>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                    <tr align="center">
                      <td>
                        <IconButton
                          aria-label="delete"
                          size="middle"
                          align="center"
                          style={{
                            minWidth: 0,
                            color: props.isagree
                              ? props.bgColor.back14
                              : props.bgColor.back13,
                          }}
                          onClick={() => {
                            setVotelistmodalopen(true);
                          }}
                        >
                          {props.item.data.votenum}
                        </IconButton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <SettingModal
        id={lonpaid}
        open={settingmodalopen}
        setOpen={setSettingmodalopen}
        increseUpdate={() => props.increseUpdate()}
        item={props.item}
      />
      <VoteListModal
        id={lonpaid}
        open={votelistmodalopen}
        setOpen={setVotelistmodalopen}
        increseUpdate={() => props.increseUpdate()}
        item={props.item}
      />
    </>
  );
}

import React from "react";

import Lonapbasis from "./Lonapbasis";

import { useStyles } from "../../Styles";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { IconButton } from "@mui/material";
import Add from "@mui/icons-material/Add";

export default function LonpaSmallWidth(props) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(props.openside);

  const handleChange = (index) => {
    setTabIndex(index);
  };

  return (
    <React.Fragment>
      {typeof props.infoagreesum === "undefined" ? (
        <>
          <Tabs
            value={tabIndex}
            onChange={(e, value) => handleChange(value)}
            variant="fullWidth"
            indicatorColor="primary"
          >
            <Tab
              // className={tabIndex === 0 && classes[`active${0}`]}
              style={{ color: "blue", fontSize: 16 }}
              label={"賛成　0人"}
            />
            <Tab
              // className={tabIndex === 1 && classes[`active${1}`]}
              style={{ color: "red", fontSize: 16 }}
              label={"反対　0人"}
            />
          </Tabs>
        </>
      ) : (
        <>
          <Tabs
            value={tabIndex}
            onChange={(e, value) => handleChange(value)}
            variant="fullWidth"
            indicatorColor="primary"
          >
            <Tab
              //className={tabIndex === 0 && classes[`active${0}`]}
              style={{ color: "blue", fontSize: 16 }}
              label={"賛成　" + props.infoagreesum + "人"}
            />
            <Tab
              //className={tabIndex === 1 && classes[`active${1}`]}
              style={{ color: "red", fontSize: 16 }}
              label={"反対　" + props.infodisagreesum + "人"}
            />
          </Tabs>
        </>
      )}

      <SwipeableViews
        enableMouseEvents
        resistance
        index={tabIndex}
        onChangeIndex={(index) => handleChange(index)}
      >
        <div
          key={0}
          className={classes[`slide${0}`]}
          style={{ height: "47vh", backgroundColor: props.bgColor.back10 }}
        >
          <div className={classes.space4}></div>
          <div
            align="center"
            style={{ padding: "4px", height: "85%", overflow: "auto" }}
          >
            <Lonapbasis
              //color="primary"
              //text={"agree"}
              isagree={true}
              addLon={() => props.addLon(true)}
              pushLon={(id, data, increment) =>
                props.pushLon(id, data, increment)
              }
              info={props.info.agree}
              login={props.info.login}
              ischoiced={props.info.ischoiced}
              ChoiceData={props.ChoiceData}
              isbuttonlock={props.isbuttonlock}
              parentclaim={props.info.claim}
              increseUpdate={() => props.increseUpdate()}
              styletext={{ ...props.styletext, isbig: 1 }}
              blocktype={props.blocktype}
              userEmailVerified={props.userEmailVerified}
              lonpacreateopen={props.lonpacreateopen.agree}
              addLonChild={props.addLonChild}
              claimChildSize={props.claimChildSize}
              createAILonData={props.createAILonData.agree}
              bgColor={props.bgColor}
            />
          </div>
          <div align="center" style={{ height: "15%" }}>
            <IconButton
              size="medium"
              sx={{ color: props.bgColor.back13 }}
              aria-label="add an alarm"
              onClick={() => props.addLon(true)}
              style={{ width: "100%" }}
            >
              <Add />
            </IconButton>
          </div>
        </div>
        <div
          key={1}
          className={classes[`slide${1}`]}
          style={{ height: "47vh", backgroundColor: props.bgColor.back09 }}
        >
          <div className={classes.space4}></div>
          <div
            align="center"
            style={{ padding: "4px", height: "85%", overflow: "auto" }}
          >
            <Lonapbasis
              //color="secondary"
              //text={"disagree"}
              isagree={false}
              addLon={() => props.addLon(false)}
              pushLon={(id, data, increment) =>
                props.pushLon(id, data, increment)
              }
              info={props.info.disagree}
              login={props.info.login}
              ischoiced={props.info.ischoiced}
              ChoiceData={props.ChoiceData}
              isbuttonlock={props.isbuttonlock}
              parentclaim={props.info.claim}
              increseUpdate={() => props.increseUpdate()}
              styletext={{ ...props.styletext, isbig: 1 }}
              blocktype={props.blocktype}
              userEmailVerified={props.userEmailVerified}
              lonpacreateopen={props.lonpacreateopen.disagree}
              addLonChild={props.addLonChild}
              claimChildSize={props.claimChildSize}
              createAILonData={props.createAILonData.disagree}
              bgColor={props.bgColor}
            />
          </div>
          <div align="center" style={{ padding: "4px", height: "15%" }}>
            <IconButton
              size="medium"
              sx={{ color: props.bgColor.back14 }}
              aria-label="add an alarm"
              onClick={() => props.addLon(false)}
              style={{ width: "100%" }}
            >
              <Add />
            </IconButton>
          </div>
        </div>
      </SwipeableViews>
    </React.Fragment>
  );
}

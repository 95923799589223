import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function TagArray(props) {
  const navigate = useNavigate();
  const handleClick = () => {
    props.setOpen(true);
  };

  return (
    <div align="center">
      {props.tagListShow.map((tag, index) => (
        <Chip
          label={"#" + tag.display}
          key={"#" + tag.display}
          variant="outlined"
          style={{
            color: props.bgColor.text01.fontColor,
          }}
          onClick={() => {
            navigate("/NewSearch/tag=" + tag.data);
          }}
          size="small"
        />
      ))}
      <Chip
        label="タグ編集"
        style={{
          color: props.bgColor.text01.fontColor,
        }}
        onClick={handleClick}
        size="small"
      />
    </div>
  );
}

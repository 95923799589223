import React from "react";

type Props = {
  bgColor: any;
  styletext: any;
};

const LodingApp: React.VFC<Props> = ({ bgColor, styletext }: Props) => {
  const buttonHeight = "70px";
  const textMarginTop = "30px";

  return (
    <>
      <div
        style={{
          height: buttonHeight, // calc() で 100% からボタンの高さを引く
          width: styletext.windowWidth,
          position: "fixed",
          backgroundColor: "#bbbbbb",
          zIndex: 200,
        }}
      ></div>
      <div
        style={{
          height: "70vh", // calc() で 100% からボタンの高さを引く
          width: styletext.windowWidth,
          marginTop: buttonHeight,
          position: "fixed",
          backgroundColor: "#ffffff",
          zIndex: 150,
        }}
      ></div>
      <div
        style={{
          height: "10vh", // calc() で 100% からボタンの高さを引く
          width: styletext.windowWidth,
          marginTop: "70vh",
          position: "fixed",
          backgroundColor: "#919191",
          zIndex: 200,
        }}
      ></div>
    </>
  );
};
export default LodingApp;

import { Button, Grid, TextField } from "@mui/material";

export default function LonpaPictureType101(props) {
  return (
    <>
      {props.aiImage && (
        <>
          <div align="center"></div>
          <div>
            <figure>
              <img
                src={`data:image/png;base64,${props.aiImage}`} // Base64 エンコードの場合
                alt="Received Data"
                width={256}
                height={256}
              />
            </figure>
          </div>
          <div align="center"></div>
        </>
      )}
    </>
  );
}

import { useStyles } from "../Styles";
import { AES, enc } from "crypto-js";
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";

import {
  createPickupTransaction,
  getPickupTransaction,
} from "../common/Helper/FirebaseTransactionHelper";
import {
  movieStringUpload,
  moviePictureUpload,
  getMovieString,
} from "../common/Helper/StorageHelper";

import {
  Button,
  Grid,
  TextField,
  Container,
  Checkbox,
  Typography,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import LodingModal from "./CommonModal/LodingModal";
import LonpaPictures from "./Lonpa/Modal/Picture/LonpaPictures";
import MakeCsv from "./MovieMaker/MakeCsv";
import MovieUsePointModal from "./MovieMaker/Modal/MovieUsePointModal";
import GenkoChanger from "./MovieMaker/GenkoChanger";
import DiscussCreater from "./MovieMaker/DiscussCreater";

import {
  getAllLonpaChild,
  getLonpaList,
  getLonpa,
  getNextPickup,
  getPrevPickup,
} from "../common/Helper/FirebaseHelper";

import URLList from "./MovieMaker/Data/link.json";
import typepic1 from "../images/movietype/type1.jpg";
import typepic2 from "../images/movietype/type2.jpg";
import typepic3 from "../images/movietype/type3.jpg";
import typepic4 from "../images/movietype/type4.jpg";
import typepic5 from "../images/movietype/type5.jpg";
import typepic6 from "../images/movietype/type6.jpg";
import typepic7 from "../images/movietype/type7.jpg";
import typepic8 from "../images/movietype/type8.jpg";

import {
  createIsMovieShort,
  createRapInput,
  createRap,
  createExplainvideoInput,
  createExplainvideo,
  createGen,
  createExplain,
  createMovieSettingText,
  maketext,
  createCsv,
  createMovie,
  createLog,
} from "./MovieMaker/MovieMakerCreateFunctions";

import {
  downloadExplain,
  downloadGen,
  downloadMovieSetting,
} from "./MovieMaker/MovieMakerDownloadFunctions";

export default function MovieMaker(props) {
  let { lonpaid } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const [togglenum, setToggleNum] = useState([0]); //上部ボタンの初期選択内容
  const [checked, setChecked] = useState(true);

  const [hira, setHira] = useState("");
  const [annkey, setAnnkey] = useState("");
  const [outdata, setOutdata] = useState("");

  const [nameAgree, setNameAgree] = useState("【女子A - 賛成】");
  const [nameDisagree, setNameDisagree] = useState("【女子B - 反対】");
  const [oneTextShow, setOneTextShow] = useState("");
  const [twoTextShow, setTwoTextShow] = useState();
  const [threeTextShow, setThreeTextShow] =
    useState("まだその機能は実装されていないよ");
  const [fourTextShow, setFourTextShow] = useState("");
  const [fiveTextShow, setFiveTextShow] = useState(
    "【女子A - 賛成】\nてすとA\n【女子B - 反対】\nてすとB"
  );
  const [lodingmodalopen, setLodingmodalopen] = useState(false);

  const [genko, setGenko] = useState([]);
  const [isopenone, setIsopenone] = useState(true);
  const [isopentwo, setIsopentwo] = useState(false);
  const [isopenthree, setIsopenthree] = useState(false);
  const [isShort, setIsShort] = useState(true);

  const [randId, setRandId] = useState(0);
  const [isflagone, setIsflagone] = useState(false);
  const [isflagtwo, setIsflagtwo] = useState(false);
  const [isflagthree, setIsflagthree] = useState(false);
  const [isflagfour, setIsflagfour] = useState(false);
  const [isflagfive, setIsflagfive] = useState(false);

  const [genkocsv, setGenkocsv] = useState("未保存");

  const [explainvideoInput, setExplainvideoInput] = useState("未保存");
  const [explainvideo, setExplainvideo] = useState();

  const [moviepng, setMoviepng] = useState("未保存");

  const [autoCreateFlag, setAutoCreateFlag] = useState(false);

  //特殊実装用
  const [changeText, setChangeText] = useState();
  const [changeVocal, setChangeVocal] = useState();
  const [remakeCount, setRemakeCount] = useState({
    num: 0,
    updateindex: "",
    text: "",
  });

  const [isDiscussUpdated, setIsDiscussUpdated] = useState(false);

  //remotionテキスト
  // const [remotionTextShow, setRemotionTextShow] = useState("");
  // const [expanded, setExpanded] = useState(false);

  const [movieStage, setMovieStage] = useState({
    1: "完", //lonpaデータ取得
    2: "未", //rap生成用文書
    3: "未", //rap文書
    4: "未", //rap文書の整形
    5: "未", //rap文書のcsv化
    6: "未", //動画タイプのセット
    7: "未", //動画に使う画像の設定
    8: "未", //動画説明文生成用文書
    9: "未", //動画説明文
    10: "未", //動画保存(アップロード)
  });

  const [saveStatus, setSaveStatus] = useState({
    1: "未",
    2: "未",
    3: "未",
    4: "未",
    5: "未",
  });

  // #set1: 動画サイズ（毎回どっちも作成してるから使ってない）
  // #set2: 動画タイプ（1:スタイリッシュ　2:落ち着き　3:ジョーク）
  // #set3: 使ってない
  // #set4: 使ってない
  // #set5: 使ってない
  const [movieSetting, setMovieSetting] = useState({
    set1: "1",
    set2: "1",
    set3: "1",
    set4: "0",
    set5: "0",
  });
  const [uploadChangeExplainFlag, setUploadChangeExplainFlag] = useState(false);
  const [uploadChangeGenkoFlag, setUploadChangeGenkoFlag] = useState(false);
  const [uploadChangeSettingFlag, setUploadChangeSettingFlag] = useState(false);
  const [uploadChangeImageFlag, setUploadChangeImageFlag] = useState(false);

  const [movieSettingText, setMovieSettingText] = useState(
    "movie,set1,set2,set3,set4,set5\nx,1,1,1,0,0"
  );

  const [movieUsePointmodalopen, setMovieUsePointmodalopen] = useState(false);
  const [pointPattarn, setPointPattarn] = useState("");
  const [editDatas, setEditDatas] = useState([]);
  const [isInitFinish, setIsInitFinish] = useState(false);

  // ここから追記
  const [movietype, setMovietype] = useState();
  const [movietypetext, setMovietypetext] = useState("");

  const [isSettingMade, setIsSettingMade] = useState(false);

  const urlList = URLList;

  const theme = createTheme({
    palette: {
      lamactstyle: {
        main: props.bgColor.text01.fontColor,
        disabled: props.bgColor.text09.fontColor,
        color: props.bgColor.text09.fontColor,
      },
      action: {
        disabled: props.bgColor.text09.fontColor,
        color: props.bgColor.text09.fontColor,
      },
    },
  });

  const executeAutomake = async () => {
    props.setAutoCreateMovie({
      create: true,
      id: lonpaid,
      rapInputCreate: true,
      rapCreate: true,
      genCreate: true,
      csvCreate: true,
      imageCreate: true,
      settingCreate: true,
      explainCreate: true,
      saveCreate: true,
    });
  };

  const executeMakeManuscript = async () => {
    createRap(
      fourTextShow,
      setFiveTextShow,
      setMovieStage,
      props.autoCreateMovie,
      props.setAutoCreateMovie,
      props.stopAuto,
      movieStage
    );
    // createCsv(
    //   genko,
    //   setGenkocsv,
    //   setMovieStage,
    //   props.autoCreateMovie,
    //   props.setAutoCreateMovie,
    //   movieStage
    // );
    setUploadChangeGenkoFlag(true);
  };

  const executeMakeSNS = async () => {
    createExplainvideo(
      explainvideoInput,
      setExplainvideo,
      setMovieStage,
      createExplain,
      props.autoCreateMovie,
      props.setAutoCreateMovie,
      props.stopAuto,
      editDatas.claim,
      explainvideoInput,
      lonpaid,
      movietypetext,
      movieStage
    );
    setUploadChangeExplainFlag(true);
  };

  const executeMakeMovieOnly = async () => {
    setIsflagfour(true);
    createMovie(lonpaid);
  };

  //自動作成autoCreateMovie
  const autoCreate = async (nextEditDatas) => {
    let useEditDatas;
    if (nextEditDatas) {
      useEditDatas = nextEditDatas;
    } else {
      useEditDatas = editDatas;
    }
    // console.log(useEditDatas);

    if (props.autoCreateMovie.create) {
      if (props.autoCreateMovie.rapInputCreate) {
        console.log("rapInputCreate");
        setLodingmodalopen(true);
        setIsopenone(true);
        setIsopentwo(false);
        setIsopenthree(false);
        createRapInput(
          useEditDatas,
          editDatas,
          setFourTextShow,
          setMovieStage,
          props.autoCreateMovie,
          props.setAutoCreateMovie,
          props.stopAuto,
          movieStage
        );
      } else if (props.autoCreateMovie.rapCreate) {
        console.log("rapCreate");
        createRap(
          fourTextShow,
          setFiveTextShow,
          setMovieStage,
          props.autoCreateMovie,
          props.setAutoCreateMovie,
          props.stopAuto,
          movieStage
        );
      } else if (props.autoCreateMovie.genCreate) {
        console.log("genCreate");
        createGen(
          fiveTextShow,
          setGenko,
          createIsMovieShort,
          setMovieStage,
          props.autoCreateMovie,
          props.setAutoCreateMovie,
          setIsShort,
          setMovieSetting,
          movieStage,
          nameDisagree,
          nameAgree,
          movieSetting
        );
        setUploadChangeGenkoFlag(true);
      } else if (props.autoCreateMovie.csvCreate) {
        console.log("csvCreate");
        createCsv(
          genko,
          setGenkocsv,
          setMovieStage,
          props.autoCreateMovie,
          props.setAutoCreateMovie,
          movieStage
        );
      } else if (props.autoCreateMovie.settingCreate) {
        console.log("settingCreate");
        setMovieSetting(movieSetting);
        createMovieSettingText(
          movieSetting,
          lonpaid,
          setMovieSettingText,
          props.autoCreateMovie,
          props.setAutoCreateMovie
        );
        setUploadChangeSettingFlag(true);
        setMovieStage({ ...movieStage, 6: "完" });
      } else if (props.autoCreateMovie.imageCreate) {
        console.log("imageCreate");
        setUploadChangeImageFlag(true);
        setAutoCreateFlag(true);
      } else if (props.autoCreateMovie.explainCreate) {
        console.log("explainCreate");
        createExplainvideo(
          explainvideoInput,
          setExplainvideo,
          setMovieStage,
          createExplain,
          props.autoCreateMovie,
          props.setAutoCreateMovie,
          props.stopAuto,
          editDatas.claim,
          explainvideoInput,
          lonpaid,
          movietypetext,
          movieStage
        );
        setUploadChangeExplainFlag(true);
      } else if (props.autoCreateMovie.saveCreate) {
        console.log("saveCreate");
        // console.log(movieStage);
        // setExpanded(expanded === "panel10" ? false : "panel10");

        if (
          movieStage[5] == "完" &&
          movieStage[6] == "完" &&
          movieStage[7] == "完"
        ) {
          uploadDatas();
        }
      } else {
        setLodingmodalopen(false);
        props.setAutoCreateMovie({ ...props.autoCreateMovie, create: false });
      }
    }
  };
  useEffect(() => {
    console.log("uE_MovieMaker");
    const f = async () => {
      //ログイン状態だった場合，自身が過去投票したlonpaの一覧を取得する
      const doc = await getPickupTransaction(lonpaid);
      let isflagtwonow = false;
      //ログイン中のアカウントで，選択中のlonpa一覧
      if (doc) {
        if (doc.data()) {
          setRandId(doc.data().id);
          setIsflagone(doc.data().isflagone);
          setIsflagtwo(doc.data().isflagtwo);
          setIsflagthree(doc.data().isflagthree);
          setIsflagfour(doc.data().isflagfour);
          setIsflagfive(doc.data().isflagfive);

          isflagtwonow = doc.data().isflagtwo;
        }
      }

      let nextEditDatas;
      try {
        nextEditDatas = await getLonpaInfo(lonpaid);
        setEditDatas(nextEditDatas);
      } catch {
        console.log("lonpaデータが取れませんでした");
      } finally {
        createExplainvideoInput(
          nextEditDatas,
          editDatas,
          setMovieStage,
          setExplainvideoInput,
          props.stopAuto,
          movieStage
        );
        setMovieSettingText(
          "movie,set1,set2,set3,set4,set5\n" + lonpaid + ",1,1,1,0,0"
        );
        setMovieStage({ ...movieStage, 6: "完", 8: "完" });
        createRapInput(
          nextEditDatas,
          editDatas,
          setFourTextShow,
          setMovieStage,
          props.autoCreateMovie,
          props.setAutoCreateMovie,
          props.stopAuto,
          movieStage
        );
        //console.log(nextEditDatas);

        setIsInitFinish(true);
        if (props.autoCreateMovie) {
          if (props.autoCreateMovie.create) {
            autoCreate(nextEditDatas);
          }
        }

        //すでに生成済みならデータを取得
        if (isflagtwonow) {
          // console.log(movieSetting);
          if (
            downloadExplain(lonpaid, setExplainvideo) &&
            downloadMovieSetting(lonpaid, setMovieSetting, setMovietype) &&
            downloadGen(
              lonpaid,
              setGenko,
              createIsMovieShort,
              setIsShort,
              setMovieSetting,
              setMovieStage,
              movieStage,
              movieSetting
            )
          ) {
            setIsSettingMade(true);
          } else {
            setIsSettingMade(false);
          }
        }
      }
    };
    f();
  }, [lonpaid]);

  useEffect(() => {
    console.log("uE_MovieMaker_autoCreateMovie");
    // console.log("isInitFinish" + isInitFinish);
    const f = async () => {
      if (isInitFinish) {
        if (props.autoCreateMovie) {
          if (props.autoCreateMovie.create) {
            // console.log("自動作成アリ");
            autoCreate(false);
          } else {
            // console.log("自動作成ナシ");
          }
        }
      }
    };
    f();
  }, [props.autoCreateMovie]);

  const setMoviepngAndFlag = (png) => {
    setMoviepng(png);
    setMovieStage({ ...movieStage, 7: "完" });

    //自動生成フラグ
    if (props.autoCreateMovie.imageCreate) {
      props.setAutoCreateMovie({
        ...props.autoCreateMovie,
        imageCreate: false,
      });
      //useEffectが動く
    }
    setAutoCreateFlag(false);

    //console.log("画像保存済み");
  };

  const getLonpaInfo = async (lonpaid) => {
    const lonpadoc = await getLonpa(lonpaid);
    //console.log(lonpadoc.data());

    const lonpaChildSnapshot = await getAllLonpaChild(lonpaid);
    let childlonsAgree = [];
    let childlonsDisagree = [];
    for (let i = 0; i < lonpaChildSnapshot.size; i++) {
      const onedata = lonpaChildSnapshot.docs[i].data();
      const onedataID = lonpaChildSnapshot.docs[i].id;
      const oneeditdata = {
        data: {
          lonpaid: onedataID,
          agree: onedata.agree,
          claim: onedata.claim,
          isDelete: onedata.isDelete,
          votenum: onedata.votenum,
        },
      };
      if (onedata.agree) {
        childlonsAgree.push(oneeditdata);
      } else {
        childlonsDisagree.push(oneeditdata);
      }
    }
    const makeEditDatas = {
      claim: lonpadoc.data().claim,
      id: lonpaid,
      infoagree: childlonsAgree,
      infodisagree: childlonsDisagree,
      infochoice: null,
      isDelete: lonpadoc.data().isDelete,
      isopinion: lonpadoc.data().isopinion,
      parent: undefined,
      agree: lonpadoc.data().agree,
      disagree: lonpadoc.data().disagree,
      imageurl: lonpadoc.data().imageurl,
      tag: lonpadoc.data().tag,
      ngram: lonpadoc.data().ngram,
    };
    return makeEditDatas;
  };

  const remakeCountIncriment = (updateindex, text, vocal, changetype) => {
    setRemakeCount({
      num: remakeCount.num + 1,
      updateindex: updateindex,
      text: text,
      vocal: vocal,
      changetype: changetype,
    });
  };

  const createOutdata = async (hira, key) => {
    console.log("平文 : " + hira);
    const out1 = AES.encrypt(hira, key);
    console.log("暗号文 : " + out1.toString());
    const out2 = AES.decrypt(out1.toString(), key).toString(enc.Utf8);
    console.log("復元文 : " + out2);
    const out3 = AES.decrypt(hira, key).toString(enc.Utf8);
    console.log("復元文2 : " + out3);
    setOutdata(out1.toString());
  };

  const uploadData01 = async () => {
    setSaveStatus({ ...saveStatus, 1: "保存中" });
    if (movieStringUpload(lonpaid, explainvideo, "explain.txt")) {
      setSaveStatus({ ...saveStatus, 1: "保存済み" });
      setUploadChangeExplainFlag(false);
    } else {
      console.log("エラーです");
      props.stopAuto();
      setSaveStatus({ ...saveStatus, 1: "エラー" });
    }
  };

  const uploadData02 = async (newGenkocsv) => {
    // console.log(newGenkocsv)
    setSaveStatus({ ...saveStatus, 2: "保存中" });
    await movieStringUpload(lonpaid, newGenkocsv, "genko.csv");

    setIsflagone(true);
    setIsflagtwo(true);
    setIsflagthree(isShort);

    await createPickupTransaction(lonpaid, {
      isflagone: true,
      isflagtwo: true,
      isflagthree: isShort,
      isflagfour: isflagfour,
      isflagfive: isflagfive,
      claim: editDatas.claim,
      discussId: 9999,
    }).then((newrandId) => {
      setRandId(newrandId);
      setSaveStatus({ ...saveStatus, 2: "保存済み" });
      setMovieStage({ ...movieStage, 10: "完" });
      setUploadChangeGenkoFlag(false);
    });
  };

  const uploadData03 = async (newMovieSettingText) => {
    setSaveStatus({ ...saveStatus, 3: "保存中" });
    await movieStringUpload(lonpaid, newMovieSettingText, "movieSetting.csv");
    setSaveStatus({ ...saveStatus, 3: "保存済み" });
    setUploadChangeSettingFlag(false);
  };

  const uploadData04 = async () => {
    //LonpaPictureの保存ボタンから開始
    setSaveStatus({ ...saveStatus, 4: "保存中" });
    await moviePictureUpload(lonpaid, moviepng, "100.png");
    setSaveStatus({ ...saveStatus, 4: "保存済み" });
    setUploadChangeImageFlag(false);
  };

  const uploadDatas = async () => {
    if (uploadChangeExplainFlag) {
      await uploadData01();
    }
    if (uploadChangeGenkoFlag) {
      await uploadData02(
        await createCsv(
          genko,
          setGenkocsv,
          setMovieStage,
          props.autoCreateMovie,
          props.setAutoCreateMovie,
          movieStage
        )
      );
    }
    if (uploadChangeSettingFlag) {
      await uploadData03(
        createMovieSettingText(
          movieSetting,
          lonpaid,
          setMovieSettingText,
          props.autoCreateMovie,
          props.setAutoCreateMovie
        )
      );
    }
    if (uploadChangeImageFlag) {
      await uploadData04();
    }

    setSaveStatus({
      1: "保存済み",
      2: "保存済み",
      3: "保存済み",
      4: "保存済み",
      5: "保存済み",
    });

    //自動生成フラグ
    if (props.autoCreateMovie.saveCreate) {
      props.setAutoCreateMovie({
        ...props.autoCreateMovie,
        saveCreate: false,
      });
      //useEffectが動く
    }
  };

  const ChoiceData = (id) => {
    setGenko([]);
    setExplainvideo();
    setMovietype();
    setIsDiscussUpdated(false); //DiscussCreaterのロックを解く
    window.scrollTo(0, 0);
    navigate("/MovieMaker/" + id);
  };

  async function GotoNextPickup(id) {
    const querysnapshot = await getNextPickup(id);
    if (querysnapshot.docs.pop()) {
      ChoiceData(querysnapshot.docs.pop().id);
    } else {
      alert("移動に失敗しました");
    }
  }

  async function GotoPrevPickup(id) {
    const querysnapshot = await getPrevPickup(id);
    if (querysnapshot.docs.pop()) {
      ChoiceData(querysnapshot.docs.pop().id);
    } else {
      alert("移動に失敗しました");
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          backgroundColor: props.bgColor.back01,
          color: props.bgColor.text01.fontColor,
        }}
      >
        <div style={{ height: "2vh" }}></div>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
            <h2 id="transition-modal-title" align="center">
              動画生成ツール
            </h2>
          </Grid> */}

            {isopenone ? (
              <>
                <Grid item xs={12} align="center">
                  <div align="center">
                    <LonpaPictures
                      picType={100} //98}
                      picText={maketext(editDatas.claim, 1)}
                      picText2={""}
                      picText3={""}
                      picText4={""}
                      url={"https://lonpa.net"}
                      setMoviepngAndFlag={setMoviepngAndFlag}
                      autoCreateFlag={autoCreateFlag}
                    />
                  </div>
                </Grid>

                <Grid
                  item
                  xs={2}
                  style={{ paddingLeft: "0px" }}
                  container
                  justifyContent="center"
                >
                  <Button
                    color="lamactstyle"
                    onClick={() => {
                      setMovietype(1);
                      setMovietypetext(urlList.type1.link);
                      setMovieSetting({
                        ...movieSetting,
                        set2: "1",
                      });
                      setUploadChangeSettingFlag(true);
                    }}
                    style={
                      movietype === 1
                        ? {
                            border: "solid 3px blue",
                          }
                        : {}
                    }
                  >
                    <img
                      src={typepic1}
                      width={"100%"}
                      alt="画像1"
                      align="top"
                    ></img>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ paddingLeft: "0px" }}
                  container
                  justifyContent="center"
                >
                  <Button
                    color="lamactstyle"
                    onClick={() => {
                      setMovietype(2);
                      setMovietypetext(urlList.type2.link);
                      setMovieSetting({
                        ...movieSetting,
                        set2: "2",
                      });
                      setUploadChangeSettingFlag(true);
                    }}
                    style={
                      movietype === 2
                        ? {
                            border: "solid 3px blue",
                          }
                        : {}
                    }
                  >
                    <img
                      src={typepic2}
                      width={"100%"}
                      alt="画像2"
                      align="top"
                    ></img>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ paddingLeft: "0px" }}
                  container
                  justifyContent="center"
                >
                  <Button
                    color="lamactstyle"
                    onClick={() => {
                      setMovietype(3);
                      setMovietypetext(urlList.type3.link);
                      setMovieSetting({
                        ...movieSetting,
                        set2: "3",
                      });
                      setUploadChangeSettingFlag(true);
                    }}
                    style={
                      movietype === 3
                        ? {
                            border: "solid 3px blue",
                          }
                        : {}
                    }
                  >
                    <img
                      src={typepic3}
                      width={"100%"}
                      alt="画像2"
                      align="top"
                    ></img>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ paddingLeft: "0px" }}
                  container
                  justifyContent="center"
                >
                  <Button
                    color="lamactstyle"
                    onClick={() => {
                      setMovietype(4);
                      setMovietypetext(urlList.type4.link);
                      setMovieSetting({
                        ...movieSetting,
                        set2: "4",
                      });
                      setUploadChangeSettingFlag(true);
                    }}
                    style={
                      movietype === 4
                        ? {
                            border: "solid 3px blue",
                          }
                        : {}
                    }
                  >
                    <img
                      src={typepic4}
                      width={"100%"}
                      alt="画像2"
                      align="top"
                    ></img>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ paddingLeft: "0px" }}
                  container
                  justifyContent="center"
                >
                  <Button
                    color="lamactstyle"
                    onClick={() => {
                      setMovietype(5);
                      setMovietypetext(urlList.type5.link);
                      setMovieSetting({
                        ...movieSetting,
                        set2: "5",
                      });
                      setUploadChangeSettingFlag(true);
                    }}
                    style={
                      movietype === 5
                        ? {
                            border: "solid 3px blue",
                          }
                        : {}
                    }
                  >
                    <img
                      src={typepic5}
                      width={"100%"}
                      alt="画像2"
                      align="top"
                    ></img>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ paddingLeft: "0px" }}
                  container
                  justifyContent="center"
                >
                  <Button
                    color="lamactstyle"
                    onClick={() => {
                      setMovietype(6);
                      setMovietypetext(urlList.type6.link);
                      setMovieSetting({
                        ...movieSetting,
                        set2: "6",
                      });
                      setUploadChangeSettingFlag(true);
                    }}
                    style={
                      movietype === 6
                        ? {
                            border: "solid 3px blue",
                          }
                        : {}
                    }
                  >
                    <img
                      src={typepic6}
                      width={"100%"}
                      alt="画像2"
                      align="top"
                    ></img>
                  </Button>
                </Grid>

                <Grid item xs={12} align="center">
                  <Typography>
                    {movietype ? (
                      <Button
                        color="lamactstyle"
                        onClick={() => {
                          setPointPattarn("autocreate");
                          setMovieUsePointmodalopen(true);
                        }}
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        動画素材 作成
                      </Button>
                    ) : (
                      <Button
                        color="lamactstyle"
                        disabled
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        動画素材 作成
                      </Button>
                    )}
                    <br />
                    {/* {movietype}:{movietypetext} */}
                    {/* <br /> */}
                    <MakeCsv
                      genko={genko}
                      setGenko={setGenko}
                      setGenkocsv={setGenkocsv}
                      setChangeText={setChangeText}
                      setChangeVocal={setChangeVocal}
                      createIsMovieShort={(flag) => {
                        createIsMovieShort(
                          flag,
                          setIsShort,
                          movieSetting,
                          setMovieSetting
                        );
                      }}
                      changeText={changeText}
                      changeVocal={changeVocal}
                      remakeCountIncriment={remakeCountIncriment}
                      remakeCount={remakeCount}
                      setUploadChangeGenkoFlag={setUploadChangeGenkoFlag}
                      bgColor={props.bgColor}
                    />

                    {explainvideo ? (
                      <TextField
                        fullWidth
                        multiline
                        maxRows={5}
                        minRows={5}
                        label="explainvideo"
                        value={explainvideo}
                        onChange={(e) => {
                          setExplainvideo(e.target.value);
                          setUploadChangeExplainFlag(true);
                        }}
                        inputProps={{
                          style: { color: props.bgColor.text04.fontColor },
                        }}
                        style={{
                          border: props.bgColor.line02,
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Typography>

                  {/* <Typography sx={{ width: "80%", flexShrink: 0 }}>
                  タイプ選択: {movieStage[1]}　動画作成：{movieStage[2]}
                  　動画保存：{saveStatus[5]}
                </Typography> */}

                  <Typography>
                    {isSettingMade ? (
                      <>
                        {uploadChangeExplainFlag ||
                        uploadChangeGenkoFlag ||
                        uploadChangeSettingFlag ||
                        uploadChangeImageFlag ? (
                          <Button
                            color="lamactstyle"
                            style={{ fontSize: 20, fontWeight: "bold" }}
                            onClick={() => {
                              uploadDatas();
                            }}
                          >
                            動画素材 上書き
                          </Button>
                        ) : (
                          <Button
                            color="lamactstyle"
                            style={{ fontSize: 20, fontWeight: "bold" }}
                            disabled
                          >
                            動画素材 上書き
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        {uploadChangeExplainFlag ||
                        uploadChangeGenkoFlag ||
                        uploadChangeSettingFlag ||
                        uploadChangeImageFlag ? (
                          <Button
                            color="lamactstyle"
                            style={{ fontSize: 20, fontWeight: "bold" }}
                            onClick={() => {
                              uploadDatas();
                            }}
                          >
                            動画素材 保存
                          </Button>
                        ) : (
                          <Button
                            color="lamactstyle"
                            style={{ fontSize: 20, fontWeight: "bold" }}
                            disabled
                          >
                            動画素材 保存
                          </Button>
                        )}
                      </>
                    )}
                    <Checkbox
                      checked={uploadChangeExplainFlag}
                      style={{ width: "10px" }}
                      disabled
                    />
                    <Checkbox
                      checked={uploadChangeGenkoFlag}
                      style={{ width: "10px" }}
                      disabled
                    />
                    <Checkbox
                      checked={uploadChangeSettingFlag}
                      style={{ width: "10px" }}
                      disabled
                    />
                    <Checkbox
                      checked={uploadChangeImageFlag}
                      style={{ width: "10px" }}
                      disabled
                    />
                    <br />
                    {!isflagfour && isflagthree ? (
                      <>
                        <Button
                          color="lamactstyle"
                          onClick={() => {
                            createPickupTransaction(lonpaid, {
                              isflagone: isflagone,
                              isflagtwo: isflagtwo,
                              isflagthree: isflagthree,
                              isflagfour: true,
                              isflagfive: isflagfive,
                              claim: editDatas.claim,
                              discussId: 9999,
                            }).then((newrandId) => {
                              setRandId(newrandId);
                              setPointPattarn("makemovieonly");
                              setMovieUsePointmodalopen(true);
                            });
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          動画 生成
                        </Button>

                        {/* <br />
                        <br />
                        <Button
                          disabled={!isflagone}
                          color="lamactstyle"
                          onClick={() => {
                            createLog();
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          【！危険！】ログ作成テスト【！危険！】
                        </Button> */}
                      </>
                    ) : (
                      <>
                        <Button
                          disabled
                          color="lamactstyle"
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          動画 生成
                        </Button>
                      </>
                    )}
                    <br />
                    {isflagfive ? (
                      <>
                        <Button
                          color="lamactstyle"
                          onClick={() => {
                            window.open("/MovieShow/" + lonpaid, "_blank");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          動画 確認
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          disabled
                          color="lamactstyle"
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          動画 確認
                        </Button>
                      </>
                    )}

                    <br />
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: props.bgColor.back01 }}
                >
                  <Button
                    onClick={() => {
                      window.open("/Lonpa/" + lonpaid, "_blank");
                    }}
                  >
                    randId:{randId}
                    {" / "}
                    {lonpaid}
                  </Button>
                  <br />
                  <Button
                    color="lamactstyle"
                    onClick={() => {
                      props.stopAuto();
                      navigate("/MovieState/");
                    }}
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    ステート画面へ移動
                  </Button>
                  <br />
                  <Checkbox
                    color="lamactstyle"
                    checked={isflagone}
                    style={{
                      width: "10px",
                      color: props.bgColor.text01.fontColor,
                    }}
                    onChange={(e) => {
                      if (isflagone) {
                        setIsflagone(false);
                      } else {
                        setIsflagone(true);
                      }
                    }}
                  />
                  <Checkbox
                    color="lamactstyle"
                    checked={isflagtwo}
                    style={{
                      width: "10px",
                      color: props.bgColor.text01.fontColor,
                    }}
                    onChange={() => {
                      if (isflagtwo) {
                        setIsflagtwo(false);
                      } else {
                        setIsflagtwo(true);
                      }
                    }}
                  />
                  <Checkbox
                    color="lamactstyle"
                    checked={isflagthree}
                    style={{
                      width: "10px",
                      color: props.bgColor.text01.fontColor,
                    }}
                    onChange={() => {
                      if (isflagthree) {
                        setIsflagthree(false);
                      } else {
                        setIsflagthree(true);
                      }
                    }}
                  />
                  <Checkbox
                    color="lamactstyle"
                    checked={isflagfour}
                    style={{
                      width: "10px",
                      color: props.bgColor.text01.fontColor,
                    }}
                    onChange={() => {
                      if (isflagfour) {
                        setIsflagfour(false);
                      } else {
                        setIsflagfour(true);
                      }
                    }}
                  />
                  <Checkbox
                    color="lamactstyle"
                    checked={isflagfive}
                    style={{
                      width: "10px",
                      color: props.bgColor.text01.fontColor,
                    }}
                    onChange={() => {
                      if (isflagfive) {
                        setIsflagfive(false);
                      } else {
                        setIsflagfive(true);
                      }
                    }}
                  />
                  <Button
                    disabled={!isflagone}
                    color="lamactstyle"
                    onClick={() => {
                      createPickupTransaction(lonpaid, {
                        isflagone: isflagone,
                        isflagtwo: isflagtwo,
                        isflagthree: isflagthree,
                        isflagfour: isflagfour,
                        isflagfive: isflagfive,
                        claim: editDatas.claim,
                        discussId: 9999,
                      }).then((newrandId) => {
                        setRandId(newrandId);
                      });
                    }}
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    更新
                  </Button>
                  <br />
                  {movieSetting.set1},{movieSetting.set2},{movieSetting.set3},
                  {movieSetting.set4},{movieSetting.set5}
                  <br />
                  <GenkoChanger
                    bgColor={props.bgColor}
                    genko={genko}
                    setGenko={setGenko}
                    remakeCountIncriment={remakeCountIncriment}
                    setUploadChangeGenkoFlag={setUploadChangeGenkoFlag}
                  />
                  <DiscussCreater
                    bgColor={props.bgColor}
                    genko={genko}
                    editDatas={editDatas}
                    isDiscussUpdated={isDiscussUpdated}
                    setIsDiscussUpdated={setIsDiscussUpdated}
                  />
                  <Button
                    onClick={() => {
                      GotoPrevPickup(randId);
                    }}
                  >
                    前
                  </Button>
                  <Button
                    onClick={() => {
                      GotoNextPickup(randId);
                    }}
                  >
                    次
                  </Button>
                </Grid>
              </>
            ) : (
              <></>
            )}

            {isopenthree ? (
              <>
                <Grid item xs={12} style={{ backgroundColor: "#eee" }}>
                  <TextField
                    label="平"
                    value={hira}
                    onChange={(e) => setHira(e.target.value)}
                  />
                  <br />
                  <TextField
                    label="鍵"
                    value={annkey}
                    onChange={(e) => setAnnkey(e.target.value)}
                  />
                  <br />
                  <Button
                    color="lamactstyle"
                    onClick={() => {
                      createOutdata(hira, annkey);
                    }}
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    シークレット
                  </Button>
                  <br />
                  <TextField label="暗" value={outdata} />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Container>

        <div style={{ height: "6vh" }}></div>
        {movieUsePointmodalopen ? (
          <MovieUsePointModal
            open={movieUsePointmodalopen}
            setOpen={setMovieUsePointmodalopen}
            pointPattarn={pointPattarn}
            executeAutomake={executeAutomake}
            executeMakeManuscript={executeMakeManuscript}
            executeMakeSNS={executeMakeSNS}
            executeMakeMovieOnly={executeMakeMovieOnly}
            id={lonpaid}
          />
        ) : (
          <></>
        )}
        <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
      </div>
    </ThemeProvider>
  );
}

import { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import BorderColor from "@mui/icons-material/BorderColor";
import { Button, Avatar } from "@mui/material";
import { useStyles } from "../../../Styles";

import { deleteLonpaChild } from "../../../common/Helper/FirebaseHelper";

export default function SettingModal(props) {
  const classes = useStyles();

  async function Post() {
    //論拠を削除
    await deleteLonpaChild(props.id, props.item.id);
    if (props.islonpa) {
      //論拠がLonpaなら，Lonpa側の親設定を削除
      await deleteLonpaChild(props.item.id, props.id);
    }
    //props.increseUpdate();
    props.setOpen(false);
  }

  const handleCloseinside = () => {
    props.setOpen(false);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">
            「{props.item.data.claim}」のセッティング画面です
          </h2>
          {typeof props.item.createdBy.name !== "undefined" ? (
            <>
              <tbody>
                <tr>
                  <td>●作成者　→　{props.item.createdBy.name}</td>
                  <td>
                    <Avatar alt="avatar" src={props.item.createdBy.imageurl} />
                  </td>
                </tr>
              </tbody>
              <br />
              ●作成日　→　{props.item.createdBy.createdAt}
              <br />
            </>
          ) : (
            <>
              <br />
              ●作成者　→　ゲストによる作成データです
              <br />
              <br />
              ●作成日　→　{props.item.createdBy.createdAt}
              <br />
            </>
          )}

          <br />
          <div id="transition-modal-description">
            <Button
              color="primary"
              endIcon={<BorderColor />}
              onClick={Post}
              style={{ fontSize: 20 }}
              disabled
            >
              削除権限がありません
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

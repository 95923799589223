import React, { useReducer, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./views/components/Navbar";

import Top from "./views/Top";
import NewTop from "./views/NewTop";
import Search from "./views/Search";
import NewSearch from "./views/NewSearch";
import Lonpa from "./views/Lonpa";
import LonpaExplain from "./views/LonpaExplain";
import Bunku from "./views/Bunku";
import Sign from "./views/Sign";
import Userpage from "./views/Userpage";
import Howto from "./views/Howto";
import Create from "./views/Create";
import Terms from "./views/Terms";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Law from "./views/Law";
import Point from "./views/Point";
import Debug from "./views/Debug";
import Sorry from "./views/Sorry";
import MovieMaker from "./views/MovieMaker";
import MovieMakerold from "./views/MovieMakerold";
import MovieState from "./views/MovieState";
import MovieShow from "./views/MovieShow";
import Cancell from "./views/Cancell";
import Payment from "./views/Payment";
import Loadmap from "./views/Loadmap";
import MovieMakerExplain from "./views/MovieMakerExplain";
import ColorPattern from "./views/ColorPattern";
import Uploader from "./views/Uploader";
import Discuss from "./views/Discuss";
import HowUserDeleteExplain from "./views/HowUserDeleteExplain";
import LoadingApp from "./views/App/LoadingApp";

import { testtheme, darktheme, lighttheme } from "./common/colors";

import Random from "./views/Random";
import GoogleAdsense from "./views/GoogleAdsense";
import { useWindowSize } from "react-use";

export default function App(props) {
  const { width, height } = useWindowSize();
  const [changeImg, setChangeImg] = useState(0);
  const [avatar, setAvatar] = useState("");
  const [styletext, setStyles] = useState({
    textstyle1: "h6",
    isbig: 0,
    stickernum: 5,
    glassstyle: "big",
    glassstyle2: "big",
    windowWidth: 100,
  });
  const [appupdate, setAppupdate] = useState(0);
  const [editDatas, setEditDatas] = useState();
  const [autoCreate, setAutoCreate] = useState({
    create: false,
    id: "",
    imageCreate: false,
    craimCreate: false,
    craimAccept: false,
    adCreate: false,
    tagCreate: false,
    movieCreate: false,
    counter: 0,
  });
  const [autoCreateMovie, setAutoCreateMovie] = useState({
    create: false,
    id: "",
    rapInputCreate: false,
    rapCreate: false,
    genCreate: false,
    csvCreate: false,
    imageCreate: false,
    settingCreate: false,
    explainCreate: false,
    saveCreate: false,
  });

  const [isParamReady, setIsParamReady] = useState(false);

  const [bgColor, setBgColor] = useState(false);

  //クリップボードにコピー関数
  const stopAuto = async () => {
    setAutoCreate({
      create: false,
      id: "",
      imageCreate: false,
      craimCreate: false,
      craimAccept: false,
      tagCreate: false,
      adCreate: false,
      movieCreate: false,
      counter: 0,
    });
    setAutoCreateMovie({
      create: false,
      id: "",
      rapInputCreate: false,
      rapCreate: false,
      genCreate: false,
      csvCreate: false,
      imageCreate: false,
      settingCreate: false,
      explainCreate: false,
      saveCreate: false,
    });
  };

  // useEffect(() => {
  //   console.log("ためし");
  //   console.log(location.pathname);
  //   setIsURLLonpa(1);
  // }, [location]);

  const phoneSize = 500;

  useEffect(() => {
    console.log("uE_APP");
    //setIsParamReady(false);
    if (width < 300) {
      setStyles({
        widthUnder: 300,
        textstyle1: "h7",
        isbig: 0,
        stickernum: 12,
        glassstyle: "small",
        glassstyle2: "small",
        windowWidth: width,
        windowPhoneWidth: width,
        windowHeight: height,
      });
    } else if (width < 500) {
      setStyles({
        widthUnder: 500,
        textstyle1: "h6",
        isbig: 0,
        stickernum: 6,
        glassstyle: "small",
        glassstyle2: "middle",
        windowWidth: width,
        windowPhoneWidth: width,
        windowHeight: height,
      });
    } else if (width < 700) {
      setStyles({
        widthUnder: 700,
        textstyle1: "h6",
        isbig: 0,
        stickernum: 6,
        glassstyle: "middle",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    } else if (width < 900) {
      setStyles({
        widthUnder: 900,
        textstyle1: "h6",
        isbig: 0,
        stickernum: 4,
        glassstyle: "middle",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    } else if (width < 1200) {
      setStyles({
        widthUnder: 1200,
        textstyle1: "h5",
        isbig: 1,
        stickernum: 3,
        glassstyle: "middle",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    } else if (width < 1300) {
      setStyles({
        widthUnder: 1300,
        textstyle1: "h5",
        isbig: 1,
        stickernum: 3,
        glassstyle: "big",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    } else if (width < 1400) {
      setStyles({
        widthUnder: 1400,
        textstyle1: "h5",
        isbig: 1,
        stickernum: 2,
        glassstyle: "big",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    } else if (width < 1600) {
      setStyles({
        widthUnder: 1600,
        textstyle1: "h5",
        isbig: 1,
        stickernum: 2,
        glassstyle: "big",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    } else if (width < 1700) {
      setStyles({
        widthUnder: 1700,
        textstyle1: "h5",
        isbig: 1,
        stickernum: 2,
        glassstyle: "big",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    } else if (width < 1800) {
      setStyles({
        widthUnder: 1800,
        textstyle1: "h5",
        isbig: 1,
        stickernum: 2,
        glassstyle: "big",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    } else if (width < 1900) {
      setStyles({
        widthUnder: 1900,
        textstyle1: "h5",
        isbig: 1,
        stickernum: 2,
        glassstyle: "big",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    } else {
      setStyles({
        widthUnder: 2000,
        textstyle1: "h4",
        isbig: 1,
        stickernum: 2,
        glassstyle: "big",
        glassstyle2: "big",
        windowWidth: width,
        windowPhoneWidth: phoneSize,
        windowHeight: height,
      });
    }
    if (!isParamReady) {
      setIsParamReady(true);
    }
  }, [width, appupdate]);

  const animationspeed = { lonpatable: 150 };

  const changeBgColor = async (colorPattern) => {
    if (colorPattern == 2) {
      setBgColor(testtheme);
    } else if (colorPattern == 1) {
      //ダーク
      setBgColor(darktheme);
    } else {
      //ライト
      setBgColor(lighttheme);
    }
  };

  return (
    <BrowserRouter>
      <React.Fragment>
        <div
          style={{
            backgroundColor: bgColor.back04,
            overscrollBehaviorY: "none",
          }}
        >
          {isParamReady ? (
            <>
              {window.location.pathname.indexOf("Bunku") !== 1 ? (
                <Navbar
                  //searchwordlist={searchwordlist}
                  changeImg={changeImg}
                  setChangeImg={setChangeImg}
                  avatar={avatar}
                  setAvatar={setAvatar}
                  stopAuto={stopAuto}
                  bgColor={bgColor}
                  changeBgColor={changeBgColor}
                  styletext={styletext}
                />
              ) : (
                <></>
              )}
              {bgColor ? (
                <>
                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={
                        <NewTop bgColor={bgColor} styletext={styletext} />
                      }
                    />
                    <Route
                      path="/NewSearch/:word"
                      element={
                        <NewSearch
                          bgColor={bgColor}
                          styletext={styletext}
                          searchword="useurl"
                          //searchwordlist={searchwordlist}
                        />
                      }
                    />
                    <Route
                      path="/NewSearch/"
                      element={
                        <NewSearch
                          bgColor={bgColor}
                          styletext={styletext}
                          searchword="useurl"
                          // searchwordlist={searchwordlist}
                        />
                      }
                    />

                    <Route
                      path="/Search/:word"
                      element={
                        <Search
                          bgColor={bgColor}
                          styletext={styletext}
                          searchword="useurl"
                          //searchwordlist={searchwordlist}
                        />
                      }
                    />
                    <Route
                      path="/Search/"
                      element={
                        <Search
                          bgColor={bgColor}
                          styletext={styletext}
                          searchword="useurl"
                          // searchwordlist={searchwordlist}
                        />
                      }
                    />
                    <Route
                      path="/Top"
                      element={<Top bgColor={bgColor} styletext={styletext} />}
                    />
                    <Route
                      path="/NewTop"
                      element={
                        <NewTop bgColor={bgColor} styletext={styletext} />
                      }
                    />
                    <Route
                      path="/Howto/"
                      element={
                        <Howto
                          bgColor={bgColor}
                          styletext={styletext}
                          width={width}
                        />
                      }
                    />
                    <Route
                      path="/Create/"
                      element={
                        <Create
                          bgColor={bgColor}
                          styletext={styletext}
                          setAutoCreate={setAutoCreate}
                        />
                      }
                    />
                    <Route
                      path="/Lonpa/:lonpaid"
                      element={
                        <Lonpa
                          bgColor={bgColor}
                          styletext={styletext}
                          animationspeed={animationspeed}
                          setEditDatas={setEditDatas}
                          autoCreate={autoCreate}
                          setAutoCreate={setAutoCreate}
                          setAutoCreateMovie={setAutoCreateMovie}
                        />
                      }
                    />
                    <Route
                      path="/Lonpa/"
                      element={
                        <LonpaExplain bgColor={bgColor} styletext={styletext} />
                      }
                    />

                    <Route
                      path="/Discuss/:lonpaid"
                      element={
                        <Discuss bgColor={bgColor} styletext={styletext} />
                      }
                    />

                    <Route
                      path="/Bunku/:lonpaid"
                      element={
                        <Bunku
                          bgColor={bgColor}
                          styletext={styletext}
                          animationspeed={animationspeed}
                        />
                      }
                    />

                    <Route
                      path="/Sign/"
                      element={
                        <Sign
                          bgColor={bgColor}
                          update={() => setAppupdate(appupdate + 1)}
                        />
                      }
                    />
                    <Route
                      path="/Signin/"
                      element={
                        <Sign
                          bgColor={bgColor}
                          kind={true}
                          update={() => setAppupdate(appupdate + 1)}
                        />
                      }
                    />
                    <Route
                      path="/Signup/"
                      element={
                        <Sign
                          bgColor={bgColor}
                          kind={false}
                          update={() => setAppupdate(appupdate + 1)}
                        />
                      }
                    />

                    <Route
                      path="/Userpage/"
                      element={
                        <Userpage
                          bgColor={bgColor}
                          styletext={styletext}
                          update={() => setAppupdate(appupdate + 1)}
                          setChangeImg={setChangeImg}
                          changeImg={changeImg}
                          avatar={avatar}
                          setAvatar={setAvatar}
                        />
                      }
                    />

                    <Route path="/Terms/" element={<Terms />} />
                    <Route path="/PrivacyPolicy/" element={<PrivacyPolicy />} />

                    <Route path="/Law/" element={<Law />} />
                    <Route
                      path="/Point/"
                      element={<Point bgColor={bgColor} />}
                    />
                    <Route
                      path="/Cancell/"
                      element={<Cancell bgColor={bgColor} />}
                    />
                    <Route
                      path="/Payment/"
                      element={<Payment bgColor={bgColor} />}
                    />
                    {/* <Route
                      path="/Loadmap/"
                      element={
                        <Loadmap styletext={styletext} bgColor={bgColor} />
                      }
                    /> */}

                    <Route
                      path="/ColorPattern/"
                      element={
                        <ColorPattern
                          bgColor={bgColor}
                          styletext={styletext}
                          changeBgColor={changeBgColor}
                          darktheme={darktheme}
                          lighttheme={lighttheme}
                          testtheme={testtheme}
                        />
                      }
                    />

                    <Route
                      path="/Debug/:lonpaid"
                      element={
                        <Debug styletext={styletext} bgColor={bgColor} />
                      }
                    />

                    <Route
                      path="/Sorry/"
                      element={<Sorry styletext={styletext} />}
                    />
                    <Route
                      path="/Sorry/:lonpaid"
                      element={<Sorry styletext={styletext} />}
                    />
                    <Route
                      path="/MovieMakerold/:lonpaid"
                      element={
                        <MovieMakerold
                          bgColor={bgColor}
                          styletext={styletext}
                          editDatas={editDatas}
                          autoCreateMovie={autoCreateMovie}
                          setAutoCreateMovie={setAutoCreateMovie}
                          stopAuto={stopAuto}
                        />
                      }
                    />
                    <Route
                      path="/MovieMaker/:lonpaid"
                      element={
                        <MovieMaker
                          bgColor={bgColor}
                          styletext={styletext}
                          editDatas={editDatas}
                          autoCreateMovie={autoCreateMovie}
                          setAutoCreateMovie={setAutoCreateMovie}
                          stopAuto={stopAuto}
                        />
                      }
                    />
                    <Route
                      path="/MovieMaker/"
                      element={
                        <MovieMakerExplain
                          bgColor={bgColor}
                          styletext={styletext}
                        />
                      }
                    />
                    <Route
                      path="/MovieState/"
                      element={
                        <MovieState
                          bgColor={bgColor}
                          styletext={styletext}
                          editDatas={editDatas}
                        />
                      }
                    />
                    <Route
                      path="/MovieShow/:lonpaid"
                      element={
                        <MovieShow bgColor={bgColor} styletext={styletext} />
                      }
                    />
                    <Route path="/Random/" element={<Random />} />
                    <Route
                      path="/Uploader/:userid"
                      element={
                        <Uploader styletext={styletext} bgColor={bgColor} />
                      }
                    />

                    <Route
                      path="/HowUserDeleteExplain/"
                      element={
                        <HowUserDeleteExplain
                          bgColor={bgColor}
                          styletext={styletext}
                        />
                      }
                    />

                    <Route element={<Sorry styletext={styletext} />} />
                  </Routes>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <LoadingApp bgColor={bgColor} styletext={styletext} />
          )}
        </div>
      </React.Fragment>
    </BrowserRouter>
  );
}

// import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  icon: {
    // marginRight: theme.spacing(2),
    mr: 2,
  },
  // heroContent: {
  //   backgroundColor: theme.palette.background.paper,
  //   padding: theme.spacing(8, 0, 6),
  // },
  space4: {
    // marginTop: theme.spacing(4),
    mt: 4,
  },
  heroButtons: {
    //marginTop: theme.spacing(4),
    mt: 4,
  },
  cardGrid: {
    // paddingTop: theme.spacing(8),
    // paddingBottom: theme.spacing(8),
    pt: 8,
    pb: 8,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    // backgroundColor: theme.palette.background.paper,★★★
    // padding: theme.spacing(6),
    p: 6,
  },
  root: {
    width: "100%",
  },
  actionsContainer: {
    // marginBottom: theme.spacing(2),
    mb: 2,
  },
  resetContainer: {
    // padding: theme.spacing(3),
    p: 3,
  },
  chip: {
    // margin: theme.spacing(0.2),
    m: 0.2,
  },
  miniicon: {
    display: "inline-block",
    // margin: theme.spacing(1),
    m: 1,
    verticalAlign: "middle",
  },
  paper: {
    // marginTop: theme.spacing(8),
    mt: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    // margin: theme.spacing(1),
    m: 1,
    // backgroundColor: theme.palette.secondary.main,★★★
    backgroundColor: "#ffffff",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3),
    mt: 3,
  },
  //ログインボタン
  submit: {
    // margin: theme.spacing(3, 0, 2),★★★
  },
  //アカウント作成ボタン
  createAccout: {
    backgroundColor: "#f0f8ff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,★★★
    border: "0px solid #000",
    backgroundColor: "#eeeeee",
    boxShadow: 5,
    overflow: "auto",

    // padding: theme.spacing(2, 4, 3),★★★
  },

  centerspace: {
    width: "94vw",
    background: "#EEEEEE",
    marginLeft: "3vw",
    marginTop: "3vh",
    marginBottom: "2vh",
    backgroundColor: "#eeeeee",
  },

  slideContainer: {
    height: 100,
    WebkitOverflowScrolling: "touch", // iOS momentum scrolling
  },
  slide: {
    minHeight: 100,
    color: "#fff",
  },
  modal: {
    width: "90vw",
    height: "90vh",
    overflow: "auto",
    marginLeft: "5vw",
    marginTop: "5vw",
  },

  imgmodal: {
    width: "60vw",
    height: "80vh",
    overflow: "auto",
    marginLeft: "20vw",
    marginTop: "10vh",
    backgroundColor: "#cccccc",
  },

  tagmodal: {
    width: "90vw",
    overflow: "auto",
    marginLeft: "5vw",
    marginTop: "10vh",
    backgroundColor: "#ffffff",
    borderRadius: "5px 5px",
  },

  taglist: {
    height: "40vh",
    overflow: "auto",
    backgroundColor: "#ffffff",
  },

  balloon_l: {
    // margin: "30px 0",
    display: "flex",
    "justify-content": "flex-start",
    "align-items": "flex-start" /*縦位置を上揃え*/,
  },
  says: {
    "max-width": "65vw" /*最大幅は任意*/,
    display: "flex",
    "flex-wrap": "wrap",
    marginLeft: "5px",
    marginBottom: "2px",
    position: "relative",
    padding: "17px 13px 15px 18px",
    borderRadius: "5px 5px",
    background: "	#DDDDDD",
    "box-sizing": "border-box",
    "line-height": "1.5",
  },
  iconimg: {
    width: "50px" /*任意のサイズ*/,
    height: "50px",
    borderRadius: "50% 50%",
    border: "solid 1px skyblue",
    marginLeft: "5px",
  },
  contents: {
    marginBottom: "2vh",
    width: "94vw",
    //height: '65vh',
    overflow: "auto",
    background: "#EEEEEE",
    marginLeft: "3vw",
    //border: "1px solid #000",
    marginTop: "3vh",
  },
  chats: {
    marginTop: "2vw",
    // width: '90vw',
    width: "100%",
    height: "40vh",
    overflow: "auto",
    // marginLeft: "2vw",
  },
  displaydate: {
    "font-size": "10px",
  },
  indicatorButtonblue: {
    display: "flex",
    width: "100%",
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.05)",
    padding: "1rem 1rem 1rem 1rem",
    borderRadius: "1.4rem 1.4rem 1.4rem 1.4rem",
    "&:hover": {
      backgroundColor: "#d0d0dd",
    },
    "&:active": {
      backgroundColor: "#c0c0cc",
    },
  },
  indicatorButtonred: {
    display: "flex",
    width: "100%",
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.05)",
    padding: "1rem 1rem 1rem 1rem",
    borderRadius: "1.4rem 1.4rem 1.4rem 1.4rem",
    "&:hover": {
      backgroundColor: "#ddd0d0",
    },
    "&:active": {
      backgroundColor: "#ccc0c0",
    },
  },
  indicatorButtongray: {
    display: "flex",
    width: "100%",
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.05)",
    padding: "1rem 1rem 1rem 1rem",
    borderRadius: "1.4rem 1.4rem 1.4rem 1.4rem",
    "&:hover": {
      backgroundColor: "#dddddd",
    },
    "&:active": {
      backgroundColor: "#cccccc",
    },
  },
  indicatorButtonwhite: {
    display: "flex",
    width: "100%",
    borderColor: "#ffffff",
    padding: "7px",
    border: "solid 4px",
    borderBottom: "solid 2px #eeeeee",

    "&:hover": {
      // filter: `blur(0.3px)`,
      opacity: 0.4,
    },
    "&:active": {},
  },
  IndicatorButtonCaption: {
    flex: 1,
    textAlign: "left",
  },

  topChoiceButton: {
    display: "flex",
    width: "100%",
    border: "solid 0px",
    padding: "0px",
  },

  //lonpa画面の一つ一つの論拠のCSS
  middleglasseffect: {
    color: "#000000",
    display: "flex",
    //角を丸くするかどうか
    //"borderRadius": "10px",
    backgroundImage: "url(/glass/middle/middleBalanced.png)",
    backgroundSize: "100% 100%",
    "background-color": "rgba(255,255,255, 0.6)",
  },
  bigglasseffect: {
    color: "#000000",
    display: "flex",
    backgroundImage: "url(/glass/big/bigBalanced.png)",
    backgroundSize: "100% 100%",
    "background-color": "rgba(255,255,255, 0.6)",
  },
  smallglasseffect: {
    color: "#000000",
    display: "flex",
    backgroundImage: "url(/glass/small/smallBalanced.png)",
    backgroundSize: "100% 100%",
    "background-color": "rgba(255,255,255, 0.6)",
  },

  bigDisagreeLevel1effect: {
    backgroundImage: "url(/glass/big/bigDisagreeLevel1.png)",
  },
  middleDisagreeLevel1effect: {
    backgroundImage: "url(/glass/middle/middleDisagreeLevel1.png)",
  },
  smallDisagreeLevel1effect: {
    backgroundImage: "url(/glass/small/smallDisagreeLevel1.png)",
  },
  bigDisagreeLevel2effect: {
    backgroundImage: "url(/glass/big/bigDisagreeLevel2.png)",
  },
  middleDisagreeLevel2effect: {
    backgroundImage: "url(/glass/middle/middleDisagreeLevel2.png)",
  },
  smallDisagreeLevel2effect: {
    backgroundImage: "url(/glass/small/smallDisagreeLevel2.png)",
  },
  bigAgreeLevel1effect: {
    backgroundImage: "url(/glass/big/bigAgreeLevel1.png)",
  },
  middleAgreeLevel1effect: {
    backgroundImage: "url(/glass/middle/middleAgreeLevel1.png)",
  },
  smallAgreeLevel1effect: {
    backgroundImage: "url(/glass/small/smallAgreeLevel1.png)",
  },
  expimg: {
    width: "100%",
    height: "100%",
    //指定された枠に全体を表示させるようにするので下記のようになる
    objectFit: "contain",
  },
  upload: {
    width: "60vw",
    height: "80vh",
    overflow: "auto",
    marginLeft: "20vw",
    marginTop: "10vh",
    backgroundColor: "#FFFFFF",
  },
  // ログイン中のアイコンのスタイル
  loginicon: {
    width: "50px" /*任意のサイズ*/,
    height: "50px",
    borderRadius: "50% 50%",
    border: "solid 1px skyblue",
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

import { useRef } from "react";
import {
  Box,
  CircularProgress,
  createTheme,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";
import Battery80Icon from "@mui/icons-material/Battery80";
import React from "react";
//import styled from 'styled-components'
import styled from "styled-components";

import rankicon from "../../images/rankicon.png";

import Compressor from "compressorjs";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { firebaseApp, userApp } from "../../common/firebase";
import { updateLonpa } from "../../common/Helper/FirebaseHelper";

const CircularInternalContent = styled.div`
  left: 0;
  top: 0px;
  bottom: 0;
  right: 0;
  position: absolute;
`;

const ProbabilitySuffix = styled(Typography)`
  margin-bottom: 4px;
`;

const StyledCircularBackground = styled(CircularProgress)`
  color: #ffffff;
`;

const StyledCircularBar = styled(CircularProgress)`
  position: absolute;
  color: #ffffff;
`;

export default function LonpaPieChart(props) {
  const inputRef = useRef(null);
  const value = 29;
  const chartcolors = { 0: "#757ce8", 1: "#ff7961" };
  const fileUpload = () => {
    inputRef.current.click();
  };
  //uploadできる画像のバイト数のリミット
  const limitUpdateByte = 300000;

  async function onSubmit(event) {
    event.preventDefault();
    if (!props.createrFlag && !props.adminFlag) {
      console.log("変更権限がありません");
      return;
    }
    if (typeof event.target.files[0] === "undefined") {
      console.log("ファイルが選択されていません");
      return;
    }
    props.setOpen(true);
    let avatarImage = event.target.files[0];
    //console.log(avatarImage);
    //画像を圧縮
    new Compressor(avatarImage, {
      quality: 0.6,
      success(result) {
        const image = result;
        if (image.size < limitUpdateByte) {
          //imgnameの名前づけ(uid+imgname)
          let imgname = Date.now();
          let uid = getAuth(userApp).currentUser.uid;
          const storage = getStorage(firebaseApp);
          //console.log(props.lonpaid);
          const storageRef = ref(
            storage,
            "lonpa/" + props.lonpaid + "/image/" + uid + imgname
          ); //どのフォルダの配下に入れるかを設定
          const uploadTask = uploadBytesResumable(storageRef, image); //ファイル名
          //ファイルをアップロードする
          uploadTask.on("state_changed", () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              const f = async () => {
                await updateLonpa(props.lonpaid, {
                  imageurl: downloadURL,
                });
                //console.log("ここから始まりそう");
                props.setimageurl(downloadURL);
                props.setOpen(false);
              };
              f();
            });
          });
        } else {
          alert("容量が大きすぎます。やり直してください");
          props.setOpen(false);
        }
      },
      error(err) {
        console.log(err);
        return;
      },
    });
  }
  return (
    <Box position="relative" display="inline-flex">
      <CircularInternalContent>
        <Grid container justify="center">
          {props.imageurl ? (
            <>
              <Avatar
                onClick={() => {
                  //console.log(props.createrFlag);
                  if (props.createrFlag || props.adminFlag) {
                    fileUpload();
                  }
                }}
                src={props.imageurl}
                sx={{
                  height: 80,
                  width: 80,
                }}
              />
              <input
                hidden
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={onSubmit}
              />
            </>
          ) : (
            <>
              <Avatar
                onClick={() => {
                  //console.log(props.createrFlag);
                  if (props.createrFlag || props.adminFlag) {
                    fileUpload();
                  }
                }}
                src={props.src}
                sx={{
                  height: 80,
                  width: 80,
                }}
              />
              <input
                hidden
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={onSubmit}
              />
            </>
          )}
        </Grid>
      </CircularInternalContent>
      {isNaN(props.disagreeratio) ? (
        <>
          {" "}
          {/* 背景用のCircularProgress */}
          <StyledCircularBackground
            variant="determinate"
            size={80}
            value={100}
            style={{ color: "gray", pointerEvents: "none" }}
          />
        </>
      ) : (
        <>
          {" "}
          {/* 背景用のCircularProgress */}
          <StyledCircularBackground
            variant="determinate"
            size={80}
            value={100}
            style={{ color: chartcolors[0], pointerEvents: "none" }}
          />
          {/* バロメーター用のCircularProgress */}
          <StyledCircularBar
            variant="determinate"
            size={80}
            value={props.disagreeratio}
            style={{ color: chartcolors[1], pointerEvents: "none" }}
          />
        </>
      )}
    </Box>
  );
}

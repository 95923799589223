import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  IconButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getAuth, updateProfile } from "firebase/auth";
import React, { useState } from "react";

type Props = {
  bgColor: any;
  genko: any;
  setGenko: any;
  remakeCountIncriment: any;
  setUploadChangeGenkoFlag: any;
};

const GenkoChanger: React.VFC<Props> = ({
  bgColor,
  genko,
  setGenko,
  remakeCountIncriment,
  setUploadChangeGenkoFlag,
}: Props) => {
  const changeGenkoVocal = () => {
    const nextgenko = genko;

    nextgenko.map((item: any, index: any) => {
      if (item.vocal == "reimu") {
        // console.log(index + " : 霊夢" + item.vocal);
        nextgenko[index].vocal = "youmu";
      } else if (item.vocal == "marisa") {
        // console.log(index + " : 魔理沙" + item.vocal);
        nextgenko[index].vocal = "reimu";
      } else if (item.vocal == "youmu") {
        // console.log(index + " : 妖夢" + item.vocal);
      }
    });
    console.log(nextgenko);

    setGenko(nextgenko);
    remakeCountIncriment(0, "", "", "remake");
    setUploadChangeGenkoFlag(true);
  };

  return (
    <>
      <br />
      <Button
        style={{
          color: bgColor.text01.fontColor,
          backgroundColor: bgColor.back06,
        }}
        onClick={() => {
          changeGenkoVocal();
        }}
      >
        危険：変換ボタン(reimu→youmu / marisa→reimu)
      </Button>
    </>
  );
};

export default GenkoChanger;

import { getAuth } from "firebase/auth";
import { doc, getFirestore, runTransaction, getDoc } from "firebase/firestore";
import moment from "moment";
import { useCookies } from "react-cookie";

import { firebaseApp, userApp } from "../firebase";

async function makeTransactionID(transaction: any, db: any, lonpaid: string) {
  //今回のlonpa更新のために、トランザクション番号を発行
  const sfDocRef = doc(db, "lonpa", lonpaid);
  const sfDoc = await transaction.get(sfDocRef);
  if (!sfDoc.exists()) {
    throw "Document does not exist!";
  }
  let transactionid;

  if (typeof sfDoc.data().transaction === "undefined") {
    transactionid = 1;
  } else {
    transactionid = sfDoc.data().transaction + 1;
  }
  return transactionid;
}

export async function transactionPushDiscussLon(
  data: any,
  increment: boolean,
  user: any,
  lonpaid: string,
  serverTimestamp: any,
  excutetimestring: string,
  infoclaim: string,
  excutetime: Date,
  cookies: any
) {
  try {
    // =1==============================================================================================
    //DBの情報を取得
    const db = getFirestore(firebaseApp);
    const userAppDB = getFirestore(userApp);

    //Dataのデータをstring型に変換する関数を定義
    const parseAsMoment = (dateTimeStr: any) => {
      return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
    };

    let changeset = { VoteChange: false, UserChange: false };
    //ログインユーザ自身の投票状態を取得
    const votedData: any = await checkSelfVote(userAppDB, lonpaid, cookies);

    //checkSelfVoteで取得するデータ
    const infoischoiced: boolean = votedData.infoischoiced;
    const infochoiceditemid: string = votedData.infochoiceditemid;
    const infochoiceditemisAgree: boolean = votedData.infochoiceditemisAgree;

    //トランザクション開始
    await runTransaction(db, async (transaction) => {
      //現在日付をDate型で取得
      const excutetime2: Date = new Date();
      //引数呼び出しタイミングとの時間乖離を確認
      const excutetime3: number = excutetime2.getTime() - excutetime.getTime();
      //乖離が大きい場合は処理スキップ． なぜかログアウト時に大量発生するバクがあるため，必須処理
      if (excutetime3 > 500) {
        console.log(excutetime3);
        const checktimestring1 = parseAsMoment(excutetime)
          .format("YYYYMMDDHHmmss")
          .toString();
        const checktimestring2 = parseAsMoment(excutetime2)
          .format("YYYYMMDDHHmmss")
          .toString();
        console.log(checktimestring1 + "/" + checktimestring2);
        throw "Too Long Span";
      }

      //firebaseのトランザクションは、複数プロジェクトをまたがることができないので、user設定は先に取得する

      ///////console.log("トランザクションIDを発行")///////
      const transactionid = await makeTransactionID(transaction, db, lonpaid);

      // =2==============================================================================================
      // ここから投票状況の収集が開始（Lonpaのデータを集める）
      // console.log("step2");

      // console.log(infoischoiced,infochoiceditemid)

      //Lonpaの現在の投票状態を取得
      const sumData: any = await checkLonpaVote(transaction, db, lonpaid);

      //checkLonpaVoteで取得するデータ
      const infoagreesum: number = sumData.infoagreesum;
      const infodisagreesum: number = sumData.infodisagreesum;

      //親の親Lonpaがいる場合の処理
      //今回は居ない想定
      const pinfo: any = [];

      // =3==============================================================================================
      //console.log("step3"); /////// 更新する論拠（未投票→投票・投票→未投票）の現在の数値取得

      // console.log(data);
      const [votenum, votednum] = await getChoicedVoteNum(
        transaction,
        db,
        lonpaid,
        data.lonpaid,
        infoischoiced,
        infochoiceditemid
      );

      // console.log(votenum, votednum);

      // 下記判定があれば−１が入るので、そこがどうなっているか確認したい
      // infoischoiced && increment && typeof infochoiceditemid !== "undefined"

      ////////console.log("処理内容を作成する（firebaseへの処理は行わない）")////////
      changeset = await makeChangeset(
        data.lonpaid,
        increment,
        user,
        lonpaid,
        serverTimestamp,
        excutetimestring,
        infoagreesum,
        infodisagreesum,
        pinfo,
        votednum,
        votenum,

        infoischoiced,
        infochoiceditemid,
        infoclaim,
        infochoiceditemisAgree,

        data.claim,
        data.agree
      );

      //更新処理一覧を表示
      // console.log(changeset);

      // =4==============================================================================================
      ////////console.log("処理内容に従い、処理を実行する")////////
      if (changeset?.VoteChange) {
        await executeDBChangeVote(
          transaction,
          db,
          changeset.VoteChange,
          lonpaid,
          transactionid
        );
      }
    });

    if (changeset?.UserChange) {
      //トランザクション開始
      await runTransaction(userAppDB, async (transaction) => {
        await executeDBChangeUser(transaction, userAppDB, changeset.UserChange);
      });
    }

    return true;
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false;
  }
}

async function getChoicedVoteNum(
  transaction: any,
  db: any,
  lonpaid: string,
  voteid: string,
  infoischoiced: boolean,
  choiceditemid: string
): Promise<any> {
  // console.log(voteid);

  //投票先の数字を確認する
  const sfDocRef1 = doc(db, "lonpa", lonpaid, "child", voteid);
  const sfDoc1 = await transaction.get(sfDocRef1);
  if (!sfDoc1.exists()) {
    throw "Doc1  Document does not exist!";
  }
  //投票先の投票前の数値
  const votenum = sfDoc1.data().votenum;

  //投票ボタン押下前に投票されていたアイテムの数値を確認する。
  let votednum;
  if (infoischoiced && choiceditemid) {
    //投票済み→未投票になる要素
    const sfDocRef2 = doc(db, "lonpa", lonpaid, "child", choiceditemid);
    const sfDoc2 = await transaction.get(sfDocRef2);
    if (!sfDoc2.exists()) {
      throw "Doc2  Document does not exist!";
    }
    //元投票先の変更前の数値
    votednum = sfDoc2.data().votenum;
  } else {
    //元投票先が存在しない場合
    votednum = undefined;
  }

  return [votenum, votednum];
}

async function makeChangeset(
  id: string,
  //   data: any,
  increment: boolean,
  user: any,
  lonpaid: string,
  serverTimestamp: any,
  excutetimestring: string,
  //   info: any,
  infoagreesum: any,
  infodisagreesum: any,
  pinfo: any,
  votednum: number,
  votenum: number,

  infoischoiced: boolean,
  infochoiceditemid: string,
  infoclaim: string,
  infochoiceditemisAgree: boolean,

  dataclaim: string,
  dataagree: boolean
): Promise<any> {
  console.log("start changeset");
  const changesetVote: any = {};
  const changesetUser: any = {};

  //ユーザ認証済みの場合，DBのデータをもとに投票調整
  let choiceid;
  // 今回選択されたアイテムの次の数値 画面表示は「data.votenum」だが、取り直したもの「votenum」を利用
  let newnum;
  // 選択解除されるアイテムの次の数値 画面表示は「info.choiceditem.votenum」だが、取り直したもの「votednum」を利用
  let newnum2;

  //////  今回選択されたアイテム、今回選択解除されるアイテムの次の数値を決定  ///////
  if (increment) {
    //未選択 → 選択の場合
    choiceid = id;
    newnum = votenum + 1;
    newnum2 = votednum - 1;
  } else {
    //選択済 → 未選択の場合
    choiceid = "";
    newnum = votenum - 1;
  }

  //////  今回選択されたアイテムの更新 ///////
  //親:lonpaid 投票先:id 数値:newnum
  changesetVote.updateLonpaChild = {
    target: { id1: lonpaid, id2: id },
    data: { votenum: newnum },
  };

  //////  今回選択解除されるアイテムの更新 ///////
  if (infoischoiced && increment && typeof infochoiceditemid !== "undefined") {
    //lonpaの論拠への投票数を更新する,押下前に選択していた要素からマイナス１する
    changesetVote.updateLonpaChild2 = {
      target: { id1: lonpaid, id2: infochoiceditemid },
      data: { votenum: newnum2 },
    };
  }

  ////// 記名投票における投票情報の更新  ///////
  if (user) {
    const uid = getAuth(userApp).currentUser?.uid;
    if (increment) {
      //vote情報を追加する
      changesetVote.setLonpaChildVote = {
        target: { id1: lonpaid, id2: id, id3: uid },
        data: { votedAt: serverTimestamp },
      };

      //ユーザが，どのlonpaで，どの要因に投票したか記録する
      changesetUser.setUserPrivateVote = {
        target: { id1: lonpaid },
        data: {
          votedLonpaId: choiceid,
          votedClaim: dataclaim,
          claim: infoclaim,
          isAgree: dataagree,
          votedAt: serverTimestamp,
        },
      };

      changesetVote.setVote = {
        target: { id1: excutetimestring },
        data: {
          votedLonpaId: choiceid,
          votedClaim: dataclaim,
          claim: infoclaim,
          isAgree: dataagree,
          votedAt: serverTimestamp,
          uid: uid,
          votedParentLonpaId: lonpaid,
        },
      };

      //////  今回選択解除されるアイテムから、自身の投票名を削除 ///////
      if (infoischoiced && typeof infochoiceditemid !== "undefined") {
        changesetVote.DeleteLonpaChildVote = {
          target: {
            id1: lonpaid,
            id2: infochoiceditemid,
            id3: uid,
          },
          data: {},
        };
      }
    } else {
      //vote情報を削除する
      changesetVote.DeleteLonpaChildVote = {
        target: { id1: lonpaid, id2: id, id3: uid },
        data: {},
      };

      //ユーザが，どのlonpaで，どの要因に投票したか記録する
      changesetVote.deleteUserPrivateVote = {
        target: { id1: lonpaid },
        data: {},
      };
    }
  } else {
    //ログインしていない場合は
    //LonpaChildVoteには情報を追加・削除しない
    //UserPrivateVoteにも情報を追加・削除しない
    //Voteにも情報を追加しない
  }

  //■■■押下された論拠以外の更新１ 親Lonpa更新
  //論拠に投票が入り，賛成と反対のバランスが変わる場合
  //Lonpaのさらに親のカードステータスを変えに行く
  let agreenum = infoagreesum;
  let disagreenum = infodisagreesum;
  if (dataagree) {
    if (increment) {
      agreenum = agreenum + 1;
      if (infoischoiced) {
        if (!infochoiceditemisAgree) {
          disagreenum = disagreenum - 1;
        }
      }
    } else {
      agreenum = agreenum - 1;
    }
  } else {
    if (increment) {
      disagreenum = disagreenum + 1;
      if (infoischoiced) {
        if (infochoiceditemisAgree) {
          agreenum = agreenum - 1;
        }
      }
    } else {
      disagreenum = disagreenum - 1;
    }
  }

  let oppositionStatus = "balanced";
  if (agreenum > disagreenum) {
    oppositionStatus = "agreeLevel1";
  } else if (agreenum < disagreenum) {
    oppositionStatus = "disagreeLevel1";
  }

  if (pinfo[0] !== undefined) {
    // 一番上の階層のLonpa以外の場合、親要素にoppositionStatusを設定
    changesetVote.setLonpaChild = {
      target: { id1: pinfo[0], id2: lonpaid },
      data: { oppositionStatus: oppositionStatus },
    };
  }

  return { VoteChange: changesetVote, UserChange: changesetUser };
}

async function getVotedData(
  user: any,
  lonpaid: string,
  doc2: any,
  cookies: any
): Promise<any> {
  let choice = {
    islogin: false,
    ischoiced: false,
    choiceditem: {
      id: "",
      votenum: 0,
      isAgree: false,
      votedClaim: "",
    },
  };
  if (user) {
    //ログイン中のアカウントで，選択中のlonpa一覧
    if (doc2) {
      if (doc2.data()) {
        choice = {
          islogin: true, //ログインしていて
          ischoiced: true, //投票していて
          choiceditem: {
            id: doc2.data().votedLonpaId,
            votenum: 0,
            isAgree: doc2.data().isAgree,
            votedClaim: doc2.data().votedClaim,
          }, //投票先はここ
        };
      } else {
        choice = {
          islogin: true, //ログインしていて
          ischoiced: false, //投票していない
          choiceditem: {
            id: "",
            votenum: 0,
            isAgree: false,
            votedClaim: "",
          },
        };
      }
    } else {
      choice = {
        islogin: true, //ログインしていて
        ischoiced: false, //投票していない
        choiceditem: {
          id: "",
          votenum: 0,
          isAgree: false,
          votedClaim: "",
        },
      };
    }
    return {
      infoischoiced: choice.ischoiced,
      infochoiceditemid: choice.choiceditem.id,
      infochoiceditemisAgree: choice.choiceditem.isAgree,
    };
  } else {
    //console.log("最初の情報取得 未ログイン状態");
    //ログインしていない場合は{ id: id, isAgree: data.agree }形式

    if (cookies[lonpaid] === undefined) {
      choice = {
        islogin: false, //ログインしていない
        ischoiced: false, //投票していない
        choiceditem: {
          id: "",
          votenum: 0,
          isAgree: false,
          votedClaim: "",
        },
      };
    } else {
      choice = {
        islogin: false, //ログインしていない
        ischoiced: true, //投票していて
        choiceditem: {
          id: cookies[lonpaid].id,
          votenum: 0,
          isAgree: cookies[lonpaid].isAgree,
          votedClaim: cookies[lonpaid].votedClaim,
        }, //投票先はここ
      };
    }
    return {
      infoischoiced: false,
      infochoiceditemid: "none",
      infochoiceditemisAgree: true,
    };
  }
}

async function checkSelfVote(
  // transaction: any,
  // db: any,
  userAppDB: any,
  lonpaid: string,
  cookies: any
) {
  if (getAuth(userApp).currentUser) {
    //クエリを作成
    const sfDocsetUserPrivateVoteRef = doc(
      userAppDB,
      "userprivate",
      getAuth(userApp).currentUser?.uid ?? "UIDなし",
      "vote",
      lonpaid
    );
    //データ取得
    const sfDocsetUserPrivateVote = await getDoc(sfDocsetUserPrivateVoteRef);

    if (!sfDocsetUserPrivateVote.exists()) {
      //取得できなくても
      //そのまま取得データを加工処理に回す
      return getVotedData(
        getAuth(userApp).currentUser?.uid,
        lonpaid,
        sfDocsetUserPrivateVote,
        cookies
      );
    } else {
      // console.log('this user voted')
      //取得できても
      //そのまま取得データ加工処理に回す
      return getVotedData(
        getAuth(userApp).currentUser?.uid,
        lonpaid,
        sfDocsetUserPrivateVote,
        cookies
      );
    }
  } else {
    return getVotedData(false, lonpaid, "UserPrivateVoteなし", cookies);
  }
  return false;
}

async function checkLonpaVote(transaction: any, db: any, lonpaid: string) {
  //Helper2作成にあたり、新規作成した関数
  //取得するデータ
  //infoagreesum;
  //infodisagreesum;

  // console.log(lonpaid);
  //クエリ作成
  const sfDocsetxxxRef = doc(db, "lonpa", lonpaid);
  //データ取得
  const sfDocsetxxx = await transaction.get(sfDocsetxxxRef);
  if (!sfDocsetxxx.exists()) {
    // console.log(sfDocsetxxx);
    //修正が必要です、正しい値を入れてください。
    return { infoagreesum: 0, infodisagreesum: 0 };
  } else {
    // console.log(sfDocsetxxx.data());
    return {
      infoagreesum: sfDocsetxxx.data().agree,
      infodisagreesum: sfDocsetxxx.data().disagree,
    };
  }
  return false;
}

async function executeDBChangeVote(
  transaction: any,
  db: any,
  changeset: any,
  lonpaid: string,
  transactionid: string
) {
  ////////console.log("DeleteLonpaChildVote");
  if ("DeleteLonpaChildVote" in changeset) {
    const sfDocDeleteLonpaChildVoteRef = doc(
      db,
      "lonpa",
      changeset.DeleteLonpaChildVote.target.id1,
      "child",
      changeset.DeleteLonpaChildVote.target.id2,
      "vote",
      changeset.DeleteLonpaChildVote.target.id3
    );

    transaction.delete(sfDocDeleteLonpaChildVoteRef);
  }

  ////////console.log("setVote");
  if ("setVote" in changeset) {
    const sfDocsetVoteRef = doc(db, "vote", changeset.setVote.target.id1);
    transaction.set(sfDocsetVoteRef, changeset.setVote.data);
  }

  ////////console.log("setLonpaChildVote");
  if ("setLonpaChildVote" in changeset) {
    const sfDocsetLonpaChildVoteRef = doc(
      db,
      "lonpa",
      changeset.setLonpaChildVote.target.id1,
      "child",
      changeset.setLonpaChildVote.target.id2,
      "vote",
      changeset.setLonpaChildVote.target.id3
    );
    transaction.set(
      sfDocsetLonpaChildVoteRef,
      changeset.setLonpaChildVote.data,
      { merge: true }
    );
  }

  ////////console.log("setLonpaChild");
  if ("setLonpaChild" in changeset) {
    const sfDocsetLonpaChildRef = doc(
      db,
      "lonpa",
      changeset.setLonpaChild.target.id1,
      "child",
      changeset.setLonpaChild.target.id2
    );
    transaction.set(sfDocsetLonpaChildRef, changeset.setLonpaChild.data, {
      merge: true,
    });
  }

  ////////console.log("updateLonpaChild");
  if ("updateLonpaChild" in changeset) {
    const sfDocupdateLonpaChildRef = doc(
      db,
      "lonpa",
      changeset.updateLonpaChild.target.id1,
      "child",
      changeset.updateLonpaChild.target.id2
    );
    transaction.update(
      sfDocupdateLonpaChildRef,
      changeset.updateLonpaChild.data
    );
  }

  ////////console.log("updateLonpaChild2");
  if ("updateLonpaChild2" in changeset) {
    const sfDocupdateLonpaChild2Ref = doc(
      db,
      "lonpa",
      changeset.updateLonpaChild2.target.id1,
      "child",
      changeset.updateLonpaChild2.target.id2
    );
    transaction.update(
      sfDocupdateLonpaChild2Ref,
      changeset.updateLonpaChild2.data
    );
  }

  ////////console.log("lonpaUpdate");
  const sfDocRef = doc(db, "lonpa", lonpaid);
  transaction.update(sfDocRef, { transaction: transactionid });

  ////////console.log("finish");
}

async function executeDBChangeUser(
  transaction: any,
  userAppDB: any,
  changeset: any
  // lonpaid: string,
  // transactionid: string
) {
  console.log(changeset);
  ////////console.log("setUserPrivateVote");
  if ("setUserPrivateVote" in changeset) {
    const sfDocsetUserPrivateVoteRef = doc(
      userAppDB,
      "userprivate",
      getAuth(userApp).currentUser?.uid ?? "UIDなし",
      "vote",
      changeset.setUserPrivateVote.target.id1
    );

    transaction.set(
      sfDocsetUserPrivateVoteRef,
      changeset.setUserPrivateVote.data,
      { merge: true }
    );
  }

  ////////console.log("deleteUserPrivateVote");
  if ("deleteUserPrivateVote" in changeset) {
    const sfDocdeleteUserPrivateVoteRef = doc(
      userAppDB,
      "userprivate",
      getAuth(userApp).currentUser?.uid ?? "UIDなし",
      "vote",
      changeset.deleteUserPrivateVote.target.id1
    );
    transaction.delete(sfDocdeleteUserPrivateVoteRef);
  }
}

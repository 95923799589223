import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useStyles } from "../../../Styles";
import { Chip, Button, TextField, Typography, IconButton } from "@mui/material";
import BorderColor from "@mui/icons-material/BorderColor";
import * as yup from "yup";

import {
  updateLonpa,
  getLonpa,
  updateTagNum,
} from "../../../common/Helper/FirebaseHelper";
import { useNavigate, useParams } from "react-router-dom";
import {
  VALIDATION_ERR_REQUIRED,
  VALIDAtION_INVALID_CHARACTER,
  VALIDATION_LONG_MAX_LENGTH,
} from "../../../constants/validation";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TAG_CHARACTER } from "../../../constants/regex";

import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import PointPictureModal from "./LonpaPictureModal/PointPictureModal";
import { getFunctions, httpsCallable } from "firebase/functions";

import LodingModal from "../../CommonModal/LodingModal";

const schema = yup.object({
  tagName: yup
    .string()
    .required(VALIDATION_ERR_REQUIRED)
    .max(32, VALIDATION_LONG_MAX_LENGTH)
    .matches(TAG_CHARACTER, VALIDAtION_INVALID_CHARACTER),
});

export default function EditTag(props) {
  let { lonpaid } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [addTag, setaddTag] = useState("");
  const [isPointCheck, setIsPointCheck] = useState(false);
  const [pointPattarn, setPointPattarn] = useState(1);
  const [oneTextShow, setOneTextShow] = useState("");
  const [twoTextShow, setTwoTextShow] = useState("");

  const [lodingmodalopen, setLodingmodalopen] = useState(false);

  const [isCreatedTag, setIsCreatedTag] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //コメント追加したり、他人が追加したりした場合に最新のコメントにスクロール
  useEffect(() => {
    console.log("uE_EditTagModal");
    const f = async () => {
      if (props.tagCreate) {
        await createTag();
      }
    };
    f();
  }, []);

  const handleCloseinside = () => {
    props.setOpen(false);
  };

  const createTagdata = async (message) => {
    try {
      const trimmessage = message
        .split("\n")
        .join("")
        .split("。")
        .join("")
        .split("(")
        .join("")
        .split(")")
        .join("")
        .trim(); // 整形
      let tagresult = trimmessage.split("- "); // 「,」 カンマ区切りで分割して配列に格納
      tagresult.splice(0, 1);

      for (let item of tagresult) {
        await Post(item);
      }
      props.setUpdate(props.update + 1);
      setLodingmodalopen(false);

      if (props.tagCreate) {
        props.setAutoCreateTagCreateFalse();
        props.setOpen(false);
      }
    } catch (error) {
      alert(
        error +
          "\n文字列が想定した形式でないようです\n一回の実行に数百円かかるので、二度と押さないでください"
      );
    }
  };

  const createTag = async () => {
    setIsCreatedTag(true);
    setLodingmodalopen(true);
    const chatGPTMessage =
      "「" +
      props.claim +
      "」" +
      "という議題対して\n 箇条書きで3個タグを作成してください\n\n" +
      "1つのタグは タグを30文字以内\n" +
      "タグは文章ではなく単語であること\n" +
      "複合名詞ではなく、短い名詞が望ましい\n" +
      "文章中にある名詞を用いることが望ましい\n\n" +
      "ただし、フォーマットは下記としてください\n" +
      "【3つのタグ】- (タグ１)- (タグ２)- (タグ３) \n\n" +
      "(タグ)は【】では囲わないでください\n";
    setOneTextShow(chatGPTMessage);

    const functions = getFunctions();
    const messageCreate = httpsCallable(functions, "messageCreate");
    messageCreate({ message: chatGPTMessage }).then((result) => {
      //console.log(result);
      // 回答の取得
      setTwoTextShow(result.data.aimessage);
      console.log(result.data.aimessage);
      createTagdata(result.data.aimessage);
    });
  };

  const Post = async (message) => {
    const doc = await getLonpa(lonpaid);
    const PreTagList = doc.data().tag;
    if (
      typeof PreTagList === "undefined" ||
      !Object.keys(PreTagList).includes(message)
    ) {
      const AftTagList = {
        ...PreTagList,
        [message]: true,
      };
      await updateLonpa(lonpaid, {
        tag: AftTagList,
      });
      await updateTagNum(message);
    }

    if (addTag) {
      props.setUpdate(props.update + 1);
    }

    await setaddTag("");
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleCloseinside}
        closeAfterTransition
      >
        {isPointCheck ? (
          <>
            <Fade in={props.open}>
              <div className={classes.paper}>
                <PointPictureModal
                  setIsPointCheck={setIsPointCheck}
                  pointPattarn={pointPattarn}
                  handleCloseinside={handleCloseinside}
                  lonpaAutoMake={props.lonpaAutoMake}
                  text={props.claim}
                  id={props.id}
                  createTag={createTag}
                />
              </div>
            </Fade>
          </>
        ) : (
          <>
            <Fade in={props.open}>
              <div className={classes.tagmodal}>
                <div className={classes.taglist}>
                  <h1>
                    Tag List
                    {}
                    {props.adminFlag && props.tagList[0].length == 0 ? (
                      <>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          disabled={isCreatedTag}
                          onClick={() => {
                            setIsPointCheck(true);
                            setPointPattarn(4);
                          }}
                        >
                          <HdrAutoIcon fontSize="inherit" />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </h1>

                  <h6>制限：Tagは32文字以下</h6>

                  {/* デバッグ用
                   {oneTextShow}
                  <br />
                  {twoTextShow}
                  <br />  */}

                  {props.tagList.map((data) => {
                    if (data != "") {
                      return (
                        <Chip
                          key={data}
                          label={"#" + data}
                          onClick={() => {
                            navigate("/NewSearch/tag=" + data);
                          }}
                        />
                      );
                    } else {
                      return <Chip key="" label="なし" />;
                    }
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Controller
                      control={control}
                      name="tagName"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          style={{ width: "50vw", bottom: 0 }}
                          variant="outlined"
                          multiline
                          maxRows={1}
                          label="add tag"
                          id="standard-required"
                          value={addTag}
                          inputProps={{ style: { fontSize: 20 } }} // font size of input text
                          onChange={(e) => {
                            onChange(e);
                            setaddTag(e.target.value);
                          }}
                        />
                      )}
                    />
                    <Button
                      color="primary"
                      endIcon={<BorderColor />}
                      style={{ flex: 1, fontSize: 20, bottom: 0 }}
                      onClick={() => {
                        handleSubmit(Post(addTag));
                      }}
                      disabled={addTag.length == 0 || addTag.length > 33}
                    ></Button>
                  </div>
                  <Typography>{errors.tagName?.message}</Typography>
                </div>
              </div>
            </Fade>
          </>
        )}
      </Modal>
      <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
    </div>
  );
}

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Avatar } from "@mui/material";
import { useStyles } from "../../../Styles";

export default function VoteListModal(props) {
  const classes = useStyles();

  const handleCloseinside = () => {
    props.setOpen(false);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">
            「{props.item.data.claim}」の投票した人一覧です
          </h2>
          {props.item.votedatas.map((data, index) => (
            <div key={data.user}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Avatar alt="avatar" src={data.imageurl} />
                    </td>
                    <td>{data.user}</td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
          ))}
          <br />
          <div id="transition-modal-description"></div>
        </div>
      </Fade>
    </Modal>
  );
}

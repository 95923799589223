import { useStyles } from "../../Styles";

import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Checkbox,
} from "@mui/material";

import PieChart from "./PieChart";
import rankicon from "../../images/rankicon.png";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function LoadingTopRank(props) {
  const classes = useStyles();

  const idealodingRank = [
    {
      id: "loading1",
      claim: "読み込み中",
      rank: 1,
      numberOfVote: 0,
      agree: 0,
      disagree: 0,
      imageurl: "",
    },
    {
      id: "loading2",
      claim: "読み込み中",
      rank: 2,
      numberOfVote: 0,
      agree: 0,
      disagree: 2,
      imageurl: "",
    },
    {
      id: "loading3",
      claim: "読み込み中",
      rank: 3,
      numberOfVote: 0,
      agree: 1,
      disagree: 2,
      imageurl: "",
    },
    {
      id: "loading4",
      claim: "読み込み中",
      rank: 4,
      numberOfVote: 0,
      agree: 1,
      disagree: 2,
      imageurl: "",
    },
    {
      id: "loading5",
      claim: "読み込み中",
      rank: 5,
      numberOfVote: 0,
      agree: 1,
      disagree: 2,
      imageurl: "",
    },
  ];

  return (
    <>
      <div style={{ height: "2vh" }}></div>
      <Typography
        color={props.bgColor.text06.fontColor}
        gutterBottom
        variant="h6"
        fontWeight="bold"
      >
        <Checkbox
          icon={<CircleIcon />}
          checkedIcon={<CheckCircleIcon />}
          disabled
          style={{
            color: props.bgColor.text06.fontColor,
          }}
        />
        ランキング(毎日更新)
      </Typography>

      <List sx={{ width: "100%", backgroundColor: props.bgColor.back01 }}>
        {idealodingRank.map((idea, index) => (
          <button
            className={classes.indicatorButtonwhite}
            key={idea.id}
            style={{
              backgroundColor: props.bgColor.back01,
              color: props.bgColor.text04.fontColor,
              border: props.bgColor.line01,
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <PieChart
                      imageurl={idea.imageurl}
                      src={rankicon}
                      disagreeratio={NaN}
                    />
                    &nbsp;&nbsp;
                  </td>

                  <td>
                    <div align="left">
                      <a
                        style={{
                          backgroundColor: props.bgColor.back06,
                          textsize: props.bgColor.text04.fontsize,
                        }}
                      >
                        {idea.rank}位
                      </a>
                    </div>
                    <div>
                      <ListItem
                        alignItems="flex-start"
                        style={{ width: "100%", padding: "0px 0px 0px 0px" }}
                      >
                        <ListItemText
                          primary={
                            <div
                              style={{
                                fontSize: 15,
                                fontWeight: "bold",
                                width: "100%",
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                              }}
                            >
                              {idea.claim}
                            </div>
                          }
                        />
                      </ListItem>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </button>
        ))}
      </List>
    </>
  );
}

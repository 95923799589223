import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
//import styled from 'styled-components'
import styled from "styled-components";

const CircularInternalContent = styled.div`
  left: 0;
  top: 0px;
  bottom: 0;
  right: 0;
  position: absolute;
`;

const ProbabilitySuffix = styled(Typography)`
  margin-bottom: 4px;
`;

const StyledCircularBackground = styled(CircularProgress)`
  color: #ffffff;
`;

const StyledCircularBar = styled(CircularProgress)`
  position: absolute;
  color: #ffffff;
`;

export default function PieChart(props) {
  const chartcolors = { 0: "#757ce8", 1: "#ff7961" };
  return (
    <Box position="relative" display="inline-flex">
      <CircularInternalContent>
        <Grid container justify="center">
          {props.imageurl ? (
            <img
              src={props.imageurl}
              alt="アイコン"
              width="50px"
              height="50px"
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <img
              src={props.src}
              alt="アイコン"
              width="50px"
              height="50px"
              style={{ borderRadius: "50%" }}
            />
          )}
        </Grid>
      </CircularInternalContent>
      {isNaN(props.disagreeratio) ? (
        <>
          {" "}
          {/* 背景用のCircularProgress */}
          <StyledCircularBackground
            variant="determinate"
            size={50}
            value={100}
            style={{ color: "gray" }}
          />
        </>
      ) : (
        <>
          {" "}
          {/* 背景用のCircularProgress */}
          <StyledCircularBackground
            variant="determinate"
            size={50}
            value={100}
            style={{ color: chartcolors[0] }}
          />
          {/* バロメーター用のCircularProgress */}
          <StyledCircularBar
            variant="determinate"
            size={50}
            value={props.disagreeratio}
            style={{ color: chartcolors[1] }}
          />
        </>
      )}
    </Box>
  );
}

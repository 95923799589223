import { AddAPhoto } from "@mui/icons-material";
import React from "react";
import styled, { keyframes } from "styled-components";

// @keyframes を使用して上にスクロールするアニメーションを定義
const scrollUpAnimation = keyframes`
0%, 20% { /* アニメーションの最初の20%の間、28%の位置に待機 */
transform: translateY(26%);
}80%{
  transform: translateY(-10%);
}90%{
  transform: translateY(7%);
}100%{
  stransform: translateY(0%);
}
`;

// アニメーションを適用するスタイルドコンポーネント
const AnimatedImage = styled.img`
  animation: ${scrollUpAnimation} 0.3s linear 1;
`;

type Props = {
  img1: any;
};

const ImageUp: React.VFC<Props> = ({ img1 }: Props) => {
  return (
    <AnimatedImage
      style={{
        width: "100%", // この変更により、画像はtdの幅に合わせて表示されます
      }}
      src={img1}
      alt="Scrolling Up"
    />
  );
};

export default ImageUp;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../Styles";
import Ranking from "./Top/Ranking";
import LatestUpdate from "./Top/LatestUpdate";
import LoadingTopNew from "./Top/LoadingTopNew";
import LoadingTopRank from "./Top/LoadingTopRank";
import TopShowTypeToggle from "./Top/TopShowTypeToggle";

import Footer from "./components/Footer";

import newicon from "../images/newicon.png";
import {
  get30Ranking,
  getLonpaDescStartAfter,
  getListUserPrivateVote,
} from "../common/Helper/FirebaseHelper";

import { userApp } from "../common/firebase";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import {
  Grid,
  Container,
  Box,
  Divider,
  Button,
  Typography,
  Checkbox,
} from "@mui/material";

import moment from "moment";
import { Timestamp } from "firebase/firestore";

export default function Top(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isloading, setIsLoding] = useState({ new: true, rank: true });
  const [idealist, setIdealist] = useState([]);
  const [ideaVotedList, setIdeaVotedList] = useState([]);
  const [latestUpdateLonpaList, setLatestUpdateLonpaList] = useState([]);
  const [targetDate, setTargetDate] = useState("");
  const [isFinishInit, setIsFinishInit] = useState(false);

  // const topTextSize = { new: "13px", rank: "15px" };

  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  useEffect(() => {
    console.log("uE_Top");

    const f = async () => {
      try {
        //rankingのデータを取得
        let querySnapshot = await get30Ranking();
        let idealist = [];
        let ideaidlist = [];

        querySnapshot.forEach((doc) => {
          idealist.push({
            id: doc.id,
            lonpaId: doc.data().lonpaId,
            claim: doc.data().claim,
            rank: doc.data().rank,
            numberOfVote: doc.data().numberOfVote,
            updatedAt: doc.data().updatedAt,
            isopinion: doc.data().isopinion,
            imageurl: doc.data().imageurl,
            agree: doc.data().agree,
            disagree: doc.data().disagree,
          });
          ideaidlist.push(doc.data().lonpaId);
        });
        setIdealist(idealist);

        let votesearchideaidlist = [];

        ideaidlist.map((ideaid) => {
          if (!ideaVotedList.includes(ideaid)) {
            //既にリストに入っている場合は重複確認はしない．
            votesearchideaidlist.push(ideaid);
          }
        });

        onAuthStateChanged(getAuth(userApp), async (user) => {
          if (user) {
            //　投票済みかどうかの判定
            let querySnapshot2 = await getListUserPrivateVote(
              votesearchideaidlist
            );
            let votedlist = [...ideaVotedList];
            querySnapshot2.forEach((doc) => {
              votedlist.push(doc.id);
            });
            setIdeaVotedList(votedlist);

            //管理者かどうかの判定
            // if (adminUid.includes(user.uid)) {
            //   setAdminFlag(true);
            // }
          }
        });
        if (idealist.length) {
          setIsLoding({ new: true, rank: false });
        } else {
        }
        //新着のデータ取得
        await UpdateDate();
      } catch (e) {
        console.log("error");
        console.log(e);
        alert("データ取得に失敗しました");
        navigate("/Sorry");
      }
    };
    f();
  }, []);

  async function UpdateDate() {
    let latestUpdateLonpaListadd = latestUpdateLonpaList;
    let targetDate2 = targetDate;
    let ideaidlist = [];
    for (let countdatanum = 0; countdatanum < 4; countdatanum++) {
      let querySnapshot2 = await getLonpaDescStartAfter(targetDate2, 20);
      querySnapshot2.forEach((doc) => {
        if (doc.data().isopinion && !doc.data().isDelete && countdatanum < 4) {
          latestUpdateLonpaListadd.push({
            id: doc.id,
            claim: doc.data().claim,
            parent: doc.data().parent,
            isopinion: doc.data().isopinion,
            imageurl: doc.data().imageurl,
            agree: doc.data().agree,
            disagree: doc.data().disagree,
            updatedAt: parseAsMoment(doc.data().updatedAt.toDate())
              .format("MM/DD HH:mm")
              .toString(),
          });
          ideaidlist.push(doc.id);
          countdatanum = countdatanum + 1;
        }
      });
      if (querySnapshot2.size > 0) {
        targetDate2 = Timestamp.fromDate(
          querySnapshot2.docs.pop().data().updatedAt.toDate()
        );
      }
    }

    //　投票済みかどうかの判定
    onAuthStateChanged(getAuth(userApp), async (user) => {
      if (user) {
        let votesearchideaidlist = [];
        let votedlist = [...ideaVotedList];

        ideaidlist.map((ideaid) => {
          if (!ideaVotedList.includes(ideaid)) {
            //既にリストに入っている場合は重複確認はしない．
            votesearchideaidlist.push(ideaid);
          }
        });
        let querySnapshot2 = await getListUserPrivateVote(votesearchideaidlist);
        querySnapshot2.forEach((doc) => {
          votedlist.push(doc.id);
        });
        setIdeaVotedList(votedlist);
      }
    });

    setTargetDate(targetDate2);
    setLatestUpdateLonpaList(latestUpdateLonpaListadd);

    if (latestUpdateLonpaListadd.length) {
      setIsLoding({ new: false, rank: false });
    }
  }

  return (
    <>
      <div style={{ backgroundColor: props.bgColor.back01 }}>
        <div style={{ height: "3vh" }}></div>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* <div style={{ height: "2vh" }}></div> */}

              <div>
                {isloading.new ? (
                  <LoadingTopNew
                    styletext={props.styletext}
                    bgColor={props.bgColor}
                  />
                ) : (
                  <>
                    <div style={{ height: "2vh" }}></div>

                    <LatestUpdate
                      disableHeader="true"
                      searchword="useurl"
                      idealist={latestUpdateLonpaList}
                      ideaVotedList={ideaVotedList}
                      bgColor={props.bgColor}
                    />
                  </>
                )}

                {isloading.rank ? (
                  <LoadingTopRank
                    styletext={props.styletext}
                    bgColor={props.bgColor}
                  />
                ) : (
                  <>
                    <div style={{ height: "2vh" }}></div>

                    <Ranking
                      disableHeader="true"
                      searchword="useurl"
                      idealist={idealist}
                      ideaVotedList={ideaVotedList}
                      bgColor={props.bgColor}
                    />
                  </>
                )}

                <div style={{ height: "2vh" }}></div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer
        bgColor={props.bgColor}
        styletext={props.styletext}
        isimgshow={true}
      />
    </>
  );
}

import { ListItem, ListItemText } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useStyles } from "../../Styles";

import PieChart from "./NewPieChart";

type Props = {
  bgColor: any;
  idea: any;
  text: any;
  itemtype: any;
  icon: any;
};

const NewTopItem: React.VFC<Props> = ({
  bgColor,
  idea,
  text,
  itemtype,
  icon,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "50px",
        backgroundColor: bgColor.back01,
        color: bgColor.text01.fontColor,
        fontSize: bgColor.text04.fontSize,
        fontWeight: bgColor.text04.fontWeight,
        textAlign: "center",
      }}
    >
      {idea.claim}
    </div>
  );
};

export default NewTopItem;

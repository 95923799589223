import { useStyles } from "../Styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getNumPickup,
  getNumPickupStartAfter,
  getDiscuss,
} from "../common/Helper/FirebaseHelper";
import {
  Button,
  Grid,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Slider,
} from "@mui/material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { createPickupTransaction } from "../common/Helper/FirebaseTransactionHelper";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function MovieState(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [pickups, setPickups] = useState([]);
  const [selectedPickups, setSelectedPickups] = useState([]);
  const [idlist, setIdlist] = useState("");
  const [choiceflag, setChoiceflag] = useState("all");
  const [showStartNum, setShowStartNum] = useState(0);

  useEffect(() => {
    console.log("uE_MovieState");
    const f = async () => {
      const querySnapshot = await getNumPickupStartAfter(10, showStartNum);
      let ideastring = "";
      let idealist = [];
      for (const doc of querySnapshot.docs) {
        if (!doc.data().discussId || doc.data().discussId == 9999) {
          //もしdiscussが作られていないレコードがあれば
          //その後作成されていないか確認しに行く

          const doc2 = await getDiscuss(doc.id);
          let discussId = 9999;

          if (doc2.exists()) {
            discussId = doc2.data().id;
          } else {
            discussId = 9999;
          }

          let setdata = {
            claim: doc.data().claim,
            isflagone: doc.data().isflagone,
            isflagtwo: doc.data().isflagtwo,
            isflagthree: doc.data().isflagthree,
            isflagfour: doc.data().isflagfour,
            isflagfive: doc.data().isflagfive,
            discussId: discussId,
          };

          createPickupTransaction(doc.id, setdata);

          idealist.push({
            url: doc.id,
            id: doc.data().id,
            claim: doc.data().claim,
            isflagone: doc.data().isflagone,
            isflagtwo: doc.data().isflagtwo,
            isflagthree: doc.data().isflagthree,
            isflagfour: doc.data().isflagfour,
            isflagfive: doc.data().isflagfive,
            discussId: discussId,
          });
        } else {
          idealist.push({
            url: doc.id,
            id: doc.data().id,
            claim: doc.data().claim,
            isflagone: doc.data().isflagone,
            isflagtwo: doc.data().isflagtwo,
            isflagthree: doc.data().isflagthree,
            isflagfour: doc.data().isflagfour,
            isflagfive: doc.data().isflagfive,
            discussId: doc.data().discussId,
          });
        }
        ideastring = ideastring + '"' + doc.id + '"' + " ";
      }
      setPickups(idealist);
      setSelectedPickups(idealist);
      ideastring = ideastring.slice(0, -2);
      ideastring = ideastring + "";
      setIdlist(ideastring);
    };
    f();
  }, [showStartNum]);

  //クリップボードにコピー関数
  const copyToClipboard = async (copyText) => {
    await global.navigator.clipboard.writeText(copyText);
  };

  const selectPick = (choicetype, isorder) => {
    let newchoiceflag;
    let leftchoiceflag;
    if (choicetype === 1) {
      newchoiceflag = "isflagone";
      leftchoiceflag = "isflagone";
    } else if (choicetype === 2) {
      newchoiceflag = "isflagtwo";
      leftchoiceflag = "isflagone";
    } else if (choicetype === 3) {
      newchoiceflag = "isflagthree";
      leftchoiceflag = "isflagtwo";
    } else if (choicetype === 4) {
      newchoiceflag = "isflagfour";
      leftchoiceflag = "isflagthree";
    } else if (choicetype === 5) {
      newchoiceflag = "isflagfive";
      leftchoiceflag = "isflagfour";
    }
    setChoiceflag({
      flag: newchoiceflag,
      leftflag: leftchoiceflag,
      isorder: isorder,
    });

    let ideastring = "";
    let idealist = [];

    pickups.forEach((doc) => {
      const ischoiceflag = isorder
        ? doc[newchoiceflag] && doc[leftchoiceflag]
        : !doc[newchoiceflag] && doc[leftchoiceflag];
      if (ischoiceflag) {
        idealist.push({
          url: doc.url,
          id: doc.id,
          claim: doc.claim,
          isflagone: doc.isflagone,
          isflagtwo: doc.isflagtwo,
          isflagthree: doc.isflagthree,
          isflagfour: doc.isflagfour,
          isflagfive: doc.isflagfive,
        });
        ideastring = ideastring + '"' + doc.url + '"' + " ";
      }
    });

    setSelectedPickups(idealist);
    ideastring = ideastring.slice(0, -2);
    ideastring = ideastring + '"' + "";
    setIdlist(ideastring);
  };

  const changeflag = (pickup, num, istrue) => {
    let newpickup = {
      claim: pickup.claim,
      isflagone: pickup.isflagone,
      isflagtwo: pickup.isflagtwo,
      isflagthree: pickup.isflagthree,
      isflagfour: pickup.isflagfour,
      isflagfive: pickup.isflagfive,
      discussId: pickup.discussId,
    };
    if (num === 1) {
      newpickup.isflagone = istrue;
    } else if (num === 2) {
      newpickup.isflagtwo = istrue;
    } else if (num === 3) {
      newpickup.isflagthree = istrue;
    } else if (num === 4) {
      newpickup.isflagfour = istrue;
    } else if (num === 5) {
      newpickup.isflagfive = istrue;
    }

    createPickupTransaction(pickup.url, newpickup);

    const newpickup2 = {
      url: pickup.url,
      id: pickup.id,
      ...newpickup,
    };

    changePickups(newpickup2);
  };

  const changePickups = (newpickup) => {
    let newPickups = pickups;
    newPickups.forEach((doc, index) => {
      if (doc.url == newpickup.url) {
        newPickups[index] = newpickup;
      }
    });
    setPickups(newPickups);

    if (choiceflag == "all") {
      setSelectedPickups(newPickups);
    } else {
      let ideastring = "";
      let idealist = [];
      newPickups.forEach((doc) => {
        const ischoiceflag = choiceflag.isorder
          ? doc[choiceflag.flag] && doc[choiceflag.leftflag]
          : !doc[choiceflag.flag] && doc[choiceflag.leftflag];

        if (ischoiceflag) {
          idealist.push({
            url: doc.url,
            id: doc.id,
            claim: doc.claim,
            isflagone: doc.isflagone,
            isflagtwo: doc.isflagtwo,
            isflagthree: doc.isflagthree,
            isflagfour: doc.isflagfour,
            isflagfive: doc.isflagfive,
          });
          ideastring = ideastring + doc.url + " ";
        }
      });
      setSelectedPickups(idealist);
      ideastring = ideastring.slice(0, -2);
      ideastring = ideastring + "";
      setIdlist(ideastring);
    }
  };

  const iconstyle = {
    fontSize: 16,
    fontWeight: "bold",
    color: props.bgColor.text01.fontColor,
  };

  const tabletitlestyle = {
    fontSize: 16,
    fontWeight: "bold",
    color: props.bgColor.text01.fontColor,
  };

  const handleSliderChange = (event, newValue) => {
    setShowStartNum(newValue);
  };

  return (
    <div
      style={{
        backgroundColor: props.bgColor.back01,
        color: props.bgColor.text01.fontColor,
      }}
    >
      <div style={{ height: "6vh" }}></div>
      <h2 id="transition-modal-title" align="center">
        進捗管理ツール
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            copyToClipboard(idlist);
          }}
        >
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
      </h2>

      <Slider
        aria-label="Temperature"
        defaultValue={0}
        valueLabelDisplay="auto"
        shiftStep={30}
        step={10}
        marks
        min={10}
        max={510}
        onChangeCommitted={handleSliderChange}
      />

      <TextField
        value={idlist}
        fullWidth
        multiline
        maxRows={5}
        minRows={5}
        onChange={(e) => setIdlist(e.target.value)}
        inputProps={{
          style: { color: props.bgColor.text04.fontColor },
        }}
        style={{
          border: props.bgColor.line02,
        }}
      />
      <div style={{ height: "3vh" }}></div>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow key="top">
              <TableCell align="center" style={tabletitlestyle}>
                id
              </TableCell>
              <TableCell align="center" style={tabletitlestyle}>
                Did
              </TableCell>
              <TableCell align="center" style={tabletitlestyle}>
                url
              </TableCell>
              <TableCell align="center" style={tabletitlestyle}>
                主張
              </TableCell>
              <TableCell align="center" style={tabletitlestyle}>
                1
                <IconButton
                  aria-label="delete"
                  size="small"
                  disabled
                  style={iconstyle}
                >
                  <ArrowDropUpIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  disabled
                  style={iconstyle}
                >
                  <ArrowDropDownIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={tabletitlestyle}>
                2
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    selectPick(2, true);
                  }}
                  style={iconstyle}
                >
                  <ArrowDropUpIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    selectPick(2, false);
                  }}
                  style={iconstyle}
                >
                  <ArrowDropDownIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={tabletitlestyle}>
                3
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    selectPick(3, true);
                  }}
                  style={iconstyle}
                >
                  <ArrowDropUpIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    selectPick(3, false);
                  }}
                  style={iconstyle}
                >
                  <ArrowDropDownIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={tabletitlestyle}>
                4
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    selectPick(4, true);
                  }}
                  style={iconstyle}
                >
                  <ArrowDropUpIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    selectPick(4, false);
                  }}
                  style={iconstyle}
                >
                  <ArrowDropDownIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
              <TableCell align="center" style={tabletitlestyle}>
                5
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    selectPick(5, true);
                  }}
                  style={iconstyle}
                >
                  <ArrowDropUpIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    selectPick(5, false);
                  }}
                  style={iconstyle}
                >
                  <ArrowDropDownIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
            </TableRow>
            {selectedPickups.map((pickup, index) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  style={{
                    width: "5%",
                    padding: "0",
                    lineHeight: "0",
                    color: props.bgColor.text01.fontColor,
                  }}
                >
                  <>
                    <Button
                      onClick={() => {
                        window.open("/Lonpa/" + pickup.url, "_blank");
                      }}
                      style={iconstyle}
                    >
                      {pickup.id}
                    </Button>
                  </>
                </TableCell>
                <TableCell align="center">
                  <>
                    <Button
                      onClick={() => {
                        window.open("/Discuss/" + pickup.url, "_blank");
                      }}
                      style={iconstyle}
                    >
                      {pickup.discussId}
                    </Button>
                  </>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "20%",
                    padding: "0",
                    lineHeight: "0",
                    wordBreak: "break-word",
                    color: props.bgColor.text01.fontColor,
                  }}
                >
                  {pickup.isflagfive ? (
                    <>
                      <Button
                        onClick={() => {
                          window.open("/MovieShow/" + pickup.url, "_blank");
                        }}
                        style={iconstyle}
                      >
                        {pickup.url}
                      </Button>
                    </>
                  ) : (
                    <>{pickup.url}</>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "50%",
                    padding: "0",
                    lineHeight: "0",
                    color: props.bgColor.text01.fontColor,
                  }}
                >
                  <Button
                    onClick={() => {
                      window.open("/MovieMaker/" + pickup.url, "_blank");
                    }}
                    style={iconstyle}
                  >
                    {pickup.claim}
                  </Button>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "5%",
                    padding: "0",
                    lineHeight: "0",
                    color: props.bgColor.text01.fontColor,
                  }}
                >
                  <Checkbox
                    checked={pickup.isflagone}
                    onChange={() => {
                      changeflag(pickup, 1, !pickup.isflagone);
                    }}
                    style={{
                      color: props.bgColor.back11,
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "5%",
                    padding: "0",
                    lineHeight: "0",
                    color: props.bgColor.text01.fontColor,
                  }}
                >
                  <Checkbox
                    checked={pickup.isflagtwo}
                    onChange={() => {
                      changeflag(pickup, 2, !pickup.isflagtwo);
                    }}
                    style={{
                      color: props.bgColor.back11,
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "5%",
                    padding: "0",
                    lineHeight: "0",
                    color: props.bgColor.text01.fontColor,
                  }}
                >
                  <Checkbox
                    checked={pickup.isflagthree}
                    onChange={() => {
                      changeflag(pickup, 3, !pickup.isflagthree);
                    }}
                    style={{
                      color: props.bgColor.back11,
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "5%",
                    padding: "0",
                    lineHeight: "0",
                    color: props.bgColor.text01.fontColor,
                  }}
                >
                  <Checkbox
                    checked={pickup.isflagfour}
                    onChange={() => {
                      changeflag(pickup, 4, !pickup.isflagfour);
                    }}
                    style={{
                      color: props.bgColor.back11,
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "5%",
                    padding: "0",
                    lineHeight: "0",
                    color: props.bgColor.text01.fontColor,
                  }}
                >
                  <Checkbox
                    checked={pickup.isflagfive}
                    onChange={() => {
                      changeflag(pickup, 5, !pickup.isflagfive);
                    }}
                    style={{
                      color: props.bgColor.back11,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

import { styled } from "@mui/material/styles";
import React, { useState } from "react";

type Props = {
  bgColor: any;
  num: string;
  text: string;
};

const TitleBar: React.VFC<Props> = ({ bgColor, num, text }: Props) => {
  return (
    <div
      style={{
        backgroundColor: bgColor.back12,
        padding: "8px 0px 8px 0px",
        borderRadius: "10px",
        textAlign: "center",
      }}
    >
      <h2 id="transition-modal-title">
        <div
          style={{
            color: bgColor.text12.fontColor,
          }}
        >
          <span
            style={{
              fontFamily: "Oswald,sans-serif",
              fontSize: "70%",
            }}
          >
            Step
          </span>
          <span
            style={{
              fontFamily: "Oswald,sans-serif",
              fontSize: "100%",
            }}
          >
            {num}
          </span>
        </div>
        <div
          style={{
            color: bgColor.text01.fontColor,
            fontFamily: "Oswald,sans-serif",
            fontSize: "80%",
          }}
        >
          {text}
        </div>
      </h2>
    </div>
  );
};

export default TitleBar;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useStyles } from "../../../Styles";
import { Button } from "@mui/material";

import {
  checkisExecutable,
  pointSubAutoCreation600movie,
  pointSubMakeManuscript,
  pointSubMakeMovieOnly,
} from "../../../common/Helper/PointHelper";
import {
  pointtableTypeH,
  pointtableTypeI,
  pointtableTypeE,
  pointtableTypeJ,
  pointtableTypeK,
} from "../../../common/pointTable";
import ReactLoading from "react-loading";

export default function MovieUsePointModal(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [executable, setExecutable] = useState({});
  const [purpose, setPurpose] = useState("");
  const [pointtable, setPointTable] = useState();
  const [isloding, setIsloding] = useState(true);

  const handleCloseinside = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    const f = async () => {
      console.log("uE_CreateUsePointModal");
      if (props.pointPattarn == "autocreate") {
        const executable = await checkisExecutable(600);
        setExecutable(executable);
        setPurpose("動画自動生成");
        setPointTable(pointtableTypeH);
      } else if (props.pointPattarn == "makeManuscript") {
        const executable = await checkisExecutable(400);
        setExecutable(executable);
        setPurpose("動画原稿の自動生成");
        setPointTable(pointtableTypeI);
      } else if (props.pointPattarn == "makeSNS") {
        const executable = await checkisExecutable(200);
        setExecutable(executable);
        setPurpose("SNSの自動生成");
        setPointTable(pointtableTypeE);
      } else if (props.pointPattarn == "makemovieonly") {
        const executable = await checkisExecutable(1200);
        setExecutable(executable);
        setPurpose("動画のみ自動生成");
        setPointTable(pointtableTypeK);
      }
      setIsloding(false);
    };
    f();
  }, []);

  async function ExcuteJob() {
    if (props.pointPattarn == "autocreate") {
      pointSubAutoCreation600movie(props.id);
      props.executeAutomake();
    } else if (props.pointPattarn == "makeManuscript") {
      pointSubMakeManuscript(props.id);
      props.executeMakeManuscript();
    } else if (props.pointPattarn == "makeSNS") {
      pointSubMakeSNS(props.id);
      props.executeMakeSNS();
    } else if (props.pointPattarn == "makemovieonly") {
      pointSubMakeMovieOnly(props.id);
      props.executeMakeMovieOnly();
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          {isloding ? (
            <>
              <div align="center" style={{ border: 0 }}>
                <ReactLoading
                  type="spin"
                  color="black"
                  style={{ marginTop: "15vh", marginBottom: "45vh" }}
                />
              </div>
            </>
          ) : (
            <>
              <br />
              <br />
              <h2 id="transition-modal-title" align="center">
                {purpose}
              </h2>
              <div align="center">
                <br />
                現在のポイント：{executable.possession}
                <br />
                {executable.required}point消費して実行しますか？
                <br />
                {executable.isSubscribe ? (
                  <>
                    {executable.isExcutable ? (
                      <>
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            ExcuteJob();
                            props.setOpen(false);
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          実行
                        </Button>
                      </>
                    ) : (
                      <>
                        {executable.deficient}ポイント不足
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            props.setOpen(false);
                            navigate("/Point/");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          ポイント追加
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {executable.isMember ? (
                      <>
                        サブスク登録しましょう
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            props.setOpen(false);
                            navigate("/Point/");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          サブスク登録
                        </Button>
                      </>
                    ) : (
                      <>
                        メンバ登録しましょう
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            props.setOpen(false);
                            navigate("/Signup/");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          メンバ登録
                        </Button>
                      </>
                    )}
                  </>
                )}
                .............
                <Button
                  color="primary"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  戻る
                </Button>
                <br />
                <br />
                内訳
                <>{pointtable}</>
              </div>
              <br />
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

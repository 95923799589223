import Sorryimg from "../images/sorry404.jpg";

import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

function Sorry(props) {
  let { lonpaid } = useParams();
  const navigate = useNavigate();
  return (
    <div align="center">
      <br />
      <br />
      お探しのページは見つかりませんでした．
      <br />
      URLを再度ご確認ください．
      <br />
      <img src={Sorryimg} alt="アイコン" />
      <br />
      {lonpaid ? (
        <>
          {" "}
          <Button
            disabled={false}
            size="large"
            variant="contained"
            style={{ fontSize: 15 }}
            onClick={() => {
              navigate("/Debug/" + lonpaid);
            }}
          >
            デバッグモード
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
export default Sorry;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useStyles } from "../../../Styles";
import { Button } from "@mui/material";

import { checkisExecutable } from "../../../common/Helper/PointHelper";
import { pointtableTypeB, pointtableTypeC } from "../../../common/pointTable";
import ReactLoading from "react-loading";

export default function CreateUsePointModal(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [executable, setExecutable] = useState({});
  const [purpose, setPurpose] = useState("");
  const [isloding, setIsloding] = useState(true);

  const handleCloseinside = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    const f = async () => {
      console.log("uE_CreateUsePointModal");
      if (props.pointPattarn) {
        const executable = await checkisExecutable(2500);
        setExecutable(executable);
        setPurpose("全自動生成");
      } else {
        const executable = await checkisExecutable(600);
        setExecutable(executable);
        setPurpose("lonpa記事の自動生成");
      }
      setIsloding(false);
    };
    f();
  }, []);

  async function ExcuteJob() {
    if (props.pointPattarn) {
      props.Post();
      //ポイント消費はPost内で実施（idを記録するため）
    } else {
      props.Post();
      //ポイント消費はPost内で実施（idを記録するため）
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          {isloding ? (
            <>
              <div align="center" style={{ border: 0 }}>
                <ReactLoading
                  type="spin"
                  color="black"
                  style={{ marginTop: "15vh", marginBottom: "45vh" }}
                />
              </div>
            </>
          ) : (
            <>
              <br />
              <br />
              <h2 id="transition-modal-title" align="center">
                {purpose}
              </h2>
              <div align="center">
                <br />
                現在のポイント：{executable.possession}
                <br />
                {executable.required}point消費して実行しますか？
                <br />
                {executable.isSubscribe ? (
                  <>
                    {executable.isExcutable ? (
                      <>
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            ExcuteJob();
                            props.setOpen(false);
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          実行
                        </Button>
                      </>
                    ) : (
                      <>
                        {executable.deficient}ポイント不足
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            props.setOpen(false);
                            navigate("/Point/");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          ポイント追加
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {executable.isMember ? (
                      <>
                        サブスク登録しましょう
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            props.setOpen(false);
                            navigate("/Point/");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          サブスク登録
                        </Button>
                      </>
                    ) : (
                      <>
                        メンバ登録しましょう
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            props.setOpen(false);
                            navigate("/Signup/");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          メンバ登録
                        </Button>
                      </>
                    )}
                  </>
                )}
                .............
                <Button
                  color="primary"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  戻る
                </Button>
                <br />
                <br />
                内訳
                {props.pointPattarn ? (
                  <>{pointtableTypeC}</>
                ) : (
                  <>{pointtableTypeB}</>
                )}
              </div>
              <br />
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useStyles } from "../../../Styles";
import CircularProgress from "@mui/material/CircularProgress";

export default function UploadModal(props) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.upload}>
          {props.error ? (
            <>
              <h1>エラー</h1>
              <h2>画像投稿中にエラーが発生しました。</h2>
              <h2>時間をおいて、再度、投稿ください。</h2>
            </>
          ) : (
            <>
              <h1>画像投稿中</h1>
              <div
                style={{
                  "margin-top": "30vh",
                  "text-align": "center",
                }}
              >
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    variant="determinate"
                    value={props.progress}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="text.secondary"
                    >
                      {`${Math.round(props.progress)}%`}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

import { Button, Grid, TextField, IconButton } from "@mui/material";
import ReactLoading from "react-loading";

export default function LodingCreatingPicture(props) {
  return (
    <>
      <h2 id="transition-modal-title" align="center">
        画像作成中
      </h2>
      <div align="center" style={{ border: 0, height: "128px" }}>
        <div style={{ marginTop: "64px" }}></div>
        <ReactLoading type="cubes" color="black" />
        <div style={{ color: "#AAAAAA" }}>{props.startTime}</div>
      </div>

      <h2 id="transition-modal-title" align="center">
        テキスト
      </h2>
      <TextField
        fullWidth
        disabled
        label="テキスト(改行もできます)"
        multiline
        maxRows={20}
        minRows={10}
        value={props.text}
        sx={{
          input: {
            color: props.bgColor.text04.fontColor,
          },
        }}
        style={{
          border: props.bgColor.line02,
        }}
      />
    </>
  );
}

// import { useSpring, animated } from "@react-spring/web";
import { IconButton } from "@mui/material";
import Add from "@mui/icons-material/Add";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import Lonpabasis from "./Lonapbasis";

import { useStyles } from "../../Styles";

export default function LonpaBigWidth(props) {
  const classes = useStyles();
  //const backcolors = { 0: "#C3CAF6", 1: "#EACDCC" };

  // const { x } = useSpring({
  //   from: { x: 0 },
  //   x: props.animationagree ? 1 : 0,
  //   config: { duration: props.animationspeed.lonpatable },
  // });

  // const { y } = useSpring({
  //   from: { y: 0 },
  //   y: props.animationdisagree ? 1 : 0,
  //   config: { duration: props.animationspeed.lonpatable },
  // });

  return (
    <TableContainer
      component={Paper}
      style={{ borderRadius: "0px", backgroundColor: props.bgColor.back01 }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{ padding: "0px" }}>
            {props.animationagree || props.animationdisagree ? (
              <>
                <TableCell
                  align="center"
                  style={{
                    color: props.bgColor.text03.fontColor,
                    fontSize: 20,
                    height: "49px",
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  style={{
                    color: props.bgColor.text02.fontColor,
                    fontSize: 20,
                    height: "49px",
                  }}
                ></TableCell>
              </>
            ) : (
              <>
                {typeof props.infoagreesum === "undefined" ? (
                  <>
                    <TableCell
                      align="center"
                      style={{
                        color: props.bgColor.text03.fontColor,
                        fontSize: props.claimChildSize.textsize1,
                        height: "25px",
                        padding: "12px",
                      }}
                    >
                      賛成　0人
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: props.bgColor.text02.fontColor,
                        fontSize: props.claimChildSize.textsize1,
                        height: "25px",
                        padding: "12px",
                      }}
                    >
                      反対　0人
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell
                      align="center"
                      style={{
                        color: props.bgColor.text03.fontColor,
                        fontSize: props.claimChildSize.textsize1,
                        height: "25px",
                        padding: "12px",
                      }}
                    >
                      賛成　{props.infoagreesum}人
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: props.bgColor.text02.fontColor,
                        fontSize: props.claimChildSize.textsize1,
                        height: "25px",
                        padding: "12px",
                      }}
                    >
                      反対　{props.infodisagreesum}人
                    </TableCell>
                  </>
                )}
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="aa" style={{ padding: "0px" }}>
            <TableCell
              align="center"
              style={{
                //論拠の高さ変更箇所1
                height: props.lonpaBigWidthUIHeight,
                backgroundColor: props.bgColor.back09,
                padding: "0px",
              }}
            >
              <>
                {props.animationdisagree ? (
                  <>
                    {/* <animated.div
                      style={{
                        width: y.to({
                          range: [0, 1],
                          output: ["45vw", " 0vw"],
                        }),

                        // height: "40vh",
                      }}
                    ></animated.div> */}
                  </>
                ) : (
                  <>
                    <div
                      key={0}
                      className={classes[`slide${0}`]}
                      style={{
                        //論拠の高さ変更箇所2
                        height: props.lonpaBigWidthUIHeight,
                        overflow: "auto",
                        padding: "4px",
                      }}
                    >
                      <Lonpabasis
                        //color="primary"
                        isagree={true}
                        // addLon={() => props.addLon(true)}
                        pushLon={(id, data, increment) =>
                          props.pushLon(id, data, increment)
                        }
                        info={props.info.agree}
                        login={props.info.login}
                        ischoiced={props.info.ischoiced}
                        ChoiceData={props.ChoiceData}
                        isbuttonlock={props.isbuttonlock}
                        parentclaim={props.info.claim}
                        increseUpdate={() => props.increseUpdate()}
                        styletext={{ ...props.styletext, isbig: 0 }}
                        blocktype={props.blocktype}
                        userEmailVerified={props.userEmailVerified}
                        lonpacreateopen={props.lonpacreateopen.agree}
                        addLonChild={props.addLonChild}
                        claimChildSize={props.claimChildSize}
                        createAILonData={props.createAILonData.agree}
                        bgColor={props.bgColor}
                      />
                    </div>
                  </>
                )}
              </>
              {/* </animated.div> */}
            </TableCell>
            <TableCell
              align="center"
              style={{
                //論拠の高さ変更箇所1
                height: props.lonpaBigWidthUIHeight,
                backgroundColor: props.bgColor.back10,
                padding: "0px",
              }}
            >
              <>
                {props.animationagree ? (
                  <>
                    {/* <animated.div
                      style={{
                        width: x.to({
                          range: [0, 1],
                          output: ["45vw", " 0vw"],
                        }),

                        // height: "40vh",
                      }}
                    ></animated.div> */}
                  </>
                ) : (
                  <>
                    <div
                      key={0}
                      className={classes[`slide${0}`]}
                      style={{
                        //論拠の高さ変更箇所2
                        height: props.lonpaBigWidthUIHeight,
                        overflow: "auto",
                        padding: "4px",
                      }}
                    >
                      <Lonpabasis
                        //color="secondary"
                        isagree={false}
                        // addLon={() => props.addLon(false)}
                        pushLon={(id, data, increment) =>
                          props.pushLon(id, data, increment)
                        }
                        info={props.info.disagree}
                        login={props.info.login}
                        ischoiced={props.info.ischoiced}
                        ChoiceData={props.ChoiceData}
                        isbuttonlock={props.isbuttonlock}
                        parentclaim={props.info.claim}
                        increseUpdate={() => props.increseUpdate()}
                        styletext={{ ...props.styletext, isbig: 0 }}
                        blocktype={props.blocktype}
                        userEmailVerified={props.userEmailVerified}
                        lonpacreateopen={props.lonpacreateopen.disagree}
                        addLonChild={props.addLonChild}
                        claimChildSize={props.claimChildSize}
                        createAILonData={props.createAILonData.disagree}
                        bgColor={props.bgColor}
                      />
                    </div>
                  </>
                )}
              </>

              {/* </animated.div> */}
            </TableCell>
          </TableRow>
          <TableRow key="bb" style={{ padding: "0px" }}>
            <TableCell
              align="center"
              style={{
                height: "5vh",
                backgroundColor: props.bgColor.back09,
                padding: "0px",
              }}
            >
              <IconButton
                size="medium"
                sx={{ color: props.bgColor.back14 }}
                aria-label="add an alarm"
                onClick={() => props.addLon(true)}
                style={{ width: "100%" }}
              >
                <Add />
              </IconButton>
            </TableCell>
            <TableCell
              align="center"
              style={{
                height: "5vh",
                backgroundColor: props.bgColor.back10,
                padding: "0px",
              }}
            >
              <>
                {!props.animationagree ? (
                  <>
                    <IconButton
                      size="medium"
                      sx={{ color: props.bgColor.back13 }}
                      aria-label="add an alarm"
                      onClick={() => props.addLon(false)}
                      style={{ width: "100%" }}
                    >
                      <Add />
                    </IconButton>
                  </>
                ) : (
                  <></>
                )}
              </>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useStyles } from "../../Styles";

export default function ImgModal(props) {
  const classes = useStyles();
  const handleCloseinside = () => {
    props.setOpen(false);
    props.setExpimg("");
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleCloseinside}
        closeAfterTransition
      >
        <Fade in={props.open}>
          <div className={classes.imgmodal}>
            <img className={classes.expimg} src={props.img} alt="img"></img>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

import { Button } from "@mui/material";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStyles } from "../../Styles";

type ConfirmDialogProps = {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  title: string;
  message: string;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onClose,
  title,
  message,
}) => {
  const classes = useStyles();
  const handleCloseinside = (confirmed: boolean) => {
    onClose(confirmed);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      <Fade in={open}>
        <div
          style={{
            border: "0px solid #000",
            backgroundColor: "#eeeeee",
            overflow: "auto",
          }}
        >
          <h2 id="transition-modal-title">
            これをするとアカウントが削除されます
          </h2>
          <h2 id="transition-modal-title">
            この処理を元に戻すことはできません
          </h2>
          <h2 id="transition-modal-title">本当に削除しますか？</h2>
          <h2 id="transition-modal-title">:</h2>
          <h2 id="transition-modal-title">
            削除前に問合せ入れることをお勧めします
          </h2>
          <div id="transition-modal-description"></div>

          <Button onClick={() => onClose(false)} color="primary">
            削除しない
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            削除する
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default ConfirmDialog;

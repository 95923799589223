import { firebaseApp, userApp } from "../firebase";
import {
  doc,
  getFirestore,
  runTransaction,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ConnectedTvOutlined } from "@mui/icons-material";
import moment from "moment";

async function makeTransactionID(transaction, db, lonpaid) {
  //今回のlonpa更新のために、トランザクション番号を発行
  const sfDocRef = doc(db, "lonpa", lonpaid);
  const sfDoc = await transaction.get(sfDocRef);
  if (!sfDoc.exists()) {
    throw "Document does not exist!";
  }
  let transactionid;

  if (typeof sfDoc.data().transaction === "undefined") {
    transactionid = 1;
  } else {
    transactionid = sfDoc.data().transaction + 1;
  }
  return transactionid;
}

async function checkSelfVote(db, lonpaid, id, info) {
  const sfDocsetUserPrivateVoteRef = doc(
    db,
    "userprivate",
    getAuth(userApp).currentUser.uid,
    "vote",
    lonpaid
  );

  const sfDocsetUserPrivateVote = await getDoc(sfDocsetUserPrivateVoteRef);

  if (!sfDocsetUserPrivateVote.exists()) {
    //console.log(info);
    if (!info.ischoiced || typeof info.choiceditem.id === "undefined") {
      return true;
    } else {
      throw "setUserPrivateVote Document does not exist!";
    }
  } else if (
    sfDocsetUserPrivateVote.data().votedLonpaId === info.choiceditem.id
  ) {
    return true;
  }
  return false;
}

async function getChoicedVoteNum(transaction, db, lonpaid, id, info) {
  let votenum;
  let votednum;

  const sfDocRef1 = doc(db, "lonpa", lonpaid, "child", id);
  const sfDoc1 = await transaction.get(sfDocRef1);
  if (!sfDoc1.exists()) {
    throw "Document does not exist!";
  }
  votenum = sfDoc1.data().votenum;

  if (info.ischoiced && typeof info.choiceditem.id !== "undefined") {
    //投票済み→未投票になる要素
    const sfDocRef2 = doc(db, "lonpa", lonpaid, "child", info.choiceditem.id);
    const sfDoc2 = await transaction.get(sfDocRef2);
    if (!sfDoc2.exists()) {
      throw "Document does not exist!";
    }
    votednum = sfDoc2.data().votenum;
  } else {
    votednum = undefined;
  }

  return [votenum, votednum];
}

async function makeChangeset(
  id,
  data,
  increment,
  user,
  lonpaid,
  serverTimestamp,
  excutetimestring,
  info,
  infoagreesum,
  infodisagreesum,
  pinfo,
  votednum,
  votenum
) {
  let changesetVote = {};
  let changesetUser = {};

  //ユーザ認証済みの場合，DBのデータをもとに投票調整
  let choiceid;
  // 今回選択されたアイテムの次の数値 画面表示は「data.votenum」だが、取り直したもの「votenum」を利用
  let newnum;
  // 選択解除されるアイテムの次の数値 画面表示は「info.choiceditem.votenum」だが、取り直したもの「votednum」を利用
  let newnum2;

  //////　 今回選択されたアイテム、今回選択解除されるアイテムの次の数値を決定 　///////
  if (increment) {
    //未選択　→　選択の場合
    choiceid = id;
    newnum = votenum + 1;
    newnum2 = votednum - 1;
  } else {
    //選択済　→　未選択の場合
    choiceid = "";
    newnum = votenum - 1;
  }

  //////　 今回選択されたアイテムの更新　///////
  changesetVote.updateLonpaChild = {
    target: { id1: lonpaid, id2: id },
    data: { votenum: newnum },
  };

  //////　 今回選択解除されるアイテムの更新　///////
  if (
    info.ischoiced &&
    increment &&
    typeof info.choiceditem.id !== "undefined"
  ) {
    //lonpaの論拠への投票数を更新する,押下前に選択していた要素からマイナス１する
    changesetVote.updateLonpaChild2 = {
      target: { id1: lonpaid, id2: info.choiceditem.id },
      data: { votenum: newnum2 },
    };
  }

  //////　記名投票における投票情報の更新  ///////
  if (user) {
    if (increment) {
      //vote情報を追加する
      changesetVote.setLonpaChildVote = {
        target: {
          id1: lonpaid,
          id2: id,
          id3: getAuth(userApp).currentUser.uid,
        },
        data: { votedAt: serverTimestamp },
      };

      //ユーザが，どのlonpaで，どの要因に投票したか記録する
      changesetUser.setUserPrivateVote = {
        target: { id1: lonpaid },
        data: {
          votedLonpaId: choiceid,
          votedClaim: data.claim,
          claim: info.claim,
          isAgree: data.agree,
          votedAt: serverTimestamp,
        },
      };

      changesetVote.setVote = {
        target: { id1: excutetimestring },
        data: {
          votedLonpaId: choiceid,
          votedClaim: data.claim,
          claim: info.claim,
          isAgree: data.agree,
          votedAt: serverTimestamp,
          uid: getAuth(userApp).currentUser.uid,
          votedParentLonpaId: lonpaid,
        },
      };

      //////　 今回選択解除されるアイテムから、自身の投票名を削除　///////
      if (info.ischoiced && typeof info.choiceditem.id !== "undefined") {
        changesetVote.DeleteLonpaChildVote = {
          target: {
            id1: lonpaid,
            id2: info.choiceditem.id,
            id3: getAuth(userApp).currentUser.uid,
          },
          data: {},
        };
      }
    } else {
      //vote情報を削除する
      changesetVote.DeleteLonpaChildVote = {
        target: {
          id1: lonpaid,
          id2: id,
          id3: getAuth(userApp).currentUser.uid,
        },
        data: {},
      };

      //ユーザが，どのlonpaで，どの要因に投票したか記録する
      changesetUser.deleteUserPrivateVote = {
        target: { id1: lonpaid },
        data: {},
      };
    }
  } else {
    //ログインしていない場合は
    //LonpaChildVoteには情報を追加・削除しない
    //UserPrivateVoteにも情報を追加・削除しない
    //Voteにも情報を追加しない
  }

  //■■■押下された論拠以外の更新１　親Lonpa更新
  //論拠に投票が入り，賛成と反対のバランスが変わる場合
  //Lonpaのさらに親のカードステータスを変えに行く
  let agreenum = infoagreesum;
  let disagreenum = infodisagreesum;
  if (data.agree) {
    if (increment) {
      agreenum = agreenum + 1;
      if (info.ischoiced) {
        if (!info.choiceditem.isAgree) {
          disagreenum = disagreenum - 1;
        }
      }
    } else {
      agreenum = agreenum - 1;
    }
  } else {
    if (increment) {
      disagreenum = disagreenum + 1;
      if (info.ischoiced) {
        if (info.choiceditem.isAgree) {
          agreenum = agreenum - 1;
        }
      }
    } else {
      disagreenum = disagreenum - 1;
    }
  }

  let oppositionStatus = "balanced";
  if (agreenum > disagreenum) {
    oppositionStatus = "agreeLevel1";
  } else if (agreenum < disagreenum) {
    oppositionStatus = "disagreeLevel1";
  }

  if (pinfo[0] !== undefined) {
    // 一番上の階層のLonpa以外の場合、親要素にoppositionStatusを設定
    changesetVote.setLonpaChild = {
      target: { id1: pinfo[0], id2: lonpaid },
      data: { oppositionStatus: oppositionStatus },
    };
  }

  return { VoteChange: changesetVote, UserChange: changesetUser };
}

async function executeDBChangeVote(
  transaction,
  db,
  changeset,
  lonpaid,
  transactionid
) {
  // console.log(changeset);
  ////////console.log("DeleteLonpaChildVote");
  if ("DeleteLonpaChildVote" in changeset) {
    const sfDocDeleteLonpaChildVoteRef = doc(
      db,
      "lonpa",
      changeset.DeleteLonpaChildVote.target.id1,
      "child",
      changeset.DeleteLonpaChildVote.target.id2,
      "vote",
      changeset.DeleteLonpaChildVote.target.id3
    );

    transaction.delete(sfDocDeleteLonpaChildVoteRef);
  }

  ////////console.log("setVote");
  if ("setVote" in changeset) {
    const sfDocsetVoteRef = doc(db, "vote", changeset.setVote.target.id1);
    transaction.set(sfDocsetVoteRef, changeset.setVote.data);
  }

  ////////console.log("setLonpaChildVote");
  if ("setLonpaChildVote" in changeset) {
    const sfDocsetLonpaChildVoteRef = doc(
      db,
      "lonpa",
      changeset.setLonpaChildVote.target.id1,
      "child",
      changeset.setLonpaChildVote.target.id2,
      "vote",
      changeset.setLonpaChildVote.target.id3
    );
    transaction.set(
      sfDocsetLonpaChildVoteRef,
      changeset.setLonpaChildVote.data,
      { merge: true }
    );
  }

  ////////console.log("setLonpaChild");
  if ("setLonpaChild" in changeset) {
    const sfDocsetLonpaChildRef = doc(
      db,
      "lonpa",
      changeset.setLonpaChild.target.id1,
      "child",
      changeset.setLonpaChild.target.id2
    );
    transaction.set(sfDocsetLonpaChildRef, changeset.setLonpaChild.data, {
      merge: true,
    });
  }

  ////////console.log("updateLonpaChild");
  if ("updateLonpaChild" in changeset) {
    const sfDocupdateLonpaChildRef = doc(
      db,
      "lonpa",
      changeset.updateLonpaChild.target.id1,
      "child",
      changeset.updateLonpaChild.target.id2
    );
    transaction.update(
      sfDocupdateLonpaChildRef,
      changeset.updateLonpaChild.data
    );
  }

  ////////console.log("updateLonpaChild2");
  if ("updateLonpaChild2" in changeset) {
    const sfDocupdateLonpaChild2Ref = doc(
      db,
      "lonpa",
      changeset.updateLonpaChild2.target.id1,
      "child",
      changeset.updateLonpaChild2.target.id2
    );
    transaction.update(
      sfDocupdateLonpaChild2Ref,
      changeset.updateLonpaChild2.data
    );
  }

  ////////console.log("lonpaUpdate");
  const sfDocRef = doc(db, "lonpa", lonpaid);
  transaction.update(sfDocRef, { transaction: transactionid });

  ////////console.log("finish");
}

async function executeDBChangeUser(transaction, userAppDB, changeset) {
  ////////console.log("setUserPrivateVote");
  if ("setUserPrivateVote" in changeset) {
    const sfDocsetUserPrivateVoteRef = doc(
      userAppDB,
      "userprivate",
      getAuth(userApp).currentUser.uid,
      "vote",
      changeset.setUserPrivateVote.target.id1
    );

    transaction.set(
      sfDocsetUserPrivateVoteRef,
      changeset.setUserPrivateVote.data,
      { merge: true }
    );
  }

  ////////console.log("deleteUserPrivateVote");
  if ("deleteUserPrivateVote" in changeset) {
    const sfDocdeleteUserPrivateVoteRef = doc(
      userAppDB,
      "userprivate",
      getAuth(userApp).currentUser.uid,
      "vote",
      changeset.deleteUserPrivateVote.target.id1
    );
    transaction.delete(sfDocdeleteUserPrivateVoteRef);
  }
}

export async function transactionPushLon(
  id,
  data,
  increment,
  user,
  lonpaid,
  serverTimestamp,
  excutetimestring,
  info,
  infoagreesum,
  infodisagreesum,
  pinfo,
  excutetime
) {
  const db = getFirestore(firebaseApp);
  const userAppDB = getFirestore(userApp);

  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  let changeset = { VoteChange: false, UserChange: false };

  try {
    if (user) {
      if (await checkSelfVote(userAppDB, lonpaid, id, info)) {
      } else {
        throw "Dont Vote at the same time";
      }
    } else {
      console.log("notlogin checkcockie");
    }

    await runTransaction(db, async (transaction) => {
      ///////console.log("引数として与えられた時間と現時間に大きな乖離がないか確認")///////
      const excutetime2 = new Date();
      const excutetime3 = excutetime2 - excutetime;
      if (excutetime3 > 550) {
        console.log(excutetime3);
        const checktimestring1 = parseAsMoment(excutetime)
          .format("YYYYMMDDHHmmss")
          .toString();
        const checktimestring2 = parseAsMoment(excutetime2)
          .format("YYYYMMDDHHmmss")
          .toString();
        console.log(checktimestring1 + "/" + checktimestring2);
        console.log(excutetime3);
        throw "Too Long Span";
      }

      ///////console.log("トランザクションIDを発行")///////
      const transactionid = await makeTransactionID(transaction, db, lonpaid);

      ////////console.log("更新する論拠（未投票→投票・投票→未投票）の現在の数値取得")///////
      //3パターン
      //未投票→投票　votenum+1
      //投票済み→未投票　votenum-1
      //投票済み→別のものに投票　votenum+1 votednum-1
      //過去投票はinfoから取得する
      const [votenum, votednum] = await getChoicedVoteNum(
        transaction,
        db,
        lonpaid,
        id,
        info
      );

      ////////console.log("処理内容を作成する（firebaseへの処理は行わない）")////////
      changeset = await makeChangeset(
        id,
        data,
        increment,
        user,
        lonpaid,
        serverTimestamp,
        excutetimestring,
        info,
        infoagreesum,
        infodisagreesum,
        pinfo,
        votednum,
        votenum
      );

      ///////console.log("トランザクション番号:" + transactionid);
      //console.log(changeset);
      //console.log("■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■");

      ////////console.log("処理内容に従い、処理を実行する")////////

      // console.log(changeset);
      if (changeset?.VoteChange) {
        await executeDBChangeVote(
          transaction,
          db,
          changeset.VoteChange,
          lonpaid,
          transactionid
        );
      }
    });
    if (changeset?.UserChange) {
      //トランザクション開始
      await runTransaction(userAppDB, async (transaction) => {
        await executeDBChangeUser(transaction, userAppDB, changeset.UserChange);
      });
    }

    //console.log("Transaction successfully committed!");
    return true;
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false;
  }
}

export async function getUserPrivateVoteTransaction(lonpaid) {
  let sfDoc;

  try {
    await runTransaction(getFirestore(userApp), async (transaction) => {
      const sfDocRef = doc(
        getFirestore(userApp),
        "userprivate",
        getAuth(userApp).currentUser.uid,
        "vote",
        lonpaid
      );
      sfDoc = await transaction.get(sfDocRef);
      if (!sfDoc.exists()) {
        throw "userprivate_vote does not exist!";
      }
    });
    return sfDoc;
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false;
  }
}

export async function createPickupTransaction(lonpaid, setdata) {
  const db = getFirestore(firebaseApp);
  let randId = 0;

  try {
    await runTransaction(db, async (transaction) => {
      const sfDocRef1 = doc(db, "setting", "pickup");

      //const sfDocRef2 = doc(db, "pickup", lonpaid);
      const sfDoc1 = await transaction.get(sfDocRef1, setdata);
      if (!sfDoc1.exists()) {
        throw "Document does not exist!";
      } else {
        const sfDocRef2 = doc(db, "pickup", lonpaid);
        const sfDoc2 = await transaction.get(sfDocRef2);
        if (!sfDoc2.exists()) {
          //レコードが存在しない＝新規作成のとき
          await transaction.set(sfDocRef1, {
            pickupCount: sfDoc1.data().pickupCount + 1,
          });
          randId = sfDoc1.data().pickupCount + 1;
        } else {
          //レコードが存在する＝上書きのとき
          randId = sfDoc2.data().id;
        }
        await transaction.set(sfDocRef2, {
          id: randId,
          discussId: setdata.discussId,
          isflagone: setdata.isflagone,
          isflagtwo: setdata.isflagtwo,
          isflagthree: setdata.isflagthree,
          isflagfour: setdata.isflagfour,
          isflagfive: setdata.isflagfive,
          claim: setdata.claim,
        });
      }
    });
    return randId;
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false;
  }
}
export async function getPickupTransaction(lonpaid) {
  const db = getFirestore(firebaseApp);
  let sfDoc;

  try {
    await runTransaction(db, async (transaction) => {
      const sfDocRef = doc(db, "pickup", lonpaid);
      sfDoc = await transaction.get(sfDocRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }
    });
    return sfDoc;
  } catch (e) {
    //まだmovie作成してない場合
    //console.log("Transaction failed: ", e);
    return false;
  }
}
export async function pluspointUserSecret(userId, pluspoint) {
  const db = getFirestore(userApp);
  let sfDoc;
  try {
    await runTransaction(db, async (transaction) => {
      const sfDocRef = doc(db, "usersecret", userId);
      sfDoc = await transaction.get(sfDocRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      } else {
        await transaction.set(sfDocRef, {
          point: sfDoc.data().point + pluspoint,
        });
      }
    });
    return sfDoc.data().point + pluspoint;
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false;
  }
}

export async function minuspointUserSecret(userId, minuspoint) {
  const db = getFirestore(userApp);
  let sfDoc;
  try {
    await runTransaction(db, async (transaction) => {
      const sfDocRef = doc(db, "usersecret", userId);
      sfDoc = await transaction.get(sfDocRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      } else if (sfDoc.data().point >= minuspoint) {
        await transaction.set(sfDocRef, {
          point: sfDoc.data().point - minuspoint,
        });
      } else {
        console.log("Point not Enough: ", e);
        return false;
      }
    });
    return sfDoc.data().point - minuspoint;
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false;
  }
}

export async function createDiscussTransaction(lonpaid, setdata) {
  const db = getFirestore(firebaseApp);
  let randId = 0;
  try {
    await runTransaction(db, async (transaction) => {
      const sfDocRef1 = doc(db, "setting", "discuss");
      const sfDoc1 = await transaction.get(sfDocRef1);
      if (!sfDoc1.exists()) {
        throw "Document does not exist!";
      } else {
        const sfDocRef2 = doc(db, "discuss", lonpaid);
        const sfDoc2 = await transaction.get(sfDocRef2);
        if (!sfDoc2.exists()) {
          //レコードが存在しない＝新規作成のとき
          await transaction.set(sfDocRef1, {
            discussCount: sfDoc1.data().discussCount + 1,
          });
          randId = sfDoc1.data().discussCount + 1;
        } else {
          //レコードが存在する＝上書きのとき
          randId = sfDoc2.data().id;
        }

        await transaction.set(sfDocRef2, {
          id: randId,
          updatedAt: serverTimestamp(),
          ...setdata,
        });
      }
    });
    return randId;
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false;
  }
}

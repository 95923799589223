import { Grid, Container } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStyles } from "../Styles";
import {
  // get30Ranking,
  // getDiscussDescStartAfter,
  getRandomDiscuss,
} from "../common/Helper/FirebaseHelper";

import LoadingNewTop from "./NewTop/LoadingNewTop";
import NewLatestUpdate from "./NewTop/NewLatestUpdate";
import TStage1 from "./NewTop/TStage1";
import LoadingTopNew from "./Top/LoadingTopNew";
import LoadingTopRank from "./Top/LoadingTopRank";
// import TopShowTypeToggle from "./Top/TopShowTypeToggle";
import Ranking from "./Top/Ranking";
import Footer from "./components/Footer";
// import newicon from "../images/newicon.png";

type Props = {
  bgColor: any;
  styletext: any;
};

const NewTop: React.VFC<Props> = ({ bgColor, styletext }: Props) => {
  // export default function NewTop(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isloading, setIsLoding] = useState({ new: true, rank: true });
  const [idealist, setIdealist] = useState([]);
  const [ideaVotedList, setIdeaVotedList] = useState<string[]>([]);
  const [latestUpdateLonpaList, setLatestUpdateLonpaList] = useState<any>([]);
  const [targetDate, setTargetDate] = useState("");
  const [isFinishInit, setIsFinishInit] = useState(false);
  const [messageReflesh, setMessageReflesh] = useState(0);

  const [isParamReady, setIsParamReady] = useState(false);

  // const topTextSize = { new: "13px", rank: "15px" };

  const parseAsMoment = (dateTimeStr: any) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };

  useEffect(() => {
    console.log("uE_NewTop");

    const f = async () => {
      try {
        //新着のデータ取得
        await UpdateDate();
        setIsParamReady(true);
      } catch (e) {
        console.log("error");
        console.log(e);
        alert("データ取得に失敗しました");
        navigate("/Sorry");
      }
    };
    f();
  }, []);

  async function UpdateDate() {
    try {
      const randomDiscussListadd: any[] = [];
      //const targetDate2: any = targetDate;
      const ideaidlist: any = [];

      const querySnapshot2: any = await getRandomDiscuss(3);
      querySnapshot2.forEach((doc: any) => {
        // if (!doc.data().isDelete) {
        randomDiscussListadd.push({
          id: doc.id,
          claim: doc.data().claim,
          // parent: doc.data().parent,
          // isopinion: doc.data().isopinion,
          imageurl: doc.data().imageurl,
          agree: doc.data().agree,
          disagree: doc.data().disagree,
          // updatedAt: parseAsMoment(doc.data().updatedAt.toDate())
          //   .format("MM/DD HH:mm")
          //   .toString(),
        });
        ideaidlist.push(doc.id);
        // }
      });

      setLatestUpdateLonpaList(randomDiscussListadd);

      if (randomDiscussListadd.length > 2) {
        setIsLoding({ new: false, rank: false });
      } else {
        navigate("/Create/");
      }
    } catch {
      console.log("error297838");
    }
  }

  return (
    <>
      {isParamReady ? (
        <>
          <div style={{ backgroundColor: bgColor.back01 }}>
            <div style={{ height: "3vh" }}></div>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div
                    style={{ height: styletext.windowPhoneWidth * 0.1 }}
                  ></div>

                  <div
                    style={{
                      height: styletext.windowPhoneWidth * 0.5,
                      minWidth: styletext.windowPhoneWidth * 0.8,
                      maxWidth: styletext.windowPhoneWidth * 1,
                      left: 0,
                      right: 0,
                      marginLeft: "auto", // 左のマージンを自動に設定
                      marginRight: "auto", // 右のマージンを自動に設定
                    }}
                  >
                    {isloading.new ? (
                      <LoadingTopNew styletext={styletext} bgColor={bgColor} />
                    ) : (
                      <>
                        <NewLatestUpdate
                          disableHeader="true"
                          searchword="useurl"
                          idealist={latestUpdateLonpaList}
                          // ideaVotedList={[]}
                          bgColor={bgColor}
                          styletext={styletext}
                        />
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Container>
            <TStage1
              bgColor={bgColor}
              styletext={styletext}
              messageReflesh={messageReflesh}
              info={{
                isloding: true,
                messageLine: [
                  {
                    index: 1,
                    text: "Lonpaってなんなん",
                    vocal: "Bob",
                    isAgree: true,
                  },
                  {
                    index: 2,
                    text: "日常の話題を話す場やで",
                    vocal: "Bob",
                    isAgree: false,
                  },
                  {
                    index: 1,
                    text: "じゃあなんか話そう",
                    vocal: "Bob",
                    isAgree: true,
                  },
                  {
                    index: 2,
                    text: "上に話題が出てるから1つ選んでみて",
                    vocal: "Bob",
                    isAgree: false,
                  },
                  {
                    index: 1,
                    text: "どれも面白そうやけど、他にはないん？",
                    vocal: "Bob",
                    isAgree: true,
                  },
                  {
                    index: 5,
                    text: "上のバーの虫眼鏡ボタン押したら、話題を検索できるで",
                    vocal: "Bob",
                    isAgree: false,
                  },
                  {
                    index: 1,
                    text: "1つ選んでみよか",
                    vocal: "Bob",
                    isAgree: true,
                  },
                  {
                    index: 5,
                    text: "上の話題をタップしてみて",
                    vocal: "Bob",
                    isAgree: false,
                  },
                ],
              }}
            />
          </div>
          <Footer bgColor={bgColor} styletext={styletext} isimgshow={false} />
        </>
      ) : (
        <LoadingNewTop bgColor={bgColor} styletext={styletext} />
      )}
    </>
  );
};
export default NewTop;

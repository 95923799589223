import { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useStyles } from "../../../Styles";
import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { pointtableTypeA } from "../../../common/pointTable";
import {
  checkisExecutable,
  pointSubMakeIdea,
} from "../../../common/Helper/PointHelper";

export default function CreateIdeaModal(props) {
  const classes = useStyles();

  const [isUsePointCheck, setIsUsePointCheck] = useState(false);
  const [aiInput, setAiInput] = useState(
    "賛成意見と反対意見が分かれそうな、斬新で面白い話題を３つ考えてください ただし、話題だけ書いてください。３行だけ箇条書きで書いてください。"
  );
  const [aiOutput, setAiOutput] = useState("回答");
  const [executable, setExecutable] = useState({});
  const [purpose, setPurpose] = useState("");

  const createLonpaIdea = async (chatGPTMessage) => {
    setAiOutput("作成中");
    try {
      const functions = getFunctions();
      const messageCreate = httpsCallable(functions, "messageCreate");
      messageCreate({ message: chatGPTMessage }).then((result) => {
        if (result.data) {
          setAiOutput(result.data.aimessage);

          return result.data.aimessage;
        } else {
          setAiOutput("エラー");
          return false;
        }
      });
    } catch (error) {
      alert(error + "\nエラー、二度と押さないでください");
      setAiOutput("エラー");
      return false;
    }
  };

  const handleCloseinside = () => {
    props.setOpen(false);
  };

  async function checkAble() {
    const executable = await checkisExecutable(200);
    setExecutable(executable);
    setPurpose("lonpa記事（意見）のタイトル案生成");
    setIsUsePointCheck(true);
  }

  async function ExcuteJob() {
    pointSubMakeIdea("none");
    createLonpaIdea(aiInput);
    setIsUsePointCheck(false);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          {isUsePointCheck ? (
            <>
              <h2 id="transition-modal-title" align="center">
                {purpose}
              </h2>
              <div align="center">
                <br />
                現在のポイント：{executable.possession}
                <br />
                {executable.required}point消費して実行しますか？
                <br />
                {executable.isSubscribe ? (
                  <>
                    {executable.isExcutable ? (
                      <>
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            ExcuteJob();
                            setIsUsePointCheck(false);
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          実行
                        </Button>
                      </>
                    ) : (
                      <>
                        {executable.deficient}ポイント不足
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            setIsUsePointCheck(false);
                            navigate("/Payment/");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          ポイント確認
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {executable.isMember ? (
                      <>
                        サブスク登録しましょう
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            setIsUsePointCheck(false);
                            navigate("/Point/");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          サブスク登録
                        </Button>
                      </>
                    ) : (
                      <>
                        メンバ登録しましょう
                        <br />
                        <Button
                          color="primary"
                          disabled={false}
                          onClick={() => {
                            setIsUsePointCheck(false);
                            navigate("/Signup/");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          メンバ登録
                        </Button>
                      </>
                    )}
                  </>
                )}
                .............
                <Button
                  color="primary"
                  onClick={() => {
                    setIsUsePointCheck(false);
                  }}
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  戻る
                </Button>
                <br />
                内訳
                {pointtableTypeA}
              </div>
            </>
          ) : (
            <>
              <Grid item xs={12} style={{ backgroundColor: "#eee" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    fontWeight="bold"
                  >
                    <Checkbox
                      //{...label}
                      icon={<CircleIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      disabled
                      checked={true}
                    />
                    議題に困ったら
                  </Typography>
                  <br />
                  <TextField
                    fullWidth
                    multiline
                    maxRows={3}
                    minRows={1}
                    label="Input"
                    value={aiInput}
                    onChange={(e) => setAiInput(e.target.value)}
                  />
                  <br />
                  <Button
                    color="primary"
                    onClick={() => {
                      checkAble();
                    }}
                    style={{ fontSize: 20 }}
                  >
                    議題案の作成(200point消費)
                  </Button>
                  <br />
                  <TextField
                    fullWidth
                    multiline
                    maxRows={3}
                    minRows={1}
                    label="Output"
                    value={aiOutput}
                    onChange={(e) => setAiInput(e.target.value)}
                  />

                  <div style={{ height: "6vh" }}></div>
                </Box>
              </Grid>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

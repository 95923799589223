import { ListItem, ListItemText } from "@mui/material";

export default function LonpaItem(props) {
  return (
    <ListItem alignItems="flex-start" style={{ padding: "5px 5px 5px 5px" }}>
      <ListItemText
        primary={
          <div
            style={{
              fontSize: props.claimChildSize.textsize2,
              fontWeight: "bold",
              width: props.itemwidth,
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              display: "grid",
              alignItems: "center",
              height: "100px",
              color: props.bgColor.text10.fontColor,
            }}
            align="center"
          >
            {props.title}
          </div>
        }
      />
    </ListItem>
  );
}

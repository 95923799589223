import { firebaseApp, userApp } from "../firebase";

import {
  doc,
  documentId,
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  orderBy,
  startAfter,
  endBefore,
  limit,
  runTransaction,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ConnectedTvOutlined } from "@mui/icons-material";

export async function getDiscuss(id1) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "discuss", id1));
  const onedata = await getDoc(q);
  return onedata;
}

export async function getDiscussDescStartAfter(index, limitnum) {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "discuss"),
    //where("isopinion", "==", false),
    orderBy("updatedAt", "desc"),
    limit(limitnum),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getRandomDiscuss(picnum) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "setting", "discuss"));
  const querySnapshot = await getDoc(q);
  if (querySnapshot.data().discussCount > 3) {
    const list = [];
    for (let i = 0; i < picnum; i++) {
      let randomNumber;
      do {
        randomNumber =
          Math.floor(Math.random() * querySnapshot.data().discussCount) + 1; // 乱数を生成
      } while (list.includes(randomNumber)); // 生成した乱数がリスト内に存在するか確認
      list.push(randomNumber);
    }

    let q2 = query(collection(db, "discuss"), where("id", "in", list));
    const querySnapshot2 = await getDocs(q2);
    return querySnapshot2;
  }
  return false;
}

export async function getRandomDiscussId() {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "setting", "discuss"));
  const querySnapshot = await getDoc(q);

  const list = [];

  let randomNumber =
    Math.floor(Math.random() * querySnapshot.data().discussCount) + 1; // 乱数を生成

  return randomNumber;
}

export async function getDiscussById(id) {
  const db = getFirestore(firebaseApp);
  let q2 = query(collection(db, "discuss"), where("id", "==", id));
  const querySnapshot2 = await getDocs(q2);
  if (!querySnapshot2.empty) {
    return querySnapshot2.docs[0]; // 最初のドキュメントのデータを返す（1つのデータのみを想定）
  } else {
    return null; // 該当するドキュメントがない場合はnullを返す
  }
}

export async function setDiscuss(id1, data) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "discuss", id1));
  await setDoc(q, data, { merge: true });
}

export async function getNumPickup(picknum) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "pickup"), orderBy("id"), limit(picknum));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}
export async function getNumPickupStartAfter(picknum, index) {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "pickup"),
    orderBy("id"),
    limit(picknum),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function get30Ranking(id1) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "ranking"), orderBy("rank"), limit(30));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function setLonpaChild(id1, id2, data) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "lonpa", id1, "child", id2));
  await setDoc(q, data, { merge: true });
}

export async function createLonpaChild(id1, data) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "lonpa", id1, "child"));
  let docRef = await addDoc(q, data);
  return docRef;
}
export async function deleteLonpaChild(id1, id2) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "lonpa", id1, "child", id2));
  await deleteDoc(q);
}

export async function getAllLonpaChild(id1) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "lonpa", id1, "child"));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function setLonpaChildVote(id1, id2, id3, data) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "lonpa", id1, "child", id2, "vote", id3));
  await setDoc(q, data, { merge: true });
}

export async function getAllLonpaChildVote(id1, id2) {
  const db = getFirestore(firebaseApp);
  let querySnapshot;
  try {
    const q = query(collection(db, "lonpa", id1, "child", id2, "vote"));
    querySnapshot = await getDocs(q);
  } catch {
    querySnapshot = false;
  }
  return querySnapshot;
}

export async function DeleteLonpaChildVote(id1, id2, id3) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "lonpa", id1, "child", id2, "vote", id3));
  await deleteDoc(q);
}

export async function createLonpa(data) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "lonpa"));
  let docRef = await addDoc(q, data);
  return docRef;
}

export async function setLonpa(id1, data) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "lonpa", id1));
  const setdoc = await setDoc(q, data);
  return setdoc;
}

export async function getLonpa(id1) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "lonpa", id1));
  const onedata = await getDoc(q);

  return onedata;
}

export async function getNextLonpa(index) {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "lonpa"),
    where("isopinion", "==", true),
    where("isDelete", "==", false),
    orderBy(documentId()),
    limit(1),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getPrevLonpa(index) {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "lonpa"),
    where("isopinion", "==", true),
    where("isDelete", "==", false),
    orderBy(documentId(), "desc"),
    limit(1),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getNextPickup(index) {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "pickup"),
    orderBy("id"),
    limit(1),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getPrevPickup(index) {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "pickup"),
    orderBy("id", "desc"),
    limit(1),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getLonpaList(itemlist) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "lonpa");
  if (itemlist.length === 0) {
    return [];
  }

  let list10 = [];
  let querySnapshot;
  let q;
  let alllistdatas = [];

  // mapのasyncだとawaitできないので，おとなしくfor使う
  //入ってくる，lonpaidのリスト分ループする
  for (let i = 1; i <= itemlist.length; i++) {
    //console.log(i)
    list10.push(itemlist[i - 1]);
    //list10に要素数が10個なるように格納していく
    if (i % 10 == 0) {
      //console.log(list10.length)
      //list10の要素が10個になったらfirebaseにデータをとりにいく
      q = query(collectionRef, where(documentId(), "in", list10));
      querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        alllistdatas.push(doc);
      });
      //リストを初期化する
      list10 = [];
    }
  }
  //ループを抜けたときに，まだ取得できていない要素がある場合
  //つまり，mod10=0でない場合
  if (list10.length % 10 != 0) {
    //console.log(list10.length)
    q = query(collectionRef, where(documentId(), "in", list10));
    querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      alllistdatas.push(doc);
    });
    list10 = [];
  }
  return alllistdatas;
}

export async function getLonpaWithLimit(limitNum) {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "lonpa"),
    limit(limitNum),
    where("isopinion", "==", true)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getLonpaWithQuery(queryParams) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "lonpa"), ...queryParams);
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getDiscussWithQuery(queryParams) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "discuss"), ...queryParams);
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function get72Tag() {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "tag"), orderBy("count", "desc"), limit(72));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function updateLonpa(lonpaid, data) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "lonpa", lonpaid));
  await updateDoc(q, data);
}

export async function updateLonpaChild(id1, id2, data) {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "lonpa", id1, "child", id2));
  await updateDoc(q, data);
}

export async function getAllLonpaComment(lonpaId) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "lonpa", lonpaId, "comment"), orderBy("date"));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}
export async function setUserPublic(userId, data) {
  const db = getFirestore(userApp);
  let q = query(doc(db, "userpublic", userId));
  await setDoc(q, data);
}

export async function updateUserPublic(userId, data) {
  const db = getFirestore(userApp);
  let q = query(doc(db, "userpublic", userId));
  await updateDoc(q, data);
}

export async function getUserPublic(userId) {
  const db = getFirestore(userApp);
  let q = query(doc(db, "userpublic", userId));
  const onedata = await getDoc(q);
  return onedata;
}

export async function getUserSecret(userId) {
  const db = getFirestore(userApp);
  let q = query(doc(db, "usersecret", userId));
  const onedata = await getDoc(q);
  return onedata;
}

export async function getUserPrivate(userId) {
  const db = getFirestore(userApp);
  let q = query(doc(db, "userprivate", userId));
  const onedata = await getDoc(q);
  return onedata;
}
export async function setUserPrivate(userId, data) {
  const db = getFirestore(userApp);
  let q = query(doc(db, "userprivate", userId));
  await setDoc(q, data);
}

export async function getListUserPrivateVote(itemlist) {
  const db = getFirestore(userApp);
  const collectionRef = collection(
    db,
    "userprivate",
    getAuth(userApp).currentUser.uid,
    "vote"
  );

  let list10 = [];
  let querySnapshot;
  let q;
  let alllistdatas = [];
  // mapのasyncだとawaitできないので，おとなしくfor使う
  //入ってくる，lonpaidのリスト分ループする
  for (let i = 0; i < itemlist.length; i++) {
    list10.push(itemlist[i]);
    //list10に要素数が10個なるように格納していく
    if (list10.length == 10) {
      //list10の要素が10個になったらfirebaseにデータをとりにいく
      q = query(collectionRef, where(documentId(), "in", list10));
      querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        alllistdatas.push(doc);
      });
      //リストを初期化する
      list10 = [];
    }
  }
  //ループを抜けたときに，まだ取得できていない要素がある場合
  //つまり，mod10=0でない場合
  if (list10.length > 0) {
    q = query(collectionRef, where(documentId(), "in", list10));
    querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      alllistdatas.push(doc);
    });
    list10 = [];
  }
  return alllistdatas;
}

export async function getUserPrivateVoteDescStartAfter(index, limitnum) {
  const db = getFirestore(userApp);
  let q = query(
    collection(db, "userprivate", getAuth(userApp).currentUser.uid, "vote"),
    orderBy("votedAt", "desc"),
    limit(limitnum),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getUserPrivateVote(lonpaid) {
  const db = getFirestore(userApp);
  let q = query(
    doc(db, "userprivate", getAuth(userApp).currentUser.uid, "vote", lonpaid)
  );
  const onedoc = await getDoc(q);
  return onedoc;
}
export async function deleteUserPrivateVote(lonpaid) {
  const db = getFirestore(userApp);
  let q = query(
    doc(db, "userprivate", getAuth(userApp).currentUser.uid, "vote", lonpaid)
  );
  await deleteDoc(q);
}

export async function setUserPrivateVote(lonpaid, data) {
  const db = getFirestore(userApp);
  let q = doc(
    db,
    "userprivate",
    getAuth(userApp).currentUser.uid,
    "vote",
    lonpaid
  );
  const onedata = await setDoc(q, data, { merge: true });
  return onedata;
}

export async function setUserPrivateUpload(lonpaid, data) {
  const db = getFirestore(userApp);
  let q = doc(
    db,
    "userprivate",
    getAuth(userApp).currentUser.uid,
    "upload",
    lonpaid
  );
  const onedata = await setDoc(q, data, { merge: true });
  return onedata;
}
export async function getUserPrivateUploadStartAfter(index, limitnum) {
  const db = getFirestore(userApp);
  let q = query(
    collection(db, "userprivate", getAuth(userApp).currentUser.uid, "upload"),
    orderBy("num"),
    limit(limitnum),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getAllUserPrivateCreateLonpa() {
  const db = getFirestore(userApp);
  let q = query(
    collection(
      db,
      "userprivate",
      getAuth(userApp).currentUser.uid,
      "createLonpa"
    )
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getUserPrivateCreateLonpaDescStartAfter(index, limitnum) {
  let q = query(
    collection(
      getFirestore(userApp),
      "userprivate",
      getAuth(userApp).currentUser.uid,
      "createLonpa"
    ),
    orderBy("createdAt", "desc"),
    limit(limitnum),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getUserPrivateCreateLonpa(lonpaid) {
  const db = getFirestore(userApp);
  let q = query(
    doc(
      db,
      "userprivate",
      getAuth(userApp).currentUser.uid,
      "createLonpa",
      lonpaid
    )
  );
  const onedoc = await getDoc(q);
  return onedoc;
}

export async function setUserPrivateCreateLonpa(lonpaid, data) {
  const db = getFirestore(userApp);
  let q = doc(
    db,
    "userprivate",
    getAuth(userApp).currentUser.uid,
    "createLonpa",
    lonpaid
  );
  const onedata = await setDoc(q, data, { merge: true });
  return onedata;
}
export async function updateUserPrivateCreateLonpa(lonpaid, data) {
  const db = getFirestore(userApp);
  let q = query(
    doc(
      db,
      "userprivate",
      getAuth(userApp).currentUser.uid,
      "createLonpa",
      lonpaid
    )
  );
  await updateDoc(q, data);
}

export async function addVote(data) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "vote"));
  await addDoc(q, data);
}

export async function addChat(id1, data) {
  const db = getFirestore(firebaseApp);
  let q = query(collection(db, "lonpa", id1, "comment"));
  await addDoc(q, data);
}

export async function getUserAvatarList(itemlist) {
  const db = getFirestore(userApp);
  const collectionRef = collection(db, "userpublic");
  if (itemlist.length === 0) {
    return [];
  }
  // const q = query(collectionRef, where(documentId(), "in", itemlist));
  // const querySnapshot = await getDocs(q);
  // return (querySnapshot);
  let list10 = [];
  let querySnapshot;
  let q;
  let alllistdatas = [];
  // mapのasyncだとawaitできないので，おとなしくfor使う
  //入ってくる，lonpaidのリスト分ループする
  for (let i = 0; i < itemlist.length; i++) {
    list10.push(itemlist[i]);
    //list10に要素数が10個なるように格納していく
    if (list10.length == 10) {
      //list10の要素が10個になったらfirebaseにデータをとりにいく
      q = query(collectionRef, where(documentId(), "in", list10));
      querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        alllistdatas.push(doc);
      });
      //リストを初期化する
      list10 = [];
    }
  }
  //ループを抜けたときに，まだ取得できていない要素がある場合
  //つまり，mod10=0でない場合
  if (list10.length < 10) {
    q = query(collectionRef, where(documentId(), "in", list10));
    querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      alllistdatas.push(doc);
    });
    list10 = [];
  }
  return alllistdatas;
}

export async function getLonpaDescStartAfter(index, limitnum) {
  const db = getFirestore(firebaseApp);
  let q = query(
    collection(db, "lonpa"),
    //where("isopinion", "==", false),
    orderBy("updatedAt", "desc"),
    limit(limitnum),
    startAfter(index)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function getUserAvatar(itemlist) {
  const db = getFirestore(userApp);
  const collectionRef = collection(db, "userpublic");
  let q = query(collectionRef, where(documentId(), "==", itemlist));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export async function updateTagNum(tag) {
  const db = getFirestore(firebaseApp);
  const collectionRef = collection(db, "tag");
  let q = query(collectionRef, where("name", "==", tag));
  const querySnapshot = await getDocs(q);
  const tagNum = (() => {
    try {
      return querySnapshot.docs.pop().data().count;
    } catch (e) {
      return 0;
    }
  })();
  const docId = (() => {
    try {
      return querySnapshot.docs.pop().id;
    } catch (e) {
      return null;
    }
  })();
  if (docId) {
    let existq = doc(db, "tag", docId);
    await setDoc(existq, { name: tag, count: tagNum + 1 });
  } else {
    let newCollectionRef = collection(db, "tag");
    await addDoc(newCollectionRef, { name: tag, count: tagNum + 1 });
  }
}

export async function getRandom() {
  const db = getFirestore(firebaseApp);
  let q = query(doc(db, "setting", "pickup"));
  const querySnapshot = await getDoc(q);
  //console.log(querySnapshot.data().pickupCount);
  const randNum =
    Math.floor(Math.random() * querySnapshot.data().pickupCount) + 1;
  //console.log(randNum);

  let q2 = query(collection(db, "pickup"), where("id", "==", randNum));
  const querySnapshot2 = await getDocs(q2);
  const pickupTarget = querySnapshot2.docs.pop().id;
  //console.log(pickupTarget);

  return pickupTarget;
}

export async function createCheckoutSessions(id1, data) {
  const db = getFirestore(userApp);
  const q = query(collection(db, "customers", id1, "checkout_sessions"));
  const docRef = await addDoc(q, data);
  return docRef;
}

export async function getCustomersPayment(id1) {
  const db = getFirestore(userApp);
  let querySnapshot;
  try {
    const q = query(collection(db, "customers", id1, "payments"));
    querySnapshot = await getDocs(q);
  } catch {
    querySnapshot = false;
  }
  return querySnapshot;
}

export async function getCustomersSubscriptions(id1) {
  const db = getFirestore(userApp);
  let querySnapshot;
  try {
    const q = query(collection(db, "customers", id1, "subscriptions"));
    querySnapshot = await getDocs(q);
  } catch {
    querySnapshot = false;
  }
  return querySnapshot;
}

export async function createUserSecretHistory(id1, data) {
  const db = getFirestore(userApp);
  const q = query(collection(db, "usersecret", id1, "history"));
  const docRef = await addDoc(q, data);
  return docRef;
}

export async function getUserSecretHistory12(id1) {
  const db = getFirestore(userApp);
  let querySnapshot;
  try {
    const q = query(
      collection(db, "usersecret", id1, "history"),
      orderBy("date", "desc"),
      limit(12)
    );
    querySnapshot = await getDocs(q);
  } catch {
    querySnapshot = false;
  }
  return querySnapshot;
}

export async function getUserSecretPoint(id1) {
  const db = getFirestore(userApp);
  let onedata;
  let point;
  try {
    const q = query(doc(db, "usersecret", id1));
    onedata = await getDoc(q);
    point = onedata.data().point;
  } catch {
    onedata = false;
    point = 0;
  }
  return point;
}

export default function LonpaPictureType1(props) {
  return (
    <>
      {" "}
      <div className="comp" align="center" style={{ display: "flex" }}>
        <img alt="icon" src={props.png} width="150" height="150" />
      </div>
      <div className="comp" align="center" style={{ display: "flex" }}>
        <img alt="icon" src={props.png2} width="150" height="150" />
      </div>
      <div className="comp" align="center" style={{ display: "flex" }}>
        <img alt="icon" src={props.png3} width="150" height="150" />
      </div>
      <div className="comp" align="center" style={{ display: "flex" }}>
        <img alt="icon" src={props.png4} width="150" height="150" />
      </div>
    </>
  );
}

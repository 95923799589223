import { useQRCode } from "next-qrcode";

export default function LonpaPictureType99(props) {
  const { Canvas } = useQRCode();
  return (
    <>
      <Canvas
        text={props.url}
        options={{
          type: "image/jpeg",
          quality: 0.3,
          level: "M",
          margin: 3,
          scale: 4,
          width: 150,
          color: {
            dark: "#000000",
            light: "#FFFFFF",
          },
        }}
      />
    </>
  );
}

import React, { useState } from "react";

import LonpaItem from "../Search/LonpaItem";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import SettingModal from "./Modal/SettingModal";
import VoteListModal from "./Modal/VoteListModal";

import { useStyles } from "../../Styles";

// import { useSpring, animated } from "react-spring";
import { useGestureResponder } from "react-gesture-responder";
import "./Sliderstyle.css";

import BoyIcon from "@mui/icons-material/Boy";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SettingsIcon from "@mui/icons-material/Settings";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import { useParams } from "react-router-dom";

import { getAuth } from "firebase/auth";

import { useNavigate } from "react-router-dom";

export default function LonpaBlock(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  let { lonpaid } = useParams();
  const [settingmodalopen, setSettingmodalopen] = useState(false);
  const [votelistmodalopen, setVotelistmodalopen] = useState(false);
  // const [user, setUser] = useState(
  //   getAuth(userApp).currentUser ?? { emailVerified: false }
  // );
  //effectの種類を設定
  const effect = {
    bigBalanced: classes.bigglasseffect + " " + "sliding-pane",
    smallBalanced: classes.smallglasseffect + " " + "sliding-pane",
    middleBalanced: classes.middleglasseffect + " " + "sliding-pane",
    bigDisagreeLevel1:
      classes.bigglasseffect +
      " " +
      classes.bigDisagreeLevel1effect +
      " " +
      "sliding-pane",
    smallDisagreeLevel1:
      classes.smallglasseffect +
      " " +
      classes.smallDisagreeLevel1effect +
      " " +
      "sliding-pane",
    middleDisagreeLevel1:
      classes.middleglasseffect +
      " " +
      classes.middleDisagreeLevel1effect +
      " " +
      "sliding-pane",
    bigDisagreeLevel2:
      classes.bigglasseffect +
      " " +
      classes.bigDisagreeLevel2effect +
      " " +
      "sliding-pane",
    smallDisagreeLevel2:
      classes.smallglasseffect +
      " " +
      classes.smallDisagreeLevel2effect +
      " " +
      "sliding-pane",
    middleDisagreeLevel2:
      classes.middleglasseffect +
      " " +
      classes.middleDisagreeLevel2effect +
      " " +
      "sliding-pane",
    bigAgreeLevel1:
      classes.bigglasseffect +
      " " +
      classes.bigAgreeLevel1effect +
      " " +
      "sliding-pane",
    smallAgreeLevel1:
      classes.smallglasseffect +
      " " +
      classes.smallAgreeLevel1effect +
      " " +
      "sliding-pane",
    middleAgreeLevel1:
      classes.middleglasseffect +
      " " +
      classes.middleAgreeLevel1effect +
      " " +
      "sliding-pane",
  };
  //どの条件で、どのeffectをつかうのかを定義
  let pattern;
  if (props.item.data.oppositionStatus === "disagreeLevel2") {
    if (props.styletext.glassstyle === "big") {
      pattern = effect.bigDisagreeLevel2;
    } else if (props.styletext.glassstyle === "middle") {
      pattern = effect.middleDisagreeLevel2;
    } else if (props.styletext.glassstyle === "small") {
      pattern = effect.smallDisagreeLevel2;
    }
  } else if (props.item.data.oppositionStatus === "disagreeLevel1") {
    if (props.styletext.glassstyle === "big") {
      pattern = effect.bigDisagreeLevel1;
    } else if (props.styletext.glassstyle === "middle") {
      pattern = effect.middleDisagreeLevel1;
    } else if (props.styletext.glassstyle === "small") {
      pattern = effect.smallDisagreeLevel1;
    }
  } else if (props.item.data.oppositionStatus === "agreeLevel1") {
    if (props.styletext.glassstyle === "big") {
      pattern = effect.bigAgreeLevel1;
    } else if (props.styletext.glassstyle === "middle") {
      pattern = effect.middleAgreeLevel1;
    } else if (props.styletext.glassstyle === "small") {
      pattern = effect.smallAgreeLevel1;
    }
  } else {
    if (props.styletext.glassstyle === "balanced") {
      pattern = effect.bigBalanced;
    } else if (props.styletext.glassstyle === "middle") {
      pattern = effect.middleBalanced;
    } else if (props.styletext.glassstyle === "small") {
      pattern = effect.smallBalanced;
    }
  }

  const [line, setline] = useState("1px rgba(192,192,192,0.4) solid");
  const [movedx, setMovedx] = useState(0);
  const [open, setOpen] = useState(false);
  // const [{ x }, setx] = useSpring(() => {
  //   return { x: 0, immediate: true };
  // });

  const { bind } = useGestureResponder({
    // onStartShouldSet: () => true,
    // onMove: ({ delta, xy }) => {},
    // onRelease: ({ delta }) => {
    //   if (delta[1] > -20 && delta[1] < 20) {
    //     if (open) {
    //       //setline("1px rgba(192,192,192,0.4) solid")
    //       setx({ x: 0, immediate: false });
    //       setMovedx(0);
    //       setOpen(false);
    //     } else {
    //       //setline("1px rgba(192,192,192,0.4) solid")
    //       setx({ x: -160, immediate: false });
    //       setMovedx(-160);
    //       setOpen(true);
    //     }
    //   }
    // },
  });

  function addResistance(x) {
    const absX = Math.abs(x);

    if (absX > 60) {
      return x + (absX - 60) * 0.6 * (x < 0 ? 1 : -1);
    }
    return x;
  }

  return (
    <>
      <div className="list-item">
        <div className={pattern}>
          <div
            className="background"
            style={{
              //borderRadius: '5px',
              overflow: "hidden",
              transform: "background 0.3s ease",
              background: "rgba(255,255,255, 0)",
            }}
          >
            <div
              style={{ width: "100%", minHeight: "100px" }}
              onClick={() => {
                props.ChoiceData(props.item.id);
              }}
            >
              <LonpaItem
                key={props.item.id}
                index={props.item.id}
                id={props.item.id}
                title={props.item.data.claim}
                agree={props.item.agree}
                disagree={props.item.disagree}
                ChoiceData={props.ChoiceData}
                parentclaim={props.parentclaim}
                styletext={props.styletext}
                itemwidth={"44vw"}
                claimChildSize={props.claimChildSize}
                bgColor={props.bgColor}
              />
            </div>
          </div>
        </div>
        <div
          {...bind}
          style={{
            height: "120px",
          }}
        >
          <p
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
              padding: "0",
            }}
          >
            {props.isbuttonlock ? (
              <>
                <IconButton
                  aria-label="vote"
                  size="middle"
                  style={{ display: "inline-block", paddingLeft: "6px" }}
                  disabled
                >
                  <FavoriteBorderIcon fontSize="inherit" />
                </IconButton>
              </>
            ) : (
              <>
                {props.ischoiced ? (
                  <>
                    {props.item.ischoiceditem ? (
                      <>
                        {!props.isagree ? (
                          <>
                            <IconButton
                              aria-label="vote"
                              size="middle"
                              onClick={() =>
                                props.pushLon(
                                  props.item.id,
                                  props.item.data,
                                  false
                                )
                              }
                              sx={{ color: props.bgColor.back13 }}
                              style={{
                                display: "inline-block",
                                paddingLeft: "6px",
                              }}
                            >
                              <FavoriteIcon fontSize="inherit" />
                              {props.item.data.votenum}
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              aria-label="vote"
                              size="middle"
                              onClick={() =>
                                props.pushLon(
                                  props.item.id,
                                  props.item.data,
                                  false
                                )
                              }
                              sx={{ color: props.bgColor.back14 }}
                              style={{
                                display: "inline-block",
                                paddingLeft: "6px",
                              }}
                            >
                              <FavoriteIcon fontSize="inherit" />
                              {props.item.data.votenum}
                            </IconButton>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <IconButton
                          aria-label="vote"
                          size="middle"
                          onClick={() =>
                            props.pushLon(props.item.id, props.item.data, true)
                          }
                          style={{
                            display: "inline-block",
                            paddingLeft: "6px",
                            color: props.isagree
                              ? props.bgColor.back14
                              : props.bgColor.back13,
                          }}
                        >
                          <FavoriteBorderIcon fontSize="inherit" />
                          {props.item.data.votenum}
                        </IconButton>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* {props.userEmailVerified ? ( */}
                    <>
                      <IconButton
                        aria-label="vote"
                        size="middle"
                        onClick={() =>
                          props.pushLon(props.item.id, props.item.data, true)
                        }
                        style={{
                          display: "inline-block",
                          paddingLeft: "6px",
                          color: props.isagree
                            ? props.bgColor.back14
                            : props.bgColor.back13,
                        }}
                      >
                        <FavoriteBorderIcon fontSize="inherit" />
                        {props.item.data.votenum}
                      </IconButton>
                    </>
                    {/* // ) : (
                    //   <>
                    //     <IconButton
                    //       aria-label="vote"
                    //       size="middle"
                    //       // disabled
                    //       onClick={() => {
                    //         alert(
                    //           "投票するには，アカウント作成 ＆ メール検証が必要です"
                    //         );
                    //         navigate("/Signin");
                    //       }}
                    //       style={{
                    //         display: "inline-block",
                    //         paddingLeft: "6px",
                    //       }}
                    //     >
                    //       <FavoriteBorderIcon fontSize="inherit" />
                    //       {props.item.data.votenum}
                    //     </IconButton>
                    //   </>
                    // )} */}
                  </>
                )}
              </>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;
          </p>
        </div>
      </div>
      <SettingModal
        id={lonpaid}
        open={settingmodalopen}
        setOpen={setSettingmodalopen}
        increseUpdate={() => props.increseUpdate()}
        item={props.item}
      />
      <VoteListModal
        id={lonpaid}
        open={votelistmodalopen}
        setOpen={setVotelistmodalopen}
        increseUpdate={() => props.increseUpdate()}
        item={props.item}
      />
    </>
  );
}

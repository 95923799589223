import { AddAPhoto } from "@mui/icons-material";
import React from "react";
import styled, { keyframes } from "styled-components";

// @keyframes を使用して上にスクロールするアニメーションを定義
const scrollDownAnimation = keyframes`
0%, 5% { /* アニメーションの最初の5%の間、0%の位置に待機 */
transform: translateY(0%);
}80%{
  transform: translateY(27%);
}90%{
  transform: translateY(25%);
}100%{
  transform: translateY(26%);
}
`;

// アニメーションを適用するスタイルドコンポーネント
const AnimatedImage = styled.img`
  animation: ${scrollDownAnimation} 0.3s linear 1 forwards;
`;

type Props = {
  img1: any;
  setIsStage25Finish: any;
};

const ImageDown: React.VFC<Props> = ({ img1, setIsStage25Finish }: Props) => {
  const handleAnimationEnd = () => {
    setIsStage25Finish(true);
  };
  return (
    <AnimatedImage
      style={{
        width: "100%", // この変更により、画像はtdの幅に合わせて表示されます
      }}
      src={img1}
      alt="Scrolling Down"
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

export default ImageDown;

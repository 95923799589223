import { Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";

// アニメーション用のスタイル付きコンポーネントを作成
const AnimatedBox = styled(Box)(({ theme }) => ({
  "@keyframes enter": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  animation: "enter 2s ease-out",
}));

type Props = {
  bgColor: any;
  styletext: any;
  info: any;
  messageReflesh: number;
};

const MessageList: React.VFC<Props> = ({
  bgColor,
  styletext,
  info,
  messageReflesh,
}: Props) => {
  const [messages, setMessages] = useState([
    { index: 1, text: "読み込み中", vocal: "Bob", isAgree: false },
    { index: 2, text: "読み込み中", vocal: "Bob", isAgree: true },
  ]);
  const [index, setIndex] = useState(0); // 現在のメッセージインデックス
  const [isInit, setIsInit] = useState(false); // 現在のメッセージインデックス

  const [opacity2, setOpacity2] = useState(0);

  // デモのために、コンポーネントがマウントされた後に新しいメッセージを追加
  useEffect(() => {
    // console.log("uE_Message");
    if (!info.isLoding) {
      const messageLength = info.messageLine.length;
      setIndex((index + 1) % messageLength);
      const timer = setTimeout(() => {
        setMessages([
          info.messageLine[index],
          info.messageLine[(index + 1) % messageLength],
        ]);
      }, 3000);
      if (!isInit) {
        setMessages([info.messageLine[0], info.messageLine[1]]);
        setIsInit(true);

        setOpacity2(1);
      }
      return () => clearTimeout(timer);
    }
  }, [info.isLoding, messages]);

  // リフレッシュ
  useEffect(() => {
    console.log("uE_Message_Refresh");
    // console.log("refresh");
    if (messageReflesh >= 1) {
      setOpacity2(0);
      setIsInit(false);
      setIndex(0);
    }
  }, [messageReflesh]);

  const combinedStyles2: React.CSSProperties = {
    transition: "opacity2 0.3s ease-in-out",
    opacity: opacity2,
  };

  return (
    <div
      style={{
        ...combinedStyles2,
      }}
    >
      {!isInit ? (
        <></>
      ) : (
        <>
          <Box
            sx={{ width: styletext.windowPhoneWidth, boxSizing: "border-box" }}
          >
            {messages.map((message) => (
              <AnimatedBox
                key={message.index}
                sx={{
                  position: "relative",
                  maxWidth: "70%",
                  marginY: 1,
                  marginLeft: message.isAgree ? "10px" : "auto",
                  marginRight: message.isAgree ? "auto" : "10px",
                  padding: "8px",
                  backgroundColor: message.isAgree
                    ? bgColor.back03
                    : bgColor.back02,
                  color: message.isAgree ? "#000000" : "#000000",
                  borderRadius: "20px",
                  textAlign: "center",
                  lineHeight: "1.1em", // テキストの行の高さを2emに設定
                  minHeight: "50px", // ここで最小高さを設定
                  display: "flex", // Flexboxを使う
                  alignItems: "center", // アイテムを垂直方向に中央に配置
                  justifyContent: "center", // アイテムを水平方向に中央に配置
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    bottom: "-25px", // 三角形の位置調整
                    left: message.isAgree ? "20%" : "80%",
                    width: "0",
                    height: "0",
                    border: "15px solid transparent", // 三角形のサイズ調整
                    borderTopColor: message.isAgree
                      ? bgColor.back03
                      : bgColor.back02, // 吹き出しと同じ色にする
                  },
                }}
              >
                {message.text}
              </AnimatedBox>
            ))}
          </Box>
        </>
      )}
    </div>
  );
};

export default MessageList;

import Signin from "./Signin";
import Signup from "./Signup";

import { Grid, Container, IconButton, Button, Link } from "@mui/material";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import { useNavigate } from "react-router-dom";

import TitleBar from "./LonpaExplain/TitleBar";
import Footer from "./components/Footer";

function MovieMakerExplain(props) {
  const navigate = useNavigate();
  const bigtext = {
    fontWeight: "bold",
    fontSize: "20px",
    textDecoration: "underline",
  };
  return (
    <>
      <div
        style={{
          backgroundColor: props.bgColor.back01,
          color: props.bgColor.text01.fontColor,
        }}
        align="left"
      >
        <div style={{ height: "6vh" }}></div>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TitleBar bgColor={props.bgColor} num={"01"} text={"権限取得"} />
              <br />
              <div style={bigtext}>有料会員登録</div>
              <br />
              動画生成には、サブスクリプション登録が必要です
              <br />
              登録前に
              <Link
                color="inherit"
                href="https://line.me/R/ti/p/@729gokgs"
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  textDecoration: "none",
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                お問い合わせ
              </Link>
              にてご相談いただくこと
              <br />
              推奨いたします
              <br />
              <br />
              まずは
              <Button
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Signin/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                ログイン
              </Button>
              しましょう
              <br />
              次に
              <Button
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Point/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                サブスクリプション登録
              </Button>
              を実施します．
              <br />
              <br />
              <TitleBar
                bgColor={props.bgColor}
                num={"02"}
                text={"意見の選択"}
              />
              <br />
              <div style={bigtext}>動画生成画面へ遷移</div>
              <br />
              動画を作成したい
              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Lonpa/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                意見
              </Button>
              へアクセスし
              <br />
              <IconButton
                aria-label="delete"
                size="small"
                style={{
                  color: props.bgColor.text01.fontColor,
                  textAlign: "center",
                }}
              >
                <AutoFixNormalIcon fontSize="inherit" />
              </IconButton>
              のボタンを押下ください
              <br />
              <br />
              <img
                src={props.bgColor.explainpic01}
                style={{
                  width: "80vw", // 例えばビューポートの幅全体
                  maxWidth: "400px",
                  // height: (721/2)+'px', // 例えばビューポートの高さ全体
                }}
              ></img>
              <br />
              <br />
              <TitleBar bgColor={props.bgColor} num={"03"} text={"Movie作成"} />
              <br />
              <div style={bigtext}>comming soon..</div>
              <br />
            </Grid>
          </Grid>
        </Container>
        <div style={{ height: "2vh" }}></div>
      </div>
      <Footer
        bgColor={props.bgColor}
        styletext={props.styletext}
        isimgshow={true}
      />
    </>
  );
}
export default MovieMakerExplain;

import {
  Box,
  CircularProgress,
  createTheme,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Compressor from "compressorjs";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { useRef, useState } from "react";
// import styled from "styled-components";

import Circle from "./Circle";
// import { updateLonpa } from "../../common/Helper/FirebaseHelper";

// CircularProgress をカスタマイズ
const CustomCircularProgress = styled(CircularProgress)({
  "& .MuiCircularProgress-circle": {
    strokeLinecap: "round", // 端を丸くする
    strokeWidth: "20", // 線の太さを変更
    transform: "translate(-30%, -30%)",
  },
  // transform: 'translate(-30%, -30%)'
});

type Props = {
  bgColor: any;
  styletext: any;
  disagreeratio: number;
};

const LonpaPieChart: React.VFC<Props> = ({
  bgColor,
  styletext,
  disagreeratio,
}: Props) => {
  const inputRef = useRef(null);
  const circlesize = styletext.windowPhoneWidth * 0.5;
  const strokecirclesize = styletext.windowPhoneWidth * 0.14;

  return (
    <>
      <Circle
        size={circlesize}
        value={100}
        strokeWidth={strokecirclesize}
        color={bgColor.back03}
      />

      <Circle
        size={circlesize}
        value={disagreeratio}
        strokeWidth={strokecirclesize}
        color={bgColor.back02}
      />
    </>
  );
};
export default LonpaPieChart;

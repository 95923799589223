import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Button, TextField } from "@mui/material";
import saveAs from "file-saver";
import { getMovieString } from "../../common/Helper/StorageHelper";

import { Controller } from "react-hook-form";

export default function MoviePlayer(props) {
  const storage = getStorage();

  const [movieurl_instagram, setMovieurl_instagram] = useState("");
  const [movieurl_youtube, setMovieurl_youtube] = useState("");
  const [movietext, setMovietext] = useState("");
  const [ismoviemade, setIsmoviemade] = useState(false);
  const [message, setMessage] = useState(
    "動画作成ボタン押下から約60分後に動画が完成します\n完成したか見るなら読込ボタンを押下ください"
  );

  useEffect(() => {
    console.log("uE_ShowMovie");
    const f = async () => {
      geturl();
    };
    f();
  }, []);

  const geturl = () => {
    getDownloadURL(
      ref(
        storage,
        "movie/" + props.lonpaid + "/instagram_" + props.lonpaid + ".mp4"
      )
    )
      .then((url) => {
        setMovieurl_instagram(url);
        setIsmoviemade(true);
      })
      .catch((error) => {
        console.log(error);
        setIsmoviemade(false);
        setMessage("動画は作成されていません");
      });

    getDownloadURL(
      ref(
        storage,
        "movie/" + props.lonpaid + "/youtube_" + props.lonpaid + ".mp4"
      )
    )
      .then((url) => {
        setMovieurl_youtube(url);
        setIsmoviemade(true);
      })
      .catch((error) => {
        console.log(error);
        setIsmoviemade(false);
        setMessage("動画は作成されていません");
      });

    downloadExplain();
  };

  const downloadExplain = async () => {
    const gendata = await getMovieString(props.lonpaid, "explain.txt");
    setMovietext(gendata.toString());
  };

  const downloadFile = async (signedDownloadUrl, contentType, fileName) => {
    const res = await fetch(signedDownloadUrl, {
      method: "GET",
      headers: { "Content-Type": contentType },
    });
    console.log(res);
    if (!res.ok) {
      console.log("Fail to download file from GCS");
      return Promise.reject(new Error("Fail to download file from GCS"));
    }
    const blob = await res.blob();

    saveAs(blob, fileName);
  };

  return (
    <div
      style={{
        color: props.bgColor.text04.fontColor,
        textAlign: "center",
        width: "100%",
      }}
    >
      {props.lonpaid}
      <br />
      <br />
      {ismoviemade ? (
        <>
          ■　縦長動画(1080x1920)
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ReactPlayer
              url={movieurl_youtube}
              id="MainPlay"
              loop
              controls={true}
              width="216px"
              height="384px"
            />
          </div>
          <br />
          ■　正方形動画(1080x1080)
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ReactPlayer
              url={movieurl_instagram}
              id="MainPlay"
              loop
              controls={true}
              width="300px"
              height="300px"
            />
          </div>
          <br />
          <TextField
            fullWidth
            label="説明"
            multiline
            maxRows={50}
            minRows={5}
            value={movietext}
            onChange={(e) => setMovietext(e.target.value)}
            style={{
              border: props.bgColor.line02,
            }}
            inputProps={{ style: { color: props.bgColor.text04.fontColor } }}
          />
          {/* <Button
            color="primary"
            onClick={() => {
              downloadFile(
                movieurl,
                "video/mp4",
                "instagram_" + props.lonpaid + ".mp4"
              );
            }}
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            動画保存-まだうまく動かない
          </Button> */}
        </>
      ) : (
        <>
          <Button
            color="primary"
            onClick={() => {
              geturl();
            }}
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            読込
          </Button>
          {message}
        </>
      )}
    </div>
  );
}

import { useStyles } from "../../Styles";

import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Checkbox,
} from "@mui/material";

import PieChart from "./PieChart";
import rankicon from "../../images/rankicon.png";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function LoadingTopNew(props) {
  const classes = useStyles();
  const idealodingNew = [
    {
      id: "loading1",
      claim: "",
      date: "xx/xx xx:xx",
      numberOfVote: 0,
      agree: 0,
      disagree: 0,
      imageurl: "",
    },
    {
      id: "loading2",
      claim: "",
      date: "xx/xx xx:xx",
      numberOfVote: 0,
      agree: 0,
      disagree: 2,
      imageurl: "",
    },
    {
      id: "loading3",
      claim: "",
      date: "xx/xx xx:xx",
      numberOfVote: 0,
      agree: 1,
      disagree: 2,
      imageurl: "",
    },
  ];

  return (
    <>
      <div style={{ height: "2vh" }}></div>

      <List sx={{ width: "100%", backgroundColor: props.bgColor.back01 }}>
        {idealodingNew.map((idea, index) => (
          <div
            className={classes.indicatorButtonwhite}
            key={idea.id}
            style={{
              backgroundColor: props.bgColor.back01,
              color: props.bgColor.text04.fontColor,
              border: props.bgColor.line01,
            }}
          >
            <table>
              <tbody>
                <tr>
                  {/* <td>
                    <PieChart
                      imageurl={idea.imageurl}
                      src={rankicon}
                      disagreeratio={NaN}
                    />
                    &nbsp;&nbsp;
                  </td> */}

                  <td>
                    <div>
                      <ListItem
                        alignItems="flex-start"
                        style={{ width: "100%", padding: "0px 0px 0px 0px" }}
                      >
                        <ListItemText
                          primary={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                minHeight: "1.5em",
                                whiteSpace: "pre-wrap",
                                overflowWrap: "break-word",
                                backgroundColor: props.bgColor.back01,
                                color: props.bgColor.text01.fontColor,
                                fontSize: props.bgColor.text04.fontSize,
                                fontWeight: props.bgColor.text04.fontWeight,
                                textAlign: "center",
                              }}
                            >
                              {idea.claim}
                            </div>
                          }
                        />
                      </ListItem>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </List>
    </>
  );
}

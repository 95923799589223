import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import {
  checkisExecutable,
  pointSubMakePicture,
  pointSubMakeSNS,
  pointSubMakeRon,
  pointSubMakeTag,
} from "../../../../common/Helper/PointHelper";
import {
  pointtableTypeD,
  pointtableTypeE,
  pointtableTypeF,
  pointtableTypeJ,
} from "../../../../common/pointTable";
import ReactLoading from "react-loading";

export default function PointPictureModal(props) {
  const navigate = useNavigate();
  const [executable, setExecutable] = useState({});
  const [purpose, setPurpose] = useState("");
  const [isloding, setIsloding] = useState(true);

  useEffect(() => {
    const f = async () => {
      console.log("uE_CreateUsePointModal");
      //console.log(props);
      if (props.pointPattarn == 1) {
        const executable = await checkisExecutable(200);
        setExecutable(executable);
        setPurpose("画像の自動生成");
      } else if (props.pointPattarn == 2) {
        const executable = await checkisExecutable(200);
        setExecutable(executable);
        setPurpose("SNSテキストの自動生成");
      } else if (props.pointPattarn == 3) {
        const executable = await checkisExecutable(200);
        setExecutable(executable);
        setPurpose("論拠の自動生成");
      } else if (props.pointPattarn == 4) {
        const executable = await checkisExecutable(200);
        setExecutable(executable);
        setPurpose("タグの自動生成");
      }
      setIsloding(false);
    };
    f();
  }, []);

  async function ExcuteJob() {
    if (props.pointPattarn == 1) {
      pointSubMakePicture(props.id);
      props.chatGPTImage(props.text, false);
    } else if (props.pointPattarn == 2) {
      pointSubMakeSNS(props.id);
      props.createMessage();
    } else if (props.pointPattarn == 3) {
      pointSubMakeRon(props.id);
      props.handleCloseinside();
      props.lonpaAutoMake();
    } else {
      pointSubMakeTag(props.id);
      props.createTag();
    }
  }

  return (
    <>
      {isloding ? (
        <>
          <div align="center" style={{ border: 0 }}>
            <ReactLoading
              type="spin"
              color="black"
              style={{ marginTop: "15vh", marginBottom: "45vh" }}
            />
          </div>
        </>
      ) : (
        <>
          <br />
          <br />
          <h2 id="transition-modal-title" align="center">
            {purpose}
          </h2>
          <div align="center">
            <br />
            現在のポイント：{executable.possession}
            <br />
            {executable.required}point消費して実行しますか？
            <br />
            {executable.isSubscribe ? (
              <>
                {executable.isExcutable ? (
                  <>
                    <br />
                    <Button
                      color="primary"
                      disabled={false}
                      onClick={() => {
                        ExcuteJob();
                        props.setIsPointCheck(false);
                      }}
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      実行
                    </Button>
                  </>
                ) : (
                  <>
                    {executable.deficient}ポイント不足
                    <br />
                    <Button
                      color="primary"
                      disabled={false}
                      onClick={() => {
                        props.setIsPointCheck(false);
                        navigate("/Point/");
                      }}
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      ポイント追加
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                {executable.isMember ? (
                  <>
                    サブスク登録しましょう
                    <br />
                    <Button
                      color="primary"
                      disabled={false}
                      onClick={() => {
                        props.setIsPointCheck(false);
                        navigate("/Point/");
                      }}
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      サブスク登録
                    </Button>
                  </>
                ) : (
                  <>
                    メンバ登録しましょう
                    <br />
                    <Button
                      color="primary"
                      disabled={false}
                      onClick={() => {
                        props.setIsPointCheck(false);
                        navigate("/Signup/");
                      }}
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      メンバ登録
                    </Button>
                  </>
                )}
              </>
            )}
            .............
            <Button
              color="primary"
              onClick={() => {
                props.setIsPointCheck(false);
              }}
              style={{ fontSize: 20, fontWeight: "bold" }}
            >
              戻る
            </Button>
            <br />
            <br />
            内訳
            {props.pointPattarn == 1 ? (
              <>{pointtableTypeD}</>
            ) : (
              <>
                {" "}
                {props.pointPattarn == 2 ? (
                  <>{pointtableTypeE}</>
                ) : (
                  <>
                    {props.pointPattarn == 3 ? (
                      <>{pointtableTypeF}</>
                    ) : (
                      <>{pointtableTypeJ}</>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <br />
        </>
      )}
    </>
  );
}

import { Button } from "@mui/material";
import React from "react";

import FadeInReason from "./FadeInReason";
import ImageDown from "./ImageDown";

type Props = {
  bgColor: any;
  styletext: any;
  setIsStage1Finish: any;
  setIsStage2Finish: any;
  setIsStage25Finish: any;
  isBlue: boolean;
  info: any;
  setChoice: any;
  talkerpic1: any;
  talkerpic2: any;
};

const Stage25: React.VFC<Props> = ({
  bgColor,
  styletext,
  setIsStage1Finish,
  setIsStage2Finish,
  setIsStage25Finish,
  isBlue,
  info,
  setChoice,
  talkerpic1,
  talkerpic2,
}: Props) => {
  return (
    <>
      <table
        style={{
          width: styletext.windowPhoneWidth,
          left: 0,
          right: 0,
          marginLeft: "auto", // 左のマージンを自動に設定
          marginRight: "auto", // 右のマージンを自動に設定
        }}
      >
        <tbody>
          <tr>
            {isBlue ? (
              <>
                <td
                  style={{
                    position: "relative",
                    width: styletext.windowPhoneWidth * 0.45,
                  }}
                >
                  <ImageDown
                    img1={talkerpic1}
                    setIsStage25Finish={setIsStage25Finish}
                  />
                </td>
                <td
                  style={{
                    position: "relative",
                    width: styletext.windowPhoneWidth * 0.55,
                  }}
                >
                  {/* <FadeInReason
                    bgColor={bgColor}
                    styletext={styletext}
                    setIsStage1Finish={setIsStage1Finish}
                    setIsStage2Finish={setIsStage2Finish}
                    message={info.infoagree}
                    isBlue={true}
                    setChoice={setChoice}
                  /> */}
                </td>
              </>
            ) : (
              <>
                <td
                  style={{
                    position: "relative",
                    width: styletext.windowPhoneWidth * 0.55,
                  }}
                >
                  {/* <FadeInReason
                    bgColor={bgColor}
                    styletext={styletext}
                    setIsStage1Finish={setIsStage1Finish}
                    setIsStage2Finish={setIsStage2Finish}
                    message={info.infodisagree}
                    isBlue={false}
                    setChoice={setChoice}
                  /> */}
                </td>
                <td
                  style={{
                    position: "relative",
                    width: styletext.windowPhoneWidth * 0.45,
                  }}
                >
                  <ImageDown
                    img1={talkerpic2}
                    setIsStage25Finish={setIsStage25Finish}
                  />
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default Stage25;

import { getAuth, onAuthStateChanged } from "firebase/auth";
import moment from "moment";

import { transactionPushDiscussLon } from "../../common/Helper/FirebaseTransactionHelper2";
import { userApp } from "../../common/firebase";

const parseAsMoment = (dateTimeStr: any) => {
  return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
};

//投票ボタン押下時に動作する ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
export async function pushLon(
  data: any,
  increment: boolean,
  lonpaid: string,
  nowtime: any,
  infoclaim: string,

  cookies: any,
  setCookie: any,
  removeCookie: any
) {
  //現在日付データ作成
  const excutetime = new Date();
  const excutetimestring = parseAsMoment(excutetime)
    .format("YYYYMMDDHHmm")
    .toString();

  onAuthStateChanged(getAuth(userApp), async (user) => {
    if (
      await transactionPushDiscussLon(
        data,
        increment,
        user,
        lonpaid,
        nowtime,
        excutetimestring,
        infoclaim,
        excutetime,
        cookies
      )
    ) {
      ///////console.log("トランザクションが成功終了")//////

      let choiceid;
      //console.log(data.claim)
      if (increment) {
        if (user) {
          choiceid = data.lonpaid;
        } else {
          choiceid = {
            id: data.lonpaid,
            isAgree: data.agree,
            votedClaim: data.claim,
          };
        }
      } else {
        choiceid = "";
      }

      ///////クッキー書き込み///////
      if (!user) {
        //ログインしていない場合はクッキーを利用
        if (increment) {
          await setCookie(lonpaid, choiceid);

          //期限付きでcookieを設定したいがうまくいかない
          const cookieDate = new Date();
          cookieDate.setDate(cookieDate.getDate() + 365); // クッキーの期限を365日後に変更する.
          //console.log(cookieDate);
          //クッキーに投票内容を書き込む
          await setCookie(lonpaid, choiceid, {
            expires: cookieDate,
            path: "/",
          });
        } else {
          await removeCookie(lonpaid); //クッキーの投票内容を削除する
        }
      }
      //console.log("トランザクションが成功終了");
    } else {
      console.log("トランザクションが失敗終了");
    }
  });
}

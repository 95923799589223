import React, { useState } from "react";
import { ChromePicker, ColorResult } from "react-color";

const ColorPicker: React.FC = () => {
  const [color, setColor] = useState<string>("#fff");

  const handleChange = (colorResult: ColorResult) => {
    setColor(colorResult.hex);
  };

  return (
    <div>
      <ChromePicker color={color} onChange={handleChange} />
      <p>選択された色: {color}</p>
    </div>
  );
};

export default ColorPicker;

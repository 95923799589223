import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useStyles } from "../Styles";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Button, Link } from "@mui/material";
import { getCustomersPayment } from "../common/Helper/FirebaseHelper";
import { userApp } from "../common/firebase";

export default function Cancell(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [lodingmodalopen, setLodingmodalopen] = useState(true);

  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [paymentdatas, setPaymentdatas] = useState([]);

  const cellStyle = {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center",
  };

  const parseDate = (dateTimeStr) => {
    const date = new Date(dateTimeStr * 1000);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    return (
      year +
      "/" +
      month +
      "/" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  useEffect(() => {
    console.log("uE_Payment");
    setLodingmodalopen(true);

    onAuthStateChanged(getAuth(userApp), async (user) => {
      if (user) {
        setUserId(user.uid);
        setUserEmail(user.email);

        const paymentquerySnapshot = await getCustomersPayment(user.uid);
        let makepaymentdatas = [];
        paymentquerySnapshot.forEach((doc) => {
          if (doc.data()) {
            const createDate = parseDate(doc.data().created);
            const amount = doc.data().amount;
            makepaymentdatas.push({
              created: createDate,
              amount: amount,
            });
          }
        });
        setPaymentdatas(makepaymentdatas);
      } else {
        alert("アカウント作成が必要です");
        navigate("/Userpage");
      }
    });
    setLodingmodalopen(false);
  }, []);

  return (
    <div
      style={{
        backgroundColor: props.bgColor.back01,
        color: props.bgColor.text01.fontColor,
      }}
      align="center"
    >
      <div style={{ height: "6vh" }}></div>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 id="transition-modal-title" align="center">
              プラン変更
            </h2>
            下記設定用リンクよりManage Subscriptionへ遷移し
            <br />
            サブスク登録時に記入いただいたメールアドレスにて
            <br />
            サブスク解約手続きが可能です。
            <br />
            <br />
            注意：解約までにお支払いいただいた金額の払い戻しはできません。
            <br />
            <br />
            プラン変更をご希望の場合、現プラン解約後に別プランへ登録お願いします
            <br />
            <br />
            <br />
            <table>
              <tbody>
                <tr style={{ borderTop: "solid 3px #CCC" }}>
                  <td style={cellStyle}>支払日</td>
                  <td style={cellStyle}>金額</td>
                </tr>

                {paymentdatas.map((item, index) => (
                  <tr style={{ borderTop: "solid 3px #CCC" }}>
                    <td style={cellStyle}>{item.created}</td>
                    <td style={cellStyle}>{item.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <br />
            アカウント情報:
            {userEmail} <br />
            <br />
            <Link
              color="inherit"
              href="https://billing.stripe.com/p/login/3cs8z0c1b6N59cA4gg"
              target="_blank"
              rel="noopener noreferrer"
            >
              解約リンクはこちら
            </Link>
            <br />
            <br />
            <Button
              disabled={false}
              size="large"
              variant="contained"
              style={{ fontSize: 15 }}
              onClick={() => {
                navigate("/Payment");
              }}
            >
              ステータス確認
            </Button>
            <br />
            <br />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

import React from "react";

import { IconButton, Button } from "@mui/material";

import Icon from "../../images/lonpaLogo/lonpa-logo.png";
import Icon_white from "../../images/lonpaLogo/lonpa-logo_white.png";
import { useNavigate } from "react-router-dom";

const Logobar = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      {/* タイトルロゴ */}
      <div align="left" style={{ height: "70px" }}>
        <IconButton
          aria-haspopup="true"
          onClick={() => {
            window.scrollTo(0, 0);
            props.stopAuto();
            navigate("/NewTop");
          }}
        >
          <div style={{ height: "55px" }}></div>
          {props.bgColor.type == "dark" ? (
            <img src={Icon_white} alt="アイコン" height="48" width="90" />
          ) : (
            <img src={Icon} alt="アイコン" height="48" width="90" />
          )}
        </IconButton>
      </div>
    </div>
  );
};

export default Logobar;

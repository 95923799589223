import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useStyles } from "../../../Styles";
import { useEffect, useState } from "react";

import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";

import { updateLonpa } from "../../../common/Helper/FirebaseHelper";

import Compressor from "compressorjs";

import LodingCreatingPicture from "./LonpaPictureModal/LodingCreatingPicture";
import TextPictureModal from "./LonpaPictureModal/TextPictureModal";
import ImagePictureModal from "./LonpaPictureModal/ImagePictureModal";
import OpinionPictureModal from "./LonpaPictureModal/OpinionPictureModal";
import PointPictureModal from "./LonpaPictureModal/PointPictureModal";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
} from "firebase/storage";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseApp, userApp } from "../../../common/firebase";

export default function LonpaPictureModal(props) {
  // このクラスで文章生成し、LonpaPicturesクラスに渡す。
  const classes = useStyles();
  const [picText, setPicText] = useState();
  const [picText2, setPicText2] = useState();
  const [picText3, setPicText3] = useState();
  const [picText4, setPicText4] = useState();
  const [textShow, setTextShow] = useState({
    text1: true,
    text2: false,
    text3: false,
  });
  const [snsTextShow, setSnsTextShow] = useState("");
  const [picType, setPicType] = useState(1);

  const [aiImage, setAiImage] = useState(false);

  const [isLoding, setIsLoding] = useState(false);
  const [isPointCheck, setIsPointCheck] = useState(false);
  const [startTime, setStartTime] = useState("start");
  const [pointPattarn, setPointPattarn] = useState(1);
  const [picturetext, setPicturetext] = useState(1);

  //クリップボードにコピー関数
  const copyToClipboard = async (copyText) => {
    await global.navigator.clipboard.writeText(copyText);
  };

  //コメント追加したり、他人が追加したりした場合に最新のコメントにスクロール
  useEffect(() => {
    const joinedTags = props.tagListString2 || props.editLonpa.claim;
    setPicturetext(joinedTags);

    console.log("uE_LonpaPictureModal");
    //console.log(props.editLonpa.claim);
    if (props.imageCreate && props.editLonpa.claim != "読み込み中") {
      const f = async () => {
        await chatGPTImage(joinedTags, true);
      };
      f();
    } else {
      if (props.editLonpa.claim) {
        //１枚目の画像のテキストをここで設定
        setPicText(maketext(props.editLonpa.claim, true));
      }

      if (props.editLonpa.infoagree) {
        if (props.editLonpa.infoagree[0]) {
          //２枚目の画像のテキストをここで設定
          setPicText2(
            "\n賛成意見\n\n" +
              maketext(props.editLonpa.infoagree[0].data.claim, false)
          );
        }
      }
      if (props.editLonpa.infodisagree) {
        if (props.editLonpa.infodisagree[0]) {
          //３枚目の画像のテキストをここで設定
          setPicText3(
            "\n反対意見\n\n" +
              maketext(props.editLonpa.infodisagree[0].data.claim, false)
          );
        }
      }
      //4枚目の画像のテキストをここで設定
      setPicText4("\n\nLonpaで\n他の意見も\n見てみよう");

      setSnsTextShow(props.editLonpa.snsText);
      //console.log(props.snsText);
    }
  }, [props.editLonpa]);

  //意見の文章をいい感じに改行するためのコード
  const maketext = (text, flag) => {
    if (text) {
      //console.log(claim);
      const locale = "ja-JP";

      const segmenter = new Intl.Segmenter(locale, { granularity: "word" });
      const splittedText = Array.from(segmenter.segment(text)).map(
        (s) => s.segment
      );

      let countwordlen = 0;
      let countlinelen = 1;
      let createText = "";
      splittedText.map((text) => {
        countwordlen = countwordlen + text.length;
        //console.log(text);
        if (countwordlen > 8) {
          if (text.length == countwordlen) {
            createText = createText + text;
          } else {
            countwordlen = text.length;
            createText = createText + "\n" + text;
            countlinelen = countlinelen + 1;
          }
        } else {
          createText = createText + text;
        }
      });
      if (flag) {
        if (countlinelen < 3) {
          createText = "\n\n\n" + createText;
        } else if (countlinelen < 4) {
          createText = "\n\n" + createText;
        } else if (countlinelen < 5) {
          createText = "\n" + createText;
        }
      }

      return createText;
      //makeone(createText);
    }
  };

  const createMessage = async () => {
    props.setIsAITwitterDisable(true);
    let chatGPTMessage = "";
    chatGPTMessage = chatGPTMessage + props.editLonpa.claim;
    chatGPTMessage = chatGPTMessage + "に対する賛成意見は";

    //console.log(props.editLonpa.infoagree)
    props.editLonpa.infoagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "、「" + item.data.claim + "」";
    });
    chatGPTMessage = chatGPTMessage + "等がある．";

    chatGPTMessage = chatGPTMessage + props.editLonpa.claim;
    chatGPTMessage = chatGPTMessage + "に対する反対意見は";

    props.editLonpa.infodisagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "、「" + item.data.claim + "」";
    });
    chatGPTMessage = chatGPTMessage + "等がある．";
    chatGPTMessage =
      chatGPTMessage +
      "\n\nこれらを踏まえてかわいい女子大生みたいなツイートを100文字以内で作ってください．＃は記載しないでください．";
    //console.log(chatGPTMessage)
    chatGPTMessage =
      chatGPTMessage +
      "ただし，下記のような設定のキャラクターになりきって文章を作成してください．";
    chatGPTMessage = chatGPTMessage + "\n・人間の女の子";
    chatGPTMessage = chatGPTMessage + "\n・大学生";
    chatGPTMessage = chatGPTMessage + "\n・#は使わない";
    chatGPTMessage = chatGPTMessage + "\n・ツイートにタグはつけない";
    chatGPTMessage = chatGPTMessage + "\n・ツイートは100文字";

    setSnsTextShow("テキスト作成中\n\n" + chatGPTMessage);

    const functions = getFunctions();
    const messageCreate = httpsCallable(functions, "messageCreate");
    messageCreate({ message: chatGPTMessage }).then((result) => {
      if (result.data) {
        setSnsTextShow(
          result.data.aimessage + "\n" + props.editLonpa.tag + " #拡散希望 \n"
        );
      } else {
        setSnsTextShow(" 生成失敗 ");
      }
    });
  };

  async function chatGPTImage(aimessage, uploadflag) {
    setIsLoding(true);
    setStartTime("開始：" + Date(Date.now()).toLocaleString());
    setPicType(102);
    // console.log(aimessage);
    const chatGPTMessage = aimessage;
    const functions = getFunctions();
    const imageCreate = httpsCallable(functions, "imageCreate");
    //console.log(chatGPTMessage);
    if (chatGPTMessage) {
      imageCreate({ message: chatGPTMessage }).then((result) => {
        if (result.data != null) {
          setAiImage(result.data.aiimage.data[0].b64_json);
          setPicType(101);
          if (uploadflag) {
            imageUpload(result.data.aiimage.data[0].b64_json);
            props.setAutoCreateImageCreateFalse();
          }
          setIsLoding(false);
        } else {
          console.log("データ取得できませんでした");
          props.setAutoCreateImageCreateFalse();
          setIsLoding(false);
        }
      });
    } else {
      console.log("画像生成のための情報が渡されていません");
    }
  }

  async function imageUploadButtonPush() {
    await imageUpload(aiImage);
    props.setOpen(false);
  }

  async function imageUpload(inputImage) {
    //https://qiita.com/rc_code/items/150003f016287750cf34

    if (typeof inputImage === "undefined") {
      return;
    }

    /// base64から画像データを作成
    const bin = atob(inputImage.replace(/^.*,/, ""));
    let barr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      barr[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([barr], { type: "image/png" });
    const file = new File([blob], "up.png", {
      type: "image/png",
    });

    //firebaseへのアップロード設定
    let imgname = Date.now();
    let uid = getAuth(userApp).currentUser.uid;
    const storage = getStorage(firebaseApp);
    const storageRef = ref(
      storage,
      "lonpa/" + props.editLonpa.id + "/image/" + uid + imgname
    ); //どのフォルダの配下に入れるかを設定

    const uploadTask = uploadBytesResumable(storageRef, file); //ファイル名

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // 進行状況を監視（オプションで使えます）
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // エラー時の処理
        console.error("Error uploading file:", error);
      },
      () => {
        // アップロード完了時にダウンロードURLを取得
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const f = async () => {
            await updateLonpa(props.editLonpa.id, {
              imageurl: downloadURL,
            });
            props.setimageurl(downloadURL);
          };
          f();
        });
      }
    );

    //ファイルをアップロードする
    // uploadTask.on("state_changed", () => {
    //   getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //     const f = async () => {
    //       await updateLonpa(props.editLonpa.id, {
    //         imageurl: downloadURL,
    //       });
    //       props.setimageurl(downloadURL);
    //     };
    //     f();
    //   });
    // });
  }

  const handleCloseinside = () => {
    if (!isLoding) {
      props.setOpen(false);
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleCloseinside}
      closeAfterTransition
    >
      {isLoding ? (
        <Fade in={props.open} style={{ outline: "none" }}>
          <div className={classes.paper}>
            <LodingCreatingPicture
              startTime={startTime}
              text={props.editLonpa.claim}
              bgColor={props.bgColor}
            />
          </div>
        </Fade>
      ) : (
        <>
          {isPointCheck ? (
            <>
              <Fade in={props.open}>
                <div className={classes.paper}>
                  <PointPictureModal
                    setIsPointCheck={setIsPointCheck}
                    pointPattarn={pointPattarn}
                    handleCloseinside={handleCloseinside}
                    lonpaAutoMake={props.lonpaAutoMake}
                    createMessage={createMessage}
                    chatGPTImage={chatGPTImage}
                    text={picturetext}
                    id={props.editLonpa.id}
                  />
                </div>
              </Fade>
            </>
          ) : (
            <>
              <Fade in={props.open}>
                <div className={classes.paper}>
                  <ImagePictureModal
                    picType={picType}
                    setPicType={setPicType}
                    picText={picText}
                    picText2={picText2}
                    picText3={picText3}
                    picText4={picText4}
                    aiImage={aiImage}
                    url={props.url}
                    adminFlag={props.adminFlag}
                    text={props.editLonpa.claim}
                    setPointPattarn={setPointPattarn}
                    setIsPointCheck={setIsPointCheck}
                    imageUploadButtonPush={imageUploadButtonPush}
                  />
                  <br />
                  <TextPictureModal
                    copyToClipboard={copyToClipboard}
                    snsTextShow={snsTextShow}
                    url={props.url}
                    adminFlag={props.adminFlag}
                    setSnsTextShow={setSnsTextShow}
                    setPointPattarn={setPointPattarn}
                    setIsPointCheck={setIsPointCheck}
                  />
                  <br />
                  <OpinionPictureModal
                    adminFlag={props.adminFlag}
                    isCreatedAI={props.isCreatedAI}
                    setPointPattarn={setPointPattarn}
                    setIsPointCheck={setIsPointCheck}
                  />
                </div>
              </Fade>
            </>
          )}
        </>
      )}
    </Modal>
  );
}

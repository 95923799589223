import { Button } from "@mui/material";

export default function LonpaPictureType100(props) {
  return (
    <>
      <div> </div>
      <div className="comp" align="center" style={{ display: "flex" }}>
        <img alt="icon" src={props.png} width="150" height="150" />
      </div>
      {/* `<Button
        color="primary"
        onClick={() => {
          props.setMoviepngAndFlag(makeone(props.picText));
        }}
        style={{ fontSize: 20, fontWeight: "bold" }}
      >
        画像
      </Button>

      <Button
        color="primary"
        onClick={() => {
          props.setMoviepngAndFlag(makepicimg(props.picText, t02_01, 1, true));
        }}
        style={{ fontSize: 20, fontWeight: "bold" }}
      >
        画像2
      </Button>

      <Button
        color="primary"
        onClick={() => {
          props.setMoviepngAndFlag(makepicimg(props.picText, t03_01, 1, true));
        }}
        style={{ fontSize: 20, fontWeight: "bold" }}
      >
        画像3
      </Button>` */}

      <br />
    </>
  );
}

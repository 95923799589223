import { useStyles } from "../Styles";
import YouTube from "react-youtube";
import Lottie from "react-lottie";
import animationData from "../images/logoAnimation.json";
import animationData2 from "../images/explainLonpa01.json";
import animationData3 from "../images/explainLonpa02.json";
import animationData4 from "../images/explainLonpa03.json";
import animationData5 from "../images/back.json";

import { useNavigate } from "react-router-dom";

import { Button, Grid, Container, Typography, Link } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import HowtoPieChart from "./Howto/HowtoPieChart";

export default function Howto(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData5,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={"50%"}
        width={"100%"}
        isClickToPauseDisabled={true}
      />
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData5,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={"50%"}
        width={"100%"}
        isClickToPauseDisabled={true}
      />

      <div style={{ height: "60vh" }}></div>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
            <p
              style={{
                position: "absolute",
                top: "10%",
                fontSize: " 20px",
                color: "#000",
                margin: 0,
              }}
            >
              <div align="center">
                <div style={{ height: "6vh" }}></div>
                <div style={{ width: props.width * 0.8, maxWidth: "700px" }}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    fontWeight="bold"
                    align="center"
                  >
                    "対立"を理解し
                    <br />
                    "共感"を形にする
                    <br />
                    意見交流サイト
                  </Typography>
                  <div style={{ height: "2vh" }}></div>
                  <img src="../../../Welcome.png" alt="アイコン" width="60%" />
                </div>
              </div>

              <div style={{ height: "10vh" }}></div>

              <div align="center">
                <div style={{ maxWidth: "700px" }}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    fontWeight="bold"
                    align="center"
                  >
                    空き時間に意見出し・投票
                  </Typography>
                  <div style={{ height: "2vh" }}></div>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationData2,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={"100%"}
                    width={"100%"}
                  />

                  <div style={{ height: "8vh" }}></div>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    fontWeight="bold"
                    align="center"
                  >
                    投票結果はリアルタイムでグラフ表示
                  </Typography>
                  <div style={{ height: "2vh" }}></div>

                  <table>
                    <tbody>
                      <tr>
                        <td
                          style={{ width: "20vw", color: "blue" }}
                          align="center"
                        >
                          賛成
                          <br />
                          2票
                        </td>
                        <td style={{ width: "10vw" }} align="center">
                          <HowtoPieChart disagreeratio={33} />
                        </td>
                        <td
                          style={{ width: "20vw", color: "red" }}
                          align="center"
                        >
                          反対
                          <br />
                          1票
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div style={{ height: "8vh" }}></div>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    fontWeight="bold"
                    align="center"
                  >
                    論拠を木構造で深堀りできる
                  </Typography>
                  <div style={{ height: "2vh" }}></div>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationData3,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={"100%"}
                    width={"100%"}
                  />

                  <div style={{ height: "8vh" }}></div>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    fontWeight="bold"
                    align="center"
                  >
                    間違った論拠に反論・論破できる
                  </Typography>
                  <div style={{ height: "2vh" }}></div>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationData4,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={"100%"}
                    width={"100%"}
                  />

                  <div style={{ height: "8vh" }}></div>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    fontWeight="bold"
                    align="center"
                  >
                    みんなの意見がまるわかり
                  </Typography>

                  {/* <Button
                    color="primary"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/Top/");
                    }}
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    ランキングを閲覧する
                  </Button>

                  <br />
                  <Button
                    color="primary"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/Create/");
                    }}
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    新しい主張を投稿する
                  </Button>
                  <br />
                  <Button
                    color="primary"
                    onClick={() => {
                      window.open(
                        "https://www.youtube.com/watch?v=MJlLx5pXZ2A&t=6s",
                        "_blank"
                      );
                    }}
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    使い方動画を視聴する
                  </Button> */}
                </div>
              </div>
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

import { Button, Grid, TextField, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import { TwitterShareButton, TwitterIcon } from "react-share";

export default function TextPictureModal(props) {
  return (
    <>
      <h2 id="transition-modal-title" align="center">
        テキスト
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            props.copyToClipboard(props.snsTextShow + props.url);
          }}
        >
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
        {props.adminFlag ? (
          <>
            <IconButton
              aria-label="delete"
              size="small"
              disabled={props.isAITwitterDisable}
              onClick={() => {
                props.setIsPointCheck(true);
                props.setPointPattarn(2);
              }}
            >
              <HdrAutoIcon fontSize="inherit" />
            </IconButton>

            <TwitterShareButton url={props.url} title={props.snsTextShow}>
              <TwitterIcon size={20} round />
            </TwitterShareButton>
          </>
        ) : (
          <></>
        )}
      </h2>
      <TextField
        fullWidth
        label="テキスト(改行もできます)"
        multiline
        maxRows={20}
        minRows={5}
        value={props.snsTextShow}
        onChange={(e) => props.setSnsTextShow(e.target.value)}
      />
    </>
  );
}

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
} from "@mui/material";
import { getAuth, updateProfile } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import { useStyles } from "../Styles";
import { setUserPrivateUpload } from "../common/Helper/FirebaseHelper";
import { userApp } from "../common/firebase";

import ShowMovie from "./MovieMaker/MoviePlayer";

type Props = {
  bgColor: any;
  styletext: any;
};

const MovieShow: React.VFC<Props> = ({ bgColor, styletext }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { lonpaid } = useParams();

  // useEffect(() => {
  //   console.log("uE_MovieShow");
  //   const f = async () => {
  //     console.log("t");
  //   };
  //   f();
  // }, []);

  async function bookUpload() {
    const user = getAuth(userApp).currentUser;
    if (user) {
      console.log(user.uid);
      console.log(lonpaid);
      setUserPrivateUpload(lonpaid, { num: "2", createdAt: serverTimestamp() });
      // await updateUserPublic(user.uid, {
      //   colorPattern: colorNum,
      // });
    }
  }

  return (
    <div style={{ backgroundColor: bgColor.back01 }}>
      <div style={{ height: "6vh" }}></div>
      <Container maxWidth="lg">
        <Grid container spacing={3} justifyContent="center">
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: bgColor.back01,
              color: bgColor.text01.fontColor,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2 id="transition-modal-title">アップロード支援ツール</h2>
              <div style={{ height: "2vh" }}></div>
              <div style={{ width: "100%" }}>
                <ShowMovie lonpaid={lonpaid} bgColor={bgColor} />
              </div>
              <Button
                onClick={() => {
                  bookUpload();
                }}
              >
                投稿予約する
              </Button>
              <div style={{ height: "2vh" }}></div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default MovieShow;

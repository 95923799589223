import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import { List, Box, Typography, Checkbox } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useStyles } from "../../Styles";
import newicon from "../../images/newicon.png";
import AdItem from "../Top/Ad/AdItem";

import NewTopItem from "./NewTopItem";

type Props = {
  bgColor: any;
  styletext: any;
  searchword: string;
  // ConnectLonpa: any;
  idealist: any;
  disableHeader: string;
};

const NewLatestUpdate: React.FC<Props> = ({
  bgColor,
  styletext,
  searchword,
  idealist,
  disableHeader,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const ChoiceData = (id: string, title: string) => {
    if (searchword === "useurl") {
      window.scrollTo(0, 0);
      navigate("/Discuss/" + id);
    } else {
      // ConnectLonpa(id, title);
    }
  };

  const ChoiceDataNewTab = (id: string, title: string) => {
    if (searchword === "useurl") {
      const url = "https://lonpa.net/Discuss/" + id;
      const win = window.open(url, "_blank");
      if (win != null) {
        win.focus();
      }
    } else {
      // props.ConnectLonpa(id, title);
      console.log("please check there are bug or not");
    }
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <List
          sx={{ width: "100%" }}
          style={{
            backgroundColor: bgColor.back01,
            color: "#ffffff",
          }}
        >
          {idealist.map((idea: any, index: number) => (
            <div
              // className={classes.indicatorButtonwhite}
              onClick={() => {
                ChoiceData(idea.id, idea.claim);
              }}
              onAuxClick={() => {
                ChoiceDataNewTab(idea.id, idea.claim);
              }}
              key={idea.id}
              style={{
                backgroundColor: bgColor.back01,
                color: "#ffffff",
                border: bgColor.line01,
              }}
            >
              <NewTopItem
                idea={idea}
                icon={newicon}
                bgColor={bgColor}
                text={idea.updatedAt}
                itemtype={"Latest"}
              />
            </div>
          ))}
          {/* <AdItem bgColor={bgColor} styletext={styletext} /> */}
        </List>
      </Box>
    </>
  );
};
export default NewLatestUpdate;

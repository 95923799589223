import Signin from "./Signin";
import Signup from "./Signup";

import { Grid, Container } from "@mui/material";

function Sign(props) {
  return (
    <div style={{ backgroundColor: props.bgColor.back01 }}>
      <div style={{ height: "6vh" }}></div>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {props.kind ? (
              <>
                <Signin update={() => props.update()} bgColor={props.bgColor} />
              </>
            ) : (
              <>
                <Signup update={() => props.update()} bgColor={props.bgColor} />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <div style={{ height: "2vh" }}></div>
    </div>
  );
}
export default Sign;

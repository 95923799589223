import { useNavigate } from "react-router-dom";
import { useStyles } from "../../Styles";

import { ListItem, ListItemText } from "@mui/material";
import PieChart from "./PieChart";

export default function TopItem(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <table>
      <tbody key={props.idea.id}>
        <tr>
          <td>
            {props.agree === 0 ? (
              <>
                {props.disagree === 0 ? (
                  <PieChart
                    imageurl={props.idea.imageurl}
                    src={props.icon}
                    disagreeratio={NaN}
                  />
                ) : (
                  <PieChart
                    imageurl={props.idea.imageurl}
                    src={props.icon}
                    disagreeratio={0}
                  />
                )}
              </>
            ) : (
              <>
                {props.disagree === 0 ? (
                  <PieChart
                    imageurl={props.idea.imageurl}
                    src={props.icon}
                    disagreeratio={100}
                  />
                ) : (
                  <PieChart
                    imageurl={props.idea.imageurl}
                    src={props.icon}
                    disagreeratio={
                      (props.idea.disagree /
                        (props.idea.agree + props.idea.disagree)) *
                      100
                    }
                  />
                )}
              </>
            )}
            {/* <img src={props.icon} alt="アイコン" width="60px" /> */}
            &nbsp;&nbsp;
          </td>

          <td>
            <div align="left">
              <a
                style={{
                  backgroundColor:
                    props.itemtype == "Ranking"
                      ? props.bgColor.back06
                      : props.bgColor.back05,
                  fontSize: props.bgColor.text07.fontSize,
                  color: props.bgColor.text07.fontColor,
                }}
              >
                {props.text}
              </a>
            </div>
            <div>
              <ListItem
                alignItems="flex-start"
                style={{
                  width: "100%",
                  padding: "0px 0px 0px 0px",
                  backgroundColor: props.bgColor.back01,
                  color: props.bgColor.text01.fontColor,
                }}
              >
                <ListItemText
                  primary={
                    <div
                      style={{
                        fontSize: props.bgColor.text04.fontSize,
                        fontWeight: props.bgColor.text04.fontWeight,
                        width: "100%",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }}
                    >
                      {props.idea.claim}
                    </div>
                  }
                />
              </ListItem>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

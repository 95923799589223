import { IconButton } from "@mui/material";
import Add from "@mui/icons-material/Add";

import LonpaBlock from "./LonpaBlock";
import LonpaBlockbig from "./LonpaBlockbig";

import LonpaBlockCreate from "./LonpaBlockCreate";
import LonpaBlockbigCreate from "./LonpaBlockbigCreate";

export default function Lonapbasis(props) {
  return (
    <>
      {props.info === undefined ? (
        <div>
          <IconButton
            // color={props.color}
            aria-label="add an alarm"
            onClick={() => props.addLon()}
          >
            <Add />
          </IconButton>
        </div>
      ) : (
        <>
          {props.info.length === 0 ? (
            <>
              {typeof props.ischoiced !== "undefined" ? (
                <>
                  {!props.lonpacreateopen ? (
                    <>
                      {!props.createAILonData ? (
                        <>
                          {props.isagree ? (
                            <>
                              <div style={{ height: "40px" }}></div>
                              <div
                                style={{
                                  width: "45vw",
                                  height: "80px",
                                  backgroundColor: props.bgColor.back09,
                                  color: props.bgColor.text01.fontColor,
                                  //"borderRadius": "10px",
                                }}
                              >
                                賛成の論拠が0件です。
                                <br />
                                下記の＋ボタンで作成してみましょう
                                <br />
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ height: "40px" }}></div>
                              <div
                                style={{
                                  width: "45vw",
                                  height: "80px",
                                  backgroundColor: props.bgColor.back10,
                                  color: props.bgColor.text01.fontColor,
                                }}
                              >
                                反対の論拠が0件です。
                                <br />
                                下記の＋ボタンで作成してみましょう
                                <br />
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {props.info.map((item) => (
                <div key={item.id}>
                  <div style={{ height: "4px" }}></div>
                  <div
                    style={{
                      padding: "0",
                      backgroundColor: item.data.agree
                        ? props.bgColor.back07
                        : props.bgColor.back08,
                    }}
                  >
                    {props.blocktype ? (
                      <LonpaBlock
                        item={item}
                        ChoiceData={props.ChoiceData}
                        parentclaim={props.parentclaim}
                        //color={props.color}
                        isagree={props.isagree}
                        increseUpdate={() => props.increseUpdate()}
                        login={props.login}
                        ischoiced={props.ischoiced}
                        isbuttonlock={props.isbuttonlock}
                        pushLon={(id, data, increment) =>
                          props.pushLon(id, data, increment)
                        }
                        styletext={props.styletext}
                        userEmailVerified={props.userEmailVerified}
                        claimChildSize={props.claimChildSize}
                        bgColor={props.bgColor}
                      />
                    ) : (
                      <LonpaBlockbig
                        item={item}
                        ChoiceData={props.ChoiceData}
                        parentclaim={props.parentclaim}
                        //color={props.color}
                        isagree={props.isagree}
                        increseUpdate={() => props.increseUpdate()}
                        login={props.login}
                        ischoiced={props.ischoiced}
                        isbuttonlock={props.isbuttonlock}
                        pushLon={(id, data, increment) =>
                          props.pushLon(id, data, increment)
                        }
                        styletext={props.styletext}
                        userEmailVerified={props.userEmailVerified}
                        claimChildSize={props.claimChildSize}
                        bgColor={props.bgColor}
                      />
                    )}
                  </div>
                </div>
              ))}
            </>
          )}

          {/* AIによる論拠作成を押すと表示される部分 */}
          {props.createAILonData ? (
            <>
              {props.createAILonData.map((item) => (
                <div key={"ai-" + item.num}>
                  {!item.iscreated ? (
                    <>
                      <div style={{ height: "4px" }}></div>
                      <div
                        style={{
                          padding: "0",
                          backgroundColor: props.isagree
                            ? props.bgColor.back07
                            : props.bgColor.back08,
                        }}
                      >
                        {props.blocktype ? (
                          <>
                            <LonpaBlockCreate
                              ChoiceData={props.ChoiceData}
                              parentclaim={props.parentclaim}
                              //color={props.color}
                              isagree={props.isagree}
                              increseUpdate={() => props.increseUpdate()}
                              login={props.login}
                              ischoiced={props.ischoiced}
                              isbuttonlock={props.isbuttonlock}
                              styletext={props.styletext}
                              userEmailVerified={props.userEmailVerified}
                              addLonChild={props.addLonChild}
                              claimChildSize={props.claimChildSize}
                              createAILonData={item}
                              isCreateAI={true}
                              initClaim={item.claim}
                              bgColor={props.bgColor}
                            />
                          </>
                        ) : (
                          <>
                            <LonpaBlockbigCreate
                              ChoiceData={props.ChoiceData}
                              parentclaim={props.parentclaim}
                              //color={props.color}
                              isagree={props.isagree}
                              increseUpdate={() => props.increseUpdate()}
                              login={props.login}
                              ischoiced={props.ischoiced}
                              isbuttonlock={props.isbuttonlock}
                              styletext={props.styletext}
                              userEmailVerified={props.userEmailVerified}
                              addLonChild={props.addLonChild}
                              claimChildSize={props.claimChildSize}
                              createAILonData={item}
                              isCreateAI={true}
                              initClaim={item.claim}
                              bgColor={props.bgColor}
                            />
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </>
          ) : (
            <></>
          )}

          {/* 論拠作成のため＋ボタンを押すと表示される部分 */}
          <div style={{ height: "4px" }}></div>
          <div
            style={{
              padding: "0",
              backgroundColor: props.isagree
                ? props.bgColor.back07
                : props.bgColor.back08,
            }}
          >
            {props.blocktype ? (
              <>
                {props.lonpacreateopen ? (
                  <LonpaBlockCreate
                    ChoiceData={props.ChoiceData}
                    parentclaim={props.parentclaim}
                    //color={props.color}
                    isagree={props.isagree}
                    increseUpdate={() => props.increseUpdate()}
                    login={props.login}
                    ischoiced={props.ischoiced}
                    isbuttonlock={props.isbuttonlock}
                    styletext={props.styletext}
                    userEmailVerified={props.userEmailVerified}
                    addLonChild={props.addLonChild}
                    claimChildSize={props.claimChildSize}
                    createAILonData={undefined}
                    isCreateAI={false}
                    initClaim={""}
                    bgColor={props.bgColor}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {props.lonpacreateopen ? (
                  <LonpaBlockbigCreate
                    ChoiceData={props.ChoiceData}
                    parentclaim={props.parentclaim}
                    //color={props.color}
                    isagree={props.isagree}
                    increseUpdate={() => props.increseUpdate()}
                    login={props.login}
                    ischoiced={props.ischoiced}
                    isbuttonlock={props.isbuttonlock}
                    styletext={props.styletext}
                    userEmailVerified={props.userEmailVerified}
                    addLonChild={props.addLonChild}
                    claimChildSize={props.claimChildSize}
                    createAILonData={undefined}
                    isCreateAI={false}
                    initClaim={""}
                    bgColor={props.bgColor}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          {props.isagree ? (
            <>
              <div
                style={{
                  width: "45vw",
                  height: "10px",
                  backgroundColor: props.bgColor.back09,
                  //"borderRadius": "10px",
                }}
              ></div>
            </>
          ) : (
            <>
              <div
                style={{
                  width: "45vw",
                  height: "10px",
                  backgroundColor: props.bgColor.back10,
                  //"borderRadius": "10px",
                }}
              ></div>
            </>
          )}
        </>
      )}
    </>
  );
}

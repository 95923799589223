import { Button, Grid, TextField, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import LonpaPictures from "../Picture/LonpaPictures";

export default function ImagePictureModal(props) {
  return (
    <>
      <h2 id="transition-modal-title" align="center">
        画像
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            props.setPicType(1);
          }}
        >
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            props.setPicType(2);
          }}
        >
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            props.setPicType(3);
          }}
        >
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            props.setPicType(99);
          }}
        >
          <QrCode2Icon fontSize="inherit" />
        </IconButton>
        {props.adminFlag ? (
          <>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                props.setIsPointCheck(true);
                props.setPointPattarn(1);
              }}
            >
              <HdrAutoIcon fontSize="inherit" />
            </IconButton>
          </>
        ) : (
          <></>
        )}
      </h2>

      <LonpaPictures
        picType={props.picType}
        picText={props.picText}
        picText2={props.picText2}
        picText3={props.picText3}
        picText4={props.picText4}
        url={props.url}
        aiImage={props.aiImage}
        imageUploadButtonPush={() => {
          props.imageUploadButtonPush();
        }}
      />
    </>
  );
}

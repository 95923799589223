import Signin from "./Signin";
import Signup from "./Signup";

import { Grid, Container, IconButton, Button } from "@mui/material";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import { useNavigate } from "react-router-dom";

import TitleBar from "./LonpaExplain/TitleBar";
import Footer from "./components/Footer";

import SettingsIcon from "@mui/icons-material/Settings";

// import { marked } from "marked";
function HowUserDeleteExplain(props) {
  const navigate = useNavigate();

  const bigtext = {
    fontWeight: "bold",
    fontSize: "20px",
    textDecoration: "underline",
  };
  // const markdown = `\n`;
  return (
    <>
      <div
        style={{
          backgroundColor: props.bgColor.back01,
          color: props.bgColor.text01.fontColor,
        }}
        align="left"
      >
        <div style={{ height: "6vh" }}></div>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TitleBar bgColor={props.bgColor} num={"01"} text={"削除"} />
              <br />
              <div style={bigtext}>ユーザ削除</div>
              {/* <span
              dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }}
            />
            <br />
            <br /> */}
              <br />
              <Button
                color="primary"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/Top/");
                }}
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: props.bgColor.text11.fontColor,
                }}
              >
                ユーザページ
              </Button>
              にて， <SettingsIcon sx={{ color: "#808080" }} />
              ボタンを押下
              <br />
              <br />
              アカウント変更に関する説明画面が表示されるので、
              <br />
              "アカウントを削除したい場合は，こちら"
              <br />
              の"こちら"部分を押下
              <br />
              <br />
              表示されたポップアップにて
              <br />
              アカウント削除を選択ください
              <br />
              <br />
              ※　注意：この操作は戻すことができませんので慎重にお願いします．
              <br />
            </Grid>
          </Grid>
        </Container>
        <div style={{ height: "2vh" }}></div>
      </div>
      <Footer
        bgColor={props.bgColor}
        styletext={props.styletext}
        isimgshow={true}
      />
    </>
  );
}
export default HowUserDeleteExplain;

import { getFunctions, httpsCallable } from "firebase/functions";

export const createIsMovieShort = (
  flag,
  setIsShort,
  movieSetting,
  setMovieSetting
) => {
  setIsShort(flag);
  const valuestring = flag ? "1" : "2";
  setMovieSetting({
    ...movieSetting,
    set4: valuestring,
  });
  //ここを動かすと暴発します．絶対に動かしてはいけない，理由は分からない．
  // createMovieSettingText({
  //   ...movieSetting,
  //   set4: valuestring,
  // });
};

export const createRapInput = async (
  nextEditDatas,
  editDatas,
  setFourTextShow,
  setMovieStage,
  autoCreateMovie,
  setAutoCreateMovie,
  stopAuto,
  movieStage
) => {
  let useEditDatas;
  if (nextEditDatas) {
    useEditDatas = nextEditDatas;
  } else {
    useEditDatas = editDatas;
  }
  //console.log(useEditDatas);
  try {
    let chatGPTMessage = "";
    chatGPTMessage = chatGPTMessage + "「" + useEditDatas.claim + "」";
    chatGPTMessage =
      chatGPTMessage +
      "という意見について，\n女子2人(【女子A - 賛成】・【女子B - 反対】)によるラップバトルを書いてください\n\n";
    chatGPTMessage = chatGPTMessage + "「" + useEditDatas.claim + "」";
    chatGPTMessage = chatGPTMessage + "に対する賛成意見は\n";

    useEditDatas.infoagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "「" + item.data.claim + "」\n";
    });
    chatGPTMessage = chatGPTMessage + "等がある．\n\n";
    chatGPTMessage = chatGPTMessage + "「" + useEditDatas.claim + "」";
    chatGPTMessage = chatGPTMessage + "に対する反対意見は\n";

    useEditDatas.infodisagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "「" + item.data.claim + "」\n";
    });

    chatGPTMessage = chatGPTMessage + "等がある．\n\n";
    chatGPTMessage =
      chatGPTMessage +
      "ただし，下記のような設定のキャラクターになりきって文章を作成してください．\n・人間の女の子\n・大学生\n・【女子A - 賛成】\n・【女子B - 反対】\n・それぞれ2回交互に発言する\n・1回の発言は2文。1文16文字以内\n文の区切りには句読点「、」を入れる\n\n";

    chatGPTMessage =
      chatGPTMessage + "（発言内容）にかぎかっこは記載しないでください\n\n";

    chatGPTMessage =
      chatGPTMessage +
      "ただし、フォーマットは下記としてください\n【女子A - 賛成】\n（発言内容）、（発言内容）\n【女子B - 反対】\n（発言内容）、（発言内容）\n【女子A - 賛成】\n（発言内容）、（発言内容）\n【女子B - 反対】\n（発言内容）、（発言内容）";
    setFourTextShow(chatGPTMessage);
    setMovieStage({ ...movieStage, 2: "完" });

    //自動生成フラグ
    if (autoCreateMovie.rapInputCreate) {
      setAutoCreateMovie({
        ...autoCreateMovie,
        rapInputCreate: false,
      });
      //useEffectが動く
    }

    return chatGPTMessage;
  } catch (error) {
    alert(error + "\nエラー#0006、二度と押さないでください");
    stopAuto();
    return false;
  }
};

export const createRap = async (
  chatGPTMessage,
  setFiveTextShow,
  setMovieStage,
  autoCreateMovie,
  setAutoCreateMovie,
  stopAuto,
  movieStage
) => {
  setFiveTextShow("作成中");
  try {
    const functions = getFunctions();
    const messageCreate = httpsCallable(functions, "messageCreate");
    messageCreate({ message: chatGPTMessage }).then((result) => {
      if (result.data) {
        setFiveTextShow(result.data.aimessage);
        setMovieStage({ ...movieStage, 3: "完" });

        //自動生成フラグ
        if (autoCreateMovie.rapCreate) {
          setAutoCreateMovie({
            ...autoCreateMovie,
            rapCreate: false,
          });
          //useEffectが動く
        }

        return result.data.aimessage;
      } else {
        setFiveTextShow("エラー");
        return false;
      }
    });
  } catch (error) {
    alert(error + "\nエラー#0001、二度と押さないでください");
    stopAuto();
    setFiveTextShow("エラー");
    return false;
  }
};
export const createExplainvideoInput = async (
  nextEditDatas,
  editDatas,
  setMovieStage,
  setExplainvideoInput,
  stopAuto,
  movieStage
) => {
  let useEditDatas;

  if (nextEditDatas) {
    useEditDatas = nextEditDatas;
  } else {
    useEditDatas = editDatas;
  }
  //console.log(useEditDatas);
  try {
    let chatGPTMessage = "";
    chatGPTMessage = chatGPTMessage + useEditDatas.claim;
    chatGPTMessage = chatGPTMessage + "に対する賛成意見は";

    useEditDatas.infoagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "、「" + item.data.claim + "」";
    });
    chatGPTMessage = chatGPTMessage + "等がある．";

    chatGPTMessage = chatGPTMessage + useEditDatas.claim;
    chatGPTMessage = chatGPTMessage + "に対する反対意見は";

    useEditDatas.infodisagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "、「" + item.data.claim + "」";
    });
    chatGPTMessage = chatGPTMessage + "等がある．";
    chatGPTMessage =
      chatGPTMessage +
      "\n\nこれらを踏まえてかわいい女子大生みたいなツイートを100文字以内で作ってください．＃は記載しないでください．";
    chatGPTMessage =
      chatGPTMessage +
      "ただし，下記のような設定のキャラクターになりきって文章を作成してください．";
    chatGPTMessage = chatGPTMessage + "\n・人間の女の子";
    chatGPTMessage = chatGPTMessage + "\n・大学生";
    chatGPTMessage = chatGPTMessage + "\n・#は使わない";
    chatGPTMessage = chatGPTMessage + "\n・タグはつけない";
    chatGPTMessage = chatGPTMessage + "\n・ツイートは100文字";

    setMovieStage({ ...movieStage, 8: "完" });
    setExplainvideoInput(chatGPTMessage);

    return chatGPTMessage;
  } catch (error) {
    alert(error + "\nエラー#0002、二度と押さないでください");
    stopAuto();
    return false;
  }
};

export const createExplainvideo = async (
  chatGPTMessage,
  setExplainvideo,
  setMovieStage,
  createExplain,
  autoCreateMovie,
  setAutoCreateMovie,
  stopAuto,
  claim,
  explainvideoInput,
  lonpaid,
  movietypetext,
  movieStage
) => {
  // setFiveTextShow("作成中");
  setExplainvideo("作成中");
  try {
    const functions = getFunctions();
    const messageCreate = httpsCallable(functions, "messageCreate");
    messageCreate({ message: chatGPTMessage }).then((result) => {
      if (result.data) {
        // setFiveTextShow(result.data.aimessage);
        setMovieStage({ ...movieStage, 9: "完" });
        setExplainvideo(
          createExplain(result.data.aimessage, claim, lonpaid, movietypetext)
        );

        //自動生成フラグ
        if (autoCreateMovie.explainCreate) {
          setAutoCreateMovie({
            ...autoCreateMovie,
            explainCreate: false,
          });
          //useEffectが動く
        }
        return result.data.aimessage;
      } else {
        return false;
      }
    });
  } catch (error) {
    alert(error + "\nエラー#0003、二度と押さないでください");
    stopAuto();
    return false;
  }
};

export const createGen = async (
  message,
  setGenko,
  createIsMovieShort,
  setMovieStage,
  autoCreateMovie,
  setAutoCreateMovie,
  setIsShort,
  setMovieSetting,
  movieStage,
  nameDisagree,
  nameAgree,
  movieSetting
) => {
  try {
    let trimmessags = [];
    let agreeindexes = [];
    let disagreeindexes = [];
    let index = 0;
    let agreeindex = 0;
    let disagreeindex = 0;
    const debug = false;

    //A 1 B 1 A 2 B 2 A 3 B 3

    //■■■■■■■■■■■■■　文章整形　■■■■■■■■■■■■■■■
    //改行を「、」でつなぎ，「。」を削除する
    trimmessags.push(
      message.split("\n").join("、").split("。").join("").trim()
    ); // 整形

    //■■■■■■■■■■■■■　分割位置捜索と，先頭が区切れている配列を作る　■■■■■■■■■■■■■■■
    if (trimmessags[index].indexOf(nameDisagree)) {
      //■■■■■■■■■■■■■
      if (debug)
        console.log("agreeindex", trimmessags[index].indexOf(nameAgree));
      agreeindexes.push(trimmessags[index].indexOf(nameAgree)); // 何文字目か検索

      //■■■■■■■■■■■■■
      if (debug)
        console.log("disagreeindex", trimmessags[index].indexOf(nameDisagree));
      disagreeindexes.push(trimmessags[index].indexOf(nameDisagree)); // 何文字目か検索
    } else {
      throw new Error("意見が抽出できませんでした");
    }

    index = index + 1;
    agreeindex = agreeindex + 1;
    disagreeindex = disagreeindex + 1;

    for (let i = 0; i < 10; i++) {
      //■■■■■■■■■■■■■
      trimmessags.push(
        trimmessags[index - 1].slice(disagreeindexes[disagreeindex - 1]).trim()
      );
      //console.log(trimmessags[index]);

      if (debug) console.log(trimmessags[index].length);
      if (trimmessags[index].length <= 1) {
        break;
      }

      if (trimmessags[index].indexOf(nameAgree)) {
        if (debug)
          console.log("agreeindex", trimmessags[index].indexOf(nameAgree));
        agreeindexes.push(trimmessags[index].indexOf(nameAgree)); // 何文字目か検索
      } else {
        throw new Error("意見が抽出できませんでした");
      }
      index = index + 1;
      agreeindex = agreeindex + 1;

      //■■■■■■■■■■■■■
      trimmessags.push(
        trimmessags[index - 1].slice(agreeindexes[agreeindex - 1]).trim()
      );
      //console.log(trimmessags[index]);

      if (debug) console.log(trimmessags[index].length);
      if (trimmessags[index].length <= 1) {
        break;
      }
      if (trimmessags[index].indexOf(nameDisagree)) {
        if (debug)
          console.log(
            "disagreeindex",
            trimmessags[index].indexOf(nameDisagree)
          );
        disagreeindexes.push(trimmessags[index].indexOf(nameDisagree)); // 何文字目か検索
      } else {
        throw new Error("意見が抽出できませんでした");
      }
      index = index + 1;
      disagreeindex = disagreeindex + 1;
    }
    if (debug) console.log("agree", agreeindexes);
    if (debug) console.log("dis", disagreeindexes);
    if (debug)
      console.log(
        "-★------------------------------------------------------------"
      );

    //■■■■■■■■■■■■■　文章分割（賛成・反対レベルで末尾も切れているようにする）　■■■■■■■■■■■■■■■
    let agreelons = [];
    let disagreelons = [];

    //■■■■■■■■■■■■■
    if (debug)
      console.log(
        trimmessags[0].slice(agreeindexes[0], disagreeindexes[0]).trim()
      );
    agreelons.push(
      trimmessags[0].slice(agreeindexes[0], disagreeindexes[0]).trim()
    ); // 賛成部分１を分割

    for (let i = 1; i < 10; i++) {
      //■■■■■■■■■■■■■
      const trimnum = i * 2 - 1;
      if (debug) console.log("trimnum:", trimnum, "   i:", i);
      if (debug)
        console.log(
          "agree:",
          agreeindexes[i],
          "   disagree:",
          disagreeindexes[i]
        );
      try {
        if (disagreeindexes[i] == -1) {
          if (debug) console.log("スライスできなかったかな1");
          break;
        } else {
          if (agreeindexes[i] == -1) {
            if (debug) console.log("最後の分割");
            if (debug) console.log(trimmessags[trimnum]);
            disagreelons.push(trimmessags[trimnum]);
          } else {
            if (debug)
              console.log(
                trimmessags[trimnum].slice(0, agreeindexes[i]).trim()
              );
            disagreelons.push(
              trimmessags[trimnum].slice(0, agreeindexes[i]).trim()
            ); // 賛成部分２を分割
          }
        }
      } catch {
        //console.log("スライスできなかったかな");
        break;
      }

      if (debug) console.log("trimnum:", trimnum + 1, "   i:", i);
      if (debug)
        console.log(
          "agree:",
          agreeindexes[i],
          "   disagree:",
          disagreeindexes[i]
        );
      //■■■■■■■■■■■■■
      try {
        if (agreeindexes[i] == -1) {
          if (debug) console.log("スライスできなかったかな2");
          break;
        } else {
          if (agreeindexes[i] == -1) {
            if (debug) console.log("最後の分割");
            if (debug) console.log(trimmessags[trimnum + 1]);
            agreelons.push(trimmessags[trimnum + 1]); // 反対部分１を分割
          } else {
            if (debug)
              console.log(
                trimmessags[trimnum + 1].slice(0, disagreeindexes[i]).trim()
              );
            agreelons.push(
              trimmessags[trimnum + 1].slice(0, disagreeindexes[i]).trim()
            ); // 反対部分１を分割
          }
        }
      } catch {
        //console.log("スライスできなかったかな");
        break;
      }
    }
    //disagreelons.push(trimmessagefive.slice(disagreeindexthree).trim());

    let allgen = [];
    let allindex = 0;
    let strnum = 0;
    const shortMovieStrNum = 400;

    let agreeresults = [];
    let disagreeresults = [];
    if (debug)
      console.log(
        "-★★------------------------------------------------------------"
      );
    //■■■■■■■■■■■■■　文章の分割（カンマ区切りで文分割）　■■■■■■■■■■■■■■■

    for (let i = 0; i < 10; i++) {
      //console.log(i);
      try {
        agreeresults.push(agreelons[i].split("、")); // 「,」 カンマ区切りで分割して配列に格納
        agreeresults[i].splice(0, 1);
        agreeresults[i].map((item, index) => {
          if (item.trim()) {
            //console.log("agree", item.trim());
            strnum = strnum + item.trim().length + 4;
            allgen.push({
              index: allindex,
              vocal: "youmu",
              text: item.trim(),
              isShortMovie: strnum < shortMovieStrNum,
            });
            allindex = allindex + 1;
          }
        });
      } catch {
        //console.log("スライスできなかったかな");
        break;
      }
      try {
        disagreeresults.push(disagreelons[i].split("、")); // 「,」 カンマ区切りで分割して配列に格納
        disagreeresults[i].splice(0, 1);
        disagreeresults[i].map((item, index) => {
          if (item.trim()) {
            //console.log("disagree", item.trim());
            strnum = strnum + item.trim().length + 4;
            allgen.push({
              index: allindex,
              vocal: "reimu",
              text: item.trim(),
              isShortMovie: strnum < shortMovieStrNum,
            });
            allindex = allindex + 1;
          }
        });
      } catch {
        //console.log("スライスできなかったかな");
        break;
      }
    }

    setGenko(allgen);
    createIsMovieShort(
      strnum < shortMovieStrNum,
      setIsShort,
      movieSetting,
      setMovieSetting
    );
    setMovieStage({ ...movieStage, 4: "完" });
    //自動生成フラグ
    if (autoCreateMovie.genCreate) {
      setAutoCreateMovie({
        ...autoCreateMovie,
        genCreate: false,
      });
      //useEffectが動く
    }
    return allgen;
  } catch (error) {
    alert(
      error +
        "\nエラー#0010 文字列が想定した形式でないようです\n二度と押さないでください"
    );
    return false;
  }
};

export const createExplain = (inputtext, claim, lonpaid, movietypetext) => {
  let EText = "";
  EText = EText + "【AI vs AI】" + claim + "?\n";
  EText = EText + "------------------\n";
  EText = EText + inputtext;
  EText = EText + "\n";
  EText = EText + "\n";
  EText = EText + "#shorts #lonpa #lamact\n";
  EText = EText + "https://lonpa.net/Lonpa/" + lonpaid + "\n";
  EText = EText + "\n";
  EText = EText + "■ツール\n";
  EText = EText + "Remotion\n";
  EText = EText + "https://www.remotion.dev/\n";
  EText = EText + "\n";
  EText = EText + "AquesTalk10 ・ AqKanji2Koe\n";
  EText = EText + "https://www.a-quest.com/download.html\n";
  EText = EText + "\n";
  EText = EText + "立ち絵\n";
  EText = EText + "http://www.nicotalk.com/charasozai_yk.html\n";
  EText = EText + "\n";
  EText = EText + "BGM\n";
  EText = EText + movietypetext + "\n";
  EText = EText + "\n";
  return EText;
};

export const createMovieSettingText = (
  nextMovieSetting,
  lonpaid,
  setMovieSettingText,
  autoCreateMovie,
  setAutoCreateMovie
) => {
  let MSText = "movie,set1,set2,set3,set4,set5\n";
  MSText = MSText + lonpaid + ",";
  MSText =
    MSText +
    nextMovieSetting.set1 +
    "," +
    nextMovieSetting.set2 +
    "," +
    nextMovieSetting.set3 +
    "," +
    nextMovieSetting.set4 +
    "," +
    nextMovieSetting.set5;
  setMovieSettingText(MSText);

  if (autoCreateMovie.settingCreate) {
    setAutoCreateMovie({
      ...autoCreateMovie,
      settingCreate: false,
    });
    //useEffectが動く
  }
  return MSText;
};

//意見の文章をいい感じに改行するためのコード
export const maketext = (text, flag) => {
  if (text) {
    const locale = "ja-JP";

    const segmenter = new Intl.Segmenter(locale, { granularity: "word" });
    const splittedText = Array.from(segmenter.segment(text)).map(
      (s) => s.segment
    );

    let countwordlen = 0;
    let countlinelen = 1;
    let createText = "";
    splittedText.map((text) => {
      countwordlen = countwordlen + text.length;
      if (countwordlen > 8) {
        if (text.length == countwordlen) {
          createText = createText + text;
        } else {
          countwordlen = text.length;
          createText = createText + "\n" + text;
          countlinelen = countlinelen + 1;
        }
      } else {
        createText = createText + text;
      }
    });
    if (flag) {
      if (countlinelen < 3) {
        createText = "\n\n\n" + createText;
      } else if (countlinelen < 4) {
        createText = "\n\n" + createText;
      } else if (countlinelen < 5) {
        createText = "\n" + createText;
      }
    }

    return createText;
    //makeone(createText);
  }
};

export const createCsv = (
  inputgenko,
  setGenkocsv,
  setMovieStage,
  autoCreateMovie,
  setAutoCreateMovie,
  movieStage
) => {
  //console.log("csv作成");
  let csvDatas = "index,vocal,text\n";

  inputgenko.map((item) => {
    const csvOneData = item.index + "," + item.vocal + "," + item.text + "\n";
    csvDatas = csvDatas + csvOneData;
  });
  setGenkocsv(csvDatas);
  setMovieStage({ ...movieStage, 5: "完" });
  if (autoCreateMovie.csvCreate) {
    setAutoCreateMovie({
      ...autoCreateMovie,
      csvCreate: false,
    });
    //useEffectが動く
  }
  return csvDatas;
};

export const createMovie = (lonpaid) => {
  try {
    const functions = getFunctions();
    const movieCreate = httpsCallable(functions, "movieCreate");
    movieCreate({ lonpaID: lonpaid }).then((result) => {
      if (result.data) {
        alert(error + "\n作成成功おめでとう");
        console.log("成功");
        return true;
      } else {
        console.log("失敗");
        return false;
      }
    });
  } catch (error) {
    alert(error + "\nエラー#0004、二度と押さないでください");
    return false;
  }
};

export const createLog = () => {
  console.log("createlog");
  try {
    const functions = getFunctions();
    const logCreate = httpsCallable(functions, "logtest");
    logCreate().then((result) => {
      console.log("send");
      if (result.data) {
        alert(error + "\n作成成功おめでとう");
        console.log("成功");
        return true;
      } else {
        console.log("失敗");
        return false;
      }
    });
  } catch (error) {
    alert(error + "\nエラー#0005、二度と押さないでください");
    return false;
  }
};

import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  IconButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import FadeInReason from "./FadeInReason";
import ImageUp from "./ImageUp";

type Props = {
  bgColor: any;
  styletext: any;
  setIsStage1Finish: any;
  setIsStage2Finish: any;
  isBlue: boolean;
  info: any;
  choice: any;
  talkerpic1: any;
  talkerpic2: any;
};

const Stage3: React.VFC<Props> = ({
  bgColor,
  styletext,
  setIsStage1Finish,
  setIsStage2Finish,
  isBlue,
  info,
  choice,
  talkerpic1,
  talkerpic2,
}: Props) => {
  const navigate = useNavigate();
  const BlueButton2 = {
    fontSize: "20px",
    color: bgColor.text01.fontColor,
    width: "100%",
    height: "50px",
    fontWeight: "bold",
    // border: bgColor.line01,
    backgroundColor: bgColor.back03,
  };
  const RedButton2 = {
    fontSize: "20px",
    color: bgColor.text01.fontColor,
    width: "100%",
    height: "50px",
    fontWeight: "bold",
    // border: bgColor.line01,
    backgroundColor: bgColor.back02,
  };
  return (
    <div
      style={{
        width: styletext.windowPhoneWidth,
        left: 0,
        right: 0,
        marginLeft: "auto", // 左のマージンを自動に設定
        marginRight: "auto", // 右のマージンを自動に設定
        textAlign: "center", // テキストを中央揃えにする
      }}
    >
      <div
        style={{
          fontSize: "22px", // フォントサイズを小さくする
          fontWeight: "bold", // テキストを太字にする
        }}
      >
        あなたと同じ意見は{choice.sameAgree}人
        <br />
        同じ理由に、{choice.sameOpinion}人が賛同しています
        <br />
      </div>

      <div
        style={{
          fontSize: "12px", // フォントサイズを小さくする
        }}
      >
        「{choice.choiceText}」
      </div>

      <div
        style={{
          zIndex: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{ width: styletext.windowPhoneWidth, boxSizing: "border-box" }}
        >
          <div
            style={{
              position: "relative",
              maxWidth: "70%",
              marginLeft: !isBlue ? "10px" : "auto",
              marginRight: !isBlue ? "auto" : "10px",
              padding: "8px",
              backgroundColor: !isBlue ? bgColor.back03 : bgColor.back02,
              color: "#000000",
              borderRadius: "20px",
              textAlign: "center",
              lineHeight: "1.1em", // テキストの行の高さを2emに設定
              minHeight: "3.2em", // ここで最小高さを設定
              display: "flex", // Flexboxを使う
              alignItems: "center", // アイテムを垂直方向に中央に配置
              justifyContent: "center", // アイテムを水平方向に中央に配置
            }}
          >
            {choice.talkText}
            <div
              style={{
                content: '""',
                position: "absolute",
                bottom: "-25px", // 三角形の位置調整
                left: !isBlue ? "20%" : "80%",
                width: "0",
                height: "0",
                border: "15px solid transparent", // 三角形のサイズ調整
                borderTopColor: !isBlue ? bgColor.back03 : bgColor.back02, // 吹き出しと同じ色にする
              }}
            />
          </div>
        </Box>
      </div>

      <div
        style={{
          zIndex: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <table style={{ width: styletext.windowPhoneWidth }}>
          <tbody>
            <tr>
              <td
                style={{
                  position: "relative",
                  width: styletext.windowPhoneWidth * 0.45,
                }}
              >
                <img
                  style={{
                    width: "100%", // この変更により、画像はtdの幅に合わせて表示されます
                  }}
                  src={talkerpic1}
                  alt="img"
                />
              </td>
              <td style={{ width: styletext.windowPhoneWidth * 0.1 }}></td>
              <td
                style={{
                  position: "relative",
                  width: styletext.windowPhoneWidth * 0.45,
                }}
              >
                <img
                  style={{
                    width: "100%", // この変更により、画像はtdの幅に合わせて表示されます
                  }}
                  src={talkerpic2}
                  alt="img"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button
        style={{
          ...(isBlue ? BlueButton2 : RedButton2),
          position: "relative",
          width: styletext.windowPhoneWidth,
        }}
        onClick={() => {
          setIsStage2Finish(true);
          navigate("/Lonpa/" + info.id);
        }}
      >
        詳しく見る
      </Button>
      <br />
      <Button
        style={{
          ...(isBlue ? BlueButton2 : RedButton2),
          position: "relative",
          width: styletext.windowPhoneWidth,
        }}
        onClick={() => {
          setIsStage2Finish(true);
          navigate("/NewTop");
        }}
      >
        別の話題を見る
      </Button>
    </div>
  );
};
export default Stage3;

import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from "@mui/icons-material/Search";
import {
  List,
  Box,
  Grid,
  Container,
  Typography,
  Paper,
  Chip,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { where, limit } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import { useStyles } from "../Styles";
import { getDiscussWithQuery, get72Tag } from "../common/Helper/FirebaseHelper";
import { NORMAL_CHARACTER } from "../constants/regex";
import {
  VALIDATION_ERR_REQUIRED,
  VALIDAtION_INVALID_CHARACTER,
} from "../constants/validation";
import searchkeyicon from "../images/searchkey.png";
import searchtagicon from "../images/searchtag.png";
import tagpic01 from "../images/tagpic/tag01.jpg";
import tagpic02 from "../images/tagpic/tag02.jpg";
import tagpic03 from "../images/tagpic/tag03.jpg";
import tagpic04 from "../images/tagpic/tag04.jpg";
import tagpic05 from "../images/tagpic/tag05.jpg";
import tagpic06 from "../images/tagpic/tag06.jpg";
import tagpic07 from "../images/tagpic/tag07.jpg";
import tagpic08 from "../images/tagpic/tag08.jpg";
import tagpic09 from "../images/tagpic/tag09.jpg";
import tagpic10 from "../images/tagpic/tag10.jpg";
import tagpic11 from "../images/tagpic/tag11.jpg";
import tagpic12 from "../images/tagpic/tag12.jpg";

import SearchItem from "./Search/SearchItem";
import Footer from "./components/Footer";

const schema = yup.object({
  searchText: yup
    .string()
    .required(VALIDATION_ERR_REQUIRED)
    .matches(NORMAL_CHARACTER, VALIDAtION_INVALID_CHARACTER),
});

SearchItem;

type Props = {
  bgColor: any;
  styletext: any;
  searchword: string;
};

const NewSearch: React.VFC<Props> = ({
  bgColor,
  styletext,
  searchword,
}: Props) => {
  const { word } = useParams();
  const classes = useStyles();
  const [idealist, setIdealist] = useState<any[]>([]);
  const navigate = useNavigate();
  const [searchtext, setSearchtext] = useState("");
  const [errortext, setErrortext] = useState("");
  //キーワードの選択状態
  const [searchbuttonshow, setSearchbuttonshow] = useState<any[]>([]);
  //URLに入っているキーワード
  const [urlsearchword, setUrlsearchword] = useState<string[]>([]);
  const [choice, setChoice] = useState(1);
  const [isOpenSearchBox, setIsOpenSearchBox] = useState(true);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      searchText: "",
    },
  });

  const datalists = [
    { text: "仕事", pic: tagpic01 },
    { text: "飲み物", pic: tagpic02 },
    { text: "AI", pic: tagpic03 },
    { text: "ゲーム", pic: tagpic04 },
    { text: "健康", pic: tagpic05 },
    { text: "食べ物", pic: tagpic06 },
    { text: "結婚", pic: tagpic07 },
    { text: "生活", pic: tagpic08 },
    { text: "恋愛", pic: tagpic09 },
    { text: "食事", pic: tagpic10 },
    { text: "人生", pic: tagpic11 },
    { text: "法律", pic: tagpic12 },
  ];

  const categoryButton = {
    fontSize: "9px",
    color: bgColor.text01.fontColor,
    width: "90%",
    fontWeight: "bold",
    border: bgColor.line01,
    marginLeft: "5%",
  };

  const categoryButtonChoiced = {
    fontSize: "9px",
    color: bgColor.text01.fontColor,
    width: "90%",
    fontWeight: "bold",
    border: bgColor.line02,
    marginLeft: "5%",
  };

  const settingButton = {
    fontSize: "10px",
    color: bgColor.text01.fontColor,
    width: "100%",
    fontWeight: "bold",
    marginLeft: "23px", // 左のマージンを10pxに設定
    border: bgColor.line01,
  };

  //MUIのボタンのUI設定
  const customTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          outlined: {
            backgroundColor: "#efefef",
          },
        },
      },
    },
  });
  //検索結果のLonpaをクリックした際に動作、Lonpaページへ遷移する
  const ChoiceData = (id: string, title: string) => {
    //if (searchtagword === 'useurl') {
    window.scrollTo(0, 0);
    navigate("/Discuss/" + id);
    //} else {
    //Lonpaモーダルの検索の名残、不要なので削除
    //ConnectLonpa(id, title);
    //}
  };
  const ChoiceDataNewTab = (id: string, title: string) => {
    const url = "https://lonpa.net/Lonpa/" + id;
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  //契機；タグ検索のボタン押下時に動作
  //内容：選択したタグの内容で、URLの記載を更新する
  const handleClick = (searchitem: any) => {
    if (searchitem.text !== null) {
      if (searchitem.isnowsearch) {
        //選択済みのタグ（URLに含まれている）のボタンを押下した場合は
        //そのタグを旧URLから削除する
        const urlsearchword_deletebuttonword = urlsearchword.filter(
          (output) => {
            if (output !== "tag=" + searchitem.text) return output;
          }
        );
        //listデータをstringデータに変換
        const stringData = urlsearchword_deletebuttonword.reduce(
          (result, item) => {
            return `${result}${item},`;
          },
          ""
        );
        //キーワードをURLから削除したものを真URLとして遷移する
        setSearchtext("");
        navigate("/NewSearch/" + stringData);
      } else {
        //未選択のキーワードの場合
        if (word === "empty" || word === undefined) {
          //元のURLが空白または、empty指定の場合は、選択したキーワードのみ記載したものを真URLとして遷移する
          setSearchtext("tag=" + searchitem.text);
          navigate("/NewSearch/tag=" + searchitem.text);
        } else {
          //元のURLに記載がある場合、その後ろに選択したワードを追加する
          setSearchtext("tag=" + searchitem.text);
          navigate("/NewSearch/tag=" + searchitem.text);
        }
      }
    }
  };

  //契機：キーワード検索の検索ボタン押下もしくはエンターキー押下
  //内容：入力した検索内容で、URLを更新する
  const handleTextchange = (searchtext: any) => {
    if (searchtext.length >= 2) {
      //文字数が２文字以上の場合
      setErrortext("");
      if (word === "empty" || word === undefined) {
        //元のURLが空白または、empty指定の場合は、入力内容のみ記載したものを真URLとして遷移する
        navigate("/NewSearch/" + searchtext);
      } else {
        //元のURLに記載がある場合、その後ろに入力したワードを追加する
        navigate("/NewSearch/" + searchtext);
      }
    } else {
      //文字数が２文字以下の場合、今回ngramのn=2なので検索できない
      setErrortext("2文字以上入力してください");
    }
  };

  //契機：UseEffect動作時、検索クエリが存在した場合
  //内容：クエリの内容で、firebaseに検索をかける
  async function setIdealistByQueryFireStore(
    urlsearchword: any,
    setIdealist: any,
    Searchkind: string
  ) {
    let querySnapshot;
    let results;
    const queryParams = [];
    let queryparam;
    const idealist: any[] = [];
    const ideaidlist: any[] = [];

    if (Searchkind == "free") {
      for (const i in urlsearchword) {
        if (urlsearchword[i]) {
          //ngram化 n=2
          for (let j = 0; j < urlsearchword[i].length - 1; j++) {
            results = [urlsearchword[i].substr(j, 2)];
            queryparam = results.map((result) => {
              return where(`ngram.${result}`, "==", true);
            });
            queryParams.push(queryparam[0]);
          }
        }
      }
      queryParams.push(limit(10));
      querySnapshot = await getDiscussWithQuery(queryParams);
      //querySnapshot = await getDocs(query(src, ...queryParams))
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        if (!doc.data().isDelete) {
          if (doc.data().imageurl === "" || doc.data().imageurl === undefined) {
            idealist.push({
              id: doc.id,
              title: doc.data().claim,
              agree: doc.data().agree,
              disagree: doc.data().disagree,
              taghit: false,
              icon: searchtagicon,
              disagreeratio:
                (doc.data().disagree /
                  (doc.data().agree + doc.data().disagree)) *
                100,
            });
          } else {
            idealist.push({
              id: doc.id,
              title: doc.data().claim,
              agree: doc.data().agree,
              disagree: doc.data().disagree,
              taghit: false,
              icon: doc.data().imageurl,
              disagreeratio:
                (doc.data().disagree /
                  (doc.data().agree + doc.data().disagree)) *
                100,
            });
          }

          ideaidlist.push(doc.id);
        }
      });
    }

    //最後に、tag検索で入力されたもの、もしくはキーワード検索で入力されたものを
    //tagとして登録されていないかを検索し結果を追記する。
    // const tagqueryParams = [];
    //const Searchtag = urlsearchword[0].replace("tag=", "");
    const Searchtag = [...urlsearchword].map((v) => v.replace("tag=", ""));
    const tagqueryParams = [...Searchtag].map((v) =>
      where(`tag.${v}`, "==", true)
    );
    tagqueryParams.push(limit(10));
    // console.log(tagqueryParams);
    // const tagquerySnapshot = await getLonpaWithQuery(tagqueryParams);
    const tagquerySnapshot = await getDiscussWithQuery(tagqueryParams);
    tagquerySnapshot.forEach((doc: any) => {
      // console.log(doc.data());
      if (!doc.data().isDelete) {
        if (ideaidlist.includes(doc.id)) {
          //自由検索とタグ検索両方引っかかったら，追加しない
        } else {
          if (doc.data().imageurl == "" || doc.data().imageurl === undefined) {
            idealist.push({
              id: doc.id,
              title: doc.data().claim,
              agree: doc.data().agree,
              disagree: doc.data().disagree,
              taghit: true,
              icon: searchkeyicon,
              disagreeratio:
                (doc.data().disagree /
                  (doc.data().agree + doc.data().disagree)) *
                100,
            });
          } else {
            idealist.push({
              id: doc.id,
              title: doc.data().claim,
              agree: doc.data().agree,
              disagree: doc.data().disagree,
              taghit: true,
              icon: doc.data().imageurl,
              disagreeratio:
                (doc.data().disagree /
                  (doc.data().agree + doc.data().disagree)) *
                100,
            });
          }
        }
      }
    });
    //ランダムシャッフル
    for (let i = idealist.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = idealist[i];
      idealist[i] = idealist[j];
      idealist[j] = temp;
    }
    setIdealist(idealist);
  }

  //契機：URLのwordが更新されたタイミング
  //内容：画面表示内容を変更する
  useEffect(() => {
    console.log("uE_Search");
    const receivedSearchWord = (() => {
      if (word !== null && word !== undefined && word !== "") {
        return word;
      } else {
        return "empty";
      }
    })();

    const urlsearchword = receivedSearchWord.split(",");

    const f = async () => {
      if (receivedSearchWord !== "empty" && receivedSearchWord !== null) {
        //検索クエリに内容があった場合は、tag検索かキーワード検索かを判別し、その内容でfirebaseに検索をかける
        const Searchkind = (() => {
          if (receivedSearchWord.includes("tag=")) {
            return "tag";
          } else {
            return "free";
          }
        })();
        setIdealistByQueryFireStore(urlsearchword, setIdealist, Searchkind);
      } else {
        setIdealist([]);
      }

      setUrlsearchword(urlsearchword);
    };
    f();
  }, [word]);

  async function getTagList() {
    //画面に表示する検索ボタンの表示内容を決定する
    //URLから取得した検索単語について，キーワード検索のボタンの内容と一致しているものが存在するかどうか確認
    const buttonincludecheck: any[] = [];

    if (searchbuttonshow.length === 0) {
      //タグランキングから上位12個取ってくる
      const searchtagwordlist = await get72Tag();
      searchtagwordlist.forEach((doc) => {
        if (
          urlsearchword.includes("tag=" + doc.data().name) ||
          urlsearchword.includes(doc.data().name)
        ) {
          //キーワード検索のボタン１つについて，URL内に一致しているものが存在する場合
          buttonincludecheck.push({
            text: doc.data().name,
            isnowsearch: true,
            variant: "outlined",
            count: doc.data().count,
          });
        } else {
          //キーワード検索のボタン１つについて，URL内に一致しているものが存在しない場合
          buttonincludecheck.push({
            text: doc.data().name,
            isnowsearch: false,
            variant: "filled",
            count: doc.data().count,
          });
        }
      });
      setSearchbuttonshow(buttonincludecheck);
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor: bgColor.back01,
          color: bgColor.text01.fontColor,
        }}
      >
        <div style={{ height: "3vh" }}></div>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <div style={{ height: "1vh" }}></div>
            {isOpenSearchBox ? (
              <>
                <Grid item xs={12}>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                    style={{
                      boxShadow: "0 0 3px ",
                      backgroundColor: bgColor.back01,
                      color: bgColor.text01.fontColor,
                    }}
                  >
                    <Controller
                      control={control}
                      name="searchText"
                      render={({ field: { onChange } }) => (
                        <InputBase
                          sx={{
                            ml: 1,
                            flex: 1,
                            input: {
                              color: bgColor.text04.fontColor,
                            },
                          }}
                          placeholder="検索"
                          inputProps={{ "aria-label": "検索" }}
                          value={searchtext}
                          onChange={(e) => {
                            onChange(e);
                            setSearchtext(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key == "Enter") {
                              e.preventDefault();
                              handleSubmit(() => handleTextchange(searchtext));
                              setIsOpenSearchBox(false);
                            }
                          }}
                        />
                      )}
                    />
                    <IconButton
                      sx={{ p: "10px" }}
                      aria-label="search"
                      style={{ color: bgColor.text01.fontColor }}
                      onClick={handleSubmit(() => {
                        handleTextchange(searchtext);
                        setIsOpenSearchBox(false);
                      })}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  {errortext}
                  {errors.searchText?.message}

                  <div style={{ height: "3vh" }}></div>

                  {choice == 0 ? (
                    <>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        style={{ color: bgColor.text01.fontColor }}
                      >
                        <Grid
                          xs={6}
                          key={"foryou"}
                          style={{
                            paddingLeft: "2px",
                          }}
                        >
                          <Button
                            color="primary"
                            onClick={() => {
                              setChoice(1);
                            }}
                            style={categoryButton}
                          >
                            オススメ
                          </Button>
                        </Grid>
                        <Grid
                          xs={6}
                          key={"trend"}
                          style={{
                            paddingLeft: "2px",
                          }}
                        >
                          <Button
                            color="primary"
                            onClick={() => {
                              setChoice(0);
                            }}
                            style={categoryButtonChoiced}
                          >
                            トレンド
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        style={{ color: bgColor.text01.fontColor }}
                      >
                        <Grid
                          xs={6}
                          key={"foryou"}
                          style={{
                            paddingLeft: "2px",
                          }}
                        >
                          <Button
                            color="primary"
                            onClick={() => {
                              setChoice(1);
                            }}
                            style={categoryButtonChoiced}
                          >
                            オススメ
                          </Button>
                        </Grid>
                        <Grid
                          xs={6}
                          key={"trend"}
                          style={{
                            paddingLeft: "2px",
                          }}
                        >
                          <Button
                            color="primary"
                            onClick={() => {
                              setChoice(0);
                              getTagList();
                            }}
                            style={categoryButton}
                          >
                            トレンド
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <div style={{ height: "2vh" }}></div>

                  {choice == 0 && !(searchbuttonshow.length === 0) ? (
                    <Grid container spacing={1}>
                      {searchbuttonshow.map((item, index) => (
                        <Grid
                          item
                          xs={styletext.stickernum}
                          key={"#" + item.text}
                          style={{
                            paddingLeft: "2px",
                          }}
                        >
                          {/* <ThemeProvider theme={customTheme}> */}
                          <Chip
                            variant={item.variant}
                            onClick={() => {
                              handleClick(item);
                              setIsOpenSearchBox(false);
                              window.scrollTo(0, 0);
                            }}
                            label={"# " + item.text}
                            style={{
                              width: "100%",
                              backgroundColor: bgColor.back04,
                              color: bgColor.text04.fontColor,

                              position: "relative",
                              display: "flex", // flexboxを使用して中央寄せを実現
                              justifyContent: "center", // 水平方向の中央寄せ
                              alignItems: "center", // 垂直方向の中央寄せ
                            }}
                          ></Chip>
                          {/* </ThemeProvider> */}
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid container spacing={1}>
                      {datalists.map((item, index) => (
                        <Grid
                          item
                          xs={styletext.stickernum}
                          key={"#" + item.text}
                          style={{
                            padding: "4px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              handleClick(item);
                              setIsOpenSearchBox(false);
                              window.scrollTo(0, 0);
                            }}
                            style={{
                              width: "100%",
                              minHeight: "105px",
                              backgroundColor: bgColor.back04,
                              color: bgColor.text04.fontColor,

                              position: "relative",
                              display: "flex", // flexboxを使用して中央寄せを実現
                              justifyContent: "center", // 水平方向の中央寄せ
                              alignItems: "center", // 垂直方向の中央寄せ
                              borderRadius: "50%", // 丸く切り抜く
                              overflow: "hidden", // 不要な要素を切り捨てる
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block", // コンテナをインラインブロック要素として表示
                              }}
                            >
                              <img
                                src={item.pic}
                                width="100%"
                                height="100%"
                                alt="画像"
                                style={{
                                  borderRadius: "50%", // 画像も丸く切り抜く
                                  objectFit: "cover", // 画像を親要素にクリッピング
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%", // コンテナの上から50%の位置
                                  left: "50%", // コンテナの左から50%の位置
                                  transform: "translate(-50%, -50%)", // 中央に配置
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: bgColor.opacity01, // 半透明の背景色
                                  borderRadius: "50%", // 画像も丸く切り抜く
                                }}
                              ></div>
                              <div
                                style={{
                                  position: "absolute",
                                  minWidth: "80px",
                                  top: "50%", // コンテナの上から50%の位置
                                  left: "50%", // コンテナの左から50%の位置
                                  transform: "translate(-50%, -50%)", // 中央に配置
                                  color: bgColor.text04.fontColor, // テキストの色
                                  backgroundColor: bgColor.opacity01, // 半透明の背景色
                                  fontWeight: "bold",
                                  borderRadius: "50%", // 画像も丸く切り抜く
                                }}
                              >
                                {item.text}
                              </div>
                            </div>
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  <div style={{ height: "1vh" }}></div>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                    style={{
                      boxShadow: "0 0 3px ",
                      backgroundColor: bgColor.back01,
                      color: bgColor.text01.fontColor,
                    }}
                  >
                    <Controller
                      control={control}
                      name="searchText"
                      render={({ field: { onChange } }) => (
                        <InputBase
                          sx={{
                            ml: 1,
                            flex: 1,
                            input: {
                              color: bgColor.text04.fontColor,
                            },
                          }}
                          placeholder="検索"
                          inputProps={{ "aria-label": "検索" }}
                          value={searchtext}
                          onChange={(e) => {
                            onChange(e);
                            setSearchtext(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (e.key == "Enter") {
                              e.preventDefault();
                              handleSubmit(() => handleTextchange(searchtext));
                            }
                          }}
                        />
                      )}
                    />
                    <IconButton
                      sx={{ p: "10px" }}
                      aria-label="search"
                      style={{ color: bgColor.text01.fontColor }}
                      onClick={handleSubmit(() => {
                        handleTextchange(searchtext);
                      })}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Grid>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsOpenSearchBox(true);
                  }}
                  style={settingButton}
                >
                  # タグ検索
                </Button>

                <Grid item xs={12}>
                  <Typography style={{ fontSize: "12px" }}>
                    検索結果 {idealist.length}件
                  </Typography>

                  <List sx={{ width: "100%" }}>
                    {/* <div align={"left"}>{urlsearchword}</div> */}
                    {idealist.map((idea, index) => (
                      <div
                        onClick={() => {
                          ChoiceData(idea.id, idea.title);
                        }}
                        onAuxClick={() => {
                          ChoiceDataNewTab(idea.id, idea.claim);
                        }}
                        key={idea.id + "_search"}
                        style={{
                          backgroundColor: bgColor.back01,
                          border: bgColor.line01,
                        }}
                      >
                        <SearchItem
                          bgColor={bgColor}
                          idea={idea}
                          text={"test"}
                        />
                      </div>
                    ))}

                    <div style={{ height: "6vh" }}></div>
                  </List>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </div>
      <Footer bgColor={bgColor} styletext={styletext} isimgshow={true} />
    </>
  );
};
export default NewSearch;
